<template>
  <v-popover
    :class="attachedToBody ? '' : 'dd-wrapper'"
    :popoverBaseClass="popoverBaseClass"
    :popoverClass="['dd', {'no-padding': noPadding}]"
    :popoverArrowClass="[{'no-arrow': !hasArrow, 'tooltip-arrow': hasArrow}]"
    :placement="placement"
    :container="attachedToBody ? 'body' : false"
    :offset="offset"
    :disabled="disabled"
    :trigger="trigger"
    @show="$emit('show')"
    @hide="$emit('hide')"
    @apply-hide="$emit('applyHide')"
  >
    <!-- Dropdown menu toggle button -->
    <slot name="button">
      <link-button icon="fa-cog" :disabled="disabled" />
    </slot>
    <!-- Dropdown menu contents -->
    <template slot="popover">
      <div v-if="closeOnClick" v-close-popover>
        <slot />
      </div>
      <div v-else>
        <slot />
      </div>
    </template>
  </v-popover>
</template>

<script>
import LinkButton from '@/components/shared/form/LinkButton'

export default {
  name: 'dropdown',
  components: {
    LinkButton
  },
  props: {
    trigger: {
      type: String,
      default: 'click'
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    hasArrow: {
      type: Boolean,
      default: true
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    offset: {
      type: Number,
      default: 0
    },
    attachedToBody: {
      type: Boolean,
      default: false
    },
    extraBaseClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    popoverBaseClass () {
      if (this.attachedToBody) {
        return `tooltip dd-wrapper ${this.extraBaseClass}`
      }
      return `tooltip ${this.extraBaseClass}`
    }
  }
}
</script>
