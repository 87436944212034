import Vue from 'vue'

const ENDPOINT = '/users'

export default {
  update (id, user) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}`, { user: user }).then(response => response.data)
  },
  updateAvatar (id, avatar) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/avatar`, { user: avatar }).then(response => response.data)
  },
  deleteAvatar (id) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/avatar`).then(response => response.data)
  },
  getSettings (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/settings`).then(response => response.data)
  },
  updateSettings (id, settings) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/settings`, { user_settings: settings }).then(response => response.data)
  },
  getBookmarkFolders (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/bookmark_folders`).then(response => response.data)
  },
  getInvites (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/invites`).then(response => response.data)
  },
  acceptInvite (id, inviteId) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/invites/${inviteId}`).then(response => response.data)
  },
  declineInvite (id, inviteId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/invites/${inviteId}`).then(response => response.data)
  },
  getInviteRequest (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/invite_requests`).then(response => response.data)
  },
  createInviteRequest (id, invite) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/invite_requests`, { invite_request: invite }).then(response => response.data)
  },
  resendInviteRequest (id, inviteId) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/invite_requests/${inviteId}`).then(response => response.data)
  },
  cancelInviteRequest (id, inviteId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/invite_requests/${inviteId}`).then(response => response.data)
  },
  importData (id, data) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/import_data`, { data: data }).then(response => response.data)
  },
  getSecondaryEmails (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/secondary_emails`).then(response => response.data)
  },
  createSecondaryEmail (id, email) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/secondary_emails`, { email_address: email }).then(response => response.data)
  },
  deleteSecondaryEmail (id, idEmail) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/secondary_emails/${idEmail}`).then(response => response.data)
  },
  updateSecondaryEmail (id, idEmail) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/secondary_emails/${idEmail}`).then(response => response.data)
  },
  resendSecondaryEmail (id, idEmail) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/secondary_emails/${idEmail}/confirmation`).then(response => response.data)
  },
  confirmSecondaryEmail (id, token) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/secondary_emails/${token}/confirmation`).then(response => response.data)
  },
  getOrganisationRequests (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/organisation_requests`).then(response => response.data)
  },
  getOrganisationRequestsByEmail (id, email) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/organisation_requests`, { email: email }).then(response => response.data)
  },
  acceptOrganisationRequest (id, requestId) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/organisation_requests/${requestId}`).then(response => response.data)
  },
  declineOrganisationRequest (id, requestId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/organisation_requests/${requestId}`).then(response => response.data)
  },
  changeCategory (id, category) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/category`, { user: { category_id: category } }).then(response => response.data)
  },
  updateTooltip (id, tooltip) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/tooltips`, { tooltips: tooltip }).then(response => response.data)
  },
  getSaveSearches (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/searches`).then(response => response.data)
  },
  saveSearch (id, search) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/searches`, { search: search }).then(response => response.data)
  },
  deleteSavedSearch (id, search) {
    return Vue.prototype.$httpSkip404.delete(`${ENDPOINT}/${id}/searches/${search}`).then(response => response.data)
  },
  getOnboardingFlow (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/onboarding_interests`).then(response => response.data)
  },
  updateOnBoardingFlow (id, params) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/onboarding_interests`, { onboarding_interest: params }).then(response => response.data)
  },
  getOpportunities (id, limit) {
    let endpoint = `${ENDPOINT}/${id}/opportunities`
    if (limit) endpoint += `?limit=${limit}`
    return Vue.prototype.$http.get(endpoint).then(response => response.data)
  },
  validateCoupon (email, couponCode) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_FLASK_URL}/api/coupons/validate`, {
      coupon_code: couponCode,
      email: email
    }).then(response => response.data)
  },
  setCoupon (id, coupon) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/coupons`, {
      user: { coupon_code: coupon }
    }).then(response => response.data)
  },
  inviteToAL (email) {
    return Vue.prototype.$http.post('/invite_colleague', { email }).then(response => {
      return response.data
    })
  }
}
