<template>
  <div class="position-relative" v-click-outside="hide">
    <a @click.prevent="show()" href="#" class="d-block block-fa clearfix width--100per bg-grey px-3 py-2 bd-radius bd-x bd-y" :class="{'open': isOpen}">
      <i class="fa fa-plus t-sm pr-3 t-secondary"></i>Add research group to community
      <i class="t-black pt-1 fa pull-right" :class="{ 'fa-angle-down': !isOpen, 'fa-angle-up': isOpen }"></i>
    </a>

    <div v-if="isOpen" class="dropdown-menu-custom--left--top-fix arrow-top-right px-2 py-2 box-shadow" aria-labelledby="invite-research_group-create">
      <div v-for="(community, index) in communities" :key="index">
        <a @click.prevent="selected(community, index)" href="#" class="dropdown-item clearfix bd-radius px-2">
          <i v-if="community.isSelected" class="fa fa-circle t-xs pr-1 t-green"></i>
          <span class="pl-1 t-600 t-overflow">{{ community.name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import CommunityService from '@/services/community'

export default {
  name: 'inviteButtonCommunityCreate',
  props: {
    communities: {
      type: Array,
      required: true
    },
    researchGroup: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    hide () {
      this.isOpen = false
    },
    show () {
      !this.isOpen ? this.isOpen = true : this.isOpen = false
    },
    selected (community, index) {
      this.$emit('selected', community, index)
    }
  }
}
</script>
