export default {
  install (Vue) {
    Vue.prototype.$alIntercom = {
      initialize (user) {
        if (this.canSeeIntercom() && user && Vue.prototype.$auth.isValidToken()) {
          if (Vue.prototype.$currentUser.intercom) {
            Vue.prototype.$intercom.boot({
              user_id: user.user_id,
              name: user.full_name,
              email: user.email,
              user_hash: Vue.prototype.$currentUser.intercom
            })
          } else {
            setTimeout(() => {
              this.initialize(user)
            }, 2500)
          }
        } else {
          Vue.prototype.$intercom.boot()
        }
      },
      show () {
        if (this.canSeeIntercom()) {
          Vue.prototype.$intercom.show()
        }
      },
      shutdown () {
        if (this.canSeeIntercom()) {
          Vue.prototype.$intercom.shutdown()
        }
      },
      startTourOnSearch () {
        if (this.canSeeIntercom()) Vue.prototype.$intercom.startTour('99895')
      },
      canSeeIntercom () {
        return true
        // return !!(Vue.prototype.$currentUser && !Vue.prototype.$currentUser.email.toLowerCase().includes('academiclabs'))
      }
    }
  }
}
