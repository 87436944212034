<template>
  <div class="">
    <!-- show about -->
    <template v-if="scope === 'researchInterests'">
      <!-- render research-interest -->
      <research-interest :isLoaded="true"
        :researcher="researcher"
        :isKeywordsLoaded="true"
        :keywords="[]"
        :canEdit="true"
        :isEdit="true"
        :containerClass="`bd-none pt-0 pb-5 px-0 min-height--300px`"
        @cancelled="$events.$emit('modal:close')">
      </research-interest>

      <!-- cancel -->
      <p class="mt-3 mb-0">
        <a href="#" @click.prevent="$events.$emit('modal:close')" class="btn btn-secondary--grey mx-auto t-sm pull-left">
          Close
        </a>
      </p>
    </template>

    <!-- show about -->
    <template v-if="scope === 'about'">
      <biography :researcher="researcher"
        :isLoaded="true"
        :canEdit="true"
        :isEdit="true"
        :containerClass="`bd-none p-reset`"
        @cancelled="$events.$emit('modal:close')">
      </biography>

      <!-- cancel -->
      <p class="mt-3 mb-0">
        <a href="#" @click.prevent="$events.$emit('modal:close')" class="btn btn-secondary--grey mx-auto t-sm pull-left">
          Close
        </a>
      </p>
    </template>

    <!-- import user data -->
    <template v-if="scope === 'social'">
      <h3 class="text-center t-700 mb-4">Enrich your profile automatically by importing data from your existing profiles.</h3>
      <import :user="user"></import>

      <!-- cancel -->
      <p class="mb-0 mt-4">
        <a href="#" @click.prevent="$events.$emit('modal:close')" class="btn btn-secondary--grey mx-auto t-sm pull-right">
          Close
        </a>
      </p>
    </template>

    <!-- experiences -->
    <template v-if="scope === 'experiences'">
      <experience :researcher="researcher"
        :canEdit="true"
        :isLoaded="true"
        :isEdit="true"
        :containerClass="`bd-none clearfix p-reset`"
        @cancelled="$events.$emit('modal:close')">
      </experience>

      <!-- cancel -->
      <p class="mt-3 mb-0">
        <a href="#" @click.prevent="$events.$emit('modal:close')" class="btn btn-secondary--grey mx-auto t-sm pull-right">
          Close
        </a>
      </p>
    </template>
  </div>
</template>

<script>
import Biography from '@/components/researcher/show/Biography'
import Experience from '@/components/researcher/show/Experience'
import ResearchInterest from '@/components/researcher/show/ResearchInterest'
import Import from '@/components/user/Import'

export default {
  name: 'editSingle',
  components: {
    Biography,
    Experience,
    Import,
    ResearchInterest
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    researcher: {
      type: Object,
      required: true
    },
    scope: {
      type: String,
      required: true
    }
  }
}
</script>
