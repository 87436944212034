import Vue from 'vue'

const ENDPOINT = '/research_types'
const RESEARCH_GROUP_TYPES = '/research_group_types'

export default {
  get () {
    return Vue.prototype.$http.get(`${ENDPOINT}`).then(response => response.data)
  },
  getAllForResearchGroup () {
    return Vue.prototype.$http.get(`${RESEARCH_GROUP_TYPES}`).then(response => response.data)
  },
  getForResearchGroup (type) {
    return Vue.prototype.$http.get(`${RESEARCH_GROUP_TYPES}?category=${type}`).then(response => response.data)
  }
}
