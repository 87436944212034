  <template>
  <div class="">
    <h2 class="t-primary t-400 mb-0">Edit your contact details</h2>
    <hr class="hr-small mb-4"/>

    <!-- render static messages -->
    <static-messages v-if="success" :messages="success" :variant="`success`" :delay="6000" @closed="success = null"/>
    <static-messages v-if="error" :messages="error" :variant="`danger`" :delay="6000" @closed="error = null"/>

    <div v-if="!isEmpty(contactInfo) &&  isLoadedContactTypes">
      <ul v-for="(info, key) in contactInfo" :key="key" class="mb-0 list-unstyled clearfix">
        <li class="list-item clearfix contact">
          <span v-if="info.contact_type.id === 'telephone' || info.contact_type.id === 'mobile-phone'">
            <i class="fa fa-phone t-secondary t-xs width--25px pr-2"></i>
            <a :href="`tel:${info.value}`" target="_blank">{{ info.value }}</a>
          </span>

          <span v-else>
            <i class="fa fa-link t-secondary t-xs width--25px pr-2 "></i>
            <a :href="info.value | formatWebsite" ref="website" target="_blank" class="t-overflow">{{ info.value }}</a>
          </span>

          <div class="pull-right">
            <a href="#" @click.prevent="openEdit(info)">
              Update
            </a>
            <span> - </span>
            <a href="#" @click.prevent="handleRemove(info, key)">
              <i class="fa fa-trash t-sm is-hover--red"></i>
            </a>
          </div>
        </li>
      </ul>
    </div>

    <!-- render spinner if not loaded -->
    <spinner v-if="!isLoaded || !isLoadedContactTypes" :containerClass="`width--50px min-height--150px`" />

    <div v-if="!isCreateMode && !isEditMode" class="row mt-4">
      <div class="col-md-12">
        <a href="#" @click.prevent="openCreate()"><i class="fa fa-plus-circle t-blue pr-2"></i>Add another contact type</a>
        or
        <a href="#" @click.prevent="openEmail()">add a secondary email address</a>
      </div>
    </div>

    <form v-if="isCreateMode || isEditMode" @submit.prevent="validateBeforeSubmit" class="mt-4" autocomplete="off">
      <div class="form-group row">
        <div class="col-md-6">
          <label class="t-500 t-sm">
            Choose your contact type
          </label>
          <label class="select-label">
            <select class="form-control" v-model="contactItem.contact_type_id" @change="handleContactChanged(contactItem)">
              <option v-for="(contactType, key) in contactTypes" :key="key" :value="contactType.id" class="t-sm">
                {{ contactType.name }}
              </option>
            </select>
          </label>
        </div>

        <div v-if="contactItem.contact_type_id" class="col-md-6" :class="{'has-icon-inline-label': errors.has('contactItem.value')}">
          <label class="t-500 t-sm">{{ contactItem.contact_type_name }}</label>
          <input
            type="text"
            class="form-control"
            :placeholder="`Add ${contactItem.contact_type_name}`"
            :name="'contactItem.value'"
            v-model="contactItem.value"
            v-validate="getValidation(contactItem.contact_type_name)"
            data-vv-delay="1000"
            :data-vv-as="contactItem.contact_type_name"
            :class="{'is-invalid': errors.has('contactItem.value')}"
          />

          <i v-show="errors.has('contactItem.value')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('contactItem.value')">{{ errors.first('contactItem.value') }}</span>
        </div>
      </div>

      <!-- action buttons -->
      <form-buttons :isRequest="isRequest"
        :isEditMode="isEditMode"
        :isCreateMode="isCreateMode"
        :isRemove="false"
        :isCancel="true"
        @cancel="handleCancel()">
      </form-buttons>
    </form>
  </div>
</template>

<script>
import ResearcherService from '@/services/researcher'
import ContactTypeService from '@/services/contact_type'

import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'
import Spinner from '@/components/shared/Spinner'

export default {
  name: 'researcherContactEdit',
  components: {
    StaticMessages,
    FormButtons,
    Spinner
  },
  props: {
    researcher: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isRequest: false,
      isEditMode: false,
      isCreateMode: false,
      isLoaded: false,
      isLoadedContactTypes: false,
      error: null,
      success: null,
      contactItem: {
        contact_type_id: null,
        contact_type_name: 'Website',
        value: '',
        id: ''
      },
      contactInfo: [],
      contactTypes: []
    }
  },

  mounted () {
    this.fetch()
  },

  methods: {
    getValidation (name) {
      return (name === 'Website') ? 'required|url:require_protocol' : 'required|min:9'
    },
    fetch () {
      ContactTypeService.get().then(contactTypes => {
        this.contactTypes = contactTypes

        this.isLoadedContactTypes = true
      }).catch(err => {
        this.error = this.handleError(err)
        this.isLoadedContactTypes = !this.isEmpty(this.error)
      })

      ResearcherService.getContactInfo(this.researcher.slug_values[0]).then(contact => {
        this.contactInfo = contact.contact_info
        if (this.isEmpty(this.contactInfo)) this.isCreateMode = true
        this.isLoaded = true
      }).catch(err => {
        this.error = this.handleError(err)
        this.isLoaded = !this.isEmpty(this.error)
      })
    },
    handleContactChanged (contactItem, index) {
      let contactType = this.contactTypes.find((c, i) => {
        if (c.id === contactItem.contact_type_id) return this.contactTypes[i]
      })
      this.contactItem.contact_type_name = contactType.name
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true
          this.isCreateMode ? this.handleCreate() : this.handleEdit()
        }
      })
    },
    openEmail () {
      this.$events.$emit('modal:user:secondary_email:open', this.user)
    },
    openCreate () {
      this.isCreateMode = true
      this.isEditMode = false
      this.contactItem = this.$options.data()
    },
    openEdit (contactItem) {
      this.isCreateMode = false
      this.isEditMode = true
      this.contactItem.value = contactItem.value
      this.contactItem.id = contactItem.id
      this.contactItem.contact_type_id = contactItem.contact_type.id
      this.contactItem.contact_type_name = contactItem.contact_type.name
    },
    handleCreate () {
      ResearcherService.createContactInfo(this.researcher.slug_values[0], this.contactItem).then(contactInfo => {
        this.contactInfo = contactInfo
        this.isRequest = this.isEditMode = this.isCreateMode = false
        this.$events.$emit('contact:researcher:updated', this.contactInfo)
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleEdit () {
      ResearcherService.updateContactInfo(this.researcher.slug_values[0], this.contactItem).then(contactInfo => {
        this.contactInfo = contactInfo
        this.isRequest = this.isEditMode = this.isCreateMode = false
        this.$events.$emit('contact:researcher:updated', this.contactInfo)
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleRemove (contactItem, index) {
      ResearcherService.deleteContactInfo(this.researcher.slug_values[0], contactItem.id).then(contactInfo => {
        this.contactInfo.splice(index, 1)
        this.isRequest = this.isEditMode = this.isCreateMode = false
        this.$events.$emit('contact:researcher:updated', this.contactInfo)
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleCancel () {
      this.$events.$emit('modal:close')
    }
  }
}
</script>
