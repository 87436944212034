<template>
  <base-modal :isLoaded="isLoaded"
    :isError404="isError404"
    :scope="scope"
    :data="data"
    :error="error"
    @clearError="error = null">
      <template #modal-content>
        <div class="d-flex justify-content-between align-items-baseline mb-3">
          <h2 class="t-secondary t-xlr">{{ scope | capitalize | humanize }}</h2>
          <div class="d-flex">
            <share
              :scope="scope"
              :itemName="data.title"
              inModal
            />
            <bookmark-folder
              v-if="!isEmpty(data) && !isEmpty($currentUser)"
              :currentItem="data"
              :scope="getScope"
              isButton
            />
          </div>
        </div>
        <!-- eslint-disable vue/no-v-text-v-html-on-component -->
        <h1 class="mb-3 t-700 t-xxlr mb-4" v-html="data.highlights.title"></h1>

        <p v-if="publicationDate" class="mb-0">
          <span class="t-500 t-md">Publication Date: </span>
          <span class="mr-4">{{ publicationDate }}</span>
          <span class="t-600">{{ publicationDateFromNow }}</span>
        </p>

        <p v-if="source" class="mb-0">
          <span class="t-500 t-md">Source: </span>
          <router-link v-if="!isEmpty(source.slug_values)"
            :to="{ name: 'organisation.show', params: { id: source.slug_values[0] }}"
          >{{ source.name }}</router-link>
          <span v-else>{{ source.name }}</span>
        </p>

        <p class="mb-0">
          <span class="t-500 t-md">Content from: </span>
          <span><a href="https://www.prnewswire.com/" target="_blank">PrNewsWire</a></span>
        </p>

        <div v-if="!isEmpty(data.industries)" class="my-4">
          <keywords
            :keywords="data.industries"
            property="">
          </keywords>
        </div>

        <long-text-collapsible
          v-if="data.text"
          title="Article"
          :truncateAt="-1"
          :isReadMore="false"
          :text="data.highlights.text"
          :withPreWrap="false"
          class="mb-4"
        />
      </template>

      <template #modal-sidebar>
        <h3 class="mb-2 t-600" v-if="data.url || source.url">
          Links
        </h3>
        <a
          v-if="data.url"
          :href="data.url | formatWebsite"
          class="d-flex align-items-center"
          target="_blank">PrNewsWire Article <i class="ml-2 fa fa-external-link" /></a>

        <a
          v-if="source.url"
          :href="source.url | formatWebsite"
          class="d-flex align-items-center"
          target="_blank">Company Website <i class="ml-2 fa fa-external-link" /></a>

        <create-alert
          v-if="!isEmpty(source.slug_values)"
          :item="source"
          :scope="'organisations'"
          :customTooltip="`Get an email when news or research <br />from ${source.name} is added`"
          isSecondary
          class="mt-4 width--200px"
        />

        <identifiers :item="data" />
      </template>
  </base-modal>
</template>

<script>
import NewsService from '@/services/news'
import sharedJsModal from '@/mixins/search/results/baseModal'

import BaseModal from '@/components/search/results/BaseModal'
import Keywords from '@/components/shared/Keywords'
import BookmarkFolder from '@/components/bookmark_folder/Show'
import LongTextCollapsible from '@/components/shared/LongTextCollapsible'
import Identifiers from '@/components/qa/Identifiers'
import Share from '@/components/shared/profile/Share'
import CreateAlert from '@/components/shared/profile/CreateAlert'

export default {
  name: 'modalNews',
  mixins: [sharedJsModal],
  components: {
    BaseModal,
    Keywords,
    BookmarkFolder,
    LongTextCollapsible,
    Identifiers,
    Share,
    CreateAlert
  },
  props: {
    slug: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      objectForHighlighting: {
        properties: ['text', 'title']
      }
    }
  },
  created () {
    this.scope = 'news'
  },
  computed: {
    publicationDate () {
      if (this.data.publication_date) {
        return this.$options.filters.formatDate(this.data.publication_date, 'MMMM D, YYYY')
      }
      return ''
    },
    publicationDateFromNow () {
      if (this.data.publication_date) {
        return this.$options.filters.fromNow(this.data.publication_date)
      }
      return ''
    },
    source () {
      if (!this.isEmpty(this.data.organisations)) {
        return this.data.organisations[0]
      }
      return null
    }
  },
  methods: {
    fetch (slug) {
      NewsService.getByID(slug).then(newsItem => {
        this.data = newsItem
        this.fetchHighlights(this.scope, slug)
      }).catch(err => {
        this.handleCustomError(err)
      })
    }
  }
}
</script>
