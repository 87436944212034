<template>
  <span>
    <router-link :to="{ name: 'research_group.show', params: { id: researchGroup.slug_values[0] }}">
      <avatar-missing v-if="!researchGroup.avatar" :name="researchGroup.name" :maxChars="maxChars" :isSmallClass="false"/>
      <avatar v-else :name="researchGroup.name" :avatar="researchGroup.avatar" :editable="false" />
    </router-link>
  </span>
</template>

<script>
import AvatarMissing from '@/components/shared/AvatarMissing'
import Avatar from '@/components/shared/Avatar'

export default {
  name: 'researchGroupThumbnail',
  components: {
    AvatarMissing,
    Avatar
  },
  props: {
    researchGroup: {
      type: Object,
      required: true
    },
    maxChars: {
      type: Number,
      required: false,
      default: 3
    }
  }
}
</script>
