<template>
  <base-modal :isLoaded="isLoaded"
    :isError404="isError404"
    :scope="scope"
    :data="data"
    :error="error"
    @clearError="error = null">
      <template #modal-content>
        <div class="d-flex justify-content-between align-items-baseline mb-3">
          <h2 class="t-secondary t-xlr">{{ formattedScope }}</h2>
          <div class="d-flex">
            <share
              :scope="scope"
              :itemName="data.title"
              inModal
            />
            <bookmark-folder
              v-if="!isEmpty(data) && !isEmpty($currentUser)"
              :currentItem="data"
              :scope="getScope"
              isButton
            />
          </div>
        </div>

        <h1 class="mb-3 t-700 t-xxlr" v-html="data.highlights.title"></h1>

        <long-text-collapsible v-if="!isEmpty(data.highlights.description)"
          :title="'Description'"
          :truncateAt="-1"
          :isReadMore="false"
          :text="data.highlights.description">
        </long-text-collapsible>
      </template>

      <template #modal-sidebar>
        <h3 class="mb-3 t-500 t-lr">
          <router-link :to="{ name: 'research_group.show', params: { id: data.research_group.slug_values[0] }}" class="t-primary" v-html="data.highlights.research_group.name"></router-link>
        </h3>

        <!-- organisation -->
        <div v-for="(organisation, i) in data.highlights.research_group.organisations" :key="`${organisation.slug_text}-${i}`" class="row-content">
          <span class="fa fa-university t-secondary mr-2" />
          <span class="text-left">
            <router-link
              v-if="organisation.slug_values"
              :to="{name: 'organisation.show', params: { id: organisation.slug_values[0] }}"
              v-html="organisation.name"
              v-tooltip="organisation.original_name || organisation.name"
            />
            <span v-else v-html="organisation.name" />
            <flag :title="renderCountryFlagTooltip(organisation.country)" v-if="organisation.country" :iso="organisation.country.alpha_2 | lowercase" :squared="false" class="flag bd-radius t-xs ml-2" />
          </span>
        </div>

        <!-- owners -->
        <researcher-summary v-if="!isEmpty(data.highlights.research_group.owners)"
          :researchers="data.highlights.research_group.owners"
          class="mt-3">
        </researcher-summary>

        <!-- other topics -->
        <search-container-preview v-if="isLoaded"
          :withTitle="`More ${formattedScope}`"
          :searchFilterObject="searchFilterObject"
          :indexes="['research_topics']"
          :route="{ slug: data.slug_values[0] }"
          :totalDocuments="50"
          :disableStyling="true">
        </search-container-preview>

        <identifiers :item="data" />
      </template>
  </base-modal>
</template>

<script>
import sharedJsModal from '@/mixins/search/results/baseModal'

import LongTextCollapsible from '@/components/shared/LongTextCollapsible'
import BaseModal from '@/components/search/results/BaseModal'
import ResearcherSummary from '@/components/shared/ResearcherSummary'
import SearchContainerPreview from '@/containers/SearchPreview'
import Share from '@/components/shared/profile/Share'
import BookmarkFolder from '@/components/bookmark_folder/Show'

import ResearchGroupService from '@/services/research_group'
import Identifiers from '@/components/qa/Identifiers'

export default {
  name: 'modalResearchTopic',
  mixins: [sharedJsModal],
  components: {
    BaseModal,
    LongTextCollapsible,
    ResearcherSummary,
    SearchContainerPreview,
    BookmarkFolder,
    Identifiers,
    Share
  },
  data () {
    return {
      objectForHighlighting: {
        properties: ['title', 'description'],
        objects: ['research_group.name'],
        researchers: ['research_group.owners.initial_name.normalized', 'research_group.owners.full_name.normalized',
          'research_group.organisations.name']
      }
    }
  },
  created () {
    this.scope = 'research_topic'
  },
  computed: {
    searchFilterObject () {
      return {
        key: 'research_groups',
        values: this.data.research_group.slug_values,
        index: 'all_categories',
        global: false,
        suggestions: []
      }
    },
    formattedScope () {
      return this.$options.filters.capitalize(this.$options.filters.humanize(this.getIndexAsString(this.scope)))
    }
  },
  watch: {
    '$route.query.research_topic' (newSlug, oldSlug) {
      if (!this.isEmpty(newSlug)) this.fetch(newSlug)
    }
  },
  methods: {
    fetch (slug) {
      this.isLoaded = false
      ResearchGroupService.getByTopicID(slug).then(researchTopic => {
        this.data = researchTopic
        this.fetchHighlights(this.$options.filters.pluralize(this.scope), slug)
      }).catch(err => {
        this.handleCustomError(err)
      })
    } }
}
</script>
