<template>
  <div>
    <h2 class="t-primary t-600 mb-1 t-xxlr">
      Analyze {{ getIndexAsString(state.index) }} (AI)
      <i class="fa fa-info-circle t-secondary t-xs ml-2" v-tooltip="'Your prompts are private, anonymous and will not be used to train any AI models. Like all generative AI, our AI can provide inaccurate responses. We recommend verifying these responses before relying on them.'" />
    </h2>

    <hr class="hr-small mb-4" />

    <div v-if="error" class="p-2 bd-radius bg-red t-white mb-4">
      {{ error }}
    </div>

    <p v-if="isLoading">
      Loading data...
      <spinner :containerClass="`d-inline ml-4`" />
    </p>

    <template v-if="!showConfirmationMessage">
      <template v-if="results.length > 0 && !answer">
        <h2 class="t-primary t-600 mb-3 t-lr">1. Select the {{ getIndexAsString(state.index) }} you want to analyze</h2>

        <span class="t-600 mr-4">{{ countSelectedResults }} selected</span>

        <a href="#" @click.prevent="selectFirstX(0)" class="mr-4" :class="{
          't-secondary': countSelectedResults === 0
        }">Select none</a>
        <a href="#" v-if="remainingCredits > 0 && remainingCredits < 10" @click.prevent="selectFirstX(remainingCredits)" class="mr-4" :class="{
          't-secondary': lastClickedMultiSelect === remainingCredits
        }">Select first {{remainingCredits}}</a>
        <a href="#" v-if="remainingCredits >= 10" @click.prevent="selectFirstX(10)" class="mr-4" :class="{
          't-secondary': lastClickedMultiSelect === 10
        }">Select first 10</a>
        <a href="#" v-if="remainingCredits > 10 && remainingCredits < 25" @click.prevent="selectFirstX(remainingCredits)" class="mr-4" :class="{
          't-secondary': lastClickedMultiSelect === remainingCredits
        }">Select first {{remainingCredits}}</a>
        <a href="#" v-if="remainingCredits >= 25" @click.prevent="selectFirstX(25)" class="mr-4" :class="{
          't-secondary': lastClickedMultiSelect === 25
        }">Select first 25</a>
        <a href="#" v-if="remainingCredits > 25 && remainingCredits < 50" @click.prevent="selectFirstX(remainingCredits)" class="mr-4" :class="{
          't-secondary': lastClickedMultiSelect === remainingCredits
        }">Select first {{remainingCredits}}</a>
        <a href="#" v-if="remainingCredits >= 50" @click.prevent="selectFirstX(50)" :class="{
          't-secondary': lastClickedMultiSelect === 50
        }">Select first 50</a>
        <ul class="border py-2 px-0 bd-radius alternating-li-bg-grey mt-2 mb-3" style="max-height: 300px; overflow-y: scroll;">
          <li v-for="(result, i) in results" :key="i" class="d-flex px-3 py-1">
            <span class="width--25px">{{ i + 1 }}</span>
            <checkbox
              class="flex-grow-1"
              :name="`select_value_${i}`"
              :text="result.name"
              :value="result.selected"
              @toggle="toggleSelected(i, result)"
            />
            <span class="text-nowrap"><router-link :to="generateLink(result)" target="_blank">open in new tab</router-link></span>
          </li>
        </ul>

        <h2 class="t-primary t-600 mb-2 t-lr">2. Ask anything</h2>

        <textarea
          class="form-control mb-2"
          name="question"
          placeholder="Ask anything..."
          v-model="question"
          v-validate="'min:3|max:2000'"
          data-vv-validate-on="blur"
          :class="{'is-invalid': errors.has('description')}"
          @click="handleClickInTextArea"
          ref="questionTextArea">
        </textarea>

        <div class="p-2 bd-radius bg-grey-light mb-2" v-html="description" />
      </template>

      <template v-if="answer">
        <p class="mb-3" style="white-space: pre-line;">{{ question }}</p>
        <div style="max-height: 60vh; height: auto; overflow-y: scroll; white-space: pre-line;" class="form-control mb-4">
          <p v-html="answer" />
        </div>
      </template>
    </template>

    <template v-if="showConfirmationMessage">
      <p class="mb-2">Closing the dialog without saving or copying the response will delete it forever.</p>
    </template>

    <div class="d-flex flex-row justify-content-end align-items-center mt-4">
      <span class="align-left"
        v-if="!showConfirmationMessage"
        v-tooltip="`1 credit per selected result (example: 1 use of AI analysis on a selection of 10 ${ getIndexAsString(state.index) } consumes 10 credits)`"
      >{{ remainingCredits }} credits remaining (<a href="https://www.academiclabs.com/contact-sales" target="_blank">buy more</a>)</span>
      <span class="flex-grow-1"><!-- spacer --></span>
      <button
        v-if="!showConfirmationMessage"
        class="btn bg-transparent no-border t-primary ml-3"
        type="button"
        @click="closeModal">Cancel
      </button>

      <button
        v-if="showConfirmationMessage"
        class="btn bg-transparent no-border t-primary ml-3"
        type="button"
        @click="showConfirmationMessage = false">Back
      </button>

      <button
        v-if="!isLoading && results && !answer"
        class="btn btn-primary px-4 ml-3"
        type="button"
        :disabled="countSelectedResults === 0 || isAnalyzing || tooManyCreditsInUse"
        @click="analyzeResults"
      >
        <template v-if="!isAnalyzing">Analyze {{ countSelectedResults }} {{ 'result' | pluralize(countSelectedResults) }}</template>
        <template v-else><span class="dots">Analyzing</span></template>
      </button>

      <button v-if="answer"
        class="btn btn-primary px-4 ml-3"
        type="button"
        v-clipboard="answer" @success="handleCopySuccess" @error="handleCopyError"
      >
        {{ copyCopy }}
      </button>

      <button v-if="answer"
        class="btn btn-primary px-4 ml-3"
        type="button"
        @click="downloadAnswer"
      >
        Download
      </button>

      <button
        v-if="showConfirmationMessage"
        class="btn btn-primary--red ml-3"
        type="button"
        @click="$events.$emit('modal:close')">Close & Delete
      </button>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/shared/Spinner'
import SearchService from '@/services/search'
import Checkbox from '@/components/shared/form/Checkbox2'

import moment from 'moment'

export default {
  name: 'takeawayModal',
  components: {
    Spinner,
    Checkbox
  },
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      error: '',
      isLoading: false,
      isFirstClickInTextArea: true,
      copyCopy: 'Copy to Clipboard',
      remainingCredits: 0,
      // Takeaways v2
      results: [],
      question: '',
      description: '',
      lastClickedMultiSelect: 0,
      isAnalyzing: false,
      answer: '',
      hasCopiedOrDownloadedAnswer: false,
      showConfirmationMessage: false
    }
  },
  mounted () {
    // Initialize remaining credits variable
    const credits = this.$currentUser.credits_remaining
    if (!credits || credits < 0) this.remainingCredits = 0
    else this.remainingCredits = credits

    if (this.remainingCredits === 0) {
      this.error = 'There are insufficient credits left on your account to perform this AI Analysis request. Please contact us if you wish to purchase additional credits.'
    } else {
      // Fetch results to analyse
      this.fetchOverview()
    }
  },
  computed: {
    countSelectedResults () {
      if (!this.results || this.results.length <= 0) return 0
      return this.results.reduce((count, currentResult) => {
        if (currentResult.selected) return count + 1
        return count
      }, 0)
    },
    tooManyCreditsInUse () {
      return this.countSelectedResults > this.remainingCredits
    }
  },
  methods: {
    generateLink (result) {
      return {
        ...this.$route,
        query: {
          ...this.$route.query,
          [this.singularizeIndex(this.state.index)]: result.slug_values[0],
          hr: this.$options.filters.slugify(result.name)
        }
      }
    },
    selectFirstX (amount) {
      this.lastClickedMultiSelect = amount
      this.results.forEach((result, index) => {
        if (index < amount) this.$set(this.results, index, { ...result, selected: true })
        else this.$set(this.results, index, { ...result, selected: false })
      })
    },
    toggleSelected (index, result) {
      // Reset "select first x results" thingie
      this.lastClickedMultiSelect = -1
      this.$set(this.results, index, {
        ...result,
        selected: !result.selected
      })
    },
    setremainingCredits (credits) {
      if (!credits || credits < 0) this.remainingCredits = 0
      else this.remainingCredits = credits

      this.$auth.updateCurrentUser({
        ...this.$currentUser,
        credits_remaining: this.remainingCredits
      })
    },
    fetchOverview () {
      this.isLoading = true
      this.error = ''

      SearchService.getTakeawayOverview({ ...this.state }).then(response => {
        this.results = response.items.map(item => {
          return {
            ...item,
            selected: false
          }
        })
        this.question = response.question
        this.description = response.description

        this.isLoading = false
      }).catch(err => {
        if (err.status === 401) {
          this.setremainingCredits(0)
          this.error = 'There are insufficient credits left on your account to perform this AI Analysis request. Please contact us if you wish to purchase additional credits.'
        } else if (err.status === 400) {
          this.error = 'Oops, our AI has stumbled! An unexpected error has occured. Please try again.'
        } else {
          this.error = 'Oops, something unexpected has happened. Please try again later.'
        }
        this.isLoading = false
      })
    },
    analyzeResults () {
      this.isAnalyzing = true
      this.error = ''

      const selectedItems = this.results
        .filter(r => r.selected)
        .map((result, i) => {
          return {
            order: i,
            slug_values: result.slug_values
          }
        })

      SearchService.getTakeawaysAnalysis({
        index: this.state.index,
        question: this.question,
        items: selectedItems
      }).then(response => {
        this.setremainingCredits(response.credits_remaining)
        this.answer = response.data

        this.isAnalyzing = false
      }).catch(err => {
        if (err.status === 401) {
          this.setremainingCredits(0)
          this.error = 'There are insufficient credits left on your account to perform this AI Analysis request. Please contact us if you wish to purchase additional credits.'
        } else if (err.status === 400) {
          if (err.data && err.data.error) {
            this.error = err.data.error
          } else {
            this.error = 'Oops, our AI has stumbled! An unexpected error has occured. Please try again.'
          }
        } else {
          this.error = 'Oops, something unexpected has happened. Please try again later.'
        }
        this.isAnalyzing = false
      })
    },
    handleCopySuccess () {
      this.hasCopiedOrDownloadedAnswer = true
      this.copyCopy = 'Succesfully copied!'
      setTimeout(this.resetCopy, 2500)
    },
    handleCopyError () {
      this.copyCopy = 'Could not copy text to clipboard, please try manually.'
      setTimeout(this.resetCopy, 2500)
    },
    handleClickInTextArea () {
      if (this.isFirstClickInTextArea) {
        this.$refs.questionTextArea.select()
        this.isFirstClickInTextArea = false
      }
    },
    resetCopy () {
      this.copyCopy = 'Copy to Clipboard'
    },
    downloadAnswer () {
      this.hasCopiedOrDownloadedAnswer = true

      const fileName = `${this.getIndexAsString(this.state.index)}-${moment().format('YYYY-MM-DD')}.txt`
      // Generate blob from response and virtual link that will be 'clicked' to download the export-file
      const url = window.URL.createObjectURL(new Blob([this.answer]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      link.click()
    },
    closeModal () {
      if (this.answer && !this.hasCopiedOrDownloadedAnswer) {
        this.showConfirmationMessage = true
      } else {
        this.$events.$emit('modal:close')
      }
    }
  }
}
</script>
