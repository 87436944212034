<template>
  <div class="">
    <h2 class="t-primary t-400 mb-0">Your disciplines</h2>
    <hr class="hr-small mb-4" />

    <!-- render static messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <!-- disciplines loaded -->
    <div class="row" v-if="isLoaded">
      <div class="col-md-12">
        <div class="position-relative" v-if="disciplines.length >= 1">
          <label class="t-500 d-block t-sm position-relative">
            Select a discipline
            <i class='fa fa-info-circle ml-1 t-sm t-secondary' v-tooltip.top="`You can select multiple disciplines`"></i>
          </label>

          <label class="select-label">
            <select
              class="form-control"
              name="discipline"
              tabindex="1"
              v-model="discipline"
              @change="disciplineChanged()">
                <option value="" selected disabled hidden>Choose here</option>
                <option v-for="(discipline, i) in disciplines" :key="i" :value="discipline" class="t-sm">
                  {{ discipline.name | humanize }}
                </option>
            </select>
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-4" v-if="isSubLoaded">
      <div class="col-md-12" v-if="discipline">
        <h4 class="mb-3 t-regular">{{ discipline.name }}</h4>

        <div class="pull-left w-50" v-for="(discipline, index) in subDisciplines" :key="index">
          <checkbox
            v-model="discipline.checked"
            :text="discipline.name"
            :name="discipline.code"
            :object="discipline"
            @input="handleCheckbox">
          </checkbox>
        </div>
      </div>
    </div>

    <div class="row mt-4" v-if="selected.length >= 1">
      <div class="col-md-12">
        <h4 class="mb-3">
          <i class="fa fa-star pr-3 t-sm t-secondary"></i>My disciplines
        </h4>
        <div v-for="(discipline, i) in selected" :key="i">
          <span class="t-600 pr-2">{{ discipline.name | humanize }}:</span>
          <a class="badge badge-pill badge-secondary bd-radius mr-2 mt-2" v-for="(d, i) in discipline.sub_disciplines" :key="i" href="#" @click.prevent="removeDiscipline(discipline, d)">
            {{ d.name | humanize }}
            <i class="fa fa-trash pl-2 t-secondary"></i>
          </a>
        </div>

        <form-buttons :isCreateMode="false"
          :isEditMode="true"
          :isRemove="currentDisciplines.length >= 1 ? true : false"
          :isRequest="isRequest"
          :containerClass="`pt-3`"
          @save="saveDisciplines()"
          @cancel="handleClose()"
          @remove="handleRemove()">
        </form-buttons>
      </div>
    </div>

    <!-- render spinner if not loaded -->
    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import DisciplineService from '@/services/discipline'
import ResearcherService from '@/services/researcher'

import Checkbox from '@/components/shared/form/Checkbox'
import StaticMessages from '@/components/shared/StaticMessages'
import Spinner from '@/components/shared/Spinner'
import FormButtons from '@/components/shared/form/Buttons'

export default {
  name: 'createDiscipline',
  props: {
    currentDisciplines: {
      type: Array,
      required: true
    }
  },
  components: {
    Checkbox,
    StaticMessages,
    Spinner,
    FormButtons
  },
  data () {
    return {
      isRequest: false,
      isLoaded: false,
      isSubLoaded: false,
      error: null,
      discipline: '',
      disciplines: [],
      subDisciplines: [],
      selected: []
    }
  },
  created () {
    this.fetch()
    this.preFill()
  },
  methods: {
    preFill () {
      for (let d of this.currentDisciplines) this.selected.push(d)
    },
    fetch () {
      DisciplineService.get().then(disciplines => {
        this.isLoaded = true
        this.disciplines = disciplines
      }).catch(err => {
        this.error = this.handleError(err)
        this.isLoaded = !this.isEmpty(this.error)
      })
    },
    fetchSub (id) {
      DisciplineService.getByID(id).then(subDisciplines => {
        this.isSubLoaded = true
        this.subDisciplines = subDisciplines

        // set check boxes on checked after load
        this.selected.find((el, index) => {
          if (el.id === id) {
            for (let d of this.subDisciplines) el.sub_disciplines.find((e, i) => { if (e.id === d.id) d.checked = true })
          }
        })
      }).catch(err => {
        this.error = this.handleError(err)
        this.isSubLoaded = !this.isEmpty(this.error)
      })
    },
    disciplineChanged () {
      this.fetchSub(this.discipline.id)
    },
    handleCheckbox (checked, subDiscipline) {
      let s = this.selected.filter((s) => { return s.id === this.discipline.id })[0]
      if (!s && checked) this.selected.push(this.discipline)

      this.selected.find((el, index) => {
        if (el.id === this.discipline.id) {
          if (!el.sub_disciplines) el.sub_disciplines = []

          let found = this.getSubDiscipline(el, subDiscipline)
          !found
            ? el.sub_disciplines.push(subDiscipline)
            : el.sub_disciplines.splice(el.sub_disciplines.findIndex(x => x.id === found.id), 1)

          return this.setOrRemoveDiscipline(el, index)
        }
      })
    },
    removeDiscipline (discipline, subDiscipline) {
      this.selected.find((el, index) => {
        if (el.id === discipline.id) {
          let found = this.getSubDiscipline(el, subDiscipline)

          if (found) {
            let i = this.subDisciplines.findIndex(x => x.id === found.id)
            if (i >= 0) this.subDisciplines[i].checked = false

            el.sub_disciplines.splice(el.sub_disciplines.findIndex(x => x.id === found.id), 1)
          }

          return this.setOrRemoveDiscipline(el, index)
        }
      })
    },
    getSubDiscipline (el, sub) {
      return el.sub_disciplines.filter((e, i) => {
        return e.id === sub.id
      }).reduce((o, n) => n, false)
    },
    setOrRemoveDiscipline (el, index) {
      return el.sub_disciplines.length > 0
        ? this.$set(this.selected, index, el)
        : this.selected.splice(index, 1)
    },
    saveDisciplines () {
      if (this.selected) {
        this.isRequest = true

        // only save the id's
        let ids = []
        for (let d of this.selected) ids.push(...d.sub_disciplines.map((obj) => { return obj.id }))
        ids.push(...this.selected.map((obj) => { return obj.id }))

        ResearcherService.updateDisciplines(this.$currentUser.slug_values[0], { ids: ids }).then(d => {
          this.$events.$emit('researcher:updated', 'disciplines', d.disciplines)
          this.isRequest = false
          this.handleClose()
        }).catch(err => {
          this.error = this.handleError(err)
          this.isRequest = false
        })
      }
    },
    handleRemove () {
      ResearcherService.updateDisciplines(this.$currentUser.slug_values[0], { ids: [] }).then(d => {
        this.$events.$emit('researcher:updated', 'disciplines', d.disciplines)
        this.isRequest = false
        this.handleClose()
      }).catch(err => {
        this.error = this.handleError(err)
        this.isRequest = false
      })
    },
    handleClose () {
      this.$events.$emit('modal:close')
    }
  }
}
</script>
