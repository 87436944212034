<template>
  <div>
    <spinner v-if="!isLoaded || isRequest" :containerClass="`width--50px min-height--65px`" key="spinner" />
    <transition-group name="list-complete">
      <link-button
        v-for="bookmarkFolder in userFolders" :key="bookmarkFolder.id"
        :icon="isInBookmarkFolder(bookmarkFolder) ? 'fa-check-remove width--20px' : 'width--20px'"
        @click="addOrRemove(bookmarkFolder)"
        class="check-remove-wrapper list-complete-item"
      >
        <span class="ml-1 flex-grow-1 t-overflow">{{ bookmarkFolder.name }}</span>
        <i
          class="fa t-sm fa-users ml-3 t-secondary justify-content-end"
          :class="{ invisible: !bookmarkFolder.contributors || bookmarkFolder.contributors.length <= 1 }"
        />
      </link-button>
    </transition-group>

    <!-- We only show the invited folders if we are not doing any "bulk bookmarking" -->
    <div v-if="!isBulkSave && !isEmpty(invitedFolders)" class="dropdown-divider" key="divider1" />
    <template v-if="!isBulkSave">
      <transition-group name="list-complete">
        <link-button
          v-for="invitedFolder in invitedFolders" :key="invitedFolder.id"
          :icon="isInBookmarkFolder(invitedFolder) ? 'fa-check-remove width--20px' : 'width--20px'"
          @click="addOrRemove(invitedFolder)"
          class="check-remove-wrapper"
        >
          <span class="ml-1 flex-grow-1 t-overflow">{{ invitedFolder.name }}</span>
          <i class="fa t-sm fa-share-alt ml-3 t-secondary" />
        </link-button>
      </transition-group>
    </template>

    <div v-if="!isEmpty(userFolders)" class="dropdown-divider" key="divider2" />
    <link-button
      key="create-new"
      icon="fa-plus width--20px"
      @click="handleCreateBookmarkFolder()"
    >
      <span class="ml-1 flex-grow-1">Create a new folder</span>
    </link-button>
  </div>
</template>

<script>
import LinkButton from '@/components/shared/form/LinkButton'
import Spinner from '@/components/shared/Spinner'

import { mapGetters, mapActions } from 'vuex'

// Bookmark items dropdown for adding new bookmarks
export default {
  name: 'bookmarkFolderItems',
  components: {
    LinkButton,
    Spinner
  },
  props: {
    currentItem: {
      type: Object,
      required: false,
      default: () => {}
    },
    scope: {
      type: String,
      required: true
    },
    isLoaded: {
      type: Boolean,
      default: false
    },
    isBulkSave: {
      type: Boolean,
      default: false
    },
    state: {
      type: Object,
      required: false
    },
    isScopedSearch: {
      type: Boolean,
      required: false
    },
    count: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      isRequest: false
    }
  },
  watch: {
    'isRequest' (value) {
      if (value) {
        this.$emit('startSave')
      } else {
        this.$emit('stopSave')
      }
    }
  },
  computed: {
    ...mapGetters({
      invitedFolders: 'bookmarks/invitedFolders',
      userFolders: 'bookmarks/userFolders'
    })
  },
  methods: {
    ...mapActions('bookmarks', [
      'addItem',
      'removeItem',
      'bulkAddItem'
    ]),
    isInBookmarkFolder (bookmarkFolder) {
      if (!this.currentItem) return false
      return !this.isEmpty(this.currentItem.bookmark_folders) ? this.currentItem.bookmark_folders.includes(bookmarkFolder.id) : false
    },
    addOrRemove (bookmarkFolder) {
      this.isInBookmarkFolder(bookmarkFolder) ? this.remove(bookmarkFolder) : this.add(bookmarkFolder)
    },
    add (bookmarkFolder) {
      if (!this.isRequest) {
        this.isRequest = true

        if (this.isBulkSave) {
          // Show warning if request is taking too long
          setTimeout(() => {
            if (this.isRequest) {
              this.$flash({
                message: 'Your bookmark save operation is taking some time. Not to worry, it will keep running in the background. You can continue using AcademicLabs.'
              }, 'warning')
            }
          }, 10000)
          this.bulkAddItem({
            bookmarkFolder,
            searchState: {
              ...this.state,
              index: this.scope
            }
          }).then(response => {
            let message = ''
            let flashType = ''
            if (response && response.count) {
              if (this.count > 500) {
                message = `Only the first ${response.count} items were added to <b>${bookmarkFolder.name}</b>`
                flashType = 'warning'
              } else {
                message = `Added ${response.count} items to <b>${bookmarkFolder.name}</b>`
                flashType = 'success'
              }
            } else {
              message = `Added ${response.count} items to <b>${bookmarkFolder.name}</b>`
              flashType = 'warning'
            }
            this.$flash({
              message: message,
              cta: this.getFlashCta(bookmarkFolder)
            }, flashType)
            this.$emit('added')
            this.isRequest = false
          }).catch(err => {
            this.isRequest = false
            // Don't throw error on timeout, has been dealt with.
            if (err && err.response && err.response.status && err.response.status === 504) return

            this.$flash({ message: this.renderError(err) }, 'danger')
          })
        } else {
          this.addItem({
            bookmarkFolder,
            item: this.currentItem,
            scope: this.scope
          }).then(() => {
            this.handleAfterAdd(bookmarkFolder)
          }).catch(err => {
            this.$flash({ message: this.renderError(err) }, 'danger')
            this.isRequest = false
          })
        }
      }
    },
    remove (bookmarkFolder) {
      if (!this.isRequest) {
        this.isRequest = true
        this.removeItem({
          bookmarkFolder,
          item: this.currentItem,
          scope: this.scope
        }).then(() => {
          let bookmarkFolders = this.currentItem.bookmark_folders ? [...this.currentItem.bookmark_folders] : []
          bookmarkFolders.splice(bookmarkFolders.findIndex((i) => i === bookmarkFolder.id), 1)

          this.$events.$emit('bookmark_folder:bookmark:modal', bookmarkFolders)
          this.$events.$emit('bookmark_folder:bookmarked', bookmarkFolders, this.scope, this.currentItem.id)

          this.isRequest = false
          this.$emit('removed')
          this.$flash({
            message: `Removed from <b>${bookmarkFolder.name}</b>`,
            cta: this.getFlashCta(bookmarkFolder)
          }, 'success')
        }).catch(err => {
          this.$flash({ message: this.renderError(err) }, 'warning')
          this.isRequest = false
        })
      }
    },
    handleAfterAdd (bookmarkFolder) {
      const currentItemBookmarkFolders = this.currentItem.bookmark_folders ? [...this.currentItem.bookmark_folders] : []
      currentItemBookmarkFolders.push(bookmarkFolder.id)

      this.$events.$emit('bookmark_folder:bookmark:modal', currentItemBookmarkFolders)
      this.$events.$emit('bookmark_folder:bookmarked', currentItemBookmarkFolders, this.scope, this.currentItem.id)
      this.$flash({
        message: `Added to <b>${bookmarkFolder.name}</b>`,
        cta: this.getFlashCta(bookmarkFolder)
      }, 'success')
      this.$emit('added')
      this.isRequest = false
    },
    getFlashCta (bookmarkFolder) {
      // Generate route to bookmark folder detail page
      const route = this.$router.resolve({
        name: 'researcher.bookmark_folders.show',
        params: { id: this.$currentUser.slug_values[0], bookmark_folder: bookmarkFolder.slug_values[0] }
      })
      return {
        icon: 'fa-folder',
        text: 'View',
        action: () => { window.open(route.href, '_blank') }
      }
    },
    handleCreateBookmarkFolder () {
      this.$events.$emit('modal:bookmark_folder:open:create', {
        item: this.currentItem,
        scope: this.scope,
        isBulkSave: this.isBulkSave,
        state: this.state,
        count: this.count
      })
    }
  }
}
</script>
