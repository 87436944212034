const state = {
  treeView: 'tree' // 'list' or 'tree'
}

// getters
const getters = {
  // Get view mode for tree chart element for current session (list/tree)
  getTreeView: (state) => state.treeView
}

// actions
const actions = {
  setTreeView ({ commit }, view) {
    commit('setTreeView', view)
  }
}

// mutations
const mutations = {
  setTreeView (state, view) {
    state.treeView = view
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
