<template>
  <div class="">
    <div class="text-center">
      <icon :icon="`register`"></icon>
    </div>

    <h3 class="t-primary t-700 my-4 text-center">
      Do you belong to this organisation?
    </h3>

    <hr class="mb-4" />

    <!-- render organisation request accept -->
    <organisation-request-accept :researcher="researcher" />

    <hr class="my-4" />

    <p class="mb-0 mt-4 text-center">
      Do you have any questions? <a href="mailto:support@academiclabs.com" target="_blank" rel="noopener noreferrer">Please contact us</a>
    </p>

    <button type="button" class="btn btn-secondary pull-right mt-3" @click="$events.$emit('modal:close')">
      Close
    </button>
  </div>
</template>

<script>
import Icon from '@/components/shared/Icon'
import OrganisationRequestAccept from '@/components/organisation_request/Accept'

export default {
  name: 'registerCompanyModal',
  components: {
    Icon,
    OrganisationRequestAccept
  },
  props: {
    researcher: {
      type: Object,
      required: true
    }
  }
}
</script>
