<template>
  <li class="nav-item dropdown position-relative pr-0">
    <dropdown
      placement="bottom-end"
      noPadding
    >
      <template #button>
        <a href="#" @click.prevent class="nav-link nav-link-icon avatar-header" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="d-none d-sm-block">
            <avatar-missing v-if="!avatar" :name="fullName" :maxChars="3"/>
            <avatar v-else :name="fullName" :avatar="avatar" :editable="false" />
          </div>
          <i class="d-sm-none fa fa-bars" aria-hidden="true"></i>
        </a>
      </template>
      <div class="dropdown-menu-custom box-shadow  dropdown-create" aria-labelledby="profile-dropdown-dropdown">
        <router-link :to="{name: 'researcher.show', params: { id: $currentUser.slug_values[0] }}" class="dropdown-item pl-0 py-0 d-flex align-items-center">
          <div class="mr-2 profile-dd">
            <avatar-missing v-if="!avatar" :name="fullName" :maxChars="3"/>
            <avatar v-else :name="fullName" :avatar="avatar" :editable="false" />
          </div>
          <div class="t-compact">
            <span class="t-500">{{ fullName }}</span>
            <br /><span class="t-secondary t-sm" v-if="$currentUser.function_title">{{ $currentUser.function_title.name }}</span>
          </div>
        </router-link>

        <router-link :to="{name: 'researcher.show', params: { id: $currentUser.slug_values[0] }}" class="dropdown-item mt-2">
          <i class="fa fa-user width--25px t-sm t-secondary"></i>View profile
        </router-link>

        <div class="dropdown-divider"></div>

        <div class="dropdown-item d-flex align-items-center pb-2">
          <span class="dropdown-item_icon">
            <i class="fa fa-users t-secondary" />
          </span>
          <span class="dropdown-item_text t-600 t-primary d-flex justify-content-between t-overflow">
            Research Groups
            <div class='mw-100'>
              <link-button
                text="Create"
                icon="fa-plus"
                @click="createRG()"
                size="sm"
                class="py-0"
              />
            </div>
          </span>
        </div>

        <div class="dropdown-item" v-if="researchGroups && researchGroups.length === 0">
          <span class="dropdown-item_text with_offset t-secondary t-sm">
            Boost visibility of your research team's capabilities, achievements and current interests.
          </span>
        </div>

        <div v-else class="half-inner-list">
          <router-link v-for="(researchGroup, index) in researchGroups" :key="`rg-${index}`" :to="{name: 'research_group.show', params: { id: researchGroup.slug_values[0] }}" class="dropdown-item dropdown-item_text with_offset">
            {{ researchGroup.name }}
          </router-link>
        </div>

        <div class="dropdown-divider"></div>

        <router-link :to="{name: 'researcher.bookmark_folders', params: { id: $currentUser.slug_values[0] }}" class="dropdown-item d-none d-sm-block">
          <i class="fa fa-folder width--25px t-sm t-secondary"></i>Bookmark folders
        </router-link>

        <a href="https://academiclabscom.notion.site/What-s-New-b87919b67e7f4734b21c64afeb6bdb89" class="dropdown-item d-sm-none" target="_blank">b87919b67e7f4734b21c64afeb6bdb89
            <i class="fa fa-gift t-secondary width--25px"></i>What's new
        </a>

        <a v-if="$currentUser && $currentUser.cookie_analytics" class="dropdown-item d-sm-none"  href="#" @click.prevent="clickChatSupport">
          <i class="fa fa-comment t-secondary width--25px"></i>Chat Support
        </a>

        <a href="https://academiclabscom.notion.site/Help-Support-3cd2c628902342e6a1d6edc199920b93" class="dropdown-item d-sm-none" target="_blank">
            <i class="fa fa-lightbulb-o t-secondary width--25px"></i>Help center
        </a>

        <div class="dropdown-divider"></div>

        <template v-if="$can('inviteToAL')">
          <a :href="`mailto:?subject=I%20invite%20you%20to%20check%20out%20AcademicLabs%2C%20the%20scientific%20intelligence%20platform%20made%20for%20you&body=Dear%20colleague%2C%0A%0AI'm%20using%20AcademicLabs%2C%20a%20scientific%20intelligence%20platform%20that%20makes%20it%20easy%20to%20find%20the%20most%20relevant%20experts%2C%20research%20groups%2C%20biotech%20startups%2C%20pharma%20research%20and%20collaborations%2C%20ongoing%20and%20published%20research%2C%20new%20projects%2C%20patents%2C%20clinical%20trials%2C%20research%20trends%2C%20and%20much%20more.%20I%20think%20you'd%20find%20it%20really%20useful%20for%20your%20work.%0A%0ASign%20up%20here%20to%20try%20a%20search%20and%20discover%20what%20you%20can%20find%3A%20https%3A%2F%2Fapp.academiclabs.com%2Fsignup%0A%0ATo%20learn%20more%2C%20here%E2%80%99s%20an%20intro%20video%20on%20the%20website%3A%20https%3A%2F%2Fwww.academiclabs.com%2F%0Aor%20schedule%20a%2010%E2%80%99%20demo%20session%3A%20https%3A%2F%2Fwww.academiclabs.com%2Facademiclabs-get-started%0A%0ABest%20Regards%2C%0A%0A${$currentUser.full_name}`"
            class="dropdown-item d-sm-none"
            target="_blank" rel="noopener noreferrer"
            @click="trackInviteClicked"
          >
              <i class="fa fa-user-plus t-secondary width--25px"></i>Invite your colleagues
          </a>

          <div class="dropdown-divider d-sm-none"></div>
        </template>

        <router-link :to="{name: 'researcher.settings', params: { id: $currentUser.slug_values[0] }}" class="dropdown-item">
          <i class="fa fa-cog width--25px t-sm t-secondary"></i>Settings
        </router-link>

        <div class="dropdown-divider"></div>

        <a href="#" @click.prevent="$auth.logout()" class="dropdown-item"><i class="fa fa-sign-out width--25px t-sm t-secondary"></i>Log out</a>
      </div>
    </dropdown>
  </li>
</template>

<script>
import AvatarMissing from '@/components/shared/AvatarMissing'
import Avatar from '@/components/shared/Avatar'
import Dropdown from '@/components/shared/form/Dropdown'
import MPService from '@/services/mixpanel'
import LinkButton from '@/components/shared/form/LinkButton'

export default {
  name: 'researcher-dropdown',
  components: {
    AvatarMissing,
    Avatar,
    Dropdown,
    LinkButton
  },
  data () {
    return {
      avatar: '',
      fullName: ''
    }
  },
  props: {
    researchGroups: {
      type: Array,
      required: true
    }
  },
  mounted () {
    this.getAvatar(this.$currentUser.avatar)
    // Store full name locally and update it via events, because just updating $currentUser doesn't trigger reactivity
    this.fullName = this.$currentUser.full_name
    this.$events.$on('user:avatar:changed', (avatar) => this.getAvatar(avatar))
    this.$events.$on('navbar:researcher:update:full_name', (name) => { this.fullName = name })
  },
  methods: {
    getAvatar (avatar) {
      avatar
        ? this.avatar = `${avatar.split('.')[0]}-thumb.${avatar.split('.')[1]}`
        : this.avatar = ''
    },
    clickChatSupport () {
      this.$alIntercom.show()
      this.track('Chat support')
    },
    track (type) {
      MPService.trackEvent('support', {
        type: type
      })
    },
    createRG () {
      this.$router.push({ name: 'create.research_group.type' })
    },
    trackInviteClicked () {
      MPService.trackEvent('invite_to_academiclabs')
    }
  },
  destroyed () {
    this.$events.$off('user:avatar:changed')
    this.$events.$off('navbar:researcher:update:full_name')
  }
}
</script>
