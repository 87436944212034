<template>
  <div class="checkbox" :class="containerClass">
    <input type="checkbox" :id="name" @change="trigger" :checked="value"  :disabled="isDisabled"/>
    <label :for="name" class="checkbox_label" :class="{'is-disabled': isDisabled }"></label>

    <label :for="name" class="checkbox_content" :class="{'is-disabled': isDisabled }">
      <i class="checkbox_icon t-secondary" :class="[{'checkbox_icon-checked': isChecked && object.checked}, icon]" v-if="icon"></i>
      <span class="checkbox_name" v-html="text"></span>
      <p class="checkbox_description t-secondary" v-if="description" v-html="description"></p>
    </label>
  </div>
</template>

<script>
export default {
  name: 'formCheckbox',
  data () {
    return {
      isChecked: false
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    object: {
      type: Object,
      default: () => ({})
    }
  },
  mounted () {
    this.isChecked = this.value
  },
  watch: {
    value (newVal) {
      this.isChecked = this.value
    }
  },
  methods: {
    trigger (e) {
      this.isChecked = this.icon && e.target.checked
      this.$emit('input', e.target.checked, this.object)
    }
  }
}
</script>
