const Base = () => import(/* webpackChunkName: "organisation" */ '@/views/organisation/Base.vue')
const Show = () => import(/* webpackChunkName: "organisation" */ '@/views/organisation/Show.vue')
// const ResearchGroup = () => import(/* webpackChunkName: "organisation" */ '@/views/organisation/ResearchGroup.vue')
// const ResearchTopic = () => import(/* webpackChunkName: "organisation" */ '@/views/organisation/ResearchTopic.vue')
// const Member = () => import(/* webpackChunkName: "organisation" */ '@/views/organisation/Member.vue')
const Explore = () => import(/* webpackChunkName: "organisation" */ '@/views/organisation/Explore.vue')
// const Webpages = () => import(/* webpackChunkName: "organisation" */ '@/views/organisation/Webpages.vue')

export default {
  path: '/organisation',
  name: 'organisation',
  meta: {
    auth: false,
    render: {
      title: 'Organisation'
    }
  },
  redirect: 'organisation.show',
  component: Base,
  children: [
    {
      path: ':id',
      name: 'organisation.show',
      meta: {
        auth: false,
        searchModal: true,
        render: {
          searchBar: true,
          title: 'About'
        }
      },
      component: Show
    },
    {
      path: ':id/explore/:category?/:buckets(.*)*',
      name: 'organisation.explore',
      meta: {
        auth: false,
        skipMixPanel: true,
        searchContainer: true,
        render: {
          searchBar: true,
          title: 'Research',
          checkForRenderInSubNavExplore: true,
          checkForPortfolio: true
        }
      },
      component: Explore
    }
  ]
}
