<template>
  <div>
    <!-- default button -->
    <a v-if="!isSubmit && !isConnect && !isLink" :href="url" class="btn btn-login-option d-block" :class="containerClass">
      <i class="bg-orcid t-white">iD</i>
      <span class="t-regular">{{ text }}</span>
    </a>

    <!-- connect button -->
    <a href="#" v-if="isConnect && !isLink" @click.prevent="doConnect()" class="btn btn-login-option d-block " :class="containerClass">
      <i class="bg-orcid t-white">iD</i>
      <span class="t-regular">{{ text }}</span>
    </a>

    <!-- connect button -->
    <a href="#" v-if="isConnect && isLink" @click.prevent="doConnect()" class="bd-block " :class="containerClass">
      <i class="t-orcid pr-2">iD</i>
      <span class="t-regular">{{ text }}</span>
    </a>

    <!-- submit button -->
    <button v-if="isSubmit" type="submit" class="btn btn-login-option d-block mb-0" @click.prevent="$emit('click', url)" :class="containerClass">
      <i class="bg-orcid t-white">iD</i>
      <span >{{ text }}</span>
    </button>

    <!-- default link -->
    <a v-if="!isConnect && isLink" :href="url" class="d-block" :class="containerClass">
      <i class="t-orcid pr-2">iD</i>
      <span>{{ text }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'orcidButton',
  props: {
    isConnect: {
      type: Boolean,
      default: false
    },
    isSubmit: {
      type: Boolean,
      default: false
    },
    isLink: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: 'Sign Up with ORCID iD'
    }
  },
  computed: {
    url () {
      return process.env.VUE_APP_ORCID_URL
    }
  },
  methods: {
    doConnect () {
      let data = { connect: true }
      this.$ls.setRegisterData(data)
      window.location.href = this.url
    }
  }
}
</script>
