import AuthService from '@/services/auth'

const Base = () => import(/* webpackChunkName: "community" */ '@/views/community/Base.vue')
const Show = () => import(/* webpackChunkName: "community" */ '@/views/community/Show.vue')
const Explore = () => import(/* webpackChunkName: "community" */ '@/views/community/Explore.vue')
const Pages = () => import(/* webpackChunkName: "community" */ '@/views/community/Pages.vue')
const QuestionsBase = () => import(/* webpackChunkName: "communityQuestions" */ '@/views/community/BaseQuestions.vue')
const QuestionsShow = () => import(/* webpackChunkName: "communityQuestions" */ '@/views/community/questions/Show.vue')
const QuestionsQuestion = () => import(/* webpackChunkName: "communityQuestions" */ '@/views/community/questions/Question.vue')
const Member = () => import(/* webpackChunkName: "communityAdmin" */ '@/views/community/Member.vue')
const Invite = () => import(/* webpackChunkName: "communityAdmin" */ '@/views/community/Invite.vue')
const Opportunity = () => import(/* webpackChunkName: "user" */ '@/views/community/Opportunities.vue')

export default {
  path: '/community',
  name: 'community',
  meta: {
    auth: false,
    render: {
      title: 'Community'
    }
  },
  redirect: 'community.show',
  beforeEnter: (to, from, next) => {
    if (to.meta.permissions || to.meta.visibility) {
      AuthService.permissions({ type: 'community', id: to.params.id }).then(permissions => {
        if (to.meta.permissions) {
          return to.meta.permissions.role.split(',').includes(permissions.role.rw_access)
            ? next()
            : next({ name: to.meta.permissions.redirectTo, params: { id: to.params.id } })
        } else {
          if (to.name === 'community.explore' && !permissions.explore && !to.meta.visibility.role.split(',').includes(permissions.role.rw_access)) return next({ name: '403' })
          if (to.name === 'community.workspace' && !permissions.more_information && !to.meta.visibility.role.split(',').includes(permissions.role.rw_access)) return next({ name: '403' })
          return next()
        }
      }).catch(() => {})
    } else {
      return next()
    }
  },
  component: Base,
  children: [
    {
      path: ':id',
      name: 'community.show',
      component: Show,
      meta: {
        auth: false,
        render: {
          searchBar: true,
          title: 'Overview',
          tooltip: 'All information of your community in one place'
        },
        visibility: {
          key: 'overview_visible'
        }
      }
    }, {
      path: ':id/explore/:category?/:buckets(.*)*',
      name: 'community.explore',
      meta: {
        auth: false,
        skipMixPanel: true,
        render: {
          searchBar: true,
          searchContainer: true,
          title: 'Explore members',
          tooltip: 'Search for Member Research Groups, People, Publications and more'
        },
        visibility: {
          key: 'search_visible',
          role: 'member,owner,admin'
        }
      },
      component: Explore
    }, {
      path: ':id/opportunities',
      name: 'community.opportunities',
      meta: {
        render: {
          searchBar: true,
          title: 'Opportunities'
        }
      },
      component: Opportunity
    }, {
      path: ':id/workspace',
      name: 'community.workspace',
      meta: {
        auth: false,
        render: {
          searchBar: true,
          checkForRenderInSubNavWorkspace: true,
          title: 'Workspace'
        },
        visibility: {
          key: 'pages_visible',
          role: 'member,owner,admin'
        }
      },
      component: Pages
    }, {
      path: ':id/questions',
      name: 'community.questions',
      redirect: ':id/questions',
      meta: {
        auth: false,
        render: {
          searchBar: true,
          title: 'Discussions',
          children: 'community.questions.question'
        },
        visibility: {
          key: 'questions_visible'
        }
      },
      component: QuestionsBase,
      children: [
        {
          path: '',
          name: 'community.questions.show',
          meta: {
            auth: false,
            render: {
              searchBar: true,
              title: 'Questions'
            }
          },
          component: QuestionsShow
        }, {
          path: ':question',
          name: 'community.questions.question',
          meta: {
            auth: false,
            render: {
              searchBar: true
            }
          },
          component: QuestionsQuestion
        }
      ]
    }, {
      path: ':id/members',
      name: 'community.members',
      meta: {
        auth: false,
        render: {
          searchBar: true,
          title: 'Members',
          tooltip: 'Manage member permissions'
        },
        permissions: {
          role: 'owner,admin',
          redirectTo: 'community.show'
        }
      },
      component: Member
    }, {
      path: ':id/invite',
      name: 'community.invite',
      meta: {
        auth: false,
        render: {
          searchBar: true,
          title: 'Manage invitations',
          tooltip: 'Invite members and see an overview of all invites sent and their current status'
        },
        permissions: {
          role: 'owner,admin',
          redirectTo: 'community.show'
        }
      },
      component: Invite
    }
  ]
}
