<template>
  <div class="p-4">
    <p class="t-600 t-lr mb-4">You must log in to do this action.</p>

    <div class="d-flex justify-content-end align-items-center pt-4">
      <button
        class="btn bg-transparent no-border t-primary mr-3"
        type="button"
        @click="$events.$emit('modal:close')"
      >Close</button>

      <router-link :to="{ name: 'login' }" class="btn btn-primary px-4 t-700 mr-3">
        Log in
      </router-link>

      <router-link @click.native="saveRouterURL()" :to="{ name: 'signup' }" class="btn btn-primary--green-dark px-4 t-700">
        Sign up
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loginModal',
  methods: {
    saveRouterURL () {
      let data = this.$ls.getLastVisitedPage()
      data.prevRoute = {}
      data.prevRoute.params = this.$route.params
      data.prevRoute.name = this.$route.name
      data.prevRoute.query = this.$route.query
      this.$ls.setLastVisitedPage(data)
    }
  },
  watch: {
    '$route.name' () {
      this.$events.$emit('modal:close')
    }
  }
}
</script>
