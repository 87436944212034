<template>
  <div :class="myClasses">
    <p v-for="(m, index) in message" :key="index" class="mb-0">
      <i v-if="variant === 'success'" class="fa fa-check t-green mr-2 t-sm"></i>
      <span v-if="variant === 'success'" class="t-600">Success: </span>

      <i v-if="variant === 'danger'" class="fa fa-times t-red mr-2 t-sm"></i>
      <span v-if="variant === 'danger'" class="t-600">Error: </span>

      <span v-html="getMessage(m)"></span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'staticMessages',
  props: {
    messages: {
      default: '',
      required: true
    },
    variant: {
      type: String,
      default: 'info'
    },
    containerClass: {
      type: String,
      default: ''
    },
    delay: {
      type: Number,
      default: 7000
    }
  },
  data () {
    return {
      message: ''
    }
  },
  mounted () {
    typeof (this.messages) === 'object' ? this.message = this.messages : this.message = [this.messages]
    if (this.delay !== -1) setTimeout(this.hide, this.delay)
  },
  computed: {
    myClasses () {
      return this.containerClass
        ? `alert alert-${this.variant} ${this.containerClass}`
        : `alert alert-${this.variant} mb-3`
    }
  },
  methods: {
    hide () {
      this.$emit('closed')
    },
    getMessage (m) {
      let err = this.$options.filters.humanize(m)
      return this.isEmpty(err) ? 'Something went wrong' : err
    }
  }
}
</script>
