import * as Sentry from '@sentry/browser'

export default {
  initialize (user) {
    if (process.env.VUE_APP_DEPLOY_ENV !== 'dev') {
      user ? this.setUser(user) : this.setPublic()
    }
  },
  setUser (user) {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: user.user_id,
        name: user.full_name,
        category: user.category.name,
        email: user.email
      })
    })
  },
  setPublic (user) {
    Sentry.configureScope(scope => {
      scope.setUser({
        name: 'Unknown'
      })
    })
  },
  captureMessage (e) {
    if (process.env.VUE_APP_DEPLOY_ENV !== 'dev') {
      Sentry.withScope(scope => {
        Sentry.captureMessage(e)
      })
    }
  },
  captureException (e) {
    if (process.env.VUE_APP_DEPLOY_ENV !== 'dev') {
      Sentry.withScope(scope => {
        Sentry.captureException(e)
      })
    }
  }
}
