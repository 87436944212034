import Vue from 'vue'

const ORCID = '/authenticate/orcid'
const ORCID_CONNECT = '/connect/orcid'
const ORCID_FETCH = '/fetch/orcid'

export default {
  authenticate (creds) {
    const couponCode = creds.couponCode ? `&coupon_code=${creds.couponCode}` : ''

    // category and email
    if (creds.category && (creds.email || creds.last_name)) {
      let url = `${process.env.VUE_APP_API_URL}${ORCID}?code=${creds.code}&category_id=${creds.category_id}${couponCode}`
      if (creds.email) url = `${url}&email=${creds.email}`
      if (creds.last_name) url = `${url}&last_name=${creds.last_name}`
      return Vue.prototype.$http.get(url).then(response => response.data)
    }

    return creds.category
      ? Vue.prototype.$http.get(`${process.env.VUE_APP_API_URL}${ORCID}?code=${creds.code}&category_id=${creds.category_id}${couponCode}`).then(response => response.data)
      : Vue.prototype.$http.get(`${process.env.VUE_APP_API_URL}${ORCID}?code=${creds.code}${couponCode}`).then(response => response.data)
  },
  connect (creds) {
    const couponCode = creds.couponCode ? `&coupon_code=${creds.couponCode}` : ''

    return Vue.prototype.$http.get(`${process.env.VUE_APP_API_URL}${ORCID_CONNECT}?code=${creds.code}${couponCode}`).then(response => response.data)
  },
  fetch (scope) {
    return Vue.prototype.$http.get(`${process.env.VUE_APP_API_URL}${ORCID_FETCH}?content=${scope}`).then(response => response.data)
  }
}
