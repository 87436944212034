<template>
  <li class="nav-item dropdown">
    <dropdown
      placement="bottom-end"
      noPadding
      @show="toggleDropdown"
    >
      <template #button>
        <a href="#" class="nav-link" @click.prevent aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-bell mr-sm-2 fade-alert" :class="{ 'clap-animating fade-alert-in': isRinging }" />
          <span class="t-500 d-none d-lg-inline-block" id="alerts-header-dropdown">Alerts</span>
        </a>
      </template>
      <div class="dropdown-menu-custom box-shadow dropdown-create" aria-labelledby="alerts-header-dropdown">
        <div class="dropdown-item d-flex align-items-center pb-2">
          <span class="dropdown-item_icon">
            <i class="fa fa-bell t-secondary" />
          </span>
          <span class="dropdown-item_text t-600 t-primary t-overflow d-flex justify-content-between">
            Alerts
            <i v-if="hasAlerts" class="t-lr fa fa-info-circle t-secondary cursor-pointer"
              v-tooltip="`When you create an alert for a search query or a profile page, we will send you an email when new results are available for your search query, or when the profile page is updated.`"
            />
          </span>
        </div>
        <div v-if="!isLoaded && !hasAlerts" class="d-flex align-content-center">
          <spinner :containerClass="`min-height--65px `" />
        </div>
        <div v-else class="inner-list">
          <template v-if="!hasAlerts">
            <router-link
              :to="{ name: 'searchHome' }"
              class="dropdown-item dropdown-item_text t-sm w-100 mt-2"
            >Search for your topic of interest and hit the "Create Alert" button to create your first alert.</router-link>
            <div class="dropdown-item dropdown-item_text w-100 mt-2">
              <span class="t-sm t-secondary">
                When you create an alert for a search or a profile page, we will send you an email when new results are available for your search, or when the profile page is updated.
              </span>
            </div>
          </template>
          <template v-else>
            <alert v-for="(alert, index) in alerts" :key="`alert-${index}`"
              alertType="alert"
              :alert="alert"
              @goToSearch="goToSearch(alert)"
              @deleteAlert="deleteSavedSearch(alert)"
            />

            <alert v-for="(researcher, index) in researchers" :key="`researcher-${index}`"
              alertType="researcher"
              :alert="researcher"
              @deleteAlert="() => deleteSavedSearch(researcher, 'researchers')"
            />

            <alert v-for="(researchGroup, index) in researchGroups" :key="`research_group-${index}`"
              alertType="research_group"
              :alert="researchGroup"
              @deleteAlert="() => deleteSavedSearch(researchGroup, 'research_groups')"
            />

            <alert v-for="(organisation, index) in organisations" :key="`organisation-${index}`"
              alertType="organisation"
              :alert="organisation"
              @deleteAlert="() => deleteSavedSearch(organisation, 'organisations')"
            />

          </template>
        </div>
      </div>
    </dropdown>
  </li>
</template>

<script>
import container from '@/mixins/search/container'
import Dropdown from '@/components/shared/form/Dropdown'
import Alert from './Alert'

import Spinner from '@/components/shared/Spinner'

import { mapActions, mapGetters } from 'vuex'

const TIMESTAMP_EPOCH = 't'

export default {
  name: 'headerAlertsDropdown',
  mixins: [container],
  components: {
    Alert,
    Spinner,
    Dropdown
  },
  data () {
    return {
      isLoaded: false,
      isRinging: false
    }
  },
  computed: {
    ...mapGetters({
      alerts: 'alerts/alerts',
      researchGroups: 'alerts/researchGroups',
      researchers: 'alerts/researchers',
      organisations: 'alerts/organisations'
    }),
    hasAlerts () {
      return this.alerts.length > 0 || this.researchGroups.length > 0 || this.researchers.length > 0 || this.organisations.length > 0
    }
  },
  created () {
    this.$events.$on('search:changed:saved', this.ringBell)
  },
  methods: {
    ...mapActions('alerts', [
      'getAlerts',
      'deleteAlert'
    ]),
    ringBell () {
      this.isRinging = true
      setTimeout(() => { this.isRinging = false }, 1600)
    },
    toggleDropdown () {
      this.fetchAlerts()
    },
    fetchAlerts () {
      this.isLoaded = false
      this.getAlerts({}).then(() => {
        this.isLoaded = true
      }, err => {
        this.$flash({ message: this.renderError(err) }, 'warning')
        this.isLoaded = true
      })
    },
    deleteSavedSearch (alert, type = '') {
      this.$dialog.confirm(`Are you sure you want to delete the alert "${alert.name}"?`, {
        okText: 'Delete',
        cancelText: 'Cancel',
        loader: true
      }).then((dialog) => {
        const id = type ? alert.slug_values[0] : alert.id

        this.deleteAlert({ id, type }).then(() => {
          this.$flash({ message: `Successfully deleted the alert for "${alert.name}"` }, 'success')
          dialog.close()
        }).catch(() => {
          // Something went wrong deleting
          this.$flash({ message: 'Could not delete this alert, please try again' }, 'danger')
          dialog.close()
        })
      }).catch(() => {
        // Clicked on cancel, do nothing
      })
    },
    goToSearch (alert) {
      const state = alert.data
      // Pass down timestamp of when the alert was sent out
      state.timestamp_epoch = alert.timestamp_epoch
      // Redirect to search home if index is all_categories (until these alerts are phased out completely)
      if (state.index === 'all_categories') {
        // Do the redirect
        const query = {
          keyword: state.keyword,
          [TIMESTAMP_EPOCH]: state.timestamp_epoch
        }
        // When user clicks on an alert that is already active on the landing page
        // We do not want to redirect.
        if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
          this.$router.push({
            name: 'searchHome',
            query: query
          })
        }
      } else {
        this.initSavedSearchFromState(state)
      }
    }
  },
  destroyed () {
    this.$events.$off('search:changed:saved')
  }
}
</script>
