<template>
  <base-modal :isLoaded="isLoaded"
    :isError404="isError404"
    :scope="scope"
    :data="data"
    :error="error"
    @clearError="error = null">
      <template #modal-content>
        <div class="d-flex justify-content-between align-items-baseline mb-3">
          <h2 class="t-secondary t-xlr">{{ scope | capitalize }} <span v-if="data.granted_date">granted</span><span v-else>application</span></h2>
          <div class="d-flex">
            <share
              :scope="scope"
              :itemName="data.title"
              inModal
            />
            <bookmark-folder
              v-if="!isEmpty(data) && !isEmpty($currentUser)"
              :currentItem="data"
              :scope="getScope"
              isButton
            />
          </div>
        </div>

        <div class="d-block">
          <h1 class="mb-3 t-700 t-xxlr" v-html="data.highlights.title"></h1>

          <div class="my-0 list-unstyled t-md">
            <template v-if="data.granted_date">
              <span class="t-500">Granted date:</span> <span>{{ data.granted_date | formatDate('YYYY-MM-DD') }}</span>
            </template>

            <template v-else-if="data.priority_date">
              <span class="t-500">Priority date:</span> <span>{{ data.priority_date | formatDate('YYYY-MM-DD') }}</span>
            </template>

            <template v-else-if="data.application_date">
              <span class="t-500">Application date:</span> <span>{{ data.application_date | formatDate('YYYY-MM-DD') }}</span>
            </template>
          </div>
        </div>

        <long-text-collapsible v-if="!isEmpty(data.highlights.abstract)"
          :title="'Abstract'"
          :text="data.highlights.abstract">
        </long-text-collapsible>

        <long-text-collapsible v-if="parsedClaims"
          :title="'Claims'"
          :text="parsedClaims">
        </long-text-collapsible>

        <template v-if="!isEmpty(data.full_texts) && isFullTextParsed">
          <h3 class="title-collapsible" @click.prevent="isFullTextCollapsed = !isFullTextCollapsed">
            <i class="fa" :class="{ 'fa-caret-down': !isFullTextCollapsed, 'fa-caret-right': isFullTextCollapsed }"></i>
            Full Text
            <font-size-editable :scope="'description'" @fontChanged="handleFontChanged"></font-size-editable>
          </h3>
          <div v-if="!isFullTextCollapsed" @click.prevent.stop="handleTransformFullTextClicked($event)">
            <long-text :text="data.full_texts"
              :class="'content-collapsible content-collapsible--parent'"
              :style="[fontStyles['description']]"
              :truncateAt="-1"
              :isReadMore="false"
              :isRichText="true">
            </long-text>
          </div>
        </template>
      </template>

      <template #modal-sidebar>
        <h2 class="t-secondary t-xlr mb-3">
          {{ data.patent_office }}
        </h2>

        <h2 class="mb-3 t-600">
          {{ data.country }} {{ data.publication_no }} {{ data.document_kind }}
        </h2>
        <template v-if="data.full_text_url">
          <h3 class="mb-2 t-600">
            Full Text Link
          </h3>
          <a
            :href="data.full_text_url"
            class="mb-4 d-flex align-items-center"
            target="_blank">View PDF (Espacenet) <i class="ml-2 fa fa-external-link" /></a>
        </template>

        <mixed-summary v-if="!isEmpty(inventors)"
          :records="inventors"
          :title="$options.filters.pluralize('Inventor', inventors.length)"
          class="mt-3">
        </mixed-summary>

        <mixed-summary v-if="!isEmpty(applicants)"
          :records="applicants"
          :title="$options.filters.pluralize('Applicant', applicants.length)"
          class="mt-3">
        </mixed-summary>

        <mixed-summary v-if="!isEmpty(assignees)"
          :records="assignees"
          :title="$options.filters.pluralize('Assignee', assignees.length)"
          class="mt-3">
        </mixed-summary>

        <mixed-summary v-if="!isEmpty(examiners)"
          :records="examiners"
          :title="$options.filters.pluralize('Examiner', examiners.length)"
          class="mt-3">
        </mixed-summary>

        <mixed-summary v-if="!isEmpty(assistantExaminers)"
          :records="assistantExaminers"
          :title="$options.filters.pluralize('Assistant Examiner', assistantExaminers.length)"
          class="mt-3">
        </mixed-summary>

        <mixed-summary v-if="!isEmpty(attorneys)"
          :records="attorneys"
          :title="$options.filters.pluralize('Attorney', attorneys.length)"
          class="mt-3">
        </mixed-summary>

        <template v-if="!isEmpty(data.classifications)">
          <h3 class="margin-t--40px mb-1 t-600">
            Classifications
          </h3>

          <ul class="list-unstyled mb-0">
            <li v-for="(classification, i) in data.classifications" :key="i"
              class="list-item mb-0 position-relative" :class="{ 'd-none': i !== 0 && !isViewAllClassifications }">
                <a :href="getClassificationLink(classification)" target="_blank"  class="position-relative">
                  {{ classification.code }}
                </a>
              </li>
          </ul>

          <a v-if="data.classifications.length > 1" href="#" @click.prevent="isViewAllClassifications = !isViewAllClassifications" class="t-sm">
            <span v-if="!isViewAllClassifications">view {{ data.classifications.length - 1 }} more classifications</span><span v-else>hide {{ data.classifications.length - 1 }} classifications</span>
          </a>
        </template>

        <template class="mb-0">
          <h3 class="margin-t--40px mb-1 t-600">
            Application {{ data.application_no }} Details
          </h3>

          <ul class="my-0 list-unstyled">
            <li v-if="data.priority_date" class="row-content row-content_text--large">
              <span class="row-content--main">
                <span class="t-400">Priority date:</span> {{ data.priority_date | formatDate('YYYY-MM-DD') }}
              </span>
            </li>

            <li v-if="data.application_date" class="row-content row-content_text--large">
              <span class="row-content--main">
                <span class="t-400">Application date:</span> {{ data.application_date | formatDate('YYYY-MM-DD') }}
              </span>
            </li>

            <li v-if="data.publication_date" class="row-content row-content_text--large">
              <span class="row-content--main">
                <span class="t-400">Publication date:</span> {{ data.publication_date | formatDate('YYYY-MM-DD') }}
              </span>
            </li>

            <li v-if="data.granted_date" class="row-content row-content_text--large">
              <span class="row-content--main">
                <span class="t-400">Granted date:</span> {{ data.granted_date | formatDate('YYYY-MM-DD') }}
              </span>
            </li>
          </ul>
        </template>

        <search-related-documents v-if="isLoaded"
          :filter="suggestionFilter"
          :parent="data"
          :routeName="'patent'"
          :indexes="['patents']">
        </search-related-documents>

        <identifiers :item="data" />
      </template>
  </base-modal>
</template>

<script>
import sharedJsModal from '@/mixins/search/results/baseModal'

import BaseModal from '@/components/search/results/BaseModal'
import LongText from '@/components/shared/LongText'
import LongTextCollapsible from '@/components/shared/LongTextCollapsible'
import FontSizeEditable from '@/components/shared/FontSizeEditable'
import SearchRelatedDocuments from '@/containers/SearchRelatedDocuments'
import MixedSummary from '@/components/shared/MixedSummary'
import BookmarkFolder from '@/components/bookmark_folder/Show'
import Identifiers from '@/components/qa/Identifiers'
import Share from '@/components/shared/profile/Share'

import PatentService from '@/services/patent'

export default {
  name: 'modalPatent',
  mixins: [sharedJsModal],
  components: {
    BaseModal,
    LongTextCollapsible,
    LongText,
    SearchRelatedDocuments,
    FontSizeEditable,
    MixedSummary,
    BookmarkFolder,
    Identifiers,
    Share
  },
  props: {
    slug: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      fullTextParsed: '',
      isFullTextCollapsed: false,
      isFullTextParsed: false,
      isViewAllClassifications: false,
      fontStyles: [],
      objectForHighlighting: {
        properties: ['title', 'abstract'],
        researchers: ['inventors.name', 'assignees.name', 'applicants.name', 'attorneys.name', 'examiners.name', 'assistant_examiners.name'],
        arrays: ['claims.value']
      },
      suggestionFilter: {
        key: 'patent',
        value: '',
        index: 'patents',
        global: false,
        suggestions: []
      }
    }
  },
  created () {
    this.scope = 'patent'
  },
  computed: {
    parsedClaims () {
      if (!this.data.highlights || !this.data.highlights.claims || this.data.highlights.claims.length === 0) return null
      let output = '<ol>'
      const sortedClaims = [...this.data.highlights.claims].sort((a, b) => a.order - b.order)
      sortedClaims.forEach(c => {
        output += `<li>${c.value}</li>`
      })
      output += '</ol>'
      return output
    },
    inventors () {
      if (!this.isEmpty(this.data.parties)) {
        return this.data.parties.filter(p => p.role === 'inventor').sort((a, b) => a.order - b.order)
      }
      return []
    },
    assignees () {
      if (!this.isEmpty(this.data.parties)) {
        return this.data.parties.filter(p => p.role === 'assignee').sort((a, b) => a.order - b.order)
      }
      return []
    },
    applicants () {
      if (!this.isEmpty(this.data.parties)) {
        return this.data.parties.filter(p => p.role === 'applicant').sort((a, b) => a.order - b.order)
      }
      return []
    },
    attorneys () {
      if (!this.isEmpty(this.data.parties)) {
        return this.data.parties.filter(p => p.role === 'attorney').sort((a, b) => a.order - b.order)
      }
      return []
    },
    examiners () {
      if (!this.isEmpty(this.data.parties)) {
        return this.data.parties.filter(p => p.role === 'examiner').sort((a, b) => a.order - b.order)
      }
      return []
    },
    assistantExaminers () {
      if (!this.isEmpty(this.data.parties)) {
        return this.data.parties.filter(p => p.role === 'assistant examiner').sort((a, b) => a.order - b.order)
      }
      return []
    }
  },
  watch: {
    '$route.query.patent' (newSlug, oldSlug) {
      if (!this.isEmpty(newSlug)) this.fetch(newSlug)
    }
  },
  methods: {
    fetch (slug) {
      this.isLoaded = false
      PatentService.getByID(slug).then(patent => {
        this.data = patent
        if (patent.classifications) {
          this.data.classifications = this.sortOrder(patent.classifications)
        } else {
          this.data.classifications = []
        }
        this.setResearchers()
        this.handleTransformFullText()
        this.fetchHighlights(this.$options.filters.pluralize(this.scope), slug)
        this.setSuggestions()
      }).catch(err => {
        this.handleCustomError(err)
      })
    },
    getClassificationLink (classification) {
      let words = classification.code.split(' ')
      return `https://worldwide.espacenet.com/classification?locale=en_EP#!/CPC=${words.join('')}`
    },
    setResearchers () {
      if (!this.isEmpty(this.data.inventors)) this.data.inventors = this.sortOrder(this.data.inventors)
      if (!this.isEmpty(this.data.assignees)) this.data.assignees = this.sortOrder(this.data.assignees)
      if (!this.isEmpty(this.data.applicants)) this.data.applicants = this.sortOrder(this.data.applicants)
    },
    sortOrder (data) {
      return data.slice().sort((a, b) => a.order - b.order)
    },
    handleFontChanged (fontStyle, scope) {
      this.$set(this.fontStyles, scope, fontStyle)
    },
    htmlParser (str) {
      let html = new DOMParser().parseFromString(str, 'text/html')
      return Array.from(html.body.children)
    },
    handleTransformFullText () {
      let headingHits = 0
      let latestTagName = ''

      if (!this.isEmpty(this.data.full_texts)) {
        this.data.full_texts.forEach(section => {
          if (headingHits > 0) this.fullTextParsed += latestTagName !== 'HEADING' ? `</div>` : `No sub content available</div>`
          const headingParsedText = this.$options.filters.titleize(this.removePunctuation(section.name))
          this.fullTextParsed += `<heading data-id="${headingHits}" class="title-collapsible"><i class="fa fa-caret-right"></i>${headingParsedText}</heading>`
          this.fullTextParsed += `<div class="d-none content-collapsible" data-id="${headingHits}">`
          headingHits++
          this.fullTextParsed += section.value
        })
      }

      if (headingHits > 0) this.fullTextParsed += `</div>`
      this.data.full_texts = this.fullTextParsed
      this.isFullTextParsed = true
    },
    removePunctuation (value) {
      if ((typeof value === 'string' || value instanceof String) && value !== '') {
        return value.replace(/[\])}[{(]/g, '')
      }
      return ''
    },
    handleTransformFullTextClicked ($el) {
      let clickedElement = $el.target.closest('HEADING')
        ? $el.target.closest('HEADING').getAttribute('data-id')
        : $el.target.getAttribute('data-id')
      let parsedText = this.htmlParser(this.fullTextParsed)
      this.fullTextParsed = ''

      parsedText.map((el, i) => {
        if (el.tagName === 'HEADING' && el.getAttribute('data-id') === clickedElement) {
          let iTag = el.getElementsByTagName('I')[0]
          if (iTag.classList[1].toString() === 'fa-caret-right') {
            iTag.classList.remove('fa-caret-right')
            iTag.classList.add('fa-caret-down')
          } else {
            iTag.classList.add('fa-caret-right')
            iTag.classList.remove('fa-caret-down')
          }

          this.fullTextParsed += el.outerHTML
        } else if (el.tagName === 'DIV' && el.getAttribute('data-id') === clickedElement) {
          let hasDisplayNone = false
          Array.from(el.classList).map((c, i) => { if (c === 'd-none') hasDisplayNone = true })
          hasDisplayNone ? el.classList.remove('d-none') : el.classList.add('d-none')

          this.fullTextParsed += el.outerHTML
        } else {
          this.fullTextParsed += el.outerHTML
        }
      })

      this.data.full_texts = this.fullTextParsed
    },
    setSuggestions () {
      this.suggestionFilter.suggestions = []
      this.suggestionFilter.suggestion_indexes = ['patents']
      this.suggestionFilter.values = this.data.slug_values
      this.suggestionFilter.suggestions.push({
        must: this.data.publication_no,
        must_not: this.data.slug_values
      })
    }
  }
}
</script>
