<template>
  <span
    v-if="!isEmpty(excludes)"
    class="t-sm bd-radius bd px-1 mr-1 t-primary d-inline-block mb-1"
    v-tooltip="`Exclude <b>${excludes.join(', ')}</b>`"
  >{{ excludes.length }} {{ 'exclude' | pluralize(excludes.length) }}</span>
</template>

<script>

export default {
  name: 'excludeLabels',
  props: {
    excludes: {
      type: Array,
      required: false
    }
  }
}
</script>
