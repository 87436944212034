import axios from 'axios'
import router from '@/router'
import Sentry from '@/plugins/sentry'
// import { UpolloClient, EventType } from '@upollo/web'

export default {
  install (Vue, options) {
    // if (process.env.VUE_APP_DEPLOY_ENV !== 'dev' && process.env.VUE_APP_UPOLLO_API_KEY) {
    //   Vue.prototype.$upollo = new UpolloClient(process.env.VUE_APP_UPOLLO_API_KEY)
    // }
    // set auth functions
    Vue.prototype.$auth = {
      login (auth, prevRoute) {
        Vue.prototype.$ls.removeRegisterData()

        this.setToken(auth.token)
        this.setCurrentUser(auth.metadata)
        Sentry.setUser(Vue.prototype.$currentUser)

        // redirect to correct route based on invite data
        if (Vue.prototype.$ls.getInviteData().token) {
          const route = router.resolve({ name: 'confirmation.invite', query: Vue.prototype.$ls.getInviteData().query })
          window.location.href = new URL(route.href, window.location.origin).href
          return
        }

        // redirect to onboarding, only for non public users
        if (Vue.prototype.$currentUser.category.name !== 'public') {
          if (Vue.prototype.$currentUser.step === 0 || Vue.prototype.$currentUser.step === -1) {
            const route = router.resolve({ name: 'onboarding.intro' })
            window.location.href = new URL(route.href, window.location.origin).href
            return
          }
          if (Vue.prototype.$currentUser.step < 3 && Vue.prototype.$currentUser.step !== -2) {
            const route = router.resolve({ name: 'onboarding.interests' })
            window.location.href = new URL(route.href, window.location.origin).href
            return
          }
          if (Vue.prototype.$currentUser.step === 3 && Vue.prototype.$currentUser.step !== -2) {
            const route = router.resolve({ name: 'onboarding.collaboration' })
            window.location.href = new URL(route.href, window.location.origin).href
            return
          }
        }

        // check if user comes\ from logout and wants to sign-in to other account
        if (Object.keys(prevRoute).length > 0 && prevRoute.name === 'researcher.show' && Vue.prototype.$currentUser.slug_values && Vue.prototype.$currentUser.slug_values.includes(prevRoute.params.id)) prevRoute = {}

        // redirect to prevRoute
        if (Object.keys(prevRoute).length === 0) {
          const route = router.resolve({ name: 'searchHome' })
          window.location.href = new URL(route.href, window.location.origin).href
        } else {
          const route = router.resolve({ name: prevRoute.name, params: prevRoute.params, query: prevRoute.query })
          window.location.href = new URL(route.href, window.location.origin).href
        }
      },
      logout () {
        Vue.prototype.$pusher.disconnect()
        Vue.prototype.$mp.logout(Vue.prototype.$currentUser)
        Vue.prototype.$ls.clearLS()
        Vue.prototype.$currentUser = null
        delete axios.defaults.headers.common.Authorization
        location.reload()
      },
      isValidToken () {
        let token = this.decodeToken()
        return !!(token !== undefined && Math.round(new Date() / 1000) < token.iat + (60 * 60 * 24 * 6))
      },
      setToken (token) {
        Vue.prototype.$axios.updateToken(token)
        Vue.prototype.$ls.setToken(token)
      },
      decodeToken () {
        let token = Vue.prototype.$ls.getToken()
        if (token) {
          let base64 = token.split('.')[1].replace('-', '+').replace('_', '/')
          return JSON.parse(window.atob(base64))
        }
      },
      getCurrentUser () {
        return Vue.prototype.$ls.getUser()
      },
      setCurrentUser (user) {
        // set user objects
        Vue.prototype.$currentUser = user
        Vue.prototype.$ls.setUser(user)

        // update research groups
        Vue.prototype.$set(Vue.prototype.$currentUser, 'research_groups', user.research_groups)
        Vue.prototype.$events.$emit('navbar:research_group:update:research_groups', user.research_groups)

        // update communities
        Vue.prototype.$set(Vue.prototype.$currentUser, 'communities', user.communities)
      },
      // Update $currentUser, sends along user object with the key-value pairs we want to update
      updateCurrentUser (user) {
        const newUser = Vue.prototype.$currentUser
        Object.entries(user).forEach(([key, value]) => {
          Vue.prototype.$set(newUser, key, value)
        })
        Vue.prototype.$currentUser = newUser
        Vue.prototype.$ls.setUser(Vue.prototype.$currentUser)
      },
      trackLogin (user) {
        // if (process.env.VUE_APP_DEPLOY_ENV === 'dev') return
        // Vue.prototype.$upollo.track(
        //   { userId: user.user_id, userEmail: user.email },
        //   EventType.EVENT_TYPE_LOGIN_SUCCESS
        // )
        // setTimeout(() => {
        //   this.heartBeat()
        // }, 1800000)
      },
      trackLoginAttempt (email) {
        // if (process.env.VUE_APP_DEPLOY_ENV === 'dev') return
        // Vue.prototype.$upollo.track(
        //   { userEmail: email },
        //   EventType.EVENT_TYPE_LOGIN
        // )
      },
      trackRegistration (email) {
        // if (process.env.VUE_APP_DEPLOY_ENV === 'dev') return
        // Vue.prototype.$upollo.track(
        //   { userEmail: email },
        //   EventType.EVENT_TYPE_REGISTER
        // )
      },
      trackRegistrationSuccess (email) {
        // if (process.env.VUE_APP_DEPLOY_ENV === 'dev') return
        // Vue.prototype.$upollo.track(
        //   { userEmail: email },
        //   EventType.EVENT_TYPE_REGISTER_SUCCESS
        // )
      },
      trackPage () {
        // if (process.env.VUE_APP_DEPLOY_ENV === 'dev') return
        // if (Vue.prototype.$currentUser) {
        //   Vue.prototype.$upollo.track(
        //     { userId: Vue.prototype.$currentUser.user_id, userEmail: Vue.prototype.$currentUser.email },
        //     EventType.EVENT_TYPE_PAGE_VISIT
        //   )
        // }
      },
      heartBeat () {
        // if (process.env.VUE_APP_DEPLOY_ENV === 'dev') return
        // if (Vue.prototype.$currentUser) {
        //   Vue.prototype.$upollo.track(
        //     { userId: Vue.prototype.$currentUser.user_id, userEmail: Vue.prototype.$currentUser.email },
        //     EventType.EVENT_TYPE_HEARTBEAT
        //   )

        //   setTimeout(() => {
        //     this.heartBeat()
        //   }, 1800000)
        // }
      }
    }

    // set currentUser
    Vue.prototype.$currentUser = Vue.prototype.$auth.getCurrentUser()
  }
}
