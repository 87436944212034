import Vue from 'vue'

const ENDPOINT = '/announcements'

export default {
  get () {
    return Vue.prototype.$http.get(`${ENDPOINT}`).then(response => response.data)
  },
  update  (ids) {
    return Vue.prototype.$http.put(`${ENDPOINT}`, { announcement: { ids: ids } }).then(response => response.data)
  }
}
