<template>
  <span class="font-style-editable pull-right" @click.stop>
    <span @click.prevent="handleDecreaseFontSize()" class="t-secondary t-400 pr-2 t-xs" :class="{'disabled': isDisabledFontSizeMin}">A-</span>
    <span @click.prevent="handleIncreaseFontSize()" class="t-secondary t-400 t-md" :class="{'t-placeholder disabled': isDisabledFontSizeMax}">A+</span>
  </span>
</template>

<script>
export default {
  name: 'fontSizeEditable',
  props: {
    scope: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      fontStyle: {
        fontSize: 16,
        lineHeight: 22
      },
      maxFontStyle: 22,
      minFontStyle: 14,
      isDisabledFontSizeMax: false,
      isDisabledFontSizeMin: false
    }
  },
  mounted () {
    this.handleEmit()
  },
  methods: {
    handleDecreaseFontSize (type, scope) {
      if (this.fontStyle.fontSize > this.minFontStyle) {
        this.fontStyle.fontSize--
        this.fontStyle.lineHeight -= 2
        this.isDisabledFontSizeMax = false
        this.isDisabledFontSizeMin = false
      } else {
        this.isDisabledFontSizeMin = true
      }

      this.handleEmit()
    },
    handleIncreaseFontSize (type, scope) {
      if (this.fontStyle.fontSize < this.maxFontStyle) {
        this.fontStyle.fontSize++
        this.fontStyle.lineHeight += 2
        this.isDisabledFontSizeMax = false
        this.isDisabledFontSizeMin = false
      } else {
        this.isDisabledFontSizeMax = true
      }

      this.handleEmit()
    },
    handleEmit () {
      let fontSize = {
        fontSize: `${this.fontStyle.fontSize}px`,
        lineHeight: `${this.fontStyle.lineHeight}px`
      }

      this.$emit('fontChanged', fontSize, this.scope)
    }
  }
}
</script>
