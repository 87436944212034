<template>
  <li class="mb-2">
    <div class="row-content">
      <span class="row-content--main">
        <i class="fa fa-user pr-2 t-secondary"></i>
        <researcher-link
          class="t-400"
          :researcher="researcher"
          :suggestSearchLink="!researcher.slug_values ? true : false"
          :showSuggestIcon="true">
        </researcher-link>
        <flag :title="renderCountryFlagTooltip(researcher.country)" v-if="!isEmpty(researcher.country) && !isEmpty(researcher.country.alpha_2)" :iso="researcher.country.alpha_2 | lowercase" :squared="false" class="flag bd-radius ml-2 t-xs" />
        <span v-if="subText" class="ml-2 t-primary t-600">{{ subText }}</span>
      </span>
    </div>

    <div v-for="(research_group, i) in parseResearchGroups(researcher.research_groups)" :key="i"
      class="row-content row-content_inline pl-3 d-flex">
      <span class="fa fa-users row-content--side width--20px mr-1" />
      <router-link
        class="flex-grow-0 t-overflow"
        v-tooltip="{content: `<p class='text-left mb-0'>${research_group.name}</p>`}"
        :to="{ name: 'research_group.show', params: { id: research_group.slug_values[0] }}">
        {{ research_group.name }}
      </router-link>
      <!-- We have to wrap flags in another span, to make display:flex behave properly -->
      <span v-if="research_group && research_group.country" >
        <flag :title="renderCountryFlagTooltip(research_group.country)" :iso="research_group.country.alpha_2 | lowercase" :squared="false" class="flag bd-radius t-xs ml-2" />
      </span>
    </div>

    <template v-if="!isEmpty(researcher.organisations)">
      <div v-for="{organisation, organisation_name} in parseOrganisations(researcher.organisations)" class="row-content row-content_inline pl-3 d-flex" :key="organisation_name">
        <span class="row-content--side width--20px mr-1" :class="renderOrganisationCategoryIcon(organisation.organisation_types)" />
          <router-link
            v-if="organisation.slug_values"
            v-tooltip.left="{ content: `<p class='text-left mb-0'>${organisation.original_name || organisation.name}</p>` }"
            class="flex-grow-0 t-overflow"
            :to="{ name: 'organisation.show', params: { id: organisation.slug_values[0] }}"
          >{{organisation.name}}</router-link>
          <span v-else
            class="flex-grow-0 t-overflow"
            v-tooltip.left="{ content: `<p class='text-left mb-0'>${organisation.original_name || organisation.name}</p>` }"
          >{{organisation.name}}</span>
        <!-- We have to wrap flags in another span, to make display:flex behave properly -->
        <span v-if="organisation && organisation.country">
          <flag :title="renderCountryFlagTooltip(organisation.country)" :iso="organisation.country.alpha_2 | lowercase" :squared="false" class="flag bd-radius t-xs ml-2" />
        </span>
        <img v-if="organisation.isAffiliationAtTimeOfWriting" class="ml-1 width--20px"
          :src="require('@/assets/img/icon/affiliation_at_time_of_writing.svg')"
          v-tooltip="'Affiliation at the time of writing'"
        />
      </div>
    </template>
  </li>
</template>

<script>
import ResearcherLink from '@/components/researcher/Link'

export default {
  name: 'researcherSummaryResearcher',
  components: {
    ResearcherLink
  },
  props: {
    researcher: {
      type: Object,
      required: true
    },
    subText: {
      type: String,
      required: false
    }
  },
  methods: {
    parseResearchGroups (groups) {
      if (!groups) return []
      return groups.filter(rg => rg.slug_values && rg.slug_values.length > 0)
    },
    parseOrganisations (organisations) {
      // Init new array for parsed organisations
      const parsedArray = []
      // Loop over all organisations
      organisations.forEach(organisation => {
        if (!organisation.name) return
        // Find index of the current organisation if it's already in our new array
        const index = parsedArray.findIndex(parsedOrg => {
          return organisation.name === parsedOrg.name
        })
        // If it's not present, simply push it to the new array
        // Also keep an array of individual organisation_names, in case we have duplicates
        if (index === -1) {
          parsedArray.push({
            organisation: organisation,
            organisation_name: organisation.name,
            organisation_names_array: [organisation.name]
          })
        } else {
          // If organisation is present on new array already, check if the organisation_name is different.
          // If it's different, append the organisation_name to the organisation_name in the parsed Array (this gets shown in the title attribute)
          if (parsedArray[index].organisation_names_array.findIndex(name => name === organisation.name) === -1) {
            parsedArray[index].organisation_name = `${parsedArray[index].organisation_name}<br /><br />${organisation.name}`
            parsedArray[index].organisation_names_array.push(organisation.name)
          }
        }
      })
      return parsedArray.sort((a, b) => a.from_researcher ? -1 : 1)
    }
  }
}
</script>
