<template>
  <router-link
    :to="url"
  >
    <div class="back-btn d-none d-sm-flex align-items-center ml-xl-5 ml-3 t-500">
      <i class="fa fa-solid fa-chevron-left mr-2 t-white" />
      <span class="t-white">{{this.lastClickedResult && this.lastClickedResult.fromSearch ? 'Back to Search' : 'Start a New Search'}}</span>
    </div>
    <div class="d-sm-none back-btn d-flex align-items-center t-500 navbar-brand">
      <i class="fa fa-arrow-left" />
      <span class="al-logo-text ml-2">AcademicLabs</span>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'barHeaderNavigation',
  computed: {
    ...mapGetters({
      lastClickedResult: 'search/lastClickedResult'
    }),
    url () {
      if (this.lastClickedResult.fromSearch) {
        return this.lastClickedResult.route || { name: 'searchHome' }
      } else {
        return { name: 'searchHome' }
      }
    }
  }
}
</script>
