import Vue from 'vue'

const ENDPOINT = '/organisations'
const FLASK_ENDPOINT = `${process.env.VUE_APP_FLASK_URL}/api`

export default {
  create (params) {
    return Vue.prototype.$http.post(`${ENDPOINT}`, { organisation: params }).then(response => response.data)
  },
  getBySlugValue (slugValue) {
    return Vue.prototype.$http.get(`${FLASK_ENDPOINT}/organisations/${slugValue}`).then(response => response.data)
  },
  getTooltipData (slugText) {
    return Vue.prototype.$http.get(`${FLASK_ENDPOINT}/pop_ups/organisation/${slugText}`).then(response => response.data)
  },
  updateByID (id, organisation) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}`, { organisation: organisation }).then(response => response.data)
  },
  getMembers (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/members`).then(response => response.data)
  },
  typeahead (query, l) {
    return Vue.prototype.$http.get(`${process.env.VUE_APP_SEARCH_URL}/organisation`, { params: { q: query, limit: l } }).then(response => response.data)
  }
}
