<template>
  <dropdown
    :placement="'top-end'"
    :offset="-44"
    :hasArrow="false"
    :class="{ 'mr-2': inModal }"
  >
    <template #button>
      <button
        class="btn btn-secondary width--100per t-600 mb-2 box-shadow"
        type="button"
        :class="{ 'py-1 px-4': inModal }"
        v-tooltip="`Share a link to this ${$options.filters.singularize(getIndexAsString(scope))} (accessible to anyone, even without an AcademicLabs account)`"
      >
        <i class="fa fa-share t-xs" />
        <span class="px-2 t-600">{{ buttonText }}</span>
        <i class="pt-1 fa fa-angle-down t-sm" />
      </button>
    </template>
    <link-button
      target="_blank"
      :href="emailText"
      icon="fa-envelope t-secondary width--20px"
      isHyperlink
      @click="trackMailClicked"
    >
      <span class="ml-1 flex-grow-1">Share link via email</span>
    </link-button>
    <link-button
      icon="fa-copy t-secondary width--20px"
      v-clipboard="copyLink" @success="handleCopySuccess" @error="handleCopyError"
    >
      <span class="ml-1 flex-grow-1">Copy link</span>
    </link-button>
  </dropdown>
</template>

<script>
import Dropdown from '@/components/shared/form/Dropdown'
import LinkButton from '@/components/shared/form/LinkButton'
import MPService from '@/services/mixpanel'

export default {
  name: 'sharePage',
  components: {
    Dropdown,
    LinkButton
  },
  props: {
    scope: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: false,
      default: () => ({})
    },
    itemName: {
      type: String,
      required: false,
      default: ''
    },
    inModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      buttonText: 'Share'
    }
  },
  computed: {
    routeToUse () {
      if (this.scope && this.inModal) {
        return this.$router.resolve({
          name: 'search',
          params: { category: this.$options.filters.pluralize(this.scope) },
          query: {
            [this.scope]: this.$route.query[this.scope],
            hr: this.$route.query.hr
          }
        }).href
      }
      const route = this.$route
      const fixedRoutes = ['researcher.show', 'research_group.show', 'organisation.show', 'community.show']

      if (fixedRoutes.indexOf(route.name) !== -1) return this.$router.resolve(this.$route).href
      return this.$route.href
    },
    copyLink () {
      return new URL(this.routeToUse, window.location.origin).href
    },
    documentTitle () {
      if (this.itemName) return this.itemName
      if (this.item.full_name) return this.item.full_name
      return this.item.name
    },
    emailText () {
      return `mailto:?subject=Check this interesting find on AcademicLabs&body=Dear colleague,%0D%0A%0D%0AI want to share this interesting find on AcademicLabs with you: ${this.documentTitle}. %0D%0A${encodeURIComponent(this.copyLink)} %0D%0A%0D%0AKind regards, %0D%0A${this.$currentUser ? this.$currentUser.full_name : ''}`
    }
  },
  methods: {
    trackMailClicked () {
      MPService.trackEvent('share_link_via_email', {
        scope: this.scope
      })
    },
    handleCopySuccess () {
      MPService.trackEvent('share_link_via_copy', {
        scope: this.scope
      })
      this.buttonText = 'Copied!'
      setTimeout(() => {
        this.buttonText = 'Share'
      }, 1500)
    },
    handleCopyError () {
      // this.$flash({ message: 'Could not copy to clipboad!' }, 'danger')
    }
  }
}
</script>
