import Vue from 'vue'

export default {
  domains (email) {
    return Vue.prototype.$http.get(`domains?email=${email}`).then(response => response.data)
  },
  typeahead (scope, query, l) {
    return Vue.prototype.$http.get(`/typeahead/${scope}`, { params: { q: query, limit: l } }).then(response => response.data)
  }
}
