<template>
  <div
    v-if="!isError"
    class="pp_avatar"
    :class="{'is-hover' : editable}"
    @click="open()"
    :style="`background-image: url('${url}')`"
    :title="name" :alt="name"
  >
    <div v-if="editable" class="pp_avatar_edit">
      <i class="fa fa-camera"></i>
    </div>
    <!-- This img doesn't get rendered, it is merely used to trigger the onError if needed -->
    <img :src="url" @error="notFound" class="d-none" />
  </div>
  <avatar-missing v-else :name="name" :maxChars="2" :isSmallClass="false"/>
</template>

<script>
import AvatarMissing from '@/components/shared/AvatarMissing'

export default {
  name: 'avatar',
  components: {
    AvatarMissing
  },
  props: {
    editable: Boolean,
    name: String,
    avatar: String
  },
  data () {
    return {
      isError: false
    }
  },
  computed: {
    url () {
      return `${process.env.VUE_APP_S3_URL}${this.avatar}`
    }
  },
  methods: {
    open () {
      if (this.editable) this.$emit('open', this.url)
    },
    notFound () {
      this.isError = true
    }
  }
}
</script>
