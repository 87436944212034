<template>
  <div class="pp_missing" :class="{...classObject, 'is-square' : isSquare}">
    <div v-if="editable" class="pp_missing_edit">
      <i class="fa fa-camera"></i>
    </div>
    <span :class="{'t-xxs' : isSmall}">{{ initials }}</span>
  </div>
</template>

<script>
export default {
  name: 'avatarMissing',
  props: {
    containerClass: {
      type: String,
      default: ''
    },
    maxChars: {
      type: Number,
      default: -1
    },
    isSmallClass: {
      type: Boolean,
      default: true
    },
    isSquare: {
      type: Boolean,
      default: false
    },
    name: String,
    editable: Boolean
  },
  computed: {
    classObject () {
      return this.editable ? `${this.containerClass} is-hover` : this.containerClass
    },
    isSmall (value) {
      if (this.name && this.name.match(/\b(\w)/g)) {
        let initials = this.name.match(/\b(\w)/g).join('').toUpperCase()
        return !!((initials.length > 2 && this.isSmallClass))
      } else {
        return false
      }
    },
    initials () {
      if (this.name) {
        if (this.name.match(/\b(\w)/g)) {
          let initials = this.name.match(/\b(\w)/g).join('').toUpperCase()
          return (initials.length >= this.maxChars && this.maxChars !== -1)
            ? initials.substring(0, this.maxChars)
            : initials
        } else {
          return this.name[0]
        }
      } else {
        return ''
      }
    }
  }
}
</script>
