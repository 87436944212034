import ls from '@/plugins/localstorage'

const Base = () => import(/* webpackChunkName: "login" */ '@/views/login/Base.vue')
const Login = () => import(/* webpackChunkName: "login" */ '@/views/login/Login.vue')
const LoginForgot = () => import(/* webpackChunkName: "login" */ '@/views/login/Forgot.vue')
const ResetPassword = () => import(/* webpackChunkName: "login" */ '@/views/password/ResetPassword.vue')
const Orcid = () => import(/* webpackChunkName: "login" */ '@/views/login/Orcid.vue')
const LinkedIn = () => import(/* webpackChunkName: "login" */ '@/views/login/LinkedIn.vue')

export default [
  {
    path: '/login',
    redirect: 'login',
    meta: {
      auth: false
    },
    name: 'login',
    props: true,
    component: Base,
    children: [
      {
        path: '',
        meta: { auth: false },
        props: true,
        component: Login,
        beforeEnter: (to, from, next) => {
          from && !from.meta.skipParams && from.name !== null ? to.params.prevRoute = from : to.params.prevRoute = {}
          return ls.getToken() && ls.getUser() ? next({ name: 'searchHome' }) : next()
        }
      }, {
        path: '/forgot',
        name: 'login.forgot',
        meta: { auth: false },
        props: true,
        component: LoginForgot
      }
    ]
  }, {
    path: '/reset-password',
    name: 'resetpassword',
    meta: {
      skipParams: true,
      auth: false
    },
    component: ResetPassword,
    beforeEnter: (to, from, next) => {
      from && !from.meta.skipParams && from.name !== null ? to.params.prevRoute = from : to.params.prevRoute = {}
      next()
    }
  }, {
    path: '/auth/orcid/callback',
    name: 'orcid.callback',
    meta: {
      auth: false
    },
    component: Orcid,
    beforeEnter: (to, from, next) => {
      from && !from.meta.skipParams && from.name !== null ? to.params.prevRoute = from : to.params.prevRoute = {}
      next()
    }
  }, {
    path: '/auth/linkedin/callback',
    name: 'linkedin.callback',
    meta: {
      auth: false
    },
    component: LinkedIn,
    beforeEnter: (to, from, next) => {
      from && !from.meta.skipParams && from.name !== null ? to.params.prevRoute = from : to.params.prevRoute = {}
      next()
    }
  }
]
