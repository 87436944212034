<template>
  <transition name="fade">
    <div class="modal modal-mask" tabindex="-1" role="dialog" aria-hidden="true" aria-modal="true" @click="$emit('close')">
      <div class="modal-dialog-l h-100 my-0 mx-auto d-flex flex-column justify-content-center" role="document">
        <div class="modal-content bd-none" @click.stop>

          <div class="modal-body t-primary" style="padding: 40px;">
            <h2 class="t-600 mb-2">Cookie Preferences</h2>
            <p class="pb-2">
              We use cookies and similar tools (collectively, "cookies") for the purposes described below and will apply your cookie preferences on this device. You might be asked to provide your consent again if you use another device.
            </p>
            <div class="d-flex justify-content-between">
              <h2 class="t-600 mb-2">Essential Cookies</h2>
              <span class="t-600 t-blue">Always active</span>
            </div>
            <p>
              Essential cookies are used to provide our services and cannot be disabled.
            </p>
            <block-collapsible :title="'Learn more about essential cookies'" class="pb-2">
              <div class="mt-2">
                <span class="d-block t-500">Pusher</span>
                <span class="d-block t-sm mb-2">Used to make the platform operational.</span>
                <div class="t-sm">
                  We use these types of cookies to:
                  <ul class="pl-4 mt-2">
                    <li>Recognize you when you sign in to use our services
                    <li>Present relevant content, providing other customized features and services</li>
                    <li>Prevent fraudulent activity and improve security</li>
                    <li>Keep track of your preferences</li>
                    <li>Gather data (restricted to AcademicLabs) through AcademicLabs-owned cookies in order to improve AcademicLabs content, products, and services and to measure and understand the performance of our services</li>
                  </ul>
                </div>
              </div>
            </block-collapsible>
            <div class="d-flex justify-content-between">
              <h2 class="t-600 my-2">Analytics Cookies</h2>
              <switches
                class="t-600 t-blue"
                v-model="settings.cookie_analytics"
                :text-enabled="`Active`"
                :text-disabled="`Inactive`"
              />
            </div>
            <p>
              These cookies collect information to help us understand how the site is being used or how effective our platform is. They help us to know which features are the most and least popular and to see how visitors use the AcademicLabs platform. These cookies may be set by us or by third-party providers whose services we have added to our pages.
            </p>
            <block-collapsible :title="'Learn more about analytics cookies'" class="pb-2">
              <div class="mt-2">
                <span class="d-block t-500">Google Analytics</span>
                <span class="d-block t-sm mb-2">Used to gather analytics.</span>
                <span class="d-block t-500">Intercom</span>
                <span class="d-block t-sm mb-2">Used to enable our chat window, guided product tours and gather analytics.</span>
                <span class="d-block t-500">Mixpanel</span>
                <span class="d-block t-sm mb-2">Used to gather analytics.</span>
                <span class="d-block t-500">Hotjar</span>
                <span class="d-block t-sm mb-2">Used to gather analytics.</span>
                <span class="d-block t-500">Hubspot</span>
                <span class="d-block t-sm mb-2">Used to gather analytics.</span>
                <span class="d-block t-500">PR Newswire</span>
                <span class="d-block t-sm mb-2">Used by PR Newswire to gather aggregate analytics when reading their press releases (see our <a href="https://www.academiclabs.com/cookie-policy" title="Cookie policy" alt="cookie-policy" target="_blank">Cookie policy</a> for details)</span>
              </div>
            </block-collapsible>
            <h2 class="t-600 my-2">Advertisement Cookies</h2>
            <p>
              AcademicLabs does not have any advertisement cookies.
            </p>
            <div class="text-right">
              <button type="button" class="btn btn-primary t-600" @click="confirmChoices">Confirm my choice</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BlockCollapsible from '@/components/shared/BlockCollapsible'
import Switches from '@/components/shared/Switches'

export default {
  name: 'cookieNotificationModal',
  components: {
    BlockCollapsible,
    Switches
  },
  data () {
    return {
      // Default settings
      settings: {
        acknowledged_cookies: true, // True by default, only gets set when submitting smth
        cookie_analytics: false
      }
    }
  },
  mounted () {
    // Prevent page scroll while modal is open
    document.body.classList.add('modal-open')

    const currentSettings = this.$currentUser ? this.$currentUser : this.$ls.getCookieSettings()

    // Store current settings
    this.settings.acknowledged_cookies = !!currentSettings.acknowledged_cookies
    this.settings.cookie_analytics = !!currentSettings.cookie_analytics
  },
  methods: {
    confirmChoices () {
      this.settings.acknowledged_cookies = true
      this.$emit('acknowledged', this.settings)
    }
  },
  destroyed () {
    document.body.classList.remove('modal-open')
  }
}
</script>
