<template>
  <div class="bd-t clearfix" :class="containerClassObject">
    <a v-if="isRemove" @click.prevent="$emit('remove')" href="#" class="t-regular t-600 t-sm pt-2 d-inline-block position-relative">
      <i class="fa fa-trash mr-2 t-red"></i>{{ removeText }}
    </a>

    <!-- save or edit -->
    <button type="submit" @click="$emit('save')" class="btn btn-form btn-primary px-4 t-700 position-relative pull-right" :class="[btnClass, { 'disabled cursor-disabled': isRequest || isDisabled }]">
      <span v-if="isCreateMode" class="pre-state-msg" :class="{'smooth-away' : isRequest}" v-html="createText"></span>
      <span v-if="isEditMode" class="pre-state-msg" :class="{'smooth-away' : isRequest}" v-html="editText"></span>
      <span class="dots" :class="{'hide': !isRequest}">{{ isRequestText }}</span>
    </button>

    <!-- cancel -->
    <a v-if="isCancel" @click.prevent="$emit('cancel')" href="#" class="t-sm mt-2 t-regular mr-3 pull-right">
      {{ cancelText }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'formButtons',
  props: {
    isEditMode: {
      type: Boolean,
      default: false
    },
    isCreateMode: {
      type: Boolean,
      default: true
    },
    isRemove: {
      type: Boolean,
      default: false
    },
    isRequest: {
      type: Boolean,
      default: false
    },
    isCancel: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isRequestText: {
      type: String,
      default: 'Working'
    },
    removeText: {
      type: String,
      default: 'Remove'
    },
    createText: {
      type: String,
      default: 'Save'
    },
    editText: {
      type: String,
      default: 'Save'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    containerClass: {
      type: String,
      default: ''
    },
    btnClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    containerClassObject () {
      return this.containerClass ? this.containerClass : `mt-3 pt-3`
    }
  }
}
</script>
