<template>
  <div class="centered-text-hr clearfix" :class="containerClass">
    <hr class="my-0" />
    <p class="mb-0 text-center t-600 t-sm">
      OR
    </p>
    <hr class="my-0" />
  </div>
</template>

<script>
export default {
  name: 'OrSeparator',
  props: {
    containerClass: {
      type: String,
      default: 'my-4'
    }
  }
}
</script>
