import Vue from 'vue'

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.event = (event) => {
      if (!(el === event.target || el.contains(event.target))) vnode.context[binding.expression](event)
    }
    document.body.addEventListener('click', el.event)
  },
  unbind (el) {
    document.body.removeEventListener('click', el.event)
  }
})

Vue.directive('click-outside-in-modal', {
  bind (el, binding, vnode) {
    el.event = (event) => {
      if (!(el === event.target || el.contains(event.target))) vnode.context[binding.expression](event)
    }

    let modal = document.getElementById('modal-body')
    if (modal) modal.addEventListener('click', el.event)
  },
  unbind (el) {
    let modal = document.getElementById('modal-body')
    if (modal) modal.removeEventListener('click', el.event)
  }
})

const currentValueProp = 'vLineClampValue'
const useFallbackFunc = 'webkitLineClamp' in document.body.style ? undefined : defaultFallbackFunc
const truncateText = (el, bindings, useFallbackFunc) => {
  let lines = parseInt(bindings.value)
  if (lines !== el[currentValueProp] && !isNaN(lines)) {
    el[currentValueProp] = lines
    useFallbackFunc ? useFallbackFunc(el, bindings, lines) : el.style.webkitLineClamp = lines || ''
  }
}

function defaultFallbackFunc (el, bindings, lines) {
  if (lines && lines !== -1) {
    let lineHeight = parseInt(bindings.arg)
    if (isNaN(lineHeight)) lineHeight = 25
    let maxHeight = lineHeight * lines
    lineHeight = parseInt(bindings.arg)
    el.style.maxHeight = maxHeight ? maxHeight + 'px' : ''
    el.style.overflowX = 'hidden'
    el.style.lineHeight = lineHeight + 'px'
  } else {
    el.style.maxHeight = el.style.overflowX = ''
  }
}

Vue.directive('line-clamp', {
  currentValue: 0,
  bind (el, bindings) {
    let styles = `
      display: block;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;`

    if (parseInt(bindings.value) === 1) styles += `word-break: break-all;`
    if (parseInt(bindings.value) !== -1) el.style.cssText += styles
  },
  inserted: (el, bindings) => truncateText(el, bindings, useFallbackFunc),
  updated: (el, bindings) => truncateText(el, bindings, useFallbackFunc),
  componentUpdated: (el, bindings) =>
    truncateText(el, bindings, useFallbackFunc)
})
