import staticData from '@/assets/js/_data'
import { mapActions } from 'vuex'

// URL Query parameters
const PAGE_PARAM = 'page'
const SORT_PARAM = 'sort'
const QUICK_FILTER = 'qf'
const TIMESTAMP_EPOCH = 't'
const HUMAN_READABLE_STR = 'hr'
const IGNORE_SYNONYMS = 'ig_s'

export default {
  data () {
    return {
      urlFilterAndBuckets: {},
      whiteListQueryParams: [
        PAGE_PARAM,
        SORT_PARAM,
        'keyword',
        'exclude',
        'search_by_fields',
        'product-tour',
        QUICK_FILTER,
        TIMESTAMP_EPOCH,
        HUMAN_READABLE_STR,
        IGNORE_SYNONYMS
      ],
      skipRouteWatcherFor: staticData.search_modals
    }
  },
  watch: {
    'state.index' (newVal, oldVal) {
      if (newVal && this.fixedSearchView) {
        this.setSearchView({ view: this.fixedSearchView, index: this.state.index })
      }
    }
  },
  computed: {
    indexConfiguration () {
      return staticData.index_configuration_options[this.state.index] || null
    },
    fixedResultComponent () {
      return (this.indexConfiguration && this.indexConfiguration.fixedResultComponent) || ''
    },
    withSearchBy () {
      if (this.indexConfiguration && this.indexConfiguration.hideSearchBy) {
        return false
      }
      return true
    },
    withFilters () {
      if (this.indexConfiguration && this.indexConfiguration.hideFilters) {
        return false
      }
      return true
    },
    fixedSearchView () { // 'block', 'list', 'landscape'
      if (this.indexConfiguration && this.indexConfiguration.fixedSearchView) {
        return this.indexConfiguration.fixedSearchView
      }
      return ''
    },
    calculatedCurrentPage () {
      return parseInt(this.state.page) / this.itemsPerPage + 1
    },
    lockedResultPlaceholders () {
      // No locks in scoped searches or for those with permissions
      if (this.isScopedSearch || this.$can('viewFullSearchResults') || this.state.index === 'news') return 0
      if (this.isMobile()) return 1
      if (this.isBlockView) return 3
      return 1
    }
  },
  methods: {
    ...mapActions('search', [
      'setSearchView'
    ]),
    initSearchFromQuery (stateFilters, isScopedSearch, index = '') {
      let initializeFilters = this.buildBucketsFromQuery(this.$route.params.buckets, stateFilters, true)

      let exclude = []
      if (this.$route.query.exclude) {
        // Exclude extracted from URL should be an array (is returned as string when there is only 1 exlude)
        if (Array.isArray(this.$route.query.exclude)) {
          exclude = this.$route.query.exclude
        } else {
          exclude = [this.$route.query.exclude]
        }
      }

      this.setState('exclude', exclude || [])
      this.setState('keyword', this.cleanKeyword(this.$route.query.keyword) || '')
      this.setState('index', index || this.$route.params.category)
      this.setState('sort', this.$route.query[SORT_PARAM] || '')
      this.setState('filters', initializeFilters.filters)
      this.setState('filter_order', initializeFilters.filter_order)
      this.setState('term_suggestions', [])
      const isExactMatch = this.$route.query?.[QUICK_FILTER] && this.$route.query[QUICK_FILTER].includes('exact_match')
      this.setState('exact_match', isExactMatch)
      const fields = this.$route.query?.search_by_fields ? this.$route.query.search_by_fields.split(',') : []
      this.setState('search_by_fields', fields)

      let ignoreSynonyms = this.$route.query?.[IGNORE_SYNONYMS]
      if (typeof ignoreSynonyms === 'string') {
        if (ignoreSynonyms === 'true') ignoreSynonyms = true
        else ignoreSynonyms = false
      }
      this.setState('ignore_synonyms', ignoreSynonyms)

      if (!isScopedSearch) {
        this.setState('suggestions', [])
        this.setState('suggestion_indexes', [])
      }

      this.cleanQueryString()
      this.setPage(this.$route.query[PAGE_PARAM])
    },
    initSavedSearchFromState (state) {
      let queryObj = {}
      let paramsObj = {}
      // build query object
      if (!this.isEmpty(state.keyword)) queryObj.keyword = this.cleanKeyword(state.keyword)
      if (!this.isEmpty(state.exclude)) queryObj.exclude = state.exclude
      queryObj.search_by_fields = state.search_by_fields ? state.search_by_fields.join(',') : ''

      // build param object
      paramsObj.category = state.index
      this.initURLParamBucketsArray(Object.assign({}, state.filters), Object.assign([], state.filter_order))

      if (!this.isEmpty(this.urlFilterAndBuckets)) paramsObj.buckets = this.getBucketsAsStringForURL(state.filter_order)
      // Timestamp when saved search reminder mail was sent to the user
      if (state.timestamp_epoch) {
        queryObj[TIMESTAMP_EPOCH] = state.timestamp_epoch
      }
      // Default sorting for saved searches
      queryObj[SORT_PARAM] = 'last_added'

      if (state.ignore_synonyms) {
        queryObj[IGNORE_SYNONYMS] = true
      }

      this.resetURLQueryPage(queryObj)
      this.cleanQueryString()

      this.pushToRoute(paramsObj, queryObj, true, 'search')
    },
    initURLParamBucketsArray (stateFilters, stateFilterOrder) {
      for (let filterName in stateFilters) {
        if (!stateFilters[filterName].hide && !this.isEmpty(stateFilters[filterName].buckets)) {
          stateFilters[filterName].buckets.forEach((b) => {
            // if (b.active && this.isReferrer && !stateFilterOrder.includes(filter)) stateFilterOrder.push(filter)
            if (b.active && !stateFilterOrder.includes(filterName)) stateFilterOrder.push(filterName)
            if (b.active) this.constructURLParamBucketsArray(filterName, b)
          })
        }
      }
      return null
      // return { filters: stateFilters, filter_order: stateFilterOrder }
    },
    buildBucketsFromQuery (queryFilters, stateFilters, initializeBucketsArray = false) {
      let filterOrder = []
      let filters = this.cleanFilters(stateFilters)

      // NOTE: We add this filter order, else scoped search is broken
      if (this.isScopedSearch) filterOrder.push(this.isWithFixedFilter.key)

      if (!this.isEmpty(queryFilters)) {
        queryFilters = this.cleanQueryFilters(queryFilters)

        // loop over all filters defined in the query
        for (let qFilter of queryFilters) {
          let splitFilter = qFilter.split(/:(.+)/)

          // check if we have a valid filter object
          if (splitFilter[0] !== undefined && splitFilter[1] !== undefined) {
            let filterName = splitFilter[0]
            let buckets = splitFilter[1].split(',')

            filterOrder.push(filterName)

            // check if filters has current filterName and has buckets
            filters = this.checkIfFilterIsBuildProperly(filters, filterName)

            for (let bucket of buckets) {
              // grab the current bucket in the state filters
              let b = filters[filterName].buckets.find(el => { return el.value === bucket })
              // set bucket on active if bucket is already in the state
              // if not, push it to the filter
              let newBucket = { value: bucket, active: true }
              b !== undefined ? b.active = true : filters[filterName].buckets.push(newBucket)
              // append bucket in to the params object, only on load of container
              if (initializeBucketsArray) this.constructURLParamBucketsArray(filterName, newBucket)
            }
          }
        }
      }

      return { filters: filters, filter_order: filterOrder }
    },
    constructURLParamBucketsArray (filterName, bucket) {
      if (this.isEmpty(this.urlFilterAndBuckets[filterName])) this.urlFilterAndBuckets[filterName] = ''
      // push with or without comma
      this.urlFilterAndBuckets[filterName].length > 0
        ? this.urlFilterAndBuckets[filterName] += `,${bucket.value}`
        : this.urlFilterAndBuckets[filterName] += `${bucket.value}`
    },
    setPage (page) {
      this.setState(PAGE_PARAM, (!page ? 0 : parseInt(page) - 1) * this.itemsPerPage)
    },
    cleanFilters (filters) {
      for (let filterName in filters) {
        if (!filters[filterName].hide && !this.isEmpty(filters[filterName].buckets)) {
          for (let bucket of filters[filterName].buckets) bucket.active = false
        }
      }
      return filters
    },
    cleanFilterOrder (filters, stateFilterOrder) {
      // check if we have filters in our state filter order that we need to remove because they are not present
      // on the indice, if we remove
      const filterArray = Object.keys(filters)
      let shouldUpdateQueryParamBasedOnRemovedFilter = false
      for (let filter of stateFilterOrder) {
        if (!filterArray.includes(filter)) {
          Object.keys(this.urlFilterAndBuckets).forEach((name, i) => {
            if (name === filter) delete this.urlFilterAndBuckets[name]
          })

          stateFilterOrder = stateFilterOrder.filter(e => e !== filter)
          shouldUpdateQueryParamBasedOnRemovedFilter = true
        } else {
          // Additional check to remove other "active" filters, without active buckets, from the URL
          const hasActiveFilters = filters[filter]?.buckets?.find(b => b.active)
          if (!hasActiveFilters) {
            // TODO: Check this behaviour in scopeds earchers for bugs
            Object.keys(this.urlFilterAndBuckets).forEach(name => {
              if (name === filter) delete this.urlFilterAndBuckets[name]
            })
            stateFilterOrder = stateFilterOrder.filter(e => e !== filter)
            shouldUpdateQueryParamBasedOnRemovedFilter = true
          }
        }
      }

      if (shouldUpdateQueryParamBasedOnRemovedFilter) {
        this.setDisableRouteWatcherOnTrue('from-replace-bucket-query-param')
        this.updateURLParamBuckets(shouldUpdateQueryParamBasedOnRemovedFilter)
      }

      return stateFilterOrder
    },
    cleanQueryString () {
      let queryObj = this.getQueryObj
      let paramsObj = this.getParamsObj

      let shouldCleanup = false

      for (let q in queryObj) {
        if (!this.whiteListQueryParams.concat(this.skipRouteWatcherFor).includes(q)) {
          shouldCleanup = true
          queryObj = this.resetURLQueryByValue(queryObj, q)
        }

        // cleaning up for max page
        if (q === PAGE_PARAM && parseInt(queryObj[q]) > this.maxPages) {
          shouldCleanup = true
          queryObj[PAGE_PARAM] = this.maxPages
        }
      }
      // reset page parameter if value isn't a number
      if (queryObj && queryObj[PAGE_PARAM] && isNaN(queryObj[PAGE_PARAM])) {
        shouldCleanup = true
        delete queryObj[PAGE_PARAM]
      }

      if (shouldCleanup) {
        this.replaceToRoute(paramsObj, queryObj)
        this.setDisableRouteWatcherOnTrue('from-replace-bucket-query-param')
      }
    },
    checkIfFilterIsBuildProperly (filters, filterName, isFilterHidden = false) {
      if (this.isEmpty(filters[filterName])) filters[filterName] = {}
      if (this.isEmpty(filters[filterName].buckets)) filters[filterName].buckets = []
      if (isFilterHidden) filters[filterName].hide = true
      return filters
    },
    checkForValidCategory (index) {
      return !!staticData.indexes.find(el => { return el.index === index })
    },
    getBucketsAsStringForURL (filterOrder = null) {
      let filterForUrl = ''
      // If filterOrder is passed, we make sure the buckets respect the same order
      if (filterOrder) {
        filterOrder.forEach((filterName, i) => {
          // Don't want to add bookmark_folder bucket to URL as regular bucket
          if (filterName === 'bookmark_folder') return
          // If the filter has no value, no need to add it to the route, usually "hidden" filters
          if (!this.urlFilterAndBuckets[filterName]) return

          if (i > 0 || i < filterOrder.length) filterForUrl += '/'
          filterForUrl += `${filterName}:`
          filterForUrl += this.urlFilterAndBuckets[filterName]
        })
      } else {
        // No filterOrder passed, base ourselves on this.urlFilterAndBuckets
        Object.keys(this.urlFilterAndBuckets).forEach((filterName, i) => {
          // Don't want to add bookmark_folder bucket to URL as regular bucket
          if (filterName === 'bookmark_folder') return
          // If the filter has no value, no need to add it to the route
          if (!this.urlFilterAndBuckets[filterName]) return

          if (i > 0 || i < this.urlFilterAndBuckets.length) filterForUrl += '/'
          filterForUrl += `${filterName}:`
          filterForUrl += this.urlFilterAndBuckets[filterName]
        })
      }
      return filterForUrl
    },
    updateURLQuery (key, value, skipPushToRoute = false) {
      let queryObj = this.getQueryObj
      let paramsObj = this.getParamsObj

      // push value in query object
      if (!this.isEmpty(value)) queryObj[key] = value
      // delete value in query object
      if (this.isEmpty(value) && queryObj[key] !== undefined) {
        delete queryObj[key]
      }
      // Clear timestamp from URL if anything changes, except the page
      if (key !== PAGE_PARAM) {
        delete queryObj[TIMESTAMP_EPOCH]
      }

      // Clear/reset page parameter when any URL query (other than page) is changed
      if (key !== PAGE_PARAM) queryObj = this.resetURLQueryPage(this.getQueryObj)

      if (!skipPushToRoute) {
        this.pushToRoute(paramsObj, queryObj)
      }
    },
    updateURLParamBuckets (fromCleanup = false) {
      let paramsObj = this.getParamsObj
      let queryObj = this.getQueryObj

      paramsObj.buckets = this.getBucketsAsStringForURL()

      if (!fromCleanup && this.activeFilterSets && this.activeFilterSets.length > 0) {
        queryObj[QUICK_FILTER] = this.activeFilterSets.join(',')
      } else {
        delete queryObj[QUICK_FILTER]
      }
      // Clear timestamp from URL if filters changes
      if (!fromCleanup) {
        delete queryObj[TIMESTAMP_EPOCH]
      }

      if (this.state.sort) {
        queryObj[SORT_PARAM] = this.state.sort
      }

      queryObj = this.resetURLQueryPage(this.getQueryObj)

      !fromCleanup ? this.pushToRoute(paramsObj, queryObj) : this.replaceToRoute(paramsObj, queryObj)
    },
    updateURLParamCategory (index) {
      let paramsObj = this.getParamsObj
      let queryObj = this.getQueryObj

      paramsObj.category = index
      // Clear quick filters (except for some that can persist across indexes) and timestamp if index changes
      if (queryObj[QUICK_FILTER]) {
        const persistentQuickFilters = ['exact_match', 'active_in_country', 'kols']
        const currentQuickFilters = queryObj[QUICK_FILTER].split(',')
          .filter(qf => persistentQuickFilters.find(pf => pf === qf))
          .join(',')

        if (currentQuickFilters) queryObj[QUICK_FILTER] = currentQuickFilters
        else delete queryObj[QUICK_FILTER]
      }
      delete queryObj[TIMESTAMP_EPOCH]
      queryObj = this.resetURLQueryPage(queryObj)
      queryObj = this.resetURLQuerySort(queryObj)
      delete queryObj['search_by_fields']
      this.pushToRoute(paramsObj, queryObj)
    },
    resetURLQueryByValue (queryObj, value) {
      delete queryObj[value]
      return queryObj
    },
    resetURLQueryPage (queryObj) {
      if (queryObj.hasOwnProperty(PAGE_PARAM)) delete queryObj[PAGE_PARAM]
      return queryObj
    },
    resetURLQuerySort (queryObj) {
      if (queryObj.hasOwnProperty(SORT_PARAM)) delete queryObj[SORT_PARAM]
      return queryObj
    },
    cleanQueryFilters (filters) {
      // push buckets to the URL (https://github.com/vuejs/vue-router/issues/1175)
      if (!Array.isArray(filters)) filters = (filters || '').split('/')
      return filters
    },
    updateSearchThroughRouteWatch (to, from) {
      // check if category is changed
      if (to.params.category !== from.params.category) {
        this.setState('index', to.params.category || this.state.index)
        this.setState('scrollToFilter', 'top')
        this.setState('search_by_fields', [])
        this.setState('term_suggestions', [])
        // We want to "reset" filters when swapping index as well as scroll back to the top.
        this.isFilterLoaded = false
        this.isFilterSetLoaded = false
        this.isInitSearchLoaded = false
      }

      // check if bucket is changed
      // if (to.params.buckets !== from.params.buckets) {
      const initializeFilters = this.buildBucketsFromQuery(to.params.buckets, Object.assign({}, this.state.filters))
      this.setState('filter_order', initializeFilters.filter_order)
      this.setState('filters', initializeFilters.filters)
      // Handle quick filters
      if (to.query && to.query[QUICK_FILTER]) {
        const isExactMatch = to.query[QUICK_FILTER].includes('exact_match')
        this.setState('exact_match', isExactMatch)
        // Make sure local filterSets state is accurate
        if (this.filterSets && this.filterSets.buckets) {
          const exactMatchFilter = this.filterSets.buckets.find(b => b.value === 'exact_match')
          if (exactMatchFilter) exactMatchFilter.active = isExactMatch
        }
      } else {
        // If no quick filters are active, make sure they're all deactivated
        this.setState('exact_match', false)
        if (this.filterSets && this.filterSets.buckets) {
          this.filterSets.buckets.forEach(bucket => {
            bucket.active = false
          })
        }
      }
      // Update urlFilterAndBuckets to be up to date with new buckets
      Object.entries(initializeFilters.filters).forEach(([filterName, filter]) => {
        if (!filter.buckets || filter.buckets.length === 0) return
        const bucketArray = []
        filter.buckets.forEach(bucket => {
          if (bucket.active) bucketArray.push(bucket.value)
        })
        if (bucketArray.length > 0) {
          this.urlFilterAndBuckets[filterName] = bucketArray.join(',')
        } else {
          delete this.urlFilterAndBuckets[filterName]
        }
      })

      // check if keyword query is changed
      if (to.query.keyword !== from.query.keyword) {
        this.setState('keyword', this.cleanKeyword(to.query.keyword) || '')
        this.setState('term_suggestions', [])
      }
      // check if exclude query is changed
      if (to.query.exclude !== from.query.exclude) {
        if (to.query.exclude) {
          let exclude
          if (Array.isArray(to.query.exclude)) {
            exclude = to.query.exclude
          } else {
            exclude = [to.query.exclude]
          }
          this.setState('exclude', exclude || [])
        } else {
          this.setState('exclude', [])
        }
      }
      // check if search by fields is changed
      if (to.query?.search_by_fields || from.query?.search_by_fields) {
        const fields = to.query.search_by_fields && !this.isEmpty(to.query.search_by_fields) ? to.query.search_by_fields.split(',') : []
        this.setState('search_by_fields', fields)
      }
      // check if sort is changed
      if (to.query[SORT_PARAM] !== from.query[SORT_PARAM]) this.setState(SORT_PARAM, to.query[SORT_PARAM] || '')

      if (IGNORE_SYNONYMS in to.query) {
        let ignoreSynonyms = to.query?.[IGNORE_SYNONYMS]
        if (typeof ignoreSynonyms === 'string') {
          if (ignoreSynonyms === 'true') ignoreSynonyms = true
          else ignoreSynonyms = false
        }
        this.setState('ignore_synonyms', ignoreSynonyms)
      } else {
        this.setState('ignore_synonyms', false)
      }

      // Remove this parameter as it has served its purpose by allowing this code to run in the first place
      if (to.query.updated) {
        delete to.query.updated
        this.replaceToRoute(to.params, to.query)
      }

      this.setPage(to.query[PAGE_PARAM])
      this.search()
    },
    pushToRoute (paramsObj, queryObj, resetBuckets = false, routeName = null) {
      // compare two objects, deep equal
      // reset buckets if we're coming from reset
      if (resetBuckets) this.urlFilterAndBuckets = {}
      if (!this.isEmpty(paramsObj.buckets)) paramsObj.buckets = this.cleanQueryFilters(paramsObj.buckets)
      if (paramsObj.hasOwnProperty('buckets') && this.isEmpty(paramsObj.buckets)) delete paramsObj.buckets

      const newRoute = this.$router.resolve({ name: routeName || this.$route.name, params: paramsObj, query: queryObj })
      const resolvedRoute = newRoute.resolved.fullPath
      const oldRoute = this.$route.fullPath
      // Compare full routes to see if they have changed
      if (resolvedRoute !== oldRoute) {
        this.$router.push({ name: routeName || this.$route.name, params: paramsObj, query: queryObj })
      }
    },
    replaceToRoute (paramsObj, queryObj) {
      // compare two objects, deep equal
      if (!this.isEmpty(paramsObj.buckets)) paramsObj.buckets = this.cleanQueryFilters(paramsObj.buckets)
      if (paramsObj.hasOwnProperty('buckets') && this.isEmpty(paramsObj.buckets)) delete paramsObj.buckets
      if (JSON.stringify(this.$route.params) !== JSON.stringify(paramsObj) || JSON.stringify(this.$route.query) !== JSON.stringify(queryObj)) {
        this.$router.replace({ name: this.$route.name, params: paramsObj, query: queryObj })
      }
    }
  }
}
