<template>
  <li class="nav-item dropdown">
    <dropdown placement="bottom-end" noPadding @show="fetchAnnouncements">
      <template #button>
        <a href="#" @click.prevent class="nav-link">
          <i class="fa fa-gift mr-sm-2 fade-alert" :class="{ 'clap-animating fade-alert-in': count > 0 }" />
          <span class="t-500 d-none d-lg-inline-block" id="notification-header-dropdown">New</span>
          <i class="fa fa-circle t-xxxs t-yellow fa-info-absolute fa-info-absolute--navbar" v-if="count > 0" />
        </a>
      </template>
      <div class="dropdown-menu-custom box-shadow dropdown-create" aria-labelledby="new-dropdown">
        <div class="dropdown-item d-flex align-items-center pb-2">
          <span class="dropdown-item_icon">
            <i class="fa fa-gift t-secondary" />
          </span>
          <span class="dropdown-item_text t-primary t-overflow d-flex justify-content-between">
            <span class="t-600">New</span>
            <a v-if="count > 0" href="#" @click.prevent="readAll()" class="pull-right t-sm">Mark all as read</a>
          </span>
        </div>
        <div v-if="!isLoaded && !hasAnnouncements" class="d-flex align-content-center">
          <spinner :containerClass="`min-height--65px `" />
        </div>
        <div v-else class="inner-list">
          <template v-if="!hasAnnouncements">
            <span class="dropdown-item">No announcements</span>
          </template>
          <template v-else>
            <a v-for="announcement in announcements"
              :key="announcement.id"
              :href="announcement.url"
              target="_blank"
              class="dropdown-item clearfix"
              @click="readAnnouncement(announcement)"
            >
              <span class="dropdown-announcement-title">{{ announcement.title }}</span>
              <span v-if="!announcement.seen" class="label-base bg-yellow pull-right">NEW</span>
            </a>
          </template>
        </div>
        <div class="dropdown-divider"></div>
        <a
          href="https://academiclabscom.notion.site/5bbba5a0c02241b199aefbd82ade60c4"
          target="_blank"
          class="dropdown-item clearfix"
        >
          <i class="fa fa-folder-open t-secondary" /> Show all new & upcoming features
        </a>
      </div>
    </dropdown>
  </li>
</template>

<script>
import Spinner from '@/components/shared/Spinner'
import Dropdown from '@/components/shared/form/Dropdown'

import AnnouncementService from '@/services/announcements'

export default {
  name: 'announcementsDropdown',
  components: {
    Spinner,
    Dropdown
  },
  data () {
    return {
      isLoaded: false,
      count: 0,
      announcements: [],
      error: null
    }
  },
  computed: {
    hasAnnouncements () {
      return this.announcements.length > 0
    }
  },
  created () {
    this.count = this.$currentUser.announcements_count

    document.addEventListener('visibilitychange', (e) => {
      if (document.visibilityState === 'visible') {
        this.fetchAnnouncements()
      }
    })
  },
  mounted () {
    this.$events.$on('pusher:announcements-new', (announcement) => {
      this.announcements.unshift(announcement)
      this.count += 1
    })
  },
  methods: {
    fetchAnnouncements () {
      AnnouncementService.get().then(announcements => {
        this.announcements = announcements
        this.isLoaded = true
        this.count = this.announcements.filter(announcement => !announcement.seen).length
      }).catch((err) => {
        this.error = this.handleError(err)
        this.isLoaded = !this.isEmpty(this.error)
      })
    },
    readAnnouncement (announcement) {
      let ids = []
      ids.push(announcement.id)
      AnnouncementService.update(ids)
      announcement.seen = true
      this.count -= 1
    },
    readAll () {
      AnnouncementService.update([])
      this.announcements.map((a) => { a.seen = true })
      this.count = 0
    }
  },
  destroyed () {
    this.$events.$off('pusher:announcements-new')
  }
}
</script>
