import Vue from 'vue'

const LOGIN_URL = '/authenticate'
const REFRESH_TOKEN = '/refresh_token'
const SIGN_UP = '/sign_up'
const CONFIRMATION = '/confirmation'
const CHANGE_PASSWORD = '/change_password'
const RESET_PASSWORD = '/reset_password'
const PERMISSIONS = '/permissions'

export default {
  login (credentials) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_API_URL}${LOGIN_URL}`, credentials).then(response => response.data)
  },
  refreshToken () {
    return Vue.prototype.$http.put(`${process.env.VUE_APP_API_URL}${REFRESH_TOKEN}`).then(response => response.data)
  },
  register (credentials) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_API_URL}${SIGN_UP}`, { user: credentials }).then(response => response.data)
  },
  confirmationEmail (token) {
    return Vue.prototype.$http.get(`${process.env.VUE_APP_API_URL}${CONFIRMATION}?confirmation_token=${token}`).then(response => response.data)
  },
  confirmationResend (email) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_API_URL}${CONFIRMATION}`, { user: email }).then(response => response.data)
  },
  passwordResetRequest (email) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_API_URL}${RESET_PASSWORD}`, { user: email }).then(response => response.data)
  },
  passwordReset (credentials) {
    return Vue.prototype.$http.put(`${process.env.VUE_APP_API_URL}${RESET_PASSWORD}`, { user: credentials }).then(response => response.data)
  },
  passwordChange (credentials) {
    return Vue.prototype.$http.put(`${process.env.VUE_APP_API_URL}${CHANGE_PASSWORD}`, { user: credentials }).then(response => response.data)
  },
  permissions (permissions) {
    return Vue.prototype.$http.post(`${PERMISSIONS}`, { permission: permissions }).then(response => response.data)
  },
  me () {
    return Vue.prototype.$http.get(`${process.env.VUE_APP_API_URL}/me`).then(response => response.data)
  }
}
