<template>
  <div>
    <div v-if="scope === 'researchers' && item.ranking_h_index">
      <ul class="list-unstyled mb-0">
        <li class="list-item"
          v-tooltip="`The <i>h</i>-index is the largest number <i>h</i> such that: the researcher has <i>h</i> publications that each have ≥ <i>h</i> citations.`"
        >
          <span class="mr-3"><i>h</i>-index</span>
          <span v-html="item.ranking_h_index"></span>
        </li>
        <li class="list-item"
          v-tooltip="`The i10-index is the number of publications by this researcher with ≥ 10 citations.`"
        >
          <span class="mr-3">i10-index</span>
          <span v-html="item.ranking_i10_index || 0"></span>
        </li>
      </ul>
    </div>

    <div v-if="!isEmpty(contacts)">
      <h3 v-if="scope !== 'researchers'" class="mb-3 mt-3 t-500">
        Contact
      </h3>

      <h3 v-if="scope === 'researchers' && canEdit " class="mb-3 t-500">
        Contact
        <editable
          :text="`Edit`"
          @create="$events.$emit('modal:researcher:edit:contact:open', item, user)">
        </editable>
      </h3>

      <ul v-for="(contact, index) in contacts" :key="index" class="list-unstyled clearfix mb-0">
        <li v-if="contact.slug_values && scope !== 'researchers'" class="list-item clearfix contact block-fa">
          <i class="fa fa-user t-secondary t-xs pull-left"></i>
          <researcher-link :researcher="contact" />
        </li>

        <div v-if="!isEmpty(contact.contact_info) && scope === 'researchers'">
          <div v-for="(info, key) in contact.contact_info" :key="key" >
            <li v-if="info.contact_type.slug === 'telephone' || info.contact_type.slug === 'mobile-phone' || info.contact_type.slug === 'phone'" class="list-item clearfix contact block-fa">
              <i class="fa fa-phone t-secondary t-xs pr-2 "></i>
              <a :href="`tel:${info.value}`" target="_blank">{{ info.value }}</a>
            </li>

            <li v-if="info.contact_type.slug === 'url'" class="list-item clearfix contact block-fa">
              <i class="fa t-secondary t-xs pr-2"
                :class="{
                  'fa-linkedin': info.isLinkedIn,
                  'fa-link': !info.isLinkedIn
                }"
              />
              <a
                @click="sendMixPanelEvent(info.value)"
                :href="info.value | formatWebsite"
                ref="website"
                target="_blank" class="t-overflow">{{ info.isLinkedIn ? 'LinkedIn Page' : 'Website' }}</a>
            </li>
            <li v-if="info.contact_type.slug === 'linkedin_suggestion'" class="list-item clearfix contact block-fa">
              <i class="fa t-secondary t-xs pr-2 fa-linkedin" />
              <a
                @click="sendMixPanelEvent(info.value)"
                :href="info.value | formatWebsite"
                ref="website"
                target="_blank" class="t-overflow">Find on LinkedIn</a>
            </li>
          </div>
        </div>
      </ul>
    </div>

    <p v-if="scope === 'researchers' && canEdit" class="mb-0">
      <i class="fa fa-envelope t-secondary t-xs pr-2 "></i> {{ $currentUser.email }} <router-link :to="{ name: 'researcher.settings', params: { id: $currentUser.slug_values[0] }}" class="t-sm">(edit)</router-link>
    </p>

    <h3 v-if="scope === 'researchers' && canEdit && !user.orcid_id" class="my-3 t-500">
      Connections
    </h3>

    <!-- orcid button -->
    <orcid-button v-if="!isEmpty(user) && canEdit && !user.orcid_id"
      :text="`Connect with ORCID`"
      :containerClass="`mt-3`"
      :isConnect="true" />

  </div>
</template>

<script>
import Editable from '@/components/shared/Editable'

import ResearcherLink from '@/components/researcher/Link'
import OrcidButton from '@/components/login/OrcidButton'
import MPService from '@/services/mixpanel'

export default {
  name: 'contactDetails',
  components: {
    ResearcherLink,
    Editable,
    OrcidButton
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: false
    },
    scope: {
      type: String,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: false
    },
    contacts: {
      type: Array,
      required: false
    }
  },
  data () {
    return {}
  },
  mounted () {
    this.$events.$on('contact:researcher:updated', (contacts) => { this.contacts[0].contact_info = contacts })
  },
  methods: {
    sendMixPanelEvent (url) {
      MPService.websiteClicked(this.scope, this.item.slug_values[0], this.getFullName(this.item), url)
    }
  },
  destroyed () {
    this.$events.$off('contact:researcher:updated')
  }
}
</script>
