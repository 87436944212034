<template>
  <img
    :src="filename"
    :width="width"
    :height="height" />
</template>

<script>
export default {
  name: 'icon',
  props: {
    format: {
      type: String,
      required: false,
      default: 'svg'
    },
    icon: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: false
    },
    height: {
      type: Number,
      required: false
    }
  },
  computed: {
    filename () {
      return require(`@/assets/img/${this.icon}.${this.format}`)
    }
  }
}
</script>
