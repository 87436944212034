import Vue from 'vue'
import Sentry from '@/plugins/sentry'
import configurationData from '@/assets/js/_data'
import { hasMatchingSlug, cleanKeyword } from '@/plugins/utils'
import { mapGetters } from 'vuex'

Vue.mixin({
  computed: {
    ...mapGetters({
      searchView: 'search/searchView'
    }),
    globalSearchKeyword () {
      const state = this.$ls.getState()
      const keyword = !this.isEmpty(state) ? state.keyword : ''
      if (this.$route.query && this.$route.query.keyword === keyword) {
        return ''
      }
      return keyword
    }
  },
  methods: {
    getTruncated (text, length, isWithStripTags = true) {
      if (length === -1) return ''

      if (!text) return ''
      if (isWithStripTags) text = this.stripTags(text)
      return (text.length > length && length !== -1) ? 'is-truncated' : ''
    },
    getFullName (data) {
      if (typeof data === 'string' || data instanceof String) return data
      if (data.full_name) return data.full_name
      if (!this.isEmpty(data.name)) return data.name
      return `${data.first_name} ${data.last_name}`
    },
    getMainStateOrScopedState () {
      return ['research_group.explore', 'researcher.explore', 'community.explore', 'researcher.bookmark_folders.show', 'organisation.explore'].includes(this.$route.name)
        ? Object.assign({}, this.$ls.getState(this.$route.name.split('.')[0]))
        : Object.assign({}, this.$ls.getState())
    },

    getMonthByInt (int) {
      return configurationData.months.filter((m, i) => {
        if (m.value === int) return configurationData.months[i]
      })[0].short_value
    },
    addOrEditText (length) {
      return length > 0 ? 'Edit' : 'Add'
    },
    getNameFromEmail (email) {
      let fullName = email.split('@')
      let name = { firstName: '', lastName: fullName[0].split('.').slice(1).join(' ') }

      if (name.lastName) {
        name.firstName = this.$options.filters.capitalize(fullName[0].split('.')[0])
        name.lastName = this.$options.filters.titleize(name.lastName)
      } else {
        name.firstName = this.$options.filters.capitalize(fullName[0])
        name.lastName = ''
      }

      return name
    },
    getIndexAsString (index) {
      switch (index) {
        case 'research_groups':
          return 'Research Groups'
        case 'research_topics':
          return 'Ongoing Research'
        case 'organisations':
          return 'Companies'
        case 'projects':
          return 'Funded Projects'
        case 'clinical_trials':
          return 'Clinical Trials'
        case 'patents':
          return 'Patents'
        case 'publications':
          return 'Publications'
        case 'researchers':
          return 'Researchers'
        case 'web_pages_organisations':
          if (this.$route.name.includes('bookmark_folders')) return 'Web Pages (Organisations)'
          return 'Web Pages'
        case 'web_pages_research_groups':
          if (this.$route.name.includes('bookmark_folders')) return 'Web Pages (Research Groups)'
          return 'Web Pages'
        default:
          return this.$options.filters.humanize(index)
      }
    },
    isEmpty (val) {
      if (val !== null && typeof val === 'object') {
        if (Object.keys(val).length === 0) return true
      } else if (val == null || val === '') {
        return true
      }
      return false
    },
    renderError (err) {
      if (!this.isEmpty(err)) {
        if (!this.isEmpty(err.data)) {
          if (!this.isEmpty(err.data.errors)) {
            return err.data.errors
          } else if (!this.isEmpty(err.data.reason)) {
            return err.data.reason
          } else if (!this.isEmpty(err.data.error)) {
            return err.data.error
          } else {
            return err
          }
        } else if (err.statusText) {
          return err.statusText
        } else {
          return err
        }
      } else {
        Sentry.captureMessage(new Error(`Something went wrong. (${err})`))
        return `Something went wrong. (${err})`
      }
    },
    handleError (err) {
      if (!err.message) {
        return this.renderError(err)
      } else if (err.response) {
        return this.renderError(err.response)
      } else {
        return ''
      }
    },
    stripTags (val) {
      return val ? val.replace(/<[^>]+>/g, '') : ''
    },
    stripTagsNoImg (val) {
      return val ? val.replace(/(<\/?(?:img)[^>]*>)|<[^>]+>/ig, '$1') : ''
    },
    stripTagsNoMark (val) {
      if (Array.isArray(val)) {
        return val.map(line => line.replace(/<(?!\/?mark>)[^>]+>/g, ' '))
      }
      return val ? val.replace(/<(?!\/?mark>)[^>]+>/g, ' ') : ''
    },
    stripSpace (val) {
      return val ? val.replace(/ /g, '') : ''
    },
    checkLength (text, length) {
      let strippedText = this.stripTags(text)

      return (strippedText.length > length)
    },
    getFirstXElAndLastFromArr (authors, length = 4, isReverse = false) {
      if (!this.isEmpty(authors) && isReverse) authors.reverse()
      if (this.isEmpty(authors)) return []

      if (authors.length > length) {
        let lastAuthors = Object.assign([], authors.slice(-1))
        return authors.slice(0, length - 1).concat(...lastAuthors)
      }

      return authors
    },
    getFullAddress (street, city, postCode, country) {
      let str = !this.isEmpty(street) ? street : ''
      let cty = !this.isEmpty(city) ? city : ''
      let zip = !this.isEmpty(postCode) ? postCode : ''
      let cntry = !this.isEmpty(country) ? country.name : ''

      if (str && cty && zip && country) {
        return `${str}, ${cty}, ${zip}, ${cntry}`
      } else if (!str && cty && zip && country) {
        return `${cty}, ${zip}, ${cntry}`
      } else if (!str && !cty && zip && country) {
        return `${zip}, ${cntry}`
      } else if (!str && !cty && !zip && country) {
        return `${cntry}`
      } else if (!str && cty && !zip && country) {
        return `${cty}, ${cntry}`
      } else if (str && cty && !zip && !country) {
        return `${str}, ${cty}`
      } else if (str && cty && zip && !country) {
        return `${cty}, ${cntry}, ${zip}`
      } else {
        return `${str} ${cty} ${zip} ${cntry}`
      }
    },
    getModuleName (researchGroup, scope, count) {
      if (scope === 'topic') {
        // return researchGroup.category === 'professional'
        //   ? this.$options.filters.pluralize('R&D topic', count)
        //   : 'Ongoing Research'
        return 'Ongoing Research'
      }

      if (scope === 'research_type') {
        return researchGroup.category === 'professional'
          ? this.$options.filters.pluralize('activities', count)
          : this.$options.filters.pluralize('type of research', count)
      }
    },
    getNextRoute (parent, to) {
      let r = ''
      this.$router.options.routes.find((el) => {
        if (el.name === parent) {
          el.children.find((e, i) => {
            if (e.name === to) r = (!this.isEmpty(el.children[i + 1])) ? el.children[i + 1] : el.children[i]
          })
          return r
        }
      })

      return r
    },
    getCategoryId (category) {
      return configurationData.categories.find(obj => { return obj.value === category })
    },
    getCommunityType (typeId) {
      let type = configurationData.community_type.find(obj => { return obj.key === typeId })
      return !this.isEmpty(type) ? type.name : ''
    },
    isValidCategory (cat) {
      return ['student', 'public', 'academic', 'professional'].includes(cat)
    },
    singularizeIndex (index) {
      return this.$options.filters.singularize(index)
    },
    isValidPublicCategory (category) {
      return category === 'public'
    },
    isValidProfessionalCategory (category) {
      return category === 'professional'
    },
    isValidAcademicCategory (category) {
      return !!(category === 'academic' || category === 'student')
    },
    checkOpenModal (query) {
      for (let value of configurationData.search_modals) {
        if (query[value]) {
          this.openModal(value, query[value])
          break
        }
      }
    },
    openModal (type, slug) {
      this.$events.$emit(`modal:${type}:open`, slug)
    },
    isEven (n) {
      return n % 2 === 0
    },
    isMobile () {
      return (/Mobi|Android/i.test(navigator.userAgent))
    },
    isStickySupported () {
      return CSS && CSS.supports && CSS.supports('position', 'sticky')
    },
    /*
      Finds first index with more than 0 results.
      Return: Array [indexName, indexObject]
    */
    getMostRelevantIndex (categories, fallback = 'publications') {
      // Loop over an object of categories, sort them, and return the first category with more than 0 results
      const firstCategory = Object.entries(categories)
        .sort((a, b) => a[1].order - b[1].order)
        .find(c => c[1].total > 0)
      return firstCategory || [fallback, { total: 0 }]
    },
    getMostRelevantOrganisationCategory (organisationTypes) {
      const priorityArray = [
        'Investor',
        'Incubator',
        'Health',
        'Hospital',
        'Government',
        'Spinoff',
        'Company',
        'Academic',
        'Consortium',
        'Other',
        'researcher',
        'research_group'
      ]

      return priorityArray.find(orgType => organisationTypes.includes(orgType)) || 'Other'
    },
    /*
      Render icon for organisations
    */
    renderOrganisationCategoryIcon (iconTypeData) {
      if (!iconTypeData) {
        // console.warn('Missing data!!')
        return 'fa fa-times t-red'
      }

      let category = ''

      if (iconTypeData instanceof Array) {
        category = this.getMostRelevantOrganisationCategory(iconTypeData).toLowerCase() // Normalize name
      } else {
        // If we have no array, we have a string. Can go through the normal switch then
        category = iconTypeData.toString().toLowerCase() // Normalize name
      }
      switch (category) {
        case 'spinoff':
          return 'al-icon-spinoff'
        case 'academic':
          return 'fa fa-university'
        case 'hospital':
        case 'health':
          return 'fa fa-hospital-o'
        case 'government':
          return 'al-icon-government'
        case 'consortium':
          return 'fa fa-cubes'
        case 'incubator':
          return 'al-icon-incubator'
        case 'investor':
          return 'al-icon-investor'
        case 'researcher':
          return 'fa fa-user'
        case 'research_group':
          return 'fa fa-users'
        default:
          return 'fa fa-building'
      }
    },

    /*
      Renders tooltip for flags
    */
    renderCountryFlagTooltip (country) {
      return country.name || country.alpha_2
    },
    hashString (str, seed = 0) {
      let h1 = 0xdeadbeef ^ seed
      let h2 = 0x41c6ce57 ^ seed
      for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i)
        h1 = Math.imul(h1 ^ ch, 2654435761)
        h2 = Math.imul(h2 ^ ch, 1597334677)
      }
      h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
      h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
      h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
      h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)

      return 4294967296 * (2097151 & h2) + (h1 >>> 0)
    },
    getActiveFilters (filters) {
      const activeFilters = []
      for (let filterType of Object.values(filters)) {
        if (filterType.hide) continue
        if (filterType.buckets) {
          filterType.buckets.forEach(bucket => {
            if (bucket.active) {
              if (bucket.label) {
                activeFilters.push(bucket.label)
              } else if (bucket.value) {
                activeFilters.push(bucket.value)
              }
            }
          })
        }
      }
      return activeFilters
    },
    isDevServer () {
      return process.env.NODE_ENV !== 'production'
    },
    // Replaces curly quotes with regular quotes
    cleanKeyword: cleanKeyword,
    hasMatchingSlug: hasMatchingSlug
  }
})
