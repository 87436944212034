<template>
  <div class="card-body" :class="classObject">
    <h3 class="mb-0 t-500">
      Research interests
      <editable v-if="isLoaded && canEdit && !isEmpty(interests)" :isActive="isEditMode"
        :text="addOrEditText(interests.length)"
        @create="handleEdit()"
        @cancel="handleEdit()">
      </editable>

      <i v-if="isLoaded && (isEmpty(interests) || !canEdit)" class="fa fa-lightbulb-o d-block pull-right t-placeholder"></i>
    </h3>

    <!-- render content when is loaded -->
    <template v-if="isLoaded">
      <!-- render error messages -->
      <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null" class="mt-3" :containerClass="'mb-0'"/>

      <!--  no research interests and cannot edit -->
      <p v-if="isEmpty(interests) && !canEdit" class="mb-0 mt-1 t-sm t-secondary">
        No research interests added.
      </p>

      <!-- research interest present -->
      <ul v-if="!isEmpty(interests) && canEdit" class="list-unstyled mb-0 mt-3 clearfix flex-wrap d-flex">
        <li class="badge badge-pill badge-secondary--white bd-radius mr-2 mb-2 word-overflow"
          v-for="(interest, index) in interests" :key="index"
          :class="{'is-hover-delete': isEditMode }"
          @click.prevent="handleRemove(interest, index)"
          :title="interest.name"
        >
            <span>{{ interest.name | capitalize }}</span>
            <i v-if="isEditMode" class="fa fa-times ml-2 t-red"></i>
        </li>
        <li v-if="!isEditMode && canCollapse" class="t-sm w-100">
          <a href="#" @click.prevent="isCollapsed = !isCollapsed">{{ isCollapsed ? 'See all' : 'See less' }}</a>
        </li>
      </ul>

      <ul v-if="!isEmpty(interests) && !canEdit" class="list-unstyled mb-0 mt-3 clearfix flex-wrap d-flex">
        <li class="badge badge-pill badge-secondary--white bd-radius mr-2 mb-2 word-overflow"
          v-for="(interest, index) in interests" :key="index"
          :title="interest.name"
        >
            <span>{{ interest.name | capitalize }}</span>
        </li>
        <li v-if="canCollapse" class="t-sm w-100">
          <a href="#" @click.prevent="isCollapsed = !isCollapsed">{{ isCollapsed ? 'See all' : 'See less' }}</a>
        </li>
      </ul>

      <spinner v-if="isRequest" :containerClass="`width--50px min-height--40px`" />

      <!-- render typeahead -->
      <typeahead v-if="(isEmpty(interests) || isEditMode) && canEdit"
        :remote="typeEndpoint"
        :name="typeEndpoint"
        :displayKey="typeDisplayKey"
        :limit="5"
        :placeholder="`Search for a research interest`"
        :canAdd="true"
        :canAddMinLength="2"
        :canAddPlaceholder="`Add research interest`"
        :focus="typeaheadFocus"
        @selected="handleTypeahead"
        @add="handleAddTypeahead"
      />
    </template>

    <!-- render spinner if not loaded -->
    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import Spinner from '@/components/shared/Spinner'
import StaticMessages from '@/components/shared/StaticMessages'
import Editable from '@/components/shared/Editable'

import Typeahead from '@/components/shared/form/Typeahead'
import ResearcherService from '@/services/researcher'

export default {
  name: 'researcherResearchInterest',
  components: {
    Typeahead,
    Spinner,
    StaticMessages,
    Editable
  },
  props: {
    isLoaded: {
      type: Boolean,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    researcher: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    containerClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      error: null,
      isEditMode: false,
      isRequest: false,
      typeaheadFocus: false,
      typeEndpoint: 'interest',
      typeDisplayKey: 'name',
      isCollapsed: true
    }
  },
  computed: {
    canCollapse () {
      const researchInterestCount = this.researcher.research_interests ? this.researcher.research_interests.length : 0
      const keywordCount = this.researcher.keywords ? this.researcher.keywords.length : 0
      return researchInterestCount + keywordCount > 10
    },
    // Computed property to check if researcher has research interests.
    // If a researcher has both interests, and keywords, interests take precendence and keywords get ignored
    hasResearchInterests () {
      return this.researcher.research_interests && this.researcher.research_interests.length
    },
    interests () {
      const interests = []
      if (this.hasResearchInterests > 0) {
        interests.push(...this.researcher.research_interests)
      } else if (this.researcher.keywords && this.researcher.keywords.length > 0) {
        // Remap keywords to correct format
        interests.push(...this.researcher.keywords.map(k => {
          return { name: k.keyword }
        }))
      }

      if (!this.isEditMode && interests.length > 10 && this.isCollapsed) {
        return interests.slice(0, 10)
      }
      return interests
    },
    classObject () {
      return this.isEmpty(this.containerClass) ? 'py-3' : this.containerClass
    }
  },
  created () {
    if (this.isEdit) this.isEditMode = true
  },
  methods: {
    focusTypeahead () {
      this.isEditMode = true
      this.typeaheadFocus = !this.typeaheadFocus
    },
    handleTypeahead (name, item) {
      if (item) {
        this.isRequest = true
        ResearcherService.addResearchInterest(this.$currentUser.slug_values[0], item.id).then(() => {
          const newInterests = [...this.researcher.research_interests]
          newInterests.push(item)
          this.updateState(newInterests)
        }).catch(err => {
          this.isRequest = false
          this.error = this.handleError(err)
        })
      }
    },
    handleAddTypeahead (name, item, minLength) {
      // Check for min length
      if (item.length < minLength) {
        this.error = `Research Interest must be at least ${minLength} characters.`
      } else {
        this.isRequest = true
        ResearcherService.createResearchInterest(this.$currentUser.slug_values[0], { name: item }).then(interest => {
          const newInterests = [...this.researcher.research_interests]
          newInterests.push(interest)
          this.updateState(newInterests)
        }).catch(err => {
          this.isRequest = false
          this.error = this.handleError(err)
        })
      }
    },
    handleRemove (value, index) {
      if (this.isEditMode && value) {
        this.isRequest = true
        ResearcherService.removeResearchInterest(this.$currentUser.slug_values[0], value.id).then(() => {
          const newInterests = [...this.researcher.research_interests]
          newInterests.splice(index, 1)
          this.updateState(newInterests)
        }).catch(err => {
          this.isRequest = false
          this.error = this.handleError(err)
        })
      }
    },
    updateState (updatedInterests) {
      this.$events.$emit('researcher:updated', 'research_interests', updatedInterests)
      this.isRequest = false
      this.isEditMode = true
    },
    handleEdit () {
      this.typeaheadFocus = !this.typeaheadFocus
      this.isEditMode = !this.isEditMode
      if (!this.isEditMode) this.$emit('cancelled')
    }
  }
}
</script>
