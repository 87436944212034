<template>
  <div class="">
    <h2 v-if="isResearchGroup" class="t-primary t-400 mb-0">Request to join {{ researchGroup.name }} </h2>
    <h2 v-if="isCommunity" class="t-primary t-400 mb-0">Request to join {{ community.name }} </h2>
    <h2 v-if="isHiddenPage" class="t-primary t-400 mb-0">Request to join this {{ scope | capitalize | humanize }} </h2>
    <hr class="hr-small mb-3" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <p v-if="isHiddenPage" class="mb-3">
      This page is only visible to members. You can join this {{ scope | capitalize | humanize }} if the owner accepts your request.
    </p>

    <p v-else class="mb-3">
      You will be member of this {{ scope | humanize }} if the owner accepts your request.
    </p>

    <!-- add invites form -->
    <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <!-- personal message -->
      <div class="row">
        <div class="form-group col-md-12 mb-0" :class="{'has-icon-inline-label': errors.has('invite.message')}">
          <label class="t-500 t-sm">Personal message</label>
          <textarea
            class="form-control"
            name="invite.message"
            placeholder="Briefly describe why you want to join."
            v-model="invite.message"
            v-validate="'min:10'"
            data-vv-as="message"
            data-vv-delay="1000"
            :class="{'is-invalid': errors.has('invite.message')}">
          </textarea>

          <i v-show="errors.has('invite.message')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('invite.message')">{{ errors.first('invite.message') }}</span>
        </div>
      </div>

      <!-- action buttons -->
      <form-buttons :isRequest="isRequest"
        :isEditMode="false"
        :isCreateMode="true"
        :isRemove="false"
        :createText="getCreateText"
        :containerClass="`pt-3`"
        @cancel="$events.$emit('modal:close')"
       />
    </form>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'
import UserService from '@/services/user'

export default {
  name: 'requestCreate',
  components: {
    StaticMessages,
    FormButtons
  },
  props: {
    slug: {
      type: String,
      required: true
    },
    scope: {
      type: String,
      required: true
    },
    researchGroup: {
      type: Object,
      required: true
    },
    community: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      error: null,
      isRequest: false,
      isResearchGroup: false,
      isCommunity: false,
      isHiddenPage: false,
      invite: {
        message: '',
        invitable_type: '',
        invitable_id: ''
      }
    }
  },
  created () {
    if (!this.isEmpty(this.researchGroup)) this.isResearchGroup = true

    if (!this.isEmpty(this.community)) this.isCommunity = true

    if (this.isEmpty(this.researchGroup) && this.isEmpty(this.community)) this.isHiddenPage = true
  },
  computed: {
    getCreateText () {
      return 'Ask to join'
    }
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (!this.isRequest) {
          this.invite.invitable_type = this.$options.filters.singularize(this.$options.filters.humanize(this.scope, ''))
          this.invite.invitable_id = this.slug
          this.isRequest = true

          if (this.isResearchGroup || this.scope === 'research_group') this.handleResearchGroupJoin()
          if (this.isCommunity || this.scope === 'community') this.handleCommunityJoin()
        }
      })
    },
    handleResearchGroupJoin () {
      UserService.createInviteRequest(this.$currentUser.user_id, this.invite).then(invite => {
        this.isRequest = false
        this.$flash({ message: `Successfully sent invite${!this.isHiddenPage && ' to join this research group'}` }, 'success')
        this.$events.$emit('research_group:updated', 'user_invite_action', 'invite_request')
        this.$events.$emit('modal:close')
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleCommunityJoin () {
      UserService.createInviteRequest(this.$currentUser.user_id, this.invite).then(invite => {
        this.isRequest = false
        this.$flash({ message: `Successfully sent invite${!this.isHiddenPage && ' to join this community'}` }, 'success')
        this.$events.$emit('community:updated', 'user_invite_action', 'invite_request')
        this.$events.$emit('modal:close')
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    }
  }
}
</script>
