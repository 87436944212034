import ls from '@/plugins/localstorage'

const ConfirmationBase = () => import(/* webpackChunkName: "confirmation" */ '@/views/confirmation/Base.vue')
const ConfirmationPending = () => import(/* webpackChunkName: "confirmation" */ '@/views/confirmation/Pending.vue')
const ConfirmationResend = () => import(/* webpackChunkName: "confirmation" */ '@/views/confirmation/Resend.vue')
const ConfirmationInvite = () => import(/* webpackChunkName: "confirmation" */ '@/views/confirmation/Invite.vue')
const ConfirmationInviteRequest = () => import(/* webpackChunkName: "confirmation" */ '@/views/confirmation/InviteRequest.vue')
const SignupBase = () => import(/* webpackChunkName: "signup" */ '@/views/signup/Base.vue')

export default [
  {
    path: '/confirmation',
    redirect: 'confirmation.resend',
    meta: {
      auth: false
    },
    component: ConfirmationBase,
    children: [
      {
        path: 'pending',
        name: 'confirmation.pending',
        meta: {
          skipParams: true,
          auth: false
        },
        beforeEnter: (to, from, next) => {
          return ls.getToken() ? next({ name: 'search' }) : next()
        },
        component: ConfirmationPending
      }, {
        path: 'resend',
        name: 'confirmation.resend',
        meta: {
          skipParams: true,
          auth: false
        },
        component: ConfirmationResend,
        beforeEnter: (to, from, next) => {
          return ls.getToken() ? next({ name: 'search' }) : next()
        }
      }, {
        path: 'invite',
        name: 'confirmation.invite',
        meta: {
          skipParams: true,
          auth: false
        },
        component: ConfirmationInvite
      }, {
        path: 'invite_request',
        name: 'confirmation.inviteRequest',
        meta: {
          skipParams: true,
          auth: false
        },
        component: ConfirmationInviteRequest
      }
    ]
  }, {
    path: '/signup',
    name: 'signup',
    meta: {
      skipParams: true,
      auth: false
    },
    component: SignupBase,
    beforeEnter: (to, from, next) => {
      return ls.getToken() ? next({ name: 'search' }) : next()
    }
  }, {
    path: '/signup/create',
    name: 'signup.create',
    meta: {
      skipParams: true,
      auth: false
    },
    component: SignupBase,
    redirect: 'signup',
    beforeEnter: (to, from, next) => {
      return ls.getToken() ? next({ name: 'search' }) : next()
    }
  }
]
