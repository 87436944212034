<template>
  <div>
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>
    <static-messages v-if="success" :messages="success" :variant="`success`" @closed="success = null"/>

    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px`" />

    <div v-if="isLoaded" class="organisation-request-box">
      <!-- joined organisations -->
      <template v-if="!isEmpty(organisations)">
        <p v-if="!isInOnboardingFlow" class="t-primary text-center">
          <!-- <i class="fa fa-info-circle mr-2" v-tooltip="`Organisation(s) you've already joined.`"></!-->
          <span class="t-700 t-grey">Current {{ 'organisation' | pluralize(organisations.length) }}</span>
        </p>

        <div v-for="(organisation, index) in organisations" :key="`org-list-${index}`">
          <div class="row mb-3">
            <div class="col-6">
              <p class="mb-0 organisation-name">
                {{ organisation.name }}
              </p>
            </div>

            <div class="col-6">
              <p class="action--small mb-0">
                <span class="btn t-sm t-primary mb-0">
                  <i class="fa fa-check t-green t-sm pr-1"></i>Joined
                </span>
              </p>
            </div>
          </div>
          <hr v-if="index < organisations.length - 1" class="mb-3" />
        </div>
      </template>

      <!-- organisation requests -->
      <template v-if="!isEmpty(organisationRequests)">
        <p v-if="!isInOnboardingFlow" class="t-primary mt-3 text-center">
          <!-- <i class="fa fa-info-circle mr-2" v-tooltip="`Based on your email, we've found these matches.`"></i> -->
          <span class="t-700 t-grey">Matched {{ 'organisation' | pluralize(organisationRequests.length) }}</span>
        </p>

        <spinner v-if="isRequest" :containerClass="`width--50px min-height--40px mb-3`" />

        <div v-for="(request, i) in organisationRequests" :key="`request-list-${i}`">
          <div class="row">
            <div class="col-6">
              <p class="mb-0">
                {{ request.organisation.name }}
              </p>
            </div>

            <div class="col-6">
              <p class="action--small mb-0">
                <a href="#" @click.prevent="declineExistingOrganisation(request, request.organisation.name, i)" class="btn btn-secondary--grey t-sm">
                  <i class="fa fa-times t-red t-sm pr-1"></i>Not now
                </a>
              </p>
              <p class="action--small mb-0">
                <a href="#" @click.prevent="acceptExistingOrganisation(request, request.organisation.name, i)" class="btn btn-secondary--grey t-sm mr-2">
                  <i class="fa fa-check t-green t-sm pr-1"></i>Join
                </a>
              </p>
            </div>
          </div>
          <hr v-if="i < organisationRequests.length - 1" class="mb-3" />
        </div>
      </template>
    </div>

    <!-- empty, ask to join by email or ask to join by searching -->
    <template v-if="isLoaded && isEmpty(organisations) && isEmpty(organisationRequests)">
      <template v-if="isOrganisationJoinByEmail">
        <p v-if="!isSuccess" class="mb-0 text-center">
          We could not find an organisation based on your email address. If you have a <strong v-if="isValidAcademicCategory(user.category.name)">academic</strong><strong v-else>professional</strong> email address, please add this below.
        </p>

        <organisation-join-by-email :user="user"
          :researcher="researcher"
          :isInOnboardingFlow="isInOnboardingFlow"
          @completed="handleSuccess"
          @joined="$emit('completed')"
          @skip="toggleOrganisation()"
          @cancel="$emit('cancel')"
          @showInfo="isInfoText = true"
          @hideInfo="handleHideInfo"
          class="mt-3">
        </organisation-join-by-email>

        <template v-if="!isSuccess && isInfoText">
          <or-separator class="mt-5" />
          <p class="my-3 text-center">
            Don't have another email?
          </p>
          <p class="text-center">
          <a href="#" @click.prevent="toggleOrganisation()" class="btn btn-secondary--grey  mx-auto">Search for an organisation</a>
          </p>
        </template>
      </template>

      <template v-if="isOrganisationCreate">
        <p v-if="!isSuccess && !isInOnboardingFlow" class="my-3 text-center">
          You will send a request to join as a member. The administrator will then accept your request.
        </p>

        <organisation-create :isWithoutTitle="true"
          :isWithoutClose="true"
          :isWithCreate="true"
          :isInOnboardingFlow="isInOnboardingFlow"
          :user="user"
          :researcher="researcher"
          @created="handleSuccess"
          @goToNextStep="handleGoToNextStep"
          @completed="handleJoinedOrganisation"
          @cancel="$emit('cancel')"
          @noResults="handleNoOrganisationResults"
          @showInfo="isInfoText = true"
          @hideInfo="handleHideInfo"
          :class="{'mb-4': !isEmpty(success)}">
        </organisation-create>

        <template v-if="!isSuccess && isInfoText">
          <or-separator class="mt-5" />
          <p class="my-3 text-center">
            Don't find your organisation?
          </p>
          <p class="text-center">
            <a href="#" @click.prevent="toggleOrganisation()" class="btn btn-secondary--grey  mx-auto">Add a secondary email address</a>
          </p>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import Spinner from '@/components/shared/Spinner'
import OrganisationJoinByEmail from '@/components/organisation/JoinByEmail'
import OrganisationCreate from '@/components/organisation/Create'
import OrSeparator from '@/components/login/OrSeparator'

import UserService from '@/services/user'
import SharedService from '@/services/shared'

export default {
  name: 'registerCompanyModal',
  components: {
    StaticMessages,
    Spinner,
    OrganisationJoinByEmail,
    OrganisationCreate,
    OrSeparator
  },
  props: {
    researcher: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: false
    },
    isInOnboardingFlow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isLoaded: false,
      error: null,
      success: null,
      organisationRequests: [],
      organisations: [],
      isRequest: false,
      isOrganisationCreate: false,
      isOrganisationJoinByEmail: false,
      isSuccess: false,
      isInfoText: true
    }
  },
  created () {
    this.fetch()
    this.organisations = this.researcher.organisations
  },
  methods: {
    fetch () {
      UserService.getOrganisationRequests(this.$currentUser.user_id).then(requests => {
        this.organisationRequests = requests
        if (!this.isEmpty(this.organisations) && this.isEmpty(this.organisationRequests)) this.$emit('completed', this.organisations)
        this.isEmpty(this.organisations) && this.isEmpty(this.organisationRequests)
          ? this.checkDomain()
          : this.isLoaded = true
      }).catch(err => {
        this.error = this.handleError(err)
        this.isLoaded = !this.isEmpty(this.error)
      })
    },
    checkDomain () {
      SharedService.domains(this.$currentUser.email).then(domain => {
        this.isLoaded = true
        domain.public_domain ? this.isOrganisationJoinByEmail = true : this.isOrganisationCreate = true
      }).catch(err => {
        this.error = this.handleError(err)
        this.isRequest = false
      })
    },
    updateResearcherOrganisations () {
      this.$events.$emit('researcher:updated', 'organisations', this.organisations)
      this.$events.$emit('user:updated', 'pending_organisation_requests_count', this.organisationRequests.length)
      this.$emit('accepted', this.organisationRequests.length)
    },
    handleNoOrganisationResults () {
      // this.isInfoText = true
      // this.$emit('completed', this.organisations)
    },
    acceptExistingOrganisation (organisation, name, index) {
      if (!this.isRequest) {
        this.isRequest = true
        UserService.acceptOrganisationRequest(this.$currentUser.user_id, organisation.id).then(organisations => {
          // set new organisations
          this.organisations = organisations

          // remove current organisation from object
          this.organisationRequests.splice(index, 1)

          // send all events for this, we want to update the profile, user object and send an emit to upper component
          this.updateResearcherOrganisations()
          this.$events.$emit('current_user:update')
          this.success = `Added ${name} to your profile.`
          this.$emit('completed', this.organisations)
          this.isRequest = false
          if (this.isEmpty(this.organisations) && this.isEmpty(this.organisationRequests)) this.checkDomain()
        }).catch(err => {
          this.isRequest = false
          this.error = this.handleError(err)
        })
      }
    },
    declineExistingOrganisation (organisation, name, index) {
      if (!this.isRequest) {
        this.isRequest = true
        UserService.declineOrganisationRequest(this.$currentUser.user_id, organisation.id).then(organisations => {
          this.organisationRequests.splice(index, 1)
          this.$events.$emit('user:updated', 'pending_organisation_requests_count', this.organisationRequests.length)
          this.$emit('declined', this.organisationRequests.length)
          this.success = `Declined ${name} request.`
          this.$emit('completed', this.organisations)
          this.isRequest = false
        }).catch(err => {
          this.isRequest = false
          this.error = this.renderError(err)
        })
      }
    },
    handleHideInfo () {
      this.isInfoText = false
    },
    handleSuccess () {
      this.$emit('completed', this.organisations)
      this.isSuccess = true
    },
    handleJoinedOrganisation () {
      this.$emit('completed', this.organisations)
      this.isSuccess = true
    },
    handleGoToNextStep () {
      this.$emit('goToNextStep')
    },
    toggleOrganisation () {
      this.isSuccess = false
      this.isInfoText = true
      this.isOrganisationCreate = !this.isOrganisationCreate
      this.isOrganisationJoinByEmail = !this.isOrganisationJoinByEmail
    }
  }
}
</script>
