import Vue from 'vue'
import moment from 'moment'
import truncate from 'html-truncate'

// Lowercase string
Vue.filter('formatWebsite', (value) => {
  if (!value.startsWith('http')) {
    return `http://${value}`
  }
  return value
})

// Capitalize string
Vue.filter('capitalize', (value) => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

// Titleize string
Vue.filter('titleize', (value) => {
  if ((typeof value === 'string' || value instanceof String) && value !== '') {
    // let splittedWords = value.toLowerCase().split(' ').map((s) => s.charAt(0).toLowerCase() + s.substring(1))
    let splittedWords = value.toLowerCase().split(' ')
    let arrWords = []
    let stopWords = ['a', 'an', 'the', 'and', 'but', 'or', 'for', 'nor', 'as', 'at',
      'by', 'for', 'from', 'in', 'into', 'near', 'of', 'on', 'onto', 'to', 'with']

    splittedWords.map((word) => {
      if (stopWords.indexOf(word) === -1) word = word.charAt(0).toUpperCase() + word.slice(1)
      arrWords.push(word)
    })

    if (arrWords.length > 1) arrWords[0] = arrWords[0].charAt(0).toUpperCase() + arrWords[0].slice(1)
    return arrWords.join(' ')
  }

  return ''
})

Vue.filter('removePunctuation', (value) => {
  if ((typeof value === 'string' || value instanceof String) && value !== '') {
    // eslint-disable-next-line no-useless-escape
    return value.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
  }

  return ''
})

// Lowercase string
Vue.filter('lowercase', (value) => {
  if (!value) return ''
  return value.toLowerCase()
})

// Truncate string
Vue.filter('truncate', (text, length = 30, clamp = '...') => {
  if (length === -1 || text === null || text === undefined) return text
  if (text.length <= 0) return text
  if (text.length <= length) return text

  var tcText = text.slice(0, length - clamp.length)
  var last = tcText.length - 1

  while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1

  // Fix for case when text don't have any `space`
  last = last || length - clamp.length

  return tcText + clamp
})

// Truncate string
Vue.filter('truncateKeepImage', (text, length = 30, clamp = '...') => {
  if (length === -1 || text === null || text === undefined) return text
  if (text.length < length) return text

  return truncate(text, length, {
    keepImageTag: true
  })
})

// Truncate string by dash
Vue.filter('truncateByDash', (text, length, clamp) => {
  clamp = clamp || '...'
  length = length || 30

  if (length === -1 || text === null || text === undefined) return text

  let frags = text.split('-')
  if (frags.length <= length) return text

  let tcText = ''
  for (let i = 0; i < frags.length; i++) if (i < length) tcText += `${frags[i]}-`

  return tcText + clamp
})

// Humanize string
Vue.filter('humanize', (text, join = ' ', splitter = '_') => {
  if ((typeof text === 'string' || text instanceof String) && text !== '') {
    let frags = text.split(splitter)

    for (let i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }

    return frags.join(join)
  } else {
    return ''
  }
})

// formatNumber string
Vue.filter('formatCurrency', ({ amount, currency }) => {
  if (!currency) return amount
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0
  }).format(amount)
})

// formatNumber string
Vue.filter('formatNumber', (number, digits = 0) => {
  return number ? number.toFixed(digits).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : number
})

// formatNumberDecimal string
Vue.filter('formatNumberDecimal', (num) => {
  const absoluteNumber = Math.abs(num)
  if (absoluteNumber >= 1000000) {
    return Math.sign(num) * ((absoluteNumber / 1000000).toFixed(1)) + 'M'
  } else if (absoluteNumber >= 1000) {
    return Math.sign(num) * ((absoluteNumber / 1000).toFixed(1)) + 'K'
  }
  return num
})

// formatNumberShort string
Vue.filter('formatNumberShort', (num, digits = 0) => {
  var si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ]
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
})

// pluralize string (simple version)
Vue.filter('pluralize', (text, count) => {
  const exceptions = ['news']
  if (exceptions.indexOf(text.toLowerCase()) > -1) return text

  if (count === -1 || count === 1) return text

  const ending = text.slice(-2)

  // last letter with ch
  if (
    ending === 'ch' ||
    ending === 'ss' ||
    ending === 'sh' ||
    ending === 'x'
  ) {
    return `${text}es`
  }
  if (ending === 'ey') return `${text}s`

  const lastLetter = text.slice(-1)
  return (lastLetter === 'y')
    ? `${text.slice(0, -1)}ies`
    : `${text}s`
})

// singularize string (simple version)
Vue.filter('singularize', (text) => {
  const exceptions = ['species', 'news']
  if (exceptions.indexOf(text.toLowerCase()) > -1) return text
  let lastLetter = text.slice(-1)
  let last3Letters = text.slice(-3)
  if (last3Letters === 'ies') return `${text.slice(0, -3)}y`
  return lastLetter === 's' ? text.slice(0, -1) : text
})

// format dateAndHours
Vue.filter('dateAndHours', (date) => {
  return moment(date).format('Do MMM YYYY HH:mm')
})

// format date
Vue.filter('formatDate', (date, format) => {
  return moment(date).format(format)
})

// format fromNow
Vue.filter('fromNow', (date) => {
  return moment(date).fromNow()
})

// format daysFromNow
Vue.filter('daysFromNow', (date) => {
  let days = moment(date).diff(new Date(), 'days')
  days = days <= 0 ? '0' : days
  return days
})

// Capitalize string
Vue.filter('slugify', (str) => {
  return str
    .toString()
    .replace(/^\s+|\s+$/g, '') // Trim
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-')
})

// Capitalize string
Vue.filter('scopify', (str) => {
  return str
    .toString()
    .replace(/^\s+|\s+$/g, '') // Trim
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '_') // collapse whitespace and replace by -
    .replace(/-+/g, '_')
})

// prepend zero before a single digit
Vue.filter('prependZeroToNumber', (number) => {
  return `${number < 10 ? '0' : ''}${number}`
})

// separate words by comma
Vue.filter('separateByComma', (objectArray, field) => {
  return objectArray.filter(record => record.hasOwnProperty(field)).map(record => record[field]).join(', ')
})
