<template>
  <transition name="slide">
    <div v-if="messages.length > 0" @click.prevent="handleNotificationClicked()" :class="`flash box-shadow arrow-top-right alert t-sm bg-white`">
      <div class="flash--left text-left">
        <avatar-missing v-if="isEmpty(notification.sender)" :name="`A L`" :maxChars="3" :containerClass="`bg-blue t-700`"/>
        <avatar-missing v-if="!isEmpty(notification.sender) && !notification.sender.avatar" :name="getFullName(notification.sender)" :maxChars="3"/>
        <avatar v-if="!isEmpty(notification.sender) && notification.sender.avatar" :avatar="notification.sender.avatar" :editable="false" />
      </div>
      <div class="flash--right t-overflow text-left">
        <span v-html="messages[0]"></span>
        <span class="d-block t-secondary">{{ currentDate | fromNow }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
import AvatarMissing from '@/components/shared/AvatarMissing'
import Avatar from '@/components/shared/Avatar'

export default {
  name: 'flash',
  components: {
    Avatar,
    AvatarMissing
  },
  data () {
    return {
      notification: {},
      messages: [],
      variant: 'default',
      delay: 4000
    }
  },
  computed: {
    currentDate () {
      return new Date()
    }
  },
  created () {
    this.$events.$on('notify', (messages, variant, notification) => {
      typeof (messages) === 'object' ? this.messages = messages : this.messages = [messages]

      this.notification = notification
      this.variant = variant

      setTimeout(this.hide, this.delay)
    })
  },
  methods: {
    hide () {
      this.messages = []
      this.notification = {}
    },
    handleNotificationClicked () {
      if (this.notification.link) this.$router.push({ path: `${this.notification.link}` })
      this.hide()
      this.$events.$emit('un-notify-bell', this.notification.notification_id)
    }
  },
  destroyed () {
    this.$events.$off('notify')
  }
}
</script>
