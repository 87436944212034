export default {
  install (Vue, options) {
    // Setup a global event bus. This is handy for communication between
    // components that have not a child parent relation.
    const events = new Vue()
    Vue.prototype.$events = events

    // Setup global flash event
    Vue.prototype.$flash = (msgs, variant, notification) => {
      if (notification) {
        events.$emit('notify-bell', notification)
        events.$emit('notify', msgs, variant, notification)
      } else {
        events.$emit('flash', msgs, variant)
      }
    }
  }
}
