<template>
  <div class="col-md-7 py-5 px-5">
    <h1 class="t-600 t-xlr mb-4">Oops! Page Not Found.</h1>
    <h2 class="t-md mb-4">
      Sorry, we're not able to find what you are looking for.
      <br />Try refreshing the page before continuing.
    </h2>

    <div class="cta-group mb-4">
      <a href="#" @click.prevent="$emit('close')" class="btn btn-primary px-4">
        <span class="t-sm t-600 t-white">Back to search</span>
      </a>
    </div>

    <p class="t-secondary mb-0">
      If this problem continues and you think something is wrong, please <u><a href="mailto:support@academiclabs.com" target="_blank" rel="noopener noreferrer" class="t-regular">send us a report</a></u> and we'll look into it.
    </p>
  </div>
</template>

<script>
export default {
  name: 'notFoundSmall'
}
</script>
