<template>
  <div class="row footer-content">
    <div class="col-md-6">
      <p class="mb-0 t-sm">
        © {{ new Date().getFullYear() }} AcademicLabs NV | All Rights Reserved
      </p>
    </div>

    <div class="col-md-6">
      <ul class="mb-0 list-inline pull-right t-sm t-uppercase">
        <li class="list-inline-item pr-3"><a href="https://www.academiclabs.com/terms" title="Terms" alt="terms" target="_blank">Terms</a></li>
        <li class="list-inline-item pr-3"><a href="https://www.academiclabs.com/privacy-policy" title="Privacy policy" alt="privacy-policy" target="_blank">Privacy policy</a></li>
        <li class="list-inline-item pr-3"><a href="https://www.academiclabs.com/cookie-policy" title="Cookie policy" alt="cookie-policy" target="_blank">Cookie policy</a></li>
        <li class="list-inline-item pr-3">
          <a href="https://www.linkedin.com/company/10129525/" title="LinkedIn" alt="linked-in" target="_blank"><i class="fa fa-linkedin"></i></a>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: 'footerContent'
}
</script>
