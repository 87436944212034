<template>
  <div class="">
    <h2 class="t-primary t-400 mb-0">Add secondary email</h2>

    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <div v-if="isEmailsLoaded && !isEmailAdded" class="mb-3">
      <label class="t-500 t-sm mb-0">
        <span v-if="!isEmpty(secondaryEmails)">My email addresses: </span>
        <span v-else class="t-secondary mr-2">My email address: <span class="t-regular mr-2">{{ user.email }}</span></span>
      </label>

      <p v-if="!isEmpty(secondaryEmails)"  class="mb-0">
        <span class="t-secondary mr-2">Primary: {{ user.email }}</span>
      </p>

      <p v-for="(email, index) in secondaryEmails" :key="index" class="mb-0">
        <span class="t-secondary mr-2">Secondary:</span><i v-if="!email.confirmed_at" class="fa fa-warning t-yellow t-sm" v-tooltip="`Please confirm this email address.`"></i> {{ email.email }}
      </p>
    </div>

    <!-- render spinner if not loaded -->
    <spinner v-if="!isEmailsLoaded" :containerClass="`width--50px min-height--150px`" />

    <form v-if="!isEmailAdded" @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <!-- name -->
      <div class="form-group row mb-4">
        <div class="col-md-12" :class="{'has-icon-inline-label': errors.has('email')}">
          <label class="t-500 t-sm label-required">Add another email address</label>
          <input ref="email"
            type="text"
            class="form-control"
            name="email"
            tabindex="1"
            v-model="email"
            v-validate="'required|email'"
            data-vv-delay="1000"
            data-vv-as="name"
            :placeholder="emailPlaceholder"
            :class="{'is-invalid': errors.has('email')}"
          />

          <i v-show="errors.has('email')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
      </div>

      <!-- action buttons -->
      <form-buttons :isRequest="isRequest"
        :isEditMode="false"
        :isCreateMode="true"
        :createText="'Add'"
        :containerClass="`pt-3`"
        @cancel="handleCancel()"
       />
    </form>

    <div v-if="isEmailAdded">
      <p class="font-italic t-sm my-3 text-left">
        <i class="fa fa-info-circle mr-2 t-secondary"></i>
        We’ve sent a message to <strong class="t-green">{{ email }}</strong>. Finalize your registration by clicking the activation link in your email.
      </p>

      <button type="button" class="btn btn-form btn-secondary pull-right mt-3" @click="$events.$emit('modal:close')">
        Close
      </button>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/user'

import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'
import Spinner from '@/components/shared/Spinner'

export default {
  name: 'userSecondaryEmailCreate',
  components: {
    StaticMessages,
    FormButtons,
    Spinner
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
      required: true
    },
    category: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      error: null,
      isRequest: false,
      isEmailsLoaded: false,
      isEmailAdded: false,
      secondaryEmails: [],
      email: ''
    }
  },
  computed: {
    emailPlaceholder () {
      if (this.category === 'academic') {
        return 'Add your institutional email address'
      } else if (this.category === 'academic') {
        return 'Add your company email address'
      } else {
        return 'Add your email address'
      }
    }
  },
  created () {
    this.fetchSecondaryEmails()
  },
  mounted () {
    this.$nextTick(() => this.$refs.email.focus())
  },
  methods: {
    fetchSecondaryEmails () {
      UserService.getSecondaryEmails(this.user.id).then(emails => {
        this.secondaryEmails = emails
        this.isEmailsLoaded = true
      }).catch(err => {
        this.error = this.handleError(err)
        this.isEmailsLoaded = !this.isEmpty(this.error)
      })
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true

          let email = { email: this.email }
          UserService.createSecondaryEmail(this.user.id, email).then(email => {
            this.isRequest = false
            this.$events.$emit('user:settings:updated', 'email', email)
            this.isEmailAdded = true
            this.$emit('created', this.email)
          }).catch(err => {
            this.isRequest = false
            this.error = this.handleError(err)
          })
        }
      })
    },
    handleCancel () {
      this.$events.$emit('modal:close')
    }
  }
}
</script>
