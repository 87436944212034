<template>
  <li :class="{'mb-2': !isEmpty(organisation)}">
    <div class="row-content">
      <span class="row-content--main d-flex align-items-center">
        <i class="fa fa-university pr-2 t-secondary"></i>
        <router-link
          v-if="getProfileLink"
          :to="getProfileLink"
          v-html="getName"
          v-tooltip="getOriginalName"
          class="t-overflow"
        />
        <router-link
          v-else-if="getSearchLink"
          :to="{ name: 'searchHome', query: { keyword: getSearchLink, suggestions: true }}"
          v-tooltip="getOriginalName"
          class="t-overflow"
        >
          <i class="fa fa-search pr-2 t-xs"></i>
          <span v-html="getName" />
        </router-link>
        <span
          v-if="!isEmpty(organisation.country) && !isEmpty(organisation.country.alpha_2)"
        >
          <flag :title="renderCountryFlagTooltip(organisation.country)" :iso="organisation.country.alpha_2 | lowercase" :squared="false" class="flag bd-radius ml-2 t-xs" />
        </span>
      </span>
    </div>
  </li>
</template>

<script>
export default {
  name: 'organisationSummaryLink',
  props: {
    organisation: {
      type: Object,
      required: true
    }
  },
  computed: {
    getName () {
      return this.getFullName(this.organisation)
    },
    getOriginalName () {
      return this.organisation.organisation?.original_name || this.organisation.original_name || ''
    },
    getProfileLink () {
      let slug
      if (this.organisation.slug_values) {
        slug = this.organisation.slug_values[0]
      } else if (this.organisation.organisation && this.organisation.organisation.slug_values) {
        slug = this.organisation.organisation.slug_values[0]
      } else {
        return false
      }
      return {
        name: 'organisation.show',
        params: { id: slug },
        query: { fullName: this.getName }
      }
    },
    getSearchLink () {
      return this.stripTags(this.getName)
    }
  }
}
</script>
