<template>
  <div>
    <h2 class="t-primary t-400 mb-1">Exclude results with specific words or phrases</h2>
    <p>
      Search results containing any words or phrases that are entered in this dialog will be excluded. For example, entering <b>gene therapy</b> will exclude all search results containing the exact phrase “gene therapy”.
    </p>
    <hr class="hr-small mb-4" />

    <!-- add invites form -->
    <form
      autocomplete="off"
      @submit.prevent="addExclude"
    >
      <div class="form-row">
        <!-- email -->
        <div
          class="form-group mb-2 col-md-10 col-xs-8"
          :class="{ 'has-icon-small is-invalid': newExcludeError }"
        >
          <input
            ref="newExclude"
            v-model="newExclude"
            type="text"
            class="form-control"
            placeholder="Enter a word or phrase"
            name="newExclude"
            :class="{ 'is-invalid': newExcludeError }"
          >

          <span
            v-show="newExcludeError"
            class="invalid-feedback"
          >{{ newExcludeError }}</span>
        </div>

        <div class="col-md-2 col-xs-4">
          <button class="btn width--100per btn-primary t-700">
            <i class="fa fa-plus t-sm mr-2" />
            Add
          </button>
        </div>
      </div>
    </form>
    <div v-if="!isEmpty(excludes)">
      <a href="#" @click.prevent="clearExcludes" class="t-sm mt-3 mb-2" v-tooltip="'Clear all terms'">Clear all</a>
      <ul class="list-unstyled mb-0 flex-wrap d-flex">
        <li class="badge badge-pill badge-secondary--white bd-radius mr-2 mb-2 word-overflow is-hover-delete"
          v-for="(exclude, index) in excludes" :key="index"
          v-tooltip="'Remove'"
          :title="exclude"
          @click="clearExclude(index)"
        >
          <span>{{ exclude }}</span>
          <i class="fa fa-times ml-2 t-red" />
        </li>
      </ul>
    </div>

    <!-- action buttons -->
    <form-buttons
      :createText="`Apply`"
      :containerClass="`mt-4 bd-t-none`"
      @cancel="$events.$emit('modal:close')"
      @save="applyExcludes"
    />
  </div>
</template>

<script>
import FormButtons from '@/components/shared/form/Buttons'

export default {
  name: 'excludeModal',
  components: {
    FormButtons
  },
  props: {
    currentExcludes: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      excludes: [],
      newExclude: '',
      newExcludeError: null
    }
  },
  mounted () {
    // Initialise exclude terms array
    if (!this.isEmpty(this.currentExcludes)) {
      this.excludes.push(...this.currentExcludes)
    }

    this.$nextTick(() => this.$refs.newExclude.focus())
  },
  methods: {
    addExclude () {
      if (this.newExclude) {
        if (this.excludes.indexOf(this.newExclude) === -1) this.excludes.push(this.newExclude)
        this.newExclude = ''
      } else {
        this.applyExcludes()
      }
    },
    clearExclude (index) {
      this.excludes.splice(index, 1)
    },
    clearExcludes () {
      this.excludes = []
    },
    applyExcludes () {
      // Also add any string that still happens to be in the input
      if (this.newExclude) {
        if (this.excludes.indexOf(this.newExclude) === -1) this.excludes.push(this.newExclude)
      }
      this.$events.$emit('excludes:update', this.excludes)
      this.$events.$emit('modal:close')
    }
  }
}
</script>
