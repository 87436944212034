
<template>
  <div id="app">
    <router-view :isSearchBar="isSearchBar" />

    <vue-progress-bar />

    <flash-bottom />

    <cookie-notification />

    <modal />
  </div>
</template>

<script>
import FlashBottom from '@/components/shared/FlashBottom'
import CookieNotification from '@/components/cookie_notification/CookieNotification'
import Modal from '@/components/shared/Modal'

import Sentry from '@/plugins/sentry'

import AuthService from '@/services/auth'

export default {
  name: 'app',
  components: {
    FlashBottom,
    CookieNotification,
    Modal
  },
  metaInfo: {
    title: 'AcademicLabs — There’s a better way to find academic expertise',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'The only platform where you find research groups with their ongoing research, publications, patents, clinical trials and more.'
      }
    ]
  },
  data () {
    return {
      isSearchBar: false
    }
  },
  created () {
    // clear older localStorage versions
    this.$ls.clearPreviousVersion()

    // initialize user object
    if (!this.isEmpty(this.$currentUser) && this.$ls.getToken() && this.$ls.getUser()) this.fetch(true)

    this.$events.$on('current_user:update', () => {
      if (!this.isEmpty(this.$currentUser) && this.$ls.getToken() && this.$ls.getUser()) this.fetch()
    })

    // [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()

    // initialize plugins
    this.$nextTick(() => {
      Sentry.initialize(this.$currentUser)
      if (this.$currentUser) this.$pusher.initialize(this.$currentUser)
    })

    // hook the progress bar to start before we move router-view
    this.$router.beforeResolve((to, from, next) => {
      // start the progress bar
      this.$Progress.start()

      // authentication is required
      if (to.meta.auth && (!this.$ls.getUser() || !this.$ls.getToken())) {
        return to.meta && to.meta.permissions
          ? next({ name: to.meta.permissions.redirectTo, params: to.params })
          : next({ name: 'login' })
      }

      // does the page we want to go to have a meta.progress object
      if (to.meta.progress) this.$Progress.parseMeta(to.meta.progress)

      // track MixPanel event
      if (!to.meta.skipMixPanel) this.$mp.trackPage(from, to)

      // hide or show the search bar
      to.meta.render && to.meta.render.searchBar ? this.isSearchBar = true : this.isSearchBar = false

      // continue to next page
      next()
    })

    // hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
  },
  mounted () {
    this.$Progress.finish()
  },
  methods: {
    fetch (isInit) {
      AuthService.me().then(user => {
        // update user verified
        if (!isInit) this.$events.$emit('user:updated', 'verified', user.verified)
        // Check if confirmation_token in URL, and reduce pending_invites_count by 1 if so.
        if (this.$route.query.confirmation_token) {
          user.pending_invites_count = user.pending_invites_count - 1
        }
        // update user object
        this.$auth.setCurrentUser(user)
        // Show notification if user has pending invites
        if (user.pending_invites_count > 0) {
          this.$flash({
            message: 'You have invitations waiting for you!',
            cta: {
              text: 'Manage invites',
              action: () => { this.$router.push(`/profile/${this.$currentUser.slug_values[0]}/invite`) }
            },
            sticky: true
          }, 'alert')
        }

        this.$auth.trackLogin(user)
      }).catch(() => {
        this.$auth.logout()
      })
    }
  }
}
</script>
