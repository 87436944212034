<template>
  <div class="card-body bd-b py-3" :class="containerClass">
    <h3 class="mb-0 t-500">
      Professional experience
      <editable v-if="isLoaded && canEdit && !isEmpty(experiences)" :isActive="isCreateMode"
        @create="handleCreate()"
        @cancel="handleCancel()">
      </editable>

      <i v-if="isLoaded && (isEmpty(experiences) || !canEdit)" class="fa fa-briefcase d-block pull-right t-placeholder"></i>
    </h3>

    <p v-if="canEdit" class="t-sm">
      using this, we automatically enrich your profile with your publicy-available data.
    </p>

    <!-- render content when is loaded -->
    <template v-if="isLoaded">
      <!-- nothing to show -->
      <p v-if="isEmpty(experiences) && canEdit && !isCreateMode" class="mb-0 mt-4 text-center">
        <i class="fa fa-plus d-block t-secondary"></i>
        <a href="#" @click.prevent="handleCreate()" class="t-sm" >Add your experience</a>
      </p>

      <!-- nothing to show -->
      <p v-if="isEmpty(experiences) && !canEdit" class="mb-0 mt-1 t-sm t-secondary">
        No experience added
      </p>

      <!-- experiences present -->
      <section v-if="!isEmpty(experiences)" class="mt-3">
        <div v-for="(experience, index) in experiences" :key="index" class="mb-0 timeline_record">
          <div class="timeline_record--left pr-3 t-secondary min-height--65px">
            <p class="mb-0">
              <span v-if="experience.from_month">{{ getMonthByInt(experience.from_month) }}</span>&nbsp;<span>{{ experience.from_year }}</span> <span v-if="(experience.from_year && experience.to_year) || (experience.from_year && experience.present)">- </span>
              <span v-if="!experience.present">{{ experience.to_month ? getMonthByInt(experience.to_month) : '' }}&nbsp;{{ experience.to_year }}</span>
              <span v-else>Present</span>
            </p>
            <p  v-if="experience.from_year && experience.to_year && experience.duration" class="mb-0">
              {{ experience.duration }}
            </p>
          </div>

          <div class="timeline_record--right px-3 pb-2 min-height--65px">
            <p class="mb-0">
              <span v-if="experience.title" class="t-600 t-primary">{{ experience.title }}<br /></span>
              <span v-if="experience.organisation" class="t-400">{{ experience.organisation }}<br /></span>
              <span v-if="experience.category" class="t-400 t-sm t-secondary">{{ experience.category | humanize }}<br /></span>
            </p>
          </div>

          <div v-if="canEdit" class="timeline_record--buttons">
            <i @mousedown.prevent="handleEdit(experience, index)" class="fa fa-pencil ml-2 t-blue"></i>
          </div>
        </div>
      </section>

      <!-- render static messages -->
      <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

      <!-- render experience form -->
      <form @submit.prevent="validateBeforeSubmit" v-if="(isEditMode || isCreateMode) && canEdit" class="mt-4 px-3 py-3 bg-grey bd-radius" autocomplete="off">
        <div class="form-group row mb-4">
          <!-- organisation -->
          <div class="col-md-6" :class="{'has-icon-inline-label': errors.has('experience.organisation')}">
            <label class="t-500 t-sm">Organisation</label>
            <input
              type="text"
              class="form-control"
              ref="organisation"
              placeholder="Organisation"
              name="experience.organisation"
              v-model="experience.organisation"
              v-validate="'required|min:2'"
              data-vv-delay="1000"
              data-vv-as="organisation"
              :class="{'is-invalid': errors.has('experience.organisation')}"
            />

            <i v-show="errors.has('experience.organisation')" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="errors.has('experience.organisation')">{{ errors.first('experience.organisation')}}</span>
          </div>

          <!-- title -->
          <div class="col-md-6" :class="{'has-icon-inline-label': errors.has('experience.title')}">
            <label class="t-500 t-sm">Position</label>
            <input
              type="text"
              class="form-control"
              placeholder="Position"
              name="experience.title"
              v-model="experience.title"
              v-validate="'required|min:2'"
              data-vv-delay="1000"
              data-vv-as="position"
              :class="{'is-invalid': errors.has('experience.title')}"
            />

            <i v-show="errors.has('experience.title')" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="errors.has('experience.title')">{{ errors.first('experience.title')}}</span>
          </div>
        </div>

        <div class="form-group mb-2 row">
          <!-- from year -->
          <div class="col-md-3">
            <label class="t-500 t-sm d-block">From month</label>
            <label class="select-label">
              <select class="form-control" v-model="experience.from_month">
                <option v-for="(month, index) in months" :key="index" :value="month.value" class="t-sm">
                  {{ month.name }}
                </option>
              </select>
            </label>
          </div>

          <!-- from year -->
          <div class="col-md-3" :class="{'has-icon-inline-label': errors.has('experience.from_year')}">
            <label class="t-500 t-sm d-block">From year</label>
            <input
              type="text"
              class="form-control"
              placeholder="From year"
              name="experience.from_year"
              v-model="experience.from_year"
              v-validate="'required|digits:4|from_year:experience.to_year'"
              data-vv-as="from year"
              data-vv-delay="1000"
              :class="{'is-invalid': errors.has('experience.from_year')}"
            />

            <i v-show="errors.has('experience.from_year')" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="errors.has('experience.from_year')">{{ errors.first('experience.from_year')}}</span>
          </div>

          <!-- to year -->
          <div class="col-md-3" v-if="!experience.present">
            <label class="t-500 t-sm d-block">To month</label>
            <label class="select-label">
              <select class="form-control" v-model="experience.to_month">
                <option v-for="(month, index) in months" :key="index" :value="month.value" class="t-sm">
                  {{ month.name }}
                </option>
              </select>
            </label>
          </div>

          <!-- to year -->
          <div class="col-md-3" v-if="!experience.present" :class="{'has-icon-inline-label': errors.has('experience.to_year')}">
            <label class="t-500 t-sm d-block">To year</label>
            <input
              type="text"
              class="form-control"
              placeholder="To year"
              name="experience.to_year"
              v-model="experience.to_year"
              minlength="4"
              maxlength="4"
              v-validate="'required|digits:4|to_year:experience.from_year'"
              data-vv-as="to year"
              data-vv-delay="1000"
              :class="{'is-invalid': errors.has('experience.to_year')}"
            />

            <i v-show="errors.has('experience.to_year')" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="errors.has('experience.to_year')">{{ errors.first('experience.to_year')}}</span>
          </div>

          <!-- to year present when checked-->
          <div class="col-md-6 mt-4" v-if="experience.present">
            <!-- to year present -->
            <checkbox v-model="experience.present"
              @input="clearToField()"
              :text="`I currently work here`"
              :name="`experience_present`">
            </checkbox>
          </div>
        </div>

        <!-- switches & checkboxes -->
        <div class="form-group row mb-0">
          <div class="col-md-6 position-relative">
            <!-- render category switches -->
            <switches v-model="category"
              :textEnabled="`Academic experience`"
              :textDisabled="`Non-academic experience`">
            </switches>
          </div>

          <div class="col-md-6" style="margin-top: 8px;" v-if="!experience.present">
            <!-- to year present -->
            <checkbox v-model="experience.present"
              @input="clearToField()"
              :text="`I currently work here`"
              :name="`experience_present`">
            </checkbox>
          </div>
        </div>

        <!-- action buttons -->
        <form-buttons :isRequest="isRequest"
          :isEditMode="isEditMode"
          :isCreateMode="isCreateMode"
          :isRemove="isEditMode"
          @remove="handleRemove(experience)"
          @cancel="handleCancel()" />
      </form>
    </template>

    <!-- render spinner if not loaded -->
    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import Spinner from '@/components/shared/Spinner'
import Switches from '@/components/shared/Switches'
import StaticMessages from '@/components/shared/StaticMessages'
import Editable from '@/components/shared/Editable'
import Checkbox from '@/components/shared/form/Checkbox'
import FormButtons from '@/components/shared/form/Buttons'

import ResearcherService from '@/services/researcher'
import enums from '@/assets/js/_data'
import sortings from '@/mixins/sortings.js'

export default {
  name: 'researcherExperience',
  mixins: [sortings],
  components: {
    Spinner,
    Switches,
    StaticMessages,
    Editable,
    Checkbox,
    FormButtons
  },
  props: {
    isLoaded: {
      type: Boolean,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    researcher: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    containerClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      isRequest: false,
      isCreateMode: false,
      isEditMode: false,
      error: null,
      category: false,
      experience: {
        organisation: '',
        title: '',
        from_month: new Date().getMonth() + 1,
        from_year: new Date().getFullYear(),
        to_month: new Date().getMonth() + 1,
        to_year: new Date().getFullYear(),
        present: false
      }
    }
  },
  created () {
    if (this.isEdit) this.handleCreate()
  },
  computed: {
    experiences () {
      return this.sortTimeline(this.researcher.experiences, 'title')
    },
    months () {
      return enums.months
    }
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true
          // set correct category via ENUM
          this.category ? this.experience.category = 0 : this.experience.category = 1
          this.isCreateMode ? this.doCreateRequest() : this.doEditRequest()
        }
      })
    },
    doCreateRequest () {
      ResearcherService.createExperience(this.$currentUser.slug_values[0], this.experience).then(experience => {
        this.$events.$emit('researcher:updated', 'experiences', experience)
        this.isRequest = false
        this.handleCancel()
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    doEditRequest () {
      ResearcherService.updateExperience(this.$currentUser.slug_values[0], this.experience).then(experience => {
        this.$events.$emit('researcher:updated', 'experiences', experience)
        this.isRequest = false
        this.handleCancel()
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleRemove (value) {
      if (this.isEditMode && value && !this.isRequest) {
        this.isRequest = true
        ResearcherService.removeExperience(this.$currentUser.slug_values[0], value.id).then(() => {
          this.experiences.splice(this.experiences.findIndex((i) => i.id === value.id), 1)
          this.$events.$emit('researcher:updated', 'experiences', this.experiences)
          this.handleCancel()
          this.isRequest = false
        }).catch(err => {
          this.isRequest = false
          this.error = this.handleError(err)
        })
      }
    },
    handleEdit (value, index) {
      value.category === 'non_academic' ? this.category = false : this.category = true
      this.resetForm(value)
      this.isEditMode = true
      this.isCreateMode = false
    },
    handleCancel () {
      this.resetForm(this.$options.data().experience)
      this.isEditMode = false
      this.isCreateMode = false
      if (!this.isEditMode) this.$emit('cancelled')
    },
    handleCreate () {
      this.resetForm(this.$options.data().experience)
      this.isCreateMode = true
      this.isEditMode = false
      this.$nextTick(() => this.$refs.organisation.focus())
    },
    resetForm (data) {
      this.$validator.pause()
      this.experience = { ...data }
      this.$validator.errors.clear()
      this.$nextTick(() => {
        // temporary fix for the delay value on inputs
        // https://github.com/baianat/vee-validate/issues/740
        setTimeout(() => {
          this.$validator.resume()
        }, 300)
      })
    },
    clearToField () {
      if (this.experience.present) {
        this.experience.to_month = new Date().getMonth() + 1
        this.experience.to_year = new Date().getFullYear()
      }
    }
  }
}
</script>
