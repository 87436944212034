<template>
  <div v-if="isNotificationVisible">
    <div class="cookie-notification-container">
      <div class="cookie-notification-inner container-fluid">
        <p class="cookie-notification-text">
          AcademicLabs uses cookies to better understand and improve your experience on our website.
          By clicking "Accept All Cookies", you consent to this activity. For additional information see our <a href="https://www.academiclabs.com/cookie-policy" title="Cookie policy" alt="cookie-policy" target="_blank">Cookie Policy</a> and <a href="https://www.academiclabs.com/privacy-policy" title="Privacy policy" alt="privacy-policy" target="_blank">Privacy Policy</a>.
          To manage your consent preferences, click "Manage Cookies".
        </p>
        <p>
          <button
            type="button"
            class="btn btn-primary--inverted py-2 t-600 mr-2"
            @click.stop.prevent="openModal"
          >
            Manage Cookies
          </button>
        </p>
        <p>
          <button
            type="button"
            class="btn btn-secondary py-2 t-600"
            @click.stop.prevent="acceptAllCookies"
          >
            Accept All Cookies
          </button>
        </p>
      </div>
    </div>
    <cookie-modal
      v-if="isModalOpen"
      @acknowledged="saveAndApplySettings"
      @close="isModalOpen = false"
    />
  </div>
</template>

<script>
import CookieModal from '@/components/cookie_notification/CookieModal'
import cookieHelpers from '@/components/cookie_notification/cookieHelpers'

export default {
  name: 'cookieNotification',
  components: {
    CookieModal
  },
  mixins: [cookieHelpers],
  data () {
    return {
      isNotificationVisible: false,
      isModalOpen: false
    }
  },
  mounted () {
    const currentSettings = this.$currentUser ? this.$currentUser : this.$ls.getCookieSettings()

    // User has not acknowledged cookies
    if (!currentSettings.acknowledged_cookies) {
      // Show cookie notification
      this.isNotificationVisible = true
    } else {
      // Store current settings
      this.settings.acknowledged_cookies = !!currentSettings.acknowledged_cookies
      this.settings.cookie_analytics = !!currentSettings.cookie_analytics
      // User has acknowledged cookies, init scripts if needed
      this.applySettings()
    }
  },
  methods: {
    saveAndApplySettings (settings) {
      this.settings = settings
      this.confirmChoices()
    },
    async acceptAllCookies () {
      this.settings.cookie_analytics = true
      this.settings.acknowledged_cookies = true
      await this.updateSettings()
      this.finalizeState()
    },
    async confirmChoices () {
      this.settings.acknowledged_cookies = true
      await this.updateSettings()
      this.finalizeState()
    },
    finalizeState () {
      this.applySettings()

      this.isNotificationVisible = false
      this.isModalOpen = false
    },
    openModal () {
      this.isModalOpen = true
    }
  }
}
</script>
