<template>
 <div class="row mx-0 modal-search-content">
    <static-messages v-if="error && isLoaded"
      :messages="error"
      :variant="`danger`"
      @closed="$emit('clearError')">
    </static-messages>

    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px`" />

    <not-found v-if="isError404" @close="closeModal" />

    <div v-if="isLoaded && !error && !isError404" class="col-md-8 py-4 py-lg-5 px-4 px-lg-5" :class="contentClass">
      <slot name="modal-content"></slot>
    </div>

    <div v-if="isLoaded && !error && !isError404" class="col-md-4 bg-modal-grey py-4 py-lg-5 px-4 px-lg-5 bd-l box-shadow" :class="sidebarClass">
      <slot name="modal-sidebar"></slot>
    </div>
  </div>
</template>

<script>
import NotFound from '@/components/shared/NotFound'
import Spinner from '@/components/shared/Spinner'
import StaticMessages from '@/components/shared/StaticMessages'
export default {
  name: 'searchModalBaseContent',
  components: {
    NotFound,
    Spinner,
    StaticMessages
  },
  props: {
    isLoaded: {
      type: Boolean,
      required: true
    },
    isError404: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    scope: {
      type: String,
      required: true
    },
    error: {
      type: String,
      default: ''
    },
    contentClass: {
      type: String,
      default: ''
    },
    sidebarClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeModal () {
      this.$events.$emit('modal:close')
    }
  }
}
</script>
