<template>
  <div>
    <template v-if="!isSearchLink">
      <span v-for="(keyword, i) in visibleKeywords" :key="`keyword-${i}`" class="btn-keyword d-inline-block capitalize" v-tooltip="tooltipProperty && keyword[tooltipProperty]">
        <span class="t-600" v-if="labelProperty">{{keyword[labelProperty]}}: </span><span v-html="getValue(keyword)" />
      </span>
    </template>
    <template v-else>
      <router-link v-for="(keyword, i) in visibleKeywords" :key="`keyword-${i}`"
        class="btn-keyword d-inline-block capitalize"
        v-tooltip:bottom="`Search other ${$options.filters.humanize(category)} related to ${$options.filters.titleize(getValueWithoutTags(keyword))}`"
        :to="{ name: 'search', params: { category: category, buckets: `${bucket}:${$options.filters.slugify(getValueWithoutTags(keyword))}` }}"
        v-html="getValue(keyword)">
      </router-link>
    </template>
    <a
      v-if="!hideShowAll && limit && keywords.length > limit"
      @click.prevent="isCollapsed = !isCollapsed"
      class="t-sm ml-1" href="#"
    >{{ isCollapsed ? 'Show all' : 'Show less' }}</a>
  </div>
</template>

<script>
export default {
  name: 'sharedKeywords',
  props: {
    keywords: {
      type: Array,
      required: true
    },
    category: {
      type: String,
      required: false
    },
    bucket: {
      type: String,
      required: false
    },
    property: {
      type: String,
      default: 'keyword'
    },
    isSearchLink: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      required: false
    },
    labelProperty: {
      type: String,
      required: false
    },
    tooltipProperty: {
      type: String,
      required: false
    },
    hideShowAll: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isCollapsed: true
    }
  },
  computed: {
    visibleKeywords () {
      // If a limit is set, we want to only show a subset of the keywords if needed,
      // with an option to toggle/show them all
      if (!this.limit || !this.isCollapsed) return this.keywords
      return this.keywords.slice(0, this.limit)
    }
  },
  methods: {
    getValue (keyword) {
      return this.isEmpty(this.property) ? keyword : keyword[this.property]
    },
    getValueWithoutTags (keyword) {
      return this.stripTags(this.getValue(keyword))
    }
  }
}
</script>
