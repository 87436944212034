<template>
  <div>
    <h2 v-if="isEmpty(success) && !isWithoutTitle" class="t-primary t-400 mb-0">Join/create your {{ textOrganisation }}</h2>
    <hr v-if="isEmpty(success) && !isWithoutTitle" class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <div v-if="!isEmpty(success)">
      <static-messages :messages="success" :variant="`success`" :containerClass="`mt-3 mb-0`" />
      <button v-if="!isWithoutClose" type="button" class="btn btn-form btn-secondary pull-right mt-3" @click="$events.$emit('modal:close')">
        Close
      </button>
    </div>

    <!-- spinner -->
    <spinner v-if="isLoaded" :containerClass="`width--50px min-height--150px`" />

    <!-- join / create or add -->
    <form v-if="isEmpty(success)" @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <div class="form-group mb-4" :class="{'has-icon-small': !isEmpty(errorName) && !isInOnboardingFlow, 'has-icon': !isEmpty(errorName) && isInOnboardingFlow}">
        <!-- <label v-if="!isInOnboardingFlow" class="t-500 t-sm label-required">{{ textOrganisation | capitalize }} name</label> -->
        <!-- render typeahead -->
        <typeahead :remote="`organisation`"
          :name="`organisation`"
          :value="create.name"
          :displayKey="`name`"
          :limit="5"
          :minLength="2"
          :emitOnBlur="true"
          :canAdd="isWithCreate"
          :isElasticSearchOrganisation="true"
          :clearOnSelect="false"
          :canAddMinLength="1"
          :canAddPlaceholder="getPlaceholder"
          :placeholder="`Search for an organisation`"
          :customClass="`mt-0`"
          :customInputClass="isInOnboardingFlow ? 'form-control-lg' : ''"
          :customDropdownClass="`mb-4`"
          :class="{'is-invalid': !isEmpty(errorName)}"
          @selected="handleTypeaheadOrganisation"
          @add="handleTypeaheadAddOrganisation"
          @keyIsDown="handleTypeaheadKeyIsDownOrganisation"
          @noResults="handleNoResultsOrganisation"
          @blurred="handleTypeaheadBlurOrganisation"
          @opened="$emit('opened')"
          @closed="$emit('closed')"
        ></typeahead>

        <i v-show="!isEmpty(errorName)" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="!isEmpty(errorName)">{{ errorName }}</span>
      </div>

      <template v-if="isCreateMode || isEditMode">
        <!-- category -->
        <div class="row">
          <div class="form-group col-md-6">
            <label class="t-500 t-sm label-required">
              Category
            </label>
            <label class="select-label">
              <select class="form-control" v-model="create.category">
                <option value="corporate" class="t-sm">Corporate</option>
                <option value="academic" class="t-sm">Academic</option>
              </select>
            </label>
          </div>

          <!-- country -->
          <div class="form-group col-md-6" :class="{'has-icon-inline-label': !isEmpty(errorCountry)}">
            <label class="t-500 t-sm label-required">Country</label>
            <typeahead :remote="countryEndpoint"
              :name="countryEndpoint"
              :value="create.country_name"
              :displayKey="countryDisplayKey"
              :emitOnBlur="true"
              :limit="5"
              :placeholder="'Country'"
              :customClass="'mt-0'"
              :class="{'is-invalid': !isEmpty(errorCountry)}"
              @selected="handleTypeaheadCountry"
              @opened="$emit('opened')"
              @closed="$emit('closed')"
            ></typeahead>

            <i v-show="!isEmpty(errorCountry)" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="!isEmpty(errorCountry)">{{ errorCountry }}</span>
          </div>
        </div>

        <!-- website -->
         <div class="row">
          <!-- website -->
          <div class="form-group col-md-12" :class="{'has-icon-inline-label': errors.has('create.urls_attributes[0].url')}">
            <label class="t-500 t-sm label-required">Website</label>
            <input
              type="text"
              class="form-control"
              placeholder="Website"
              name="create.urls_attributes[0].url"
              v-model="create.urls_attributes[0].url"
              v-validate="'required|url:require_protocol'"
              data-vv-delay="1000"
              data-vv-as="website"
              :class="{'is-invalid': errors.has('create.urls_attributes[0].url')}"
            />

            <i v-show="errors.has('create.urls_attributes[0].url')" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="errors.has('create.urls_attributes[0].url')">{{ errors.first('create.urls_attributes[0].url') }}</span>
          </div>
        </div>

        <div class="row">
          <!-- domain -->
          <!-- <div class="form-group col-md-12" :class="{'has-icon-inline-label': errors.has('create.urls_attributes[0].domain')}">
            <label class="t-500 t-sm label-required">Domain</label>
            <input
              ref="domain"
              type="text"
              class="form-control"
              placeholder="Domain"
              name="create.urls_attributes[0].domain"
              v-model="create.urls_attributes[0].domain"
              v-validate="'required'"
              data-vv-as="domain"
              data-vv-delay="1000"
              :class="{'is-invalid': errors.has('create.urls_attributes[0].domain')}"
            />

            <i v-show="errors.has('create.urls_attributes[0].domain')" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="errors.has('create.urls_attributes[0].domain')">{{ errors.first('create.urls_attributes[0].domain') }}</span>
          </div> -->

          <!-- fte's -->
          <!-- <div class="form-group col-md-4">
            <label class="t-500 t-sm">
              FTE's
            </label>
            <label class="select-label">
              <select class="form-control" v-model="create.fte">
                <option value="" selected disabled hidden class="t-secondary">Choose</option>
                <option v-for="(fte, index) in ftes" :key="index" :value="fte.value" class="t-sm">
                  {{ fte.name }}
                </option>
              </select>
            </label>
          </div> -->
        </div>

        <!-- email -->
        <!-- <div class="form-group mb-4" :class="{'has-icon-label': errors.has('create.email')}">
          <label class="t-500 t-sm label-required">
            General contact email
            <i class='fa fa-info-circle ml-1 t-sm t-secondary' v-tooltip.top="`In settings you will be able to choose a contact person for this`"></i>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Contact email"
            name="create.email"
            v-model="create.email"
            v-validate="'required|email'"
            data-vv-delay="1000"
            data-vv-as="email"
            :class="{'is-invalid': errors.has('create.email')}"
          />

          <i v-show="errors.has('create.email')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.email')">{{ errors.first('create.email') }}</span>
        </div> -->

        <!-- phone -->
        <!-- <div class="form-group mb-4" :class="{'has-icon-label': errors.has('create.phone')}">
          <label class="t-500 t-sm">
            General contact phone
            <i class='fa fa-info-circle ml-1 t-sm t-secondary' v-tooltip.top="`In settings you will be able to choose a contact person for this`"></i>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Contact phone"
            name="create.phone"
            v-model="create.phone"
            :class="{'is-invalid': errors.has('create.phone')}"
          />

          <i v-show="errors.has('create.phone')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.phone')">{{ errors.first('create.phone') }}</span>
        </div> -->

        <!-- street -->
        <!-- <div class="form-group mb-4" :class="{'has-icon-label': errors.has('create.street')}">
          <label class="t-500 t-sm">Street & Nr.</label>
          <input
            type="text"
            class="form-control"
            placeholder="Street"
            name="create.street"
            v-model="create.street"
            :class="{'is-invalid': errors.has('create.street')}"
          />

          <i v-show="errors.has('create.street')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.street')">{{ errors.first('create.street') }}</span>
        </div> -->

        <!-- <div class="row">
          <div class="form-group col-md-8" :class="{'has-icon-label': errors.has('create.city')}">
            <label class="t-500 t-sm">City</label>
            <input
              type="text"
              class="form-control"
              placeholder="City"
              name="create.city"
              v-model="create.city"
              :class="{'is-invalid': errors.has('create.city')}"
            />

            <i v-show="errors.has('create.city')" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="errors.has('create.city')">{{ errors.first('create.city') }}</span>
          </div>

          <div class="form-group col-md-4" :class="{'has-icon-label': errors.has('create.post_code')}">
            <label class="t-500 t-sm">Postal code</label>
            <input
              type="text"
              class="form-control"
              placeholder="Postal code"
              name="create.post_code"
              v-model="create.post_code"
              :class="{'is-invalid': errors.has('create.post_code')}"
            />

            <i v-show="errors.has('create.post_code')" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="errors.has('create.post_code')">{{ errors.first('create.post_code') }}</span>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-4" :class="{'has-icon-inline-label': errors.has('create.foundation_year')}">
            <label class="t-500 t-sm">Foundation year</label>
            <input
              type="text"
              class="form-control"
              placeholder="e.g 2014"
              name="create.foundation_year"
              v-model="create.foundation_year"
              v-validate="'digits:4|year'"
              data-vv-delay="1000"
              data-vv-as="foundation year"
              :class="{'is-invalid': errors.has('create.foundation_year')}"
            />

            <i v-show="errors.has('create.foundation_year')" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="errors.has('create.foundation_year')">{{ errors.first('create.foundation_year') }}</span>
          </div>
        </div> -->
      </template>

      <!-- action buttons -->
      <form-buttons v-if="!isEmpty(create.id) || isCreateMode"
        :isCreateMode="isCreateMode || isJoinMode"
        :isEditMode="isEditMode"
        :isRequest="isRequest"
        :createText="getCreateText"
        :isCancel="false"
        :btnClass="isInOnboardingFlow ? 'btn-scoped-view-flow-centered ' : 'pull-none px-5'"
        :containerClass="isInOnboardingFlow ? 'bd-none mb-4 mt-0' : 'bd-none text-center '"
        @cancel="handleCancel()">
      </form-buttons>
    </form>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import Spinner from '@/components/shared/Spinner'
import Typeahead from '@/components/shared/form/Typeahead'
import FormButtons from '@/components/shared/form/Buttons'

import OrganisationService from '@/services/organisation'
import UserService from '@/services/user'
import staticData from '@/assets/js/_data'

export default {
  name: 'organisationCreate',
  components: {
    StaticMessages,
    Typeahead,
    Spinner,
    FormButtons
  },
  props: {
    user: {
      type: Object,
      required: false
    },
    researcher: {
      type: Object,
      required: false
    },
    isInOnboardingFlow: {
      type: Boolean,
      required: false,
      default: false
    },
    isWithoutTitle: {
      type: Boolean,
      required: false,
      default: false
    },
    isWithoutClose: {
      type: Boolean,
      required: false,
      default: false
    },
    isWithCreate: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      error: null,
      isJoinMode: true,
      isCreateMode: false,
      isEditMode: false,
      isLoaded: false,
      isRequest: false,
      countryEndpoint: 'country',
      countryDisplayKey: 'name',
      errorCountry: '',
      errorName: '',
      success: '',
      textOrganisation: 'organisation',
      create: {
        name: '',
        slug: '',
        country_id: '',
        country_name: '',
        category: 'corporate',
        sub_category: '',
        state: '',
        city: '',
        post_code: '',
        street: '',
        foundation_year: '',
        urls_attributes: [
          {
            url: '',
            domain: '',
            notes: 'PD'
          }
        ]
      }
    }
  },
  computed: {
    ftes () {
      return staticData.fte
    },
    getCreateText () {
      return this.isCreateMode ? 'Create' : 'Join'
    },
    getPlaceholder () {
      return (this.isValidAcademicCategory(this.user.category.name))
        ? `Don't find your institution? Create new.`
        : `Don't find your company? Create new.`
    }
  },
  mounted () {
    this.preFill()
  },
  metaInfo () {
    return {
      titleTemplate: `Organisation create  | AcademicLabs`,
      meta: [
        { vmid: 'description', name: 'description', content: `Create an organisation` }
      ]
    }
  },
  watch: {
    'create.country_name' (value) {
      this.isEmpty(value) ? this.errorCountry = 'The country field is required' : this.errorCountry = ''
    },
    'create.name': {
      handler (value) {
        !this.isEmpty(value) ? this.$emit('hideInfo') : this.$emit('showInfo')
      },
      deep: true
    }
  },
  methods: {
    preFill () {
      if (this.isValidAcademicCategory(this.user.category.name)) this.textOrganisation = 'institution'
    },
    preFillFromResearcher () {
      this.create.email = this.user.email
      this.create.phone_number = this.researcher.phone_number

      if (!this.isEmpty(this.researcher.country)) {
        this.create.country_id = this.researcher.country.id
        this.create.country_name = this.researcher.country.name
      }

      if (this.isValidAcademicCategory(this.user.category.name)) this.create.category = 'academic'
      if (!this.isEmpty(this.$currentUser.email)) this.fillInDomain(this.$currentUser.email)
    },
    fillInDomain (email) {
      let match = email.split('@')
      if (match != null && match.length > 1 && typeof match[1] === 'string' && match[1].length > 0) {
        this.create.urls_attributes[0].domain = match[1]
        this.create.urls_attributes[0].url = match[1]
      }
    },
    handleTypeaheadOrganisation (name, item) {
      this.isCreateMode = false
      this.isJoinMode = true

      if (typeof item === 'object' && this.isJoinMode) {
        this.create.name = item.name
        this.create.id = item.slug_values[0]
      }
    },
    handleTypeaheadAddOrganisation (name, item, minLength) {
      this.$nextTick(() => { this.$emit('hideInfo') })
      this.isCreateMode = true
      this.isJoinMode = false
      this.addCustom(name, item, minLength)
    },
    handleTypeaheadBlurOrganisation (name, item, minLength) {
      if (item.length > 0 && this.isCreateMode) {
        this.create.name = item
      }
    },
    handleTypeaheadKeyIsDownOrganisation (item) {
      this.isEmpty(item) ? this.$emit('showInfo') : this.$emit('hideInfo')
    },
    handleNoResultsOrganisation () {
      // if (!this.isInOnboardingFlow) this.isHideJoinButton
      this.$emit('noResults')
    },
    addCustom (name, item, minLength) {
      if (typeof item === 'string') {
        this.preFillFromResearcher()
        this.create.name = item
      }
    },
    handleTypeaheadCountry (name, item) {
      this.$set(this.create, 'country_id', item.id)
      this.$set(this.create, 'country_name', item.name)
    },
    validateBeforeSubmit () {
      if (this.isJoinMode) {
        if (!this.isEmpty(this.create.id) && !this.isRequest) this.handleJoin()
      } else {
        this.$validator.validateAll().then((result) => {
          if (this.isEmpty(this.create.country_name)) {
            result = false
            this.errorCountry = 'The country field is required'
          }

          if (this.isEmpty(this.create.name)) {
            result = false
            this.errorName = 'The organisation name field is required'
          }

          if (result && !this.isRequest) {
            this.isRequest = true
            this.handleCreate()
          }
        })
      }
    },
    handleCreate () {
      OrganisationService.create(this.create).then(organisation => {
        this.isRequest = false
        this.addOrganisationToResearcher(organisation)
        this.$emit('created')

        this.isInOnboardingFlow
          ? this.$emit('goToNextStep')
          : this.success = 'You have successfully sent a request to create the organisation. Our team will review your verify request and contact you very soon.'
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleJoin () {
      this.isRequest = true
      let invite = {
        message: 'Invite send via AcademicLabs join request',
        invitable_type: 'Organisation',
        invitable_id: this.create.id
      }

      UserService.createInviteRequest(this.$currentUser.user_id, invite).then(invite => {
        if (invite.action === 'confirmed') {
          this.addOrganisationToResearcher({ name: invite.invitable.name, slug_values: invite.invitable.slug_values })
          this.success = 'You have successfully joined the organisation.'
          this.$events.$emit('current_user:update')
        } else {
          this.success = 'Great! Our team will review this in less than 24 hours and send you a notification.'
        }

        this.$emit('completed')
        this.isRequest = false
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    addOrganisationToResearcher (organisation) {
      let organisations = Object.assign([], this.researcher.organisations)
      organisations.push(organisation)
      this.$events.$emit('researcher:updated', 'organisations', organisations)
    },
    handleCancel () {
      this.$emit('cancel')
      this.$events.$emit('modal:close')
    }
  }
}
</script>
