<template>
  <div v-if="shortPitch">
    <p class="t-xxxlr t-600">
      Book a demo to benefit from:
    </p>
    <ul class="pl-3 pb-2">
      <li><b>Full access to all results for all categories of content</b></li>
      <li>Full access to our filters and analytics</li>
      <li>Export your results to Excel or CSV</li>
      <li>Save your results to shared bookmark folders</li>
      <li>Create alerts and stay informed when new data is available on your topics</li>
      <li>Benefit from free training on demand and tips by our product experts</li>
    </ul>
  </div>
  <div v-else>
    <p class="t-xxxlr t-600">
      Learn in a demo how you'll benefit from one platform uniting all science and innovators
    </p>
    <p class="t-500">With full access to all results, filters, visualisations, bookmarking and exporting capabilities, you can easily:</p>
    <block-collapsible title="Find ideal research groups for collaboration" class="pb-1" hideIcon>
      Identify perfect partners for your projects allowing you to de-risk, expand and accelerate your R&D efforts to strengthen your R&D pipeline.
    </block-collapsible>
    <block-collapsible title="Connect with top-tier experts worldwide" class="pb-1" hideIcon>
      Gain direct access to the best-fit experts across the globe. Connect with Key Opinion Leaders (KOLs), Rising Star Principal Investigators (PIs), and other subject-specific specialists to make informed decisions that drive your R&D, clinical trials, and investment strategies.
    </block-collapsible>
    <block-collapsible title="Discover untapped potential in emerging biotech startups" class="pb-1" hideIcon>
      Uncover a hidden world of emerging biotech startups before anyone else. Our platform provides unique access to early-stage ventures that are not typically found in pipeline and funding databases. Expand your opportunities for licensing, partnerships, strategic investments, or commercialization.
    </block-collapsible>
    <block-collapsible title="Master your competitive landscape with ease" class="pb-1" hideIcon>
      Gain a comprehensive understanding of your evolving competitive landscape, from startups to major players in the pharmaceutical industry. Effortlessly assess their publicly funded research project, patents, clinical trials, publications, collaborations, and trends related to specific diseases, targets, and technology adoption. Stay one step ahead of your competitors and position your company with confidence.
    </block-collapsible>
    <block-collapsible title="Stay at the forefront of scientific innovations" class="pb-1" hideIcon>
      Keep up with cutting-edge technologies, breakthrough discoveries, and emerging trends. Leverage our platform to be the first to capitalize on the latest advancements, gaining a significant competitive advantage in the market.
    </block-collapsible>
    <block-collapsible title="Understand any research topic and its research landscape in no time" class="pb-1" hideIcon>
      From new early-stage research projects and recent discoveries up to phase 3 clinical trials, our platform allows you to comprehensively understand a technology, disease, target or any topics research landscape with ease.
    </block-collapsible>
    <block-collapsible title="Accelerate your literature studies exponentially" class="pb-1" hideIcon>
      With our trend analysis, advanced search capabilities, and efficient filtering, you can complete literature reviews up to five times faster. Gain instant insights and access the most relevant scientific literature effortlessly, saving valuable time and effort.
    </block-collapsible>
    <block-collapsible title="Obtain instant state-of-the-art knowledge" class="pb-1" hideIcon>
      Get up-to-speed and stay up-to-date on the latest advancements in technologies, targets, and diseases to make informed decisions with unparalleled speed and accuracy.
    </block-collapsible>
    <block-collapsible title="Conduct rigorous scientific due diligence" class="pb-1" hideIcon>
      Ensure your investment decisions are based on solid foundations. Our platform equips you with the necessary tools to perform thorough scientific due diligence. Evaluate biotech startups, scientific experts, the technology space and competitive landscape with confidence and ease, reducing risks and maximizing your potential for success.
    </block-collapsible>
    <block-collapsible title="Unlock limitless commercial opportunities" class="pb-1" hideIcon>
      With our 360° in-depth scientific profiles and extensive search criteria, identify the perfect academic research groups, biotech leaders, and senior researcher champions in the pharmaceutical industry for licensing, collaboration, or commercial partnerships to grow your business sustainably.
    </block-collapsible>
    <block-collapsible title="Gain market size insights" class="pb-1" hideIcon>
      Leverage our powerful filters and analytics to gain in-depth insights into your target market. Uncover valuable market size data and trends, empowering you to make strategic decisions that drive your business forward.
    </block-collapsible>
  </div>
</template>

<script>
import BlockCollapsible from '@/components/shared/BlockCollapsible'

export default {
  name: 'proPitch',
  components: {
    BlockCollapsible
  },
  props: {
    shortPitch: {
      type: Boolean,
      default: false
    }
  }
}
</script>
