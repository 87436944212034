<template>
  <li class="nav-item d-none d-sm-block">
    <a
      v-if="!$can('giveFreeAccess')"
      :href="`mailto:?subject=I%20invite%20you%20to%20check%20out%20AcademicLabs%2C%20the%20scientific%20intelligence%20platform%20made%20for%20you&body=Dear%20colleague%2C%0A%0AI'm%20using%20AcademicLabs%2C%20a%20scientific%20intelligence%20platform%20that%20makes%20it%20easy%20to%20find%20the%20most%20relevant%20experts%2C%20research%20groups%2C%20biotech%20startups%2C%20pharma%20research%20and%20collaborations%2C%20ongoing%20and%20published%20research%2C%20new%20projects%2C%20patents%2C%20clinical%20trials%2C%20research%20trends%2C%20and%20much%20more.%20I%20think%20you'd%20find%20it%20really%20useful%20for%20your%20work.%0A%0ASign%20up%20here%20to%20try%20a%20search%20and%20discover%20what%20you%20can%20find%3A%20https%3A%2F%2Fapp.academiclabs.com%2Fsignup%0A%0ATo%20learn%20more%2C%20here%E2%80%99s%20an%20intro%20video%20on%20the%20website%3A%20https%3A%2F%2Fwww.academiclabs.com%2F%0Aor%20schedule%20a%2010%E2%80%99%20demo%20session%3A%20https%3A%2F%2Fwww.academiclabs.com%2Facademiclabs-get-started%0A%0ABest%20Regards%2C%0A%0A${$currentUser.full_name}`"
      class="nav-link-button"
      target="_blank" rel="noopener noreferrer"
      v-tooltip="`Invite your colleagues to AcademicLabs`"
      @click="trackInviteClicked"
    >
      <i class="fa fa-user-plus" />
      <span class="ml-2 d-none d-lg-inline-block t-500">Invite</span>
    </a>
    <dropdown
      v-else
      placement="bottom-end"
      noPadding
      :closeOnClick="false"
    >
      <template #button>
        <a href="#" @click.prevent class="nav-link-button" aria-haspopup="true" aria-expanded="false"
          v-tooltip="`Invite your colleagues to AcademicLabs`">
          <i class="fa fa-user-plus" />
          <span class="ml-2 d-none d-lg-inline-block t-500">Invite</span>
        </a>
      </template>
      <div class="dropdown-menu-custom box-shadow" aria-labelledby="get-started-dropdown">
        <div class="dropdown-item d-flex align-items-center pb-2 mb-2">
          <span class="dropdown-item_text t-primary">
            Treat a colleague to <span class="t-600">14 days free access!</span>
          </span>
        </div>
        <div class="form-group mb-1">
          <form @submit.prevent="clickButton">
            <input
              type="text"
              class="form-control"
              placeholder="Colleague's email address"
              name="email address"
              v-model="email"
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              :class="{'is-invalid': errors.has('email address')}"
            />
            <span class="invalid-feedback" v-show="errors.has('email address')">{{ errors.first('email address') }}</span>
            <button
              class="btn width--100per t-600 btn-primary mt-2"
            >
              Invite
            </button>
          </form>
        </div>
      </div>
    </dropdown>
  </li>
</template>

<script>
import MPService from '@/services/mixpanel'
import UserService from '@/services/user'

import Dropdown from '@/components/shared/form/Dropdown'

export default {
  name: 'headerInviteDropdown',
  components: {
    Dropdown
  },
  data () {
    return {
      email: ''
    }
  },
  methods: {
    trackInviteClicked () {
      MPService.trackEvent('invite_to_academiclabs')
    },
    clickButton () {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) return

        const email = this.email

        UserService.inviteToAL(email)
          .then(() => {
            this.$flash({ message: `Thanks! Your request to invite <span class="t-600">${email}</span> has been received.` }, 'success')
          })
          .catch(e => {
            if (e.data && e.data.errors) {
              this.$flash({ message: e.data.errors }, 'danger')
            }
          })
      })
    }
  }
}
</script>
