<template>
  <div>
    <template v-if="!isEmpty(records)">
      <h3 class="mb-2 t-600" v-if="title">
        {{ title }}
      </h3>

      <ul class="mb-2 list-inline list-meta-data">
        <template v-for="(record, i) in records">
          <researcher
            v-if="record.party_type === 'researcher'"
            :key="i"
            :researcher="getResearcher(record)"
          />
          <organisation
            v-else-if="record.party_type === 'organisation'"
            :key="i"
            :organisation="getOrganisation(record)"
          />
        </template>
      </ul>
    </template>

    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import Researcher from '@/components/shared/researcherSummary/Researcher'
import Organisation from '@/components/shared/organisationSummary/Organisation'

import Spinner from '@/components/shared/Spinner'

export default {
  name: 'mixedSummary',
  components: {
    Spinner,
    Researcher,
    Organisation
  },
  props: {
    records: {
      type: Array,
      required: true
    },
    isLoaded: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    getResearcher (record) {
      for (let key in record.researcher) {
        record[key] = record.researcher[key]
      }
      return record
    },
    getOrganisation (record) {
      for (let key in record.organisation) {
        record[key] = record.organisation[key]
      }
      return record
    }
  }
}
</script>
