import Vue from 'vue'

const ENDPOINT = '/bookmark_folders'
const FLASK_ENDPOINT = `${process.env.VUE_APP_FLASK_URL}/api/bookmark_folders`

export default {
  getByID (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}`).then(response => response.data)
  },
  getCounts (userId) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}/count_bookmarks`, { user_id: userId }).then(response => response.data)
  },
  addItemFlask (bookmarkFolder, params) {
    return Vue.prototype.$http.post(`${FLASK_ENDPOINT}/${bookmarkFolder}/bookmarks`, params).then(response => response.data)
  },
  bulkFetchIds (data) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}/bookmarks`, data).then(response => response.data)
  },
  bulkAddItem (data) {
    return Vue.prototype.$http.post(`${process.env.VUE_APP_SEARCH_URL}/bookmark_folder`, data).then(response => response.data)
  },
  removeItemFlask (bookmarkFolder, params) {
    return Vue.prototype.$http.delete(
      `${FLASK_ENDPOINT}/${bookmarkFolder}/bookmarks/${params.slug_value}`,
      { data: { index: params.index } }
    ).then(response => response.data)
  },
  create (params) {
    return Vue.prototype.$http.post(`${ENDPOINT}`, { bookmark_folder: params }).then(response => response.data)
  },
  update (bookmarkFolder, params) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${bookmarkFolder}`, { bookmark_folder: params }).then(response => response.data)
  },
  delete (bookmarkFolder) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${bookmarkFolder}`).then(response => response.data)
  },
  getInvites (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/invites`).then(response => response.data)
  },
  createInvite (id, invites) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/invites`, { invite: invites }).then(response => response.data)
  },
  resendInvite (id, inviteId) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/invites/${inviteId}`).then(response => response.data)
  },
  cancelInvite (id, inviteId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/invites/${inviteId}`).then(response => response.data)
  },
  getContributors (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/contributors`).then(response => response.data)
  },
  updateContributor (id, contributor) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/contributors/${contributor.slug}`, { contributor: contributor }).then(response => response.data)
  },
  removeContributor (id, researcherId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/contributors/${researcherId}`).then(response => response.data)
  }
}
