import Vue from 'vue'

const ENDPOINT = 'api/publications'

export default {
  getByID (slug) {
    return Vue.prototype.$httpSkip404.get(`${process.env.VUE_APP_FLASK_URL}/${ENDPOINT}/${slug}`).then(response => response.data)
  },
  getAuthors (slug) {
    return Vue.prototype.$http.get(`${process.env.VUE_APP_FLASK_URL}/${ENDPOINT}/${slug}/authors`).then(response => response.data)
  }
}
