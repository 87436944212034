import Vue from 'vue'

const ENDPOINT = '/researchers'
const FLASK_ENDPOINT = `${process.env.VUE_APP_FLASK_URL}/api/researchers`

export default {
  get (id) {
    return Vue.prototype.$httpSkip404.get(`${FLASK_ENDPOINT}/${id}`).then(response => response.data)
  },
  update (id, researcher) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}`, { researcher: researcher }).then(response => response.data)
  },
  updateCollaborationInterest (id, collaborationInterest) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/collaboration_interests`, { collaboration_interest: collaborationInterest }).then(response => response.data)
  },
  addDisease (id, idDisease) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/interests/${idDisease}?type=disease`).then(response => response.data)
  },
  createDisease (id, disease) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/interests?type=disease`, { interest: disease }).then(response => response.data)
  },
  removeDisease (id, idDisease) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/interests/${idDisease}?type=disease`).then(response => response.data)
  },
  addModelOrganism (id, idModelOrganism) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/interests/${idModelOrganism}?type=model_organism`).then(response => response.data)
  },
  createModelOrganism (id, modelOrganism) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/interests?type=model_organism`, { interest: modelOrganism }).then(response => response.data)
  },
  removeModelOrganism (id, idModelOrganism) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/interests/${idModelOrganism}?type=model_organism`).then(response => response.data)
  },
  updateExperience (id, experience) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/experiences/${experience.id}`, { experience: experience }).then(response => response.data)
  },
  createExperience (id, experience) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/experiences`, { experience: experience }).then(response => response.data)
  },
  removeExperience (id, idExperience) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/experiences/${idExperience}`).then(response => response.data)
  },
  updateEducation (id, education) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/educations/${education.id}`, { education: education }).then(response => response.data)
  },
  createEducation (id, education) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/educations`, { education: education }).then(response => response.data)
  },
  removeEducation (id, idEducation) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/educations/${idEducation}`).then(response => response.data)
  },
  updateResearchType (id, researchType) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/research_types`, { research_type: researchType }).then(response => response.data)
  },
  updateSector (id, sector) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/sectors`, { sector: sector }).then(response => response.data)
  },
  updateTherapeutic (id, therapeutic) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/therapeutics`, { therapeutic: therapeutic }).then(response => response.data)
  },
  updateMedicalTechnology (id, medicalTechnologies) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/medical_technologies`, { medical_technology: medicalTechnologies }).then(response => response.data)
  },
  updateDisciplines (id, disciplines) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/disciplines`, { discipline: disciplines }).then(response => response.data)
  },
  getProjects (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/projects`).then(response => response.data)
  },
  getPublications (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/publications`).then(response => response.data)
  },
  getPublicationByID (id, idPublication) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/publications/${idPublication}`).then(response => response.data)
  },
  createPublication (id, publication) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/publications`, { publication: publication }).then(response => response.data)
  },
  updatePublication (id, publication) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/publications/${publication.slug}`, { publication: publication }).then(response => response.data)
  },
  deletePublication (id, idPublication) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/publications/${idPublication}`).then(response => response.data)
  },
  addResearchInterest (id, idResearch) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/interests/${idResearch}`).then(response => response.data)
  },
  getResearchInterests (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/interests`).then(response => response.data)
  },
  createResearchInterest (id, interest) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/interests`, { interest: interest }).then(response => response.data)
  },
  removeResearchInterest (id, idResearch) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/interests/${idResearch}`).then(response => response.data)
  },
  getCommunities (id, allCommunities = false) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/communities?all=${allCommunities}`).then(response => response.data)
  },
  joinCommunity (id, communityId, researchGroupIds) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/communities/${communityId}`, { join: researchGroupIds }).then(response => response.data)
  },
  getCommunitiesByResearchGroupId (id, researchGroupId) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/communities?research_group_id=${researchGroupId}`).then(response => response.data)
  },
  getCommunitiesInterests (id, limit) {
    let endpoint = `${ENDPOINT}/${id}/community_interests`
    if (limit) endpoint += `?limit=${limit}`
    return Vue.prototype.$http.get(endpoint).then(response => response.data)
  },
  getResearchGroups (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/research_groups`).then(response => response.data)
  },
  leaveCommunity (id, communityId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/communities/${communityId}`).then(response => response.data)
  },
  leaveResearchGroup (id, researchGroupId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/research_groups/${researchGroupId}`).then(response => response.data)
  },
  leaveBookmarkFolder (id, folderId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/bookmark_folders/${folderId}`).then(response => response.data)
  },
  leaveOrganisation (id, organisationId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/organisations/${organisationId}`).then(response => response.data)
  },
  addUserToOrganisation (id, orgId) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/organisations`, { organisation: { id: orgId } }).then(response => response.data)
  },
  getOrganisations (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/organisations`).then(response => response.data)
  },
  getContactInfo (id) {
    return Vue.prototype.$httpSilentError.get(`${ENDPOINT}/${id}/contact_info`).then(response => response.data)
  },
  createContactInfo (id, contactInfo) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/contact_info`, { contact_info: contactInfo }).then(response => response.data)
  },
  updateContactInfo (id, contactInfo) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/contact_info/${contactInfo.id}`, { contact_info: contactInfo }).then(response => response.data)
  },
  deleteContactInfo (id, contactInfoId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/contact_info/${contactInfoId}`).then(response => response.data)
  },
  message (id, message) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/messages`, { message: message }).then(response => response.data)
  },
  getKeywords (id) {
    return Vue.prototype.$httpSilentError.get(`${ENDPOINT}/${id}/keywords`).then(response => response.data)
  },
  getTooltipData (slugText) {
    return Vue.prototype.$http.get(`${process.env.VUE_APP_FLASK_URL}/api/pop_ups/researcher/${slugText}`).then(response => response.data)
  }
}
