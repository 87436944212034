const Base = () => import(/* webpackChunkName: "create" */ '@/views/create/Base.vue')
const ResearchGroupType = () => import(/* webpackChunkName: "create" */ '@/views/create/research_group/Type.vue')
const ResearchGroupCompany = () => import(/* webpackChunkName: "create" */ '@/views/create/research_group/Company.vue')
const ResearchGroupAcademic = () => import(/* webpackChunkName: "create" */ '@/views/create/research_group/Academic.vue')
const CommunityType = () => import(/* webpackChunkName: "create" */ '@/views/create/community/Type.vue')
const CommunityOrganisation = () => import(/* webpackChunkName: "create" */ '@/views/create/community/Organisation.vue')
const CommunityProject = () => import(/* webpackChunkName: "create" */ '@/views/create/community/Project.vue')
const Organisation = () => import(/* webpackChunkName: "create" */ '@/views/create/organisation/Create.vue')
const Opportunity = () => import(/* webpackChunkName: "create" */ '@/views/create/opportunity/Create.vue')

export default [
  {
    path: '/create',
    name: 'create',
    meta: {
      auth: true
    },
    component: Base,
    children: [
      {
        path: 'opportunity/:id',
        name: 'create.opportunity',
        meta: {
          auth: true,
          render: {
            searchBar: false,
            title: 'Opportunity'
          }
        },
        component: Opportunity
      }, {
        path: 'research_group',
        name: 'create.research_group.type',
        meta: {
          auth: true,
          render: {
            searchBar: true
          }
        },
        component: ResearchGroupType
      }, {
        path: 'research_group/company',
        name: 'create.research_group.company',
        meta: {
          auth: true,
          render: {
            searchBar: true
          }
        },
        component: ResearchGroupCompany
      }, {
        path: 'research_group/academic',
        name: 'create.research_group.academic',
        meta: {
          auth: true,
          render: {
            searchBar: true
          }
        },
        component: ResearchGroupAcademic
      }, {
        path: 'community',
        name: 'create.community.type',
        meta: {
          auth: true,
          render: {
            searchBar: true
          }
        },
        component: CommunityType
      }, {
        path: 'community/organisation',
        name: 'create.community.organisation',
        meta: {
          auth: true,
          render: {
            searchBar: true
          }
        },
        component: CommunityOrganisation
      }, {
        path: 'community/project',
        name: 'create.community.project',
        meta: {
          auth: true,
          render: {
            searchBar: true
          }
        },
        component: CommunityProject
      }, {
        path: 'organisation',
        name: 'create.organisation',
        meta: {
          auth: true,
          render: {
            searchBar: true
          }
        },
        component: Organisation
      }
    ]
  }
]
