<template>
  <div>
    <div class="row py-2 no-gutters no-padding border-bottom" v-for="(invite) in invites" :key="invite.email">
      <div class="col-3 d-flex align-items-center py-2">
        <researcher-link v-if="invite.user" :researcher="invite.user" />
        <span v-if="invite.first_name && !invite.user">{{ getFullName(invite) }}</span>
        <span v-if="!invite.first_name && !invite.user">{{ predictName(invite) }}</span>
      </div>
      <div class="col-4 d-flex align-items-center">
        {{ invite.email }}
      </div>
      <div class="col-3 d-flex align-items-center">
        <span v-if="!invite.action && !invite.deleted_at">
          <i class="fa fa-circle t-xs t-yellow" v-tooltip="`Invite sent`" />
          <a v-if="!resentInvites.includes(invite.id)" href="#" @click.prevent="resend(invite)" class="t-green ml-2">resend</a>
          <span v-else class="t-green ml-2">sent</span>
        </span>
        <span v-else>
          <i class="fa fa-circle t-xs"
            :class="inviteStates[invite.action || 'default'].color"
            v-tooltip="inviteStates[invite.action || 'default'].text" />
        </span>
      </div>
      <div class="col-2 d-flex align-items-center justify-content-end">
        <!-- <button
          type="button"
          class="btn t-secondary no-border btn-select-menu disabled"
          disabled
        >
          <i
            class="fa t-sm"
            :class="getIcon(invite.role)"
          />
        </button> -->
        <link-button
          class="p-2 d-inline-block"
          v-if="!invite.action && !invite.deleted_at"
          icon="fa-close t-red mx-1"
          @click="$emit('cancelInvite', invite)"
          v-tooltip="'Cancel invitation'"
        />
      </div>
    </div>

    <div class="row border-bottom py-2 no-gutters no-padding" v-for="(invite) in contributors" :key="invite.email">
      <div class="col-3 d-flex align-items-center py-2">
        <researcher-link :researcher="invite" />
        <span v-if="$currentUser && $currentUser.email === invite.email" class="ml-1 t-secondary">(you)</span>
      </div>
      <div class="col-4 d-flex align-items-center">
        {{ invite.email }}
      </div>
      <div class="col-3 d-flex align-items-center">
        <i v-if="canInvite" class="fa fa-circle t-xs t-green" v-tooltip="`Invite accepted`"></i>
      </div>
      <div class="col-2 d-flex align-items-center justify-content-end">
        <span v-if="invite.role.name === 'owner'" class="t-secondary mr-2">Is owner</span>
        <template v-if="canInvite && invite.role.name !== 'owner' && $currentUser && $currentUser.email !== invite.email">
          <dropdown>
            <template #button>
              <button
                type="button"
                class="btn btn-select-menu t-secondary no-border"
                v-tooltip="'Change permission'"
              >
                <i
                  class="fa t-sm"
                  :class="getIcon(invite.role)"
                />
              </button>
            </template>
            <link-button icon="fa-eye" text="Can view bookmarks" size="sm" @click="$emit('updateContributorRole', invite, 1)" />
            <link-button icon="fa-pencil" text="Can add bookmarks" size="sm" @click="$emit('updateContributorRole', invite, 2)" />
          </dropdown>
          <link-button
            class="p-2 mr-1"
            icon="fa-close t-red mx-1"
            @click="$emit('removeContributor', invite)"
            v-tooltip="'Remove colleague'"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ResearcherLink from '@/components/researcher/Link'
import Dropdown from '@/components/shared/form/Dropdown'
import LinkButton from '@/components/shared/form/LinkButton'

export default {
  name: 'contributorList',
  components: {
    ResearcherLink,
    Dropdown,
    LinkButton
  },
  props: {
    invites: {
      type: Array,
      required: true
    },
    contributors: {
      type: Array,
      required: true
    },
    canInvite: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      resentInvites: [], // Ids of the invites that have been resent
      inviteStates: {
        confirmed: {
          text: 'Invite accepted',
          color: 't-green'
        },
        declined: {
          text: 'Invite declined',
          color: 't-red'
        },
        removed_by_user: {
          text: 'User left',
          color: 't-red'
        },
        removed_by_admin: {
          text: 'User removed',
          color: 't-red'
        },
        default: {
          text: 'Invite cancelled',
          color: 't-red'
        }
      }
    }
  },
  methods: {
    resend (invite) {
      this.$emit('resend', invite)
      this.resentInvites.push(invite.id)
    },
    getIcon (role) {
      switch (role.name) {
        case 'member':
          return 'fa-eye'
        case 'admin':
          return 'fa-pencil'
        case 'owner':
          return 'fa-cog'
        default:
          return ''
      }
    },
    getReadablePermission (permission) {
      if (permission === 'owner') return 'Owner'
      if (permission === 'admin') return 'Edit'
      return 'View'
    },
    predictName (invite) {
      let name = this.getNameFromEmail(invite.email)
      invite.first_name = name.firstName
      invite.last_name = name.lastName
    },
    getCurrentInviteState (invite) {
      let content = ''
      switch (invite.action) {
        case 'confirmed':
          content = `<i class="fa fa-circle t-xs t-green" v-tooltip="'Invite accepted'"></i>`
          break

        case 'declined':
          content = `<i class="fa fa-circle t-xs t-red" v-tooltip="'Invite declined'"></i>`
          break

        case 'removed_by_user':
          content = `<i class="fa fa-circle t-xs t-red" v-tooltip="'User left'"></i>`
          break

        case 'removed_by_admin':
          content = `<i class="fa fa-circle t-xs t-red" v-tooltip="'User removed'"></i>`
          break
      }

      if (!content && invite.deleted_at) content = `<i class="fa fa-circle t-xs t-red" v-tooltip="'Invite cancelled'"></i>`
      if (content) return content
    }
  }
}
</script>
