import Vue from 'vue'

const ENDPOINT = '/notifications'

export default {
  get (page, perPage) {
    return Vue.prototype.$http.get(`${ENDPOINT}?page=${page}&per_page=${perPage}`).then(response => response.data)
  },
  update (ids) {
    return Vue.prototype.$http.put(`${ENDPOINT}`, { notification: { ids: ids } }).then(response => response.data)
  }
}
