<template>
  <div class="answer-create">
    <div class="">
      <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
        <div class="form-group mb-3" :class="{'has-icon-label': errors.has('content')}">
          <label v-if="!hideLabel" class="t-700 t-md mb-3">Your answer</label>

          <rich-text-editor v-model="content"
            :placeholder="'Write your answer here...'"
            :content="content"
            :class="{'is-invalid': errors.has('content')}">
          </rich-text-editor>

          <i v-show="errors.has('content')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('content')">{{ errors.first('content') }}</span>
        </div>

        <!-- action buttons -->
        <form-buttons :isRequest="isRequest"
          :isEditMode="isEditMode"
          :isCreateMode="isCreateMode"
          :isRemove="isEditMode"
          :createText="`Post answer`"
          :editText="`Edit answer`"
          :containerClass="`pt-0 bd-none`"
          :btnClass="`btn-primary`"
          :isCancel="isCancel"
          @remove="handleRemove()"
          @cancel="handleCancel()">
        </form-buttons>
      </form>
    </div>
  </div>
</template>

<script>
import RichTextEditor from '@/components/shared/form/RichTextEditor'
import FormButtons from '@/components/shared/form/Buttons'

import CommunityService from '@/services/community'

export default {
  name: 'answerCreate',
  components: {
    RichTextEditor,
    FormButtons
  },
  props: {
    community: {
      type: Object,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    answer: {
      type: Object,
      default: () => {}
    },
    isCancel: {
      type: Boolean,
      default: true
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isRequest: false,
      error: null,
      content: '',
      isEditMode: false,
      isCreateMode: false
    }
  },
  created () {
    this.$validator.attach({ name: 'content', rules: 'required|min:10', alias: 'content' })

    if (this.answer) {
      this.isEditMode = true
      this.content = this.answer.content
    } else {
      this.isCreateMode = true
    }
  },
  mounted () {
    // this.$nextTick(() => { this.$refs.textEditor.focus() })
  },
  watch: {
    'content' (value) {
      this.$validator.validate('content', this.stripTags(value))
    }
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validateAll({ content: this.stripTags(this.content) }).then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true
          !this.isEditMode ? this.handleCreate() : this.handleEdit()
        }
      })
    },
    handleCreate () {
      let answer = { content: this.content }
      CommunityService.createAnswer(this.community.slug_values[0], this.question.id, answer).then(answer => {
        this.isRequest = false
        this.reset()
        this.$events.$emit('community:questions:answer:created', answer)
        this.handleCancel()
      }).catch(err => {
        this.error = this.handleError(err)
        this.isRequest = false
      })
    },
    handleEdit () {
      let answer = { id: this.answer.id, content: this.content }
      CommunityService.updateAnswer(this.community.slug_values[0], this.question.id, answer).then(answer => {
        this.isRequest = false
        this.reset()
        this.handleCancel()
        this.$events.$emit('community:questions:answer:updated', answer)
      }).catch(err => {
        this.error = this.handleError(err)
        this.isRequest = false
      })
    },
    handleCancel () {
      this.$emit('close')
      this.$events.$emit('modal:close')
    },
    handleRemove () {
      if (!this.isRequest) {
        this.isRequest = true
        CommunityService.removeAnswer(this.community.slug_values[0], this.question.id, this.answer.id).then(() => {
          this.isRequest = false
          this.reset()
          this.handleCancel()
          this.$events.$emit('community:questions:answer:removed', this.answer.id)
        }).catch(err => {
          this.error = this.handleError(err)
          this.isRequest = false
        })
      }
    },
    reset () {
      this.$validator.pause()
      this.content = ''
      this.$nextTick(() => {
        // temporary fix for the delay value on inputs
        // https://github.com/baianat/vee-validate/issues/740
        setTimeout(() => {
          this.$validator.resume()
        }, 300)
      })
    }
  }
}
</script>
