<template>
  <div>
    <h2 class="t-primary t-400 mb-0">
      Colleagues with access to
      <span class="t-600">{{ getName | truncate(50) }}</span>
    </h2>
    <hr class="hr-small mb-4">

    <spinner v-if="!isBookmarkInvitesLoaded" :containerClass="`width--50px min-height--65px`" />

    <!-- render error messages -->
    <static-messages
      v-if="error"
      :messages="error"
      :variant="`danger`"
      @closed="error = null"
    />

    <contributor-list
      v-if="isBookmarkInvitesLoaded"
      :invites="invites"
      :contributors="contributors"
      :canInvite="canInvite"
      @resend="resendBookmarkInvite"
      @cancelInvite="cancelBookmarkInvite"
      @updateInviteRole="updateInviteRole"
      @updateContributorRole="updateContributorRole"
      @removeContributor="showDeleteConfirmation"
    />
    <div class="text-right">
      <button type="button" class="btn btn-form btn-primary px-3 t-700 mt-3"
        @click="$events.$emit('modal:close')"
      >
        <span class="pre-state-msg">
          <i class="fa fa-check mr-2"></i>
          Done
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
// import SelectMenu from '@/components/shared/SelectMenu'
import ContributorList from '@/components/bookmark_folder/ContributorList'
// import LinkButton from '@/components/shared/form/LinkButton'
// import Dropdown from '@/components/shared/form/Dropdown'
import Spinner from '@/components/shared/Spinner'

import BookmarkFolderService from '@/services/bookmark_folder'

export default {
  name: 'bookmarkFolderContributors',
  components: {
    StaticMessages,
    ContributorList,
    Spinner
    // SelectMenu,
    // LinkButton,
    // Dropdown
  },
  props: {
    bookmarkFolder: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      error: null,
      isRequest: false,
      isBookmarkInvitesLoaded: false,
      bookmarkInvites: [],
      contributors: []
    }
  },
  computed: {
    invites () {
      // Filter out accepted invitations
      return this.bookmarkInvites.filter(invite => invite.action !== 'confirmed')
    },
    getName () {
      return this.bookmarkFolder.name
    },
    canInvite () {
      return this.$can.invite('bookmark', this.bookmarkFolder.rw_access)
    }
  },
  created () {
    if (!this.isEmpty(this.bookmarkFolder)) {
      this.fetchBookmarkInvites()
    }
  },
  methods: {
    updateContributorRole (contributor, roleId) {
      contributor.role.id = roleId

      BookmarkFolderService.updateContributor(this.bookmarkFolder.slug_values[0], {
        slug: contributor.slug_values[0],
        role_id: roleId
      })
        .then(invites => {
          this.isBookmarkInvitesLoaded = true
          this.contributors = invites.sort(i => i.role.name === 'owner' ? 1 : -1)
        })
        .catch(err => {
          this.error = this.handleError(err)
          this.isBookmarkInvitesLoaded = !this.isEmpty(this.error)
        })
    },
    updateInviteRole (invite, roleId) {
      // TODO: Make this work, does nothing right now... when it works, re enable the button in the list
      invite.role_id = roleId
    },
    fetchBookmarkInvites () {
      if (this.canInvite) {
        BookmarkFolderService.getInvites(this.bookmarkFolder.slug_values[0])
          .then(invites => {
            this.isBookmarkInvitesLoaded = true
            this.bookmarkInvites = invites
          })
          .catch(err => {
            this.error = this.handleError(err)
            this.isBookmarkInvitesLoaded = !this.isEmpty(this.error)
          })
      }
      BookmarkFolderService.getContributors(this.bookmarkFolder.slug_values[0])
        .then(contributors => {
          this.isBookmarkInvitesLoaded = true
          this.contributors = contributors.sort(i => i.role.name === 'owner' ? 1 : -1)
        })
    },
    resendBookmarkInvite (invite, index) {
      if (!this.isRequest) {
        this.isRequest = true
        BookmarkFolderService.resendInvite(this.bookmarkFolder.slug_values[0], invite.id)
          .then(invite => {
            // invite.resended = true
            // this.$set(this.userInvites, index, invite)
            // this.success = 'Invite resent'
            this.isRequest = false
          })
          .catch(err => {
            this.isRequest = false
            this.error = this.handleError(err)
          })
      }
    },
    cancelBookmarkInvite (invite) {
      if (!this.isRequest) {
        this.isRequest = true
        BookmarkFolderService.cancelInvite(this.bookmarkFolder.slug_values[0], invite.id)
          .then(updatedInvite => {
            const index = this.bookmarkInvites.findIndex(i => i.id === invite.id)
            if (index !== -1) {
              this.$set(this.bookmarkInvites, index, updatedInvite)
            }
            this.isRequest = false
          })
          .catch(err => {
            this.isRequest = false
            this.error = this.handleError(err)
          })
      }
    },
    showDeleteConfirmation (contributor) {
      this.$dialog.confirm('Are you sure you want to remove this user?', {
        okText: 'Remove',
        cancelText: 'Cancel',
        ok: dialog => this.removeContributor(contributor, dialog)
      }).then(dialog => {
        this.removeContributor(contributor, dialog)
      })
    },
    removeContributor (contributor, dialog) {
      if (!this.isRequest) {
        this.isRequest = true
        BookmarkFolderService.removeContributor(this.bookmarkFolder.slug_values[0], contributor.slug_values[0])
          .then(updatedContributors => {
            this.contributors = updatedContributors.sort(i => i.role.name === 'owner' ? 1 : -1)
            this.isRequest = false
            dialog.close()
          })
          .catch(err => {
            this.isRequest = false
            this.error = this.handleError(err)
            dialog.close()
          })
      }
    }
  }
}
</script>
