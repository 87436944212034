import BookmarkFolderService from '@/services/bookmark_folder'
import UserService from '@/services/user'
import ResearcherService from '@/services/researcher'
import { hasMatchingSlug } from '@/plugins/utils'

const state = {
  bookmarkFoldersOwnerId: null,
  bookmarkFolders: [],
  personalBookmarkFolders: [],
  lastUsedFolderId: 0,
  currentUserSlug: null // temporary
}

// getters
const getters = {
  folderById: (state) => (id) => {
    let folder = state.personalBookmarkFolders.find(bm => bm.id === id)
    if (!folder) folder = state.bookmarkFolders.find(bm => bm.id === id)
    return folder
  },
  // List of bookmark folders of the profile currently being visisted (public)
  bookmarkFolders: (state) => state.bookmarkFolders,
  // All of the bookmark folders a user is linked to (owned & invited)
  personalBookmarkFolders: (state) => state.personalBookmarkFolders,
  // Returns a list of our the user's bookmark folders (created by the user itself)
  // Last bookmark user interacted with is returned at the top of the list.
  userFolders: (state) => {
    const slug = state.currentUserSlug
    if (state.lastUsedFolderId) {
      // Only a folder of the user can be used as lastUsedBookmark, invited folders don't count
      const lastUsedBookmark = state.personalBookmarkFolders.find(bm => hasMatchingSlug(bm.owner.slug_values, slug) && bm.id === state.lastUsedFolderId)
      if (lastUsedBookmark) {
        return [
          lastUsedBookmark,
          ...state.personalBookmarkFolders.filter(bm => {
            const matchingSlug = hasMatchingSlug(bm.owner.slug_values, slug)
            return matchingSlug && bm.id !== state.lastUsedFolderId
          })
        ]
      }
    }
    return state.personalBookmarkFolders.filter(bm => {
      return hasMatchingSlug(bm.owner.slug_values, slug)
    })
  },
  // Returns a list of folders we're invited to and can add items to
  invitedFolders: (state) => {
    const slug = state.currentUserSlug
    return state.personalBookmarkFolders.filter(bm => {
      const matchingSlug = hasMatchingSlug(bm.owner.slug_values, slug)
      return !matchingSlug && ['owner', 'admin'].indexOf(bm.rw_access) !== -1
    })
  }
}

// actions
const actions = {
  resetBookmarkFolders ({ commit }) {
    commit('resetBookmarkFolders')
  },
  async getBookmarkFolders ({ commit, state }, { userId, useCache = true }) {
    let isPersonalBookmarkFolders = false
    let slug = null
    // TODO; Use user module once we have it
    if (this._vm.$currentUser && this._vm.$currentUser.user_id === userId) {
      isPersonalBookmarkFolders = true
      slug = this._vm.$currentUser.slug_values ? this._vm.$currentUser.slug_values : null
    }

    if (
      useCache &&
      (
        userId === state.bookmarkFoldersOwnerId ||
        (isPersonalBookmarkFolders && state.personalBookmarkFolders.length > 0)
      )
    ) {
      // Use cached version of bookmark folders
      return Promise.resolve()
    } else {
      // Clean up bookmark folders if needed
      if (!isPersonalBookmarkFolders) {
        commit('setBookmarkFolders', { bookmarkFolders: [], userId })
      }

      try {
        // Get both folders and counts
        let [bookmarkFolders, countResponse] = await Promise.all([
          UserService.getBookmarkFolders(userId),
          BookmarkFolderService.getCounts(userId)
        ])
        const counts = countResponse.folders
        // In case we get a falsy value from API
        if (!bookmarkFolders) bookmarkFolders = []
        // Merge counts and bookmark data
        bookmarkFolders = bookmarkFolders.map(folder => {
          folder.count = Object.values(counts[folder.id].categories).reduce((total, cur) => {
            return total + cur.total
          }, 0)
          folder.categories = counts[folder.id].categories
          return folder
        })

        if (isPersonalBookmarkFolders) {
          commit('setPersonalBookmarkFolders', { bookmarkFolders, userSlug: slug })
        } else {
          commit('setBookmarkFolders', { bookmarkFolders, userId })
        }

        return Promise.resolve()
      } catch (e) {
        return Promise.reject(e)
      }
    }
  },
  getBookmarkFolderBySlugValue ({ commit }, slugValue) {
    return new Promise((resolve, reject) => {
      BookmarkFolderService.getByID(slugValue).then(bookmarkFolder => {
        delete bookmarkFolder.count
        commit('updateBookmarkFolder', { slug: slugValue, bookmarkFolder })
        resolve(bookmarkFolder)
      }, error => reject(error))
    })
  },
  createBookmarkFolder ({ commit }, newBookmarkFolder) {
    return new Promise((resolve, reject) => {
      BookmarkFolderService.create(newBookmarkFolder).then(bookmarkFolder => {
        if (!bookmarkFolder.count) {
          bookmarkFolder.count = 0
        }
        commit('addPersonalBookmarkFolder', { bookmarkFolder })
        resolve(bookmarkFolder)
      }, error => reject(error))
    })
  },
  updateBookmarkFolder ({ commit }, { slug, data }) {
    return new Promise((resolve, reject) => {
      BookmarkFolderService.update(slug, data).then(bookmarkFolder => {
        commit('updateBookmarkFolder', { slug, bookmarkFolder })
        resolve(bookmarkFolder)
      }, error => reject(error))
    })
  },
  updateLocalBookmarkFolder ({ commit }, { slug, data }) {
    return new Promise((resolve, reject) => {
      commit('updateBookmarkFolder', { slug, bookmarkFolder: data })
      resolve(data)
    })
  },
  deleteBookmarkFolder ({ commit }, slug) {
    return new Promise((resolve, reject) => {
      BookmarkFolderService.delete(slug).then(() => {
        commit('removePersonalBookmarkFolder', { slug })
        resolve()
      }, error => reject(error))
    })
  },
  leaveBookmarkFolder ({ commit }, { user, bookmarkFolder }) {
    return new Promise((resolve, reject) => {
      ResearcherService.leaveBookmarkFolder(user.slug_values[0], bookmarkFolder.slug_values[0]).then(() => {
        commit('removePersonalBookmarkFolder', { slug: bookmarkFolder.slug_values[0] })
        resolve()
      }, error => reject(error))
    })
  },
  addItem ({ commit, state }, { bookmarkFolder, item, scope }) {
    return new Promise((resolve, reject) => {
      // In this case, 'id' is an array of slug_values
      BookmarkFolderService.addItemFlask(bookmarkFolder.slug_values[0], { slug_values: item.slug_values, index: scope }).then(() => {
        commit('updateBookmarkFolderCount', { id: bookmarkFolder.id, count: 1 })

        if (hasMatchingSlug(bookmarkFolder.owner.slug_values, state.currentUserSlug)) {
          commit('updateLastUsedBookmark', { id: bookmarkFolder.id })
        }
        resolve()
      }, error => reject(error))
    })
  },
  async bulkAddItem ({ commit, state }, { bookmarkFolder, searchState }) {
    const { items } = await BookmarkFolderService.bulkFetchIds(searchState)

    return new Promise((resolve, reject) => {
      // In this case, 'id' is an array of slug_values
      BookmarkFolderService.addItemFlask(bookmarkFolder.slug_values[0], { slug_values: items, index: searchState.index }).then(response => {
        commit('updateBookmarkFolderCount', { id: bookmarkFolder.id, count: response.count })

        if (hasMatchingSlug(bookmarkFolder.owner.slug_values, state.currentUserSlug)) {
          commit('updateLastUsedBookmark', { id: bookmarkFolder.id })
        }
        resolve(response)
      }, error => reject(error))
    })
  },
  removeItem ({ commit }, { bookmarkFolder, item, scope }) {
    return new Promise((resolve, reject) => {
      BookmarkFolderService.removeItemFlask(bookmarkFolder.slug_values[0], { index: scope, slug_value: item.slug_values[0] }).then(() => {
        commit('updateBookmarkFolderCount', { id: bookmarkFolder.id, count: -1 })
        resolve()
      }, error => reject(error))
    })
  }
}

// mutations
const mutations = {
  resetBookmarkFolders (state) {
    state.bookmarkFolders = []
    state.personalBookmarkFolders = []
    state.bookmarkFoldersOwnerId = 0
    state.currentUserSlug = null
  },
  setBookmarkFolders (state, { bookmarkFolders, userId }) {
    state.bookmarkFolders = bookmarkFolders
    state.bookmarkFoldersOwnerId = userId
  },
  addPersonalBookmarkFolder (state, { bookmarkFolder }) {
    state.personalBookmarkFolders.unshift(bookmarkFolder)
  },
  setPersonalBookmarkFolders (state, { bookmarkFolders, userSlug }) {
    state.personalBookmarkFolders = bookmarkFolders
    state.currentUserSlug = userSlug
  },
  updateBookmarkFolder (state, { slug, bookmarkFolder }) {
    let folderToUpdate = state.personalBookmarkFolders.find(bm => hasMatchingSlug(bm.slug_values, slug))
    if (!folderToUpdate) folderToUpdate = state.bookmarkFolders.find(bm => hasMatchingSlug(bm.slug_values, slug))
    // Found a folder to update!
    if (folderToUpdate) Object.assign(folderToUpdate, bookmarkFolder)
  },
  removePersonalBookmarkFolder (state, { slug }) {
    state.personalBookmarkFolders = state.personalBookmarkFolders.filter(bm => !hasMatchingSlug(bm.slug_values, slug))
  },
  updateBookmarkFolderCount (state, { id, count }) {
    let folderToUpdate = state.personalBookmarkFolders.find(bm => bm.id === id)
    if (!folderToUpdate) folderToUpdate = state.bookmarkFolders.find(bm => bm.id === id)
    if (folderToUpdate) folderToUpdate.count += count
  },
  updateLastUsedBookmark (state, { id }) {
    state.lastUsedFolderId = id
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
