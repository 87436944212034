<template>
  <div class="">
    <h2 class="t-primary t-400 mb-0" v-if="!isEditMode">Create a new {{ getModuleName(researchGroup, 'topic', 1) }}</h2>
    <h2 class="t-primary t-400 mb-0" v-if="isEditMode">Edit {{ topic.title }}</h2>
    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <!-- render form edit/create -->
    <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <!-- name -->
      <div class="form-group mb-4" :class="{'has-icon-inline-label': errors.has('topic.title')}">
        <label class="t-500 t-sm label-required">Name</label>
        <input
          type="text"
          class="form-control"
          placeholder="Name"
          name="topic.title"
          v-model="topic.title"
          v-validate="'required|min:3'"
          data-vv-delay="1000"
          data-vv-as="title"
          ref="title"
          :class="{'is-invalid': errors.has('topic.title')}"
        />

        <i v-show="errors.has('topic.title')" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('topic.title')">{{ errors.first('topic.title') }}</span>
      </div>

      <!-- mission -->
      <div class="form-group mb-4">
        <label class="t-500 t-sm d-block">Description</label>
        <rich-text-editor v-model="topic.description" :content="topic.description"></rich-text-editor>
      </div>

      <!-- action buttons -->
      <form-buttons :isRequest="isRequest"
        :isCreateMode="isCreateMode"
        :isEditMode="isEditMode"
        :isRemove="isEditMode"
        @remove="handleRemove()"
        @cancel="$events.$emit('modal:close')">
      </form-buttons>
    </form>
  </div>
</template>

<script>
import RichTextEditor from '@/components/shared/form/RichTextEditor'
import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'

import ResearchGroupService from '@/services/research_group'

export default {
  name: 'createResearchTopic',
  components: {
    RichTextEditor,
    StaticMessages,
    FormButtons
  },
  props: {
    currentResearchTopic: {
      type: Object,
      required: true
    },
    researchGroup: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      error: null,
      isCreateMode: false,
      isEditMode: false,
      isRequest: false,
      topic: {
        title: '',
        description: ''
      }
    }
  },
  created () {
    this.preFill()
  },
  mounted () {
    this.$nextTick(() => this.$refs.title.focus())
  },
  methods: {
    preFill () {
      if (Object.keys(this.currentResearchTopic).length > 0) {
        this.isEditMode = true
        this.topic.title = this.currentResearchTopic.title
        this.topic.description = this.currentResearchTopic.description || ''
        this.topic.id = this.currentResearchTopic.id
        this.topic.slug_values = this.currentResearchTopic.slug_values
      } else {
        this.isCreateMode = true
      }
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true
          this.isEditMode ? this.handleEdit() : this.handleCreate()
        }
      })
    },
    handleCreate () {
      ResearchGroupService.createResearchTopic(this.researchGroup.slug_values[0], this.topic).then(topics => {
        this.isRequest = false
        this.$events.$emit('research_group:updated', 'research_topics', topics)
        this.$events.$emit('modal:close')
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleEdit () {
      ResearchGroupService.updateResearchTopic(this.researchGroup.slug_values[0], this.topic).then(topics => {
        this.isRequest = false
        this.$events.$emit('research_group:updated', 'research_topics', topics)
        this.$events.$emit('modal:close')
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleRemove () {
      if (!this.isRequest) {
        this.isRequest = true
        ResearchGroupService.removeResearchTopic(this.researchGroup.slug_values[0], this.topic).then(() => {
          this.isRequest = false
          this.$events.$emit('research_group:removed', 'research_topics', this.topic.slug_values)
          this.$events.$emit('modal:close')
        }).catch(err => {
          this.isRequest = false
          this.error = this.handleError(err)
        })
      }
    }
  }
}
</script>
