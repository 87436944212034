const hasMatchingSlug = (slugValues1, slugValues2) => {
  // If both variables are arrays
  if (Array.isArray(slugValues1) && Array.isArray(slugValues2)) {
    return slugValues1.find(slug1 => {
      return slugValues2.includes(slug1)
    })
  } else if (typeof slugValues1 === 'string' && Array.isArray(slugValues2)) {
    // If 1 is string and 2 is array
    return slugValues2.find(slug => slug === slugValues1)
  } else if (Array.isArray(slugValues1) && typeof slugValues2 === 'string') {
    // If 1 is array and 2 is string
    return slugValues1.find(slug => slug === slugValues2)
  } else if (typeof slugValues1 === 'string' && typeof slugValues2 === 'string') {
    // If both are string
    return slugValues1 === slugValues2 ? slugValues1 : false
  }
  return false
}

const cleanKeyword = (keyword) => {
  return keyword ? keyword.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"') : ''
}

const getCategoryLink = (suggestedViewForCategory, currentRoute, category, targetRouteName = null) => {
  let routeName = targetRouteName || currentRoute.name
  // if (routeName === 'searchLandscape' && !hasLandscapeView(currentRoute, category)) {
  //   routeName = 'search'
  // } else
  if (routeName === 'searchLandscape' || routeName === 'search' || routeName === 'searchHome') {
    if (suggestedViewForCategory === 'landscape' && hasLandscapeView(currentRoute, category)) {
      routeName = 'searchLandscape'
    } else {
      routeName = 'search'
    }
  }
  const routeObject = {
    name: routeName,
    params: {
      ...currentRoute.params,
      category: category
    },
    query: { ...currentRoute.query }
  }
  delete routeObject.query.search_by_fields
  delete routeObject.query.sort
  delete routeObject.query.page
  delete routeObject.query.t

  return routeObject
}

const hasLandscapeView = (currentRoute, index) => {
  const indexesWithLandscape = ['researchers', 'research_groups', 'organisations']
  if (currentRoute.meta.isGlobalSearch && indexesWithLandscape.indexOf(index) > -1) {
    return true
  }
  return false
}

const parseLocalStorageViewPerIndex = function () {
  if (localStorage.getItem('preferred_view_per_index')) {
    try {
      return JSON.parse(localStorage.getItem('preferred_view_per_index'))
    } catch (e) {
      // On error, reset localStorage
      localStorage.setItem('preferred_view_per_index', '{}')
      return {}
    }
  }
  return {}
}

export { hasMatchingSlug, cleanKeyword, getCategoryLink, hasLandscapeView, parseLocalStorageViewPerIndex }
