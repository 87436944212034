import Vue from 'vue'
import SearchService from '@/services/search'
import { cleanKeyword, parseLocalStorageViewPerIndex } from '@/plugins/utils'

const DEFAULT_SEARCH_OBJECT = {
  keyword: '', // the query we search for
  exclude: [],
  filter_order: [], // It matters apparently
  filters: {}, // active filters
  global: true,
  index: 'all_categories',
  page: 0,
  per_page: 12,
  preference: '', // Has some use on search
  sort: '', // sorting string
  index_changed: true,
  synonyms: {},
  search_by_fields: []
}

const state = {
  searchObject: {
    keyword: '', // the query we search for
    exclude: [],
    filter_order: [], // It matters apparently
    filters: {}, // active filters
    global: true, // Used for global/scoped search (and filters)
    index: 'all_categories',
    page: 0,
    per_page: 12,
    preference: '', // Has some use on search
    sort: '', // sorting string
    index_changed: true,
    synonyms: {},
    search_by_fields: []
  },
  indexes: {}, // Search indexes (with counts)
  lastClickedResult: {
    id: '',
    fromSearch: false,
    route: ''
  },
  comparison: 'graph',
  viewPerIndex: parseLocalStorageViewPerIndex()
}

// getters
const getters = {
  keyword: state => state.searchObject.keyword,
  searchObject: state => state.searchObject,
  indexes: state => state.indexes,
  lastClickedResult: state => state.lastClickedResult,
  comparison: state => state.comparison,
  searchView: (state) => (arg) => {
    if (state.viewPerIndex[arg]) return state.viewPerIndex[arg]
    // Default to 'block' if view has not been set yet for index
    return 'block'
  }
}

// actions
const actions = {
  resetSearch ({ commit }) {
    const preference =
      this._vm.$currentUser && this._vm.$currentUser.slug_values
        ? this._vm.$currentUser.slug_values[0]
        : new Date()

    commit('resetSearch')
    commit('setPreference', preference)
  },
  setKeyword ({ commit }, keyword) {
    commit('setKeyword', cleanKeyword(keyword))
  },
  fetchCounts ({ commit, state }) {
    return new Promise((resolve, reject) => {
      SearchService.counts(state.searchObject).then(counts => {
        if (counts && counts.categories) {
          commit('setIndexes', counts.categories)
          resolve()
        } else {
          reject(Error('Could not get counts'))
        }
      }, error => reject(error))
    })
  },
  fetchSynonyms ({ commit, state }) {
    if (!state.searchObject.keyword) {
      commit('setSynonyms', {})
      return Promise.resolve()
    }
    return new Promise((resolve, reject) => {
      SearchService.synonyms(state.searchObject).then(synonyms => {
        commit('setSynonyms', synonyms)
        resolve()
      }, error => reject(error))
    })
  },
  setLastClickedResult ({ commit }, { id, fromSearch, fromRoute }) {
    commit('setLastClickedResult', { id, fromSearch, route: fromRoute })
  },
  clearLastClickedResult ({ commit }) {
    commit('setLastClickedResult', { id: '', fromSearch: false, route: '' })
  },
  setCompare ({ commit }, comparison) {
    commit('setCompare', comparison)
  },
  setSearchView ({ commit }, { view, index }) {
    commit('setSearchView', { view, index })
  }
}

// mutations
const mutations = {
  resetSearch (state) {
    state.searchObject = { ...DEFAULT_SEARCH_OBJECT }
  },
  setPreference (state, preference) {
    state.searchObject.preference = preference
  },
  setKeyword (state, keyword) {
    state.searchObject.keyword = keyword
  },
  setIndexes (state, indexes) {
    state.indexes = indexes
  },
  setSynonyms (state, synonyms) {
    state.searchObject.synonyms = synonyms
  },
  setLastClickedResult (state, payload) {
    state.lastClickedResult = payload
  },
  setCompare (state, comparison) {
    state.comparison = comparison
  },
  setSearchView (state, { view, index }) {
    Vue.set(state.viewPerIndex, index, view)
    localStorage.setItem('preferred_view_per_index', JSON.stringify(state.viewPerIndex))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
