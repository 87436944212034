<template>
  <div class="flash-container">
    <div
      v-for="flash in flashes"
      :key="flash.id"
      class="flash-item"
      :class="`f-${flash.variant} ${flash.sticky ? 'flash-sticky' : ''}`"
      @mouseover="cancelTimer(flash)"
      @mouseleave="endAndStartTimer(flash)"
      @click="endAndStartTimer(flash)"
      :style="{'animation-delay': `${flash.duration}ms`}"
    >
      <i class="flash-icon fa" :class="getIcon(flash)" />
      <div class="flash-text">
        <span :title="stripTags(flash.message)" v-html="flash.message" v-line-clamp="3" />
      </div>
      <button v-if="flash.cta" class="flash-button" @click="performAction(flash)">
        <i v-if="flash.cta.icon" class="fa" :class="flash.cta.icon" />{{ flash.cta.text }}
      </button>
      <button class="flash-close" @click="close(flash)">
        <i class="fa fa-times" />
      </button>
      <div class="flash-bar" :style="{'animation-duration': `${flash.duration}ms`}" />
    </div>
  </div>
</template>

<script>
const ICONS = {
  info: 'fa-question-circle',
  alert: 'fa-comment',
  warning: 'fa-exclamation-triangle',
  danger: 'fa-times-circle',
  success: 'fa-check'
}

export default {
  name: 'flashBottom',
  data () {
    return {
      flashes: []
    }
  },
  created () {
    this.$events.$on('flash', ({
      message,
      sticky = false,
      cta,
      cta: {
        icon,
        text,
        action
      } = {}
    }, variant) => {
      const flash = {
        id: Math.random().toString(36).substring(7),
        message: message,
        variant: variant || 'info',
        timer: null,
        duration: sticky ? 0 : Math.min(Math.max(message.length * 50, 4000), 16000),
        sticky: sticky
      }

      if (cta) {
        flash.duration = flash.duration + 1000 // Give users some extra time if there's a call to action
        flash.cta = {
          icon: icon,
          text: text,
          action: action
        }
      }

      this.flashes.push(flash)
      this.endAndStartTimer(flash)
    })
  },
  methods: {
    getIcon (flash) {
      return ICONS[flash.variant] || ICONS.info // Default icon
    },
    close (flash) {
      this.cancelTimer(flash)
      const id = flash.id
      this.flashes = this.flashes.filter(f => f.id !== id)
    },
    performAction (flash) {
      if (flash && flash.cta && flash.cta.action) {
        flash.cta.action()
      }
      this.close(flash)
    },
    cancelTimer (flash) {
      if (flash.sticky) return

      if (flash.timer) {
        window.clearTimeout(flash.timer)
        flash.timer = null
      }
    },
    endAndStartTimer (flash) {
      if (flash.sticky) return

      this.cancelTimer(flash)
      flash.timer = window.setTimeout(() => this.close(flash), flash.duration)
    }
  },
  destroyed () {
    this.$events.$off('flash')
  }
}
</script>
