 <template>
  <div class="card-body py-3" :class="containerClass">
    <h3 class="mb-0 t-500">
      About
      <editable v-if="isLoaded && canEdit && !isEmpty(about)" :isActive="isEditMode"
        :text="addOrEditText(getLengthAbout(about))"
        @create="handleEdit()"
        @cancel="validateBeforeSubmit()">
      </editable>

      <i v-if="isLoaded && (isEmpty(about) || !canEdit)" class="fa fa-lightbulb-o d-block pull-right t-placeholder"></i>
    </h3>

    <p v-if="canEdit" class="t-sm mb-0">
      Introduce yourself with your bio, research focus, key achievements…
    </p>

    <!-- render content when is loaded -->
    <template v-if="isLoaded">
      <!-- no about available -->
      <p v-if="!about && !canEdit" class="mb-0 mt-1 t-sm t-secondary">
        No biography present.
      </p>

      <!-- nothing to show -->
      <p v-if="isEmpty(about) && canEdit && !isEditMode" class="mb-0 mt-3 text-center">
        <i class="fa fa-plus d-block t-secondary"></i>
        <a href="#" @click.prevent="handleEdit()" class="t-sm">Add your bio</a>
      </p>

      <!-- about available -->
      <long-text v-if="researcher.highlights.about && !isEditMode" :text="researcher.highlights.about" :truncateAt="500" :isRichText="true" class="mt-3"/>

      <!-- render static messages -->
      <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

      <!-- edit mode -->
      <form v-if="isEditMode && canEdit" @submit.prevent="validateBeforeSubmit" class="mt-3" autocomplete="off">
        <!-- add rich text editor -->
        <rich-text-editor v-model="about"
          :content="about">
        </rich-text-editor>

        <form-buttons :isCreateMode="false"
          :isEditMode="true"
          :isRequest="isRequest"
          :containerClass="`pt-3`"
          @cancel="handleEdit()">
        </form-buttons>
      </form>
    </template>

    <!-- render spinner if not loaded -->
    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import Spinner from '@/components/shared/Spinner'
import LongText from '@/components/shared/LongText'
import RichTextEditor from '@/components/shared/form/RichTextEditor'
import StaticMessages from '@/components/shared/StaticMessages'
import Editable from '@/components/shared/Editable'
import FormButtons from '@/components/shared/form/Buttons'

import ResearcherService from '@/services/researcher'

export default {
  name: 'researcherAbout',
  components: {
    Spinner,
    LongText,
    RichTextEditor,
    StaticMessages,
    Editable,
    FormButtons
  },
  props: {
    isLoaded: {
      type: Boolean,
      required: true
    },
    canEdit: {
      type: Boolean,
      required: true
    },
    researcher: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    containerClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      isRequest: false,
      isEditMode: false,
      error: null,
      about: ''
    }
  },
  created () {
    if (this.isLoaded) this.getAbout()
    if (this.isEdit) this.isEditMode = this.isEdit
  },
  watch: {
    isLoaded (newVal, oldVal) {
      if (newVal !== oldVal && newVal) this.getAbout()
    }
  },
  methods: {
    getAbout () {
      this.about = this.isEmpty(this.researcher.about) ? '' : this.researcher.about
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true

          ResearcherService.update(this.$currentUser.slug_values[0], { about: this.about }).then(r => {
            this.about = r.about
            this.$events.$emit('researcher:updated', 'about', r.about)
            this.isEditMode = false
            this.isRequest = false
          }).catch(err => {
            this.isRequest = false
            this.error = this.handleError(err)
          })
        }
      })
    },
    handleEdit () {
      this.isEditMode = !this.isEditMode
      if (!this.isEditMode) this.$emit('cancelled')
    },
    getLengthAbout (about) {
      return !!about
    }
  }
}
</script>
