
<template>
  <base-modal :isLoaded="isLoaded"
    :isError404="isError404"
    :scope="scope"
    :data="data"
    :error="error"
    @clearError="error = null"
    ref="projectModal">
      <template #modal-content>
        <div class="d-flex justify-content-between align-items-baseline mb-3">
          <h2 class="t-secondary t-xlr">{{ getIndexAsString(scope) | capitalize | humanize }}</h2>
          <div class="d-flex">
            <share
              :scope="scope"
              :itemName="data.title"
              inModal
            />
            <bookmark-folder
              v-if="!isEmpty(data) && !isEmpty($currentUser)"
              :currentItem="data"
              :scope="getScope"
              isButton
            />
          </div>
        </div>

        <h1 class="mb-3 t-700 t-xxlr" v-html="data.highlights.title"></h1>

        <!-- organisations -->
        <h3 v-if="hasOrganisations" class="mt-4 mb-2 t-500">{{ 'Organisation' | pluralize(data.organisations.length) }}:</h3>
        <linked-list-collapsible
          v-if="hasOrganisations"
          :items="sortedOrganisations"
          collapsible
          showRoles
        />

        <long-text-collapsible v-for="(text, i) in sortedTexts"
          :key="i"
          :title="text.name"
          :truncateAt="-1"
          :isReadMore="false"
          :text="text.value"
        />

        <h3 v-if="!isEmpty(data.url)" class="mt-4 mb-0 t-500">Website:</h3>
        <a v-if="!isEmpty(data.url)" :href="data.url | formatWebsite" target="_blank">{{ data.url }}</a>

        <h3 v-if="!isEmpty(data.highlights.keywords)" class="mt-4 mb-1 t-500">Keywords:</h3>
        <keywords v-if="!isEmpty(data.highlights.keywords)"
          :limit="10"
          :keywords="data.highlights.keywords">
        </keywords>

        <template v-if="data.parent_project">
          <h3 class="mt-4 mb-0 t-500">Parent Project:</h3>
          <div class="d-inline-block width--90px mr-1">{{ data.parent_project.year_range }}</div>
          <i class="fa fa-file-text t-secondary mr-1" />
          <router-link :to="{ name: $route.name, params: $route.params, query: getQuery($route.query, data.parent_project.slug_values) }">
            {{ data.parent_project.title }}
          </router-link>
        </template>
        <h3 v-if="!isEmpty(data.sub_projects)" class="mt-4 mb-0 t-500">{{ data.parent_project ? 'Other Subprojects' : 'Subprojects'}}:</h3>
        <div v-for="{ title, year_range, slug_values } in data.sub_projects" :key="title">
          <div class="d-inline-block width--90px mr-1">{{ year_range }}</div>
          <i class="fa fa-file-text t-secondary mr-1" />
          <router-link :to="{ name: $route.name, params: $route.params, query: getQuery($route.query, slug_values) }">
            {{ title }}
          </router-link>
        </div>

      </template>

      <template #modal-sidebar>
        <p v-if="data.total_cost && data.total_cost_currency" class="mb-4">
          <span class="t-600">Funding Amount:</span><br />
          <span class="t-600 t-xxxlr">{{ { amount: data.total_cost, currency: data.total_cost_currency } | formatCurrency }}</span>
        </p>

        <p v-if="data.funding_source" class="mb-0">
          <span class="t-600">Funding Source:</span> {{ data.funding_source.full_name }}
        </p>

        <p v-if="data.funding_mechanism && data.funding_mechanism.length > 0" class="mb-0">
          <span class="t-600">Funding Mechanism:</span> {{ data.funding_mechanism }}
        </p>

        <p v-if="data.nih_activity_code" class="mb-4">
          <span class="t-600">NIH Activity Code:</span> {{ data.nih_activity_code.value }} ({{ data.nih_activity_code.long_form }})
        </p>

        <p v-if="data.start_date" class="mb-0">
          <span class="t-600">Start Date:</span> {{ data.start_date | formatDate('YYYY-MM-DD') }}
        </p>

        <p v-if="data.end_date" class="mb-0">
          <span class="t-600">End Date:</span> {{ data.end_date | formatDate('YYYY-MM-DD') }}
        </p>

        <p v-if="data.acronym" class="mb-0">
          <span class="t-600">Acronym:</span> {{ data.acronym }}
        </p>

        <p v-if="data.call_identifier" class="mb-0">
          <span class="t-600">Identifier:</span> {{ data.call_identifier }}
        </p>

        <researcher-summary
          v-for="group in groupedParticipants" :key="group.role"
          :researchers="group.participants"
          :title="group.role | pluralize(group.participants.length) | capitalize | humanize"
          class="mt-3 mb-3">
        </researcher-summary>

        <identifiers :item="data" />
      </template>
  </base-modal>
</template>

<script>
import sharedJsModal from '@/mixins/search/results/baseModal'

import BaseModal from '@/components/search/results/BaseModal'
import LongTextCollapsible from '@/components/shared/LongTextCollapsible'
import ResearcherSummary from '@/components/shared/ResearcherSummary'
import Keywords from '@/components/shared/Keywords'
import LinkedListCollapsible from '@/components/shared/LinkedListCollapsible'
import Identifiers from '@/components/qa/Identifiers'
import Share from '@/components/shared/profile/Share'
import BookmarkFolder from '@/components/bookmark_folder/Show'

import ProjectService from '@/services/project'

export default {
  name: 'modalProject',
  mixins: [sharedJsModal],
  components: {
    BaseModal,
    LongTextCollapsible,
    ResearcherSummary,
    Keywords,
    BookmarkFolder,
    LinkedListCollapsible,
    Identifiers,
    Share
  },
  props: {
    slug: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      objectForHighlighting: {
        properties: ['title'],
        arrays: ['keywords.keyword', 'organisations.name', 'texts.value'],
        researchers: ['participants.initial_name.normalized', 'participants.participant_name.normalized']
      }
    }
  },
  watch: {
    '$route.query.project' (newSlug, oldSlug) {
      if (!this.isEmpty(newSlug)) this.fetch(newSlug)
    }
  },
  created () {
    this.scope = 'project'
  },
  computed: {
    sortedOrganisations () {
      if (!this.hasOrganisations) return []

      const sortOrder = ['awardee', 'coordinator', 'co-funder', 'participant', 'partner', 'fellow organisation']
      const sortedOrganisations = []
      this.data.highlights.organisations.forEach(organisation => {
        let order = sortOrder.indexOf(organisation.role)
        if (order === -1) order = 19
        sortedOrganisations.push({
          ...organisation,
          order
        })
      })
      return sortedOrganisations.sort((a, b) => a.order - b.order)
    },
    groupedParticipants () {
      const sortOrder = ['principal investigator', 'lead applicant', 'co-principal investigator']
      const sortedGroups = []
      // Roles of participants that are present
      const roles = []
      // Roles of participants that are no longer present (former ...)
      const formerRoles = []
      if (this.data.highlights.participants) {
        this.data.highlights.participants.forEach(researcher => {
          if (!researcher.role) researcher.role = 'participant'
          if (researcher.present) {
            if (!roles.includes(researcher.role)) roles.push(researcher.role)
          } else {
            if (!formerRoles.includes(researcher.role)) formerRoles.push(researcher.role)
          }
        })

        roles.forEach(role => {
          const participants = this.data.highlights.participants.filter(researcher => {
            return researcher.role === role && researcher.present
          })
          let order = sortOrder.indexOf(role)
          if (order === -1) order = 20 // if order value is negative, place it further
          if (role.includes('former')) order += 21 // Hack for roles where `former` copy is already added. Only for specific edge cases (e.g. project slug_value = WTAKkWwXMGC3 )
          sortedGroups.push({ order, role, participants })
        })
        formerRoles.forEach(role => {
          const participants = this.data.highlights.participants.filter(researcher => {
            return researcher.role === role && !researcher.present
          })
          let order = sortOrder.indexOf(role)
          if (order === -1) order = 20 // if order value is negative, place it further
          const roleName = `Former ${role}`
          sortedGroups.push({ order: order + 21, role: roleName, participants })
        })
      }
      sortedGroups.sort((a, b) => {
        return a.order - b.order
      })
      return sortedGroups
    },
    hasOrganisations () {
      return !this.isEmpty(this.data.organisations)
        ? this.data.organisations[0].name !== null
        : false
    },
    sortedTexts () {
      if (!this.data.highlights.texts) return []
      const copy = [...this.data.highlights.texts]
      return copy.sort((a, b) => a.order - b.order)
    }
  },
  methods: {
    getQuery (currentQuery, slugValues) {
      return {
        ...currentQuery,
        project: slugValues[0]
      }
    },
    fetch (slug) {
      this.isLoaded = false

      ProjectService.getByID(slug).then(project => {
        this.data = project
        this.fetchHighlights(this.$options.filters.pluralize(this.scope), slug)
      }).catch(err => {
        this.handleCustomError(err)
      })
    },
    handleFontChanged (fontStyle, scope) {
      this.$set(this.fontStyles, scope, fontStyle)
    }
  }
}
</script>
