<template>
  <span
    v-if="parsedFilters.length > 0"
    class="t-sm bd-radius bd px-1 mr-1 t-primary d-inline-block mb-1"
    v-tooltip="parsedFilters.join(', ')"
  >{{ parsedFilters.length }} {{ 'filter' | pluralize(parsedFilters.length) }}</span>
</template>

<script>

export default {
  name: 'filterLabels',
  props: {
    filters: {
      type: Object,
      required: false
    }
  },
  computed: {
    parsedFilters () {
      return this.getActiveFilters(this.filters)
    }
  }
}
</script>
