<template>
  <base-modal
    :isLoaded="isLoaded"
    :isError404="isError404"
    :error="error"
    :scope="scope"
    :data="data"
    @clearError="error = null">
      <template #modal-content>
        <div class="d-flex justify-content-between align-items-baseline mb-3">
          <h2 class="t-secondary t-xlr">{{ scope | capitalize | humanize }}</h2>
          <div class="d-flex">
            <share
              :scope="scope"
              :itemName="data.title"
              inModal
            />
            <bookmark-folder
              v-if="!isEmpty(data) && !isEmpty($currentUser)"
              :currentItem="data"
              :scope="getScope"
              isButton
            />
          </div>
        </div>

        <h1 class="mb-4 t-700 t-xxlr" v-html="data.highlights.title" />

        <span v-if="data.is_retracted" class="label-base bg-red mb-2 d-inline-block">RETRACTED</span>

        <p v-if="data.publication_date" class="mb-0">
          <span class="t-500 t-md">Publication Date: </span>
          <span>{{ data.publication_date | formatDate('MM/YYYY') }}</span>
        </p>
        <p v-if="!isEmpty(journal)" class="mb-0">
          <span class="t-500 t-md">Journal: </span>
          <span>{{ journal.name }}
            <span v-if="journal.ranking_h_index" class="ml-2"><i>h</i>-index: {{ journal.ranking_h_index }}</span>
          </span>
        </p>

        <p v-if="doiUrl" class="mb-0">
          <span class="t-500">DOI: </span>
          <a
            :href="doiUrl"
            @click="track('doi', doiUrl)"
            target="_blank"
          >{{ doiIdentifier }}</a>
        </p>
        <p v-if="data.estimated_citation_count" class="mb-0">
          <span class="t-500 t-md">Citations: </span>
          <span>{{ data.estimated_citation_count }}</span>
        </p>

        <div class="scite-badge-al" v-if="doiIdentifier">
          <div
            class="scite-badge"
            :data-doi="doiIdentifier"
            data-layout="horizontal"
            data-tooltip-placement="bottom"
            data-show-zero="true"
            data-small="true"
            data-show-labels="false"
            data-tally-show="true"
            data-show-total="false"
          >
            <spinner class="v-spinner-sm" />
          </div>
          <div class="scite-badge"
            :data-doi="doiIdentifier"
            data-show-zero="true"
            data-small="true"
            data-tooltip-placement="bottom"
            data-show-labels="false"
            data-tally-show="false"
            data-section-tally-show="true"
            data-section-tally-layout="horizontal"
          >
            <spinner class="v-spinner-sm" />
          </div>
        </div>

        <long-text-collapsible v-if="!isEmpty(data.highlights.abstract)"
          :truncateAt="-1"
          :isReadMore="false"
          :title="'Abstract'"
          :text="data.highlights.abstract"
        />

        <label-list
          v-if="!isEmpty(data.fields_of_study) && isDevServer()"
          :title="'Fields of study'"
          :labels="data.highlights.fields_of_study"
          noDropdown
          class="mt-4"
        />
        <label-list
          v-if="!isEmpty(data.research_areas) && isDevServer()"
          :title="'Research Areas'"
          :labels="data.highlights.research_areas"
          noDropdown
          class="mt-4"
        />

        <label-list
          v-if="!isEmpty(data.mesh)"
          :title="'MeSH Terms'"
          :filterKey="'meSH_terms'"
          :externalUrlLabel="'Open in NIH MeSH database'"
          :labels="data.highlights.mesh"
          class="mt-4"
        />

        <label-list
          v-if="!isEmpty(data.diseases)"
          :title="'Diseases'"
          :externalUrlLabel="'Open in NIH MeSH database'"
          :labels="data.highlights.diseases"
          class="mt-4"
        />
        <label-list
          v-if="!isEmpty(data.genes)"
          :title="'Genes'"
          :externalUrlLabel="'Open in NIH gene database'"
          :labels="data.highlights.genes"
          class="mt-4"
        />
        <label-list
          v-if="!isEmpty(data.mutations)"
          :title="'Mutations'"
          :externalUrlLabel="'Open in dbSNP database'"
          :labels="data.highlights.mutations"
          class="mt-4"
        />
        <label-list
          v-if="!isEmpty(data.chemicals)"
          :title="'Chemicals'"
          :externalUrlLabel="'Open in NIH MeSH database'"
          :labels="data.highlights.chemicals"
          class="mt-4"
        />
        <label-list
          v-if="!isEmpty(data.species)"
          :title="'Species'"
          :externalUrlLabel="'Open in NIH taxonomy database'"
          :labels="data.highlights.species"
          class="mt-4"
        />
        <label-list
          v-if="!isEmpty(data.cell_lines)"
          :title="'Cell lines'"
          :externalUrlLabel="'Open in Cellosaurus'"
          :labels="data.highlights.cell_lines"
          class="mt-4"
        />
      </template>

      <template #modal-sidebar>
        <h3 class="mb-2 t-600">
          Full Text Links
        </h3>
        <div class="d-flex flex-column mb-4">
          <div class="d-flex align-items-center"
            v-if="pdfUrl"
          >
            <a
              :href="pdfUrl"
              @click="track('pdf', pdfUrl)"
              target="_blank">View PDF <i class="ml-1 fa fa-external-link" /></a>
            <img
              v-if="data.is_open_access"
              :src="require('@/assets/img/icon/open_access.svg')"
              class="ml-2"
              style="height: 20px; width: 12.8px;">
          </div>
          <div class="d-flex align-items-center"
            v-if="doiUrl"
          >
            <a
              :href="doiUrl"
              @click="track('doi', doiUrl)"
              target="_blank">Publisher Website <i class="ml-1 fa fa-external-link" /></a>
            <img
              v-if="data.is_open_access && !pdfUrl"
              :src="require('@/assets/img/icon/open_access.svg')"
              class="ml-2"
              style="height: 20px; width: 12.8px;">
          </div>
          <a
            v-if="pubmedUrl"
            :href="pubmedUrl"
            @click="track('pubmed_central', pubmedUrl)"
            target="_blank">Find on PubMed Central <i class="ml-1 fa fa-external-link" /></a>
          <a
            :href="googleScholarUrl"
            @click="track('google_scholar', googleScholarUrl)"
            target="_blank">Find on Google Scholar <i class="ml-1 fa fa-external-link" /></a>
        </div>

        <researcher-summary v-if="!isEmpty(data.highlights.authors)"
          :researchers="data.highlights.authors"
          :totalResearchers="data.authors_count"
          :title="'Authors'"
          :isLoadedResearchers="isAuthorsLoaded"
          :maxResearchers="10"
          @fetchAllResearchers="fetchAllAuthors">
        </researcher-summary>

        <identifiers :item="data" />
      </template>
  </base-modal>
</template>

<script>
import sharedJsModal from '@/mixins/search/results/baseModal'

import LongTextCollapsible from '@/components/shared/LongTextCollapsible'
import BaseModal from '@/components/search/results/BaseModal'
import ResearcherSummary from '@/components/shared/researcherSummary/Summary'
import LabelList from '@/components/shared/label/LabelList'
import Spinner from '@/components/shared/Spinner'
import Identifiers from '@/components/qa/Identifiers'
import Share from '@/components/shared/profile/Share'

import PublicationService from '@/services/publication'
import BookmarkFolder from '@/components/bookmark_folder/Show'
import MPService from '@/services/mixpanel'

export default {
  name: 'modalPublication',
  mixins: [sharedJsModal],
  components: {
    BaseModal,
    LongTextCollapsible,
    ResearcherSummary,
    BookmarkFolder,
    LabelList,
    Spinner,
    Identifiers,
    Share
  },
  props: {
    slug: {
      type: String,
      required: false,
      default: ''
    }
  },
  metaInfo () {
    return {
      meta: [{
        'dc.identifier': this.dioIdentifier
      }]
    }
  },
  data () {
    return {
      isAuthorsLoaded: true,
      dioIdentifier: '',
      objectForHighlighting: {
        properties: ['title', 'abstract'],
        objects: ['journal.title'],
        researchers: ['authors.full_name.normalized', 'authors.author_name.normalized'],
        arrays: ['fields_of_study', 'research_areas', 'mesh.value', 'diseases.value', 'genes.value', 'mutations.value', 'chemicals.value', 'species.value', 'cell_lines.value']
      }
    }
  },
  computed: {
    doiIdentifier () {
      if (this.isEmpty(this.data.identifiers)) return false
      let doiObject = this.data.identifiers.find(identifier => {
        if (identifier.name === 'doi') return true
        return false
      })
      if (!doiObject) return false
      let doiIdentifier = doiObject.value.toLowerCase()
      // Make sure identifier doesn't start with redundant `doi/...`
      if (doiIdentifier.startsWith('doi/')) doiIdentifier = doiIdentifier.slice(4)
      return doiIdentifier
    },
    doiUrl () {
      if (this.doiIdentifier) return `https://doi.org/${this.doiIdentifier}`
      return null
    },
    pubmedUrl () {
      if (this.isEmpty(this.data.identifiers)) return false
      const identifier = this.data.identifiers.find(id => {
        if (id.name === 'pmc') return true
        return false
      })
      if (identifier) return `https://www.ncbi.nlm.nih.gov/pmc/articles/${identifier.value}`
      return null
    },
    googleScholarUrl () {
      if (this.doiIdentifier) return `https://scholar.google.com/scholar?hl=en&q=${this.doiIdentifier}`
      return `https://scholar.google.com/scholar?hl=en&q=${this.data.title}`
    },
    pdfUrl () {
      if (!this.isEmpty(this.data.locations)) {
        const pdfLocation = this.data.locations.find(l => l.pdf_url)
        if (pdfLocation) return pdfLocation.pdf_url
      }
      return ''
    },
    journal () {
      if (!this.isEmpty(this.data.locations)) {
        const journal = this.data.locations.find(l => l.category === 'journal')
        return journal
      }
      return null
    }
  },
  created () {
    this.scope = 'publication'
  },
  watch: {
    isLoaded (newValue, oldValue) {
      if (newValue && newValue !== oldValue && this.doiIdentifier) {
        // Recursive function to init Scite (max 5 tries)
        const initScite = function (tries = 0) {
          if (tries > 5) return
          if (window.__SCITE) window.__SCITE.insertBadges()
          else {
            setTimeout(() => initScite(tries + 1), 200)
          }
        }
        this.$nextTick(() => {
          initScite()
        })
      }
    }
  },
  methods: {
    fetch (slug) {
      PublicationService.getByID(slug).then(publication => {
        this.data = publication

        if (publication.authors) {
          this.data = {
            ...this.data,
            authors: this.data.authors.map(author => {
              return {
                ...author,
                organisations: author.organisations.map(org => {
                  return {
                    ...org,
                    isAffiliationAtTimeOfWriting: !org.from_researcher // Extra property to show affiliation at time of writing in ResearcherSummary
                  }
                })
              }
            })
          }
        }
        this.fetchHighlights(this.$options.filters.pluralize(this.scope), slug)
      }).catch(err => {
        this.handleCustomError(err)
      })
    },
    fetchAllAuthors () {
      this.isAuthorsLoaded = false
      PublicationService.getAuthors(this.data.slug_values[0]).then(authors => {
        this.data.authors = authors.map(author => {
          return {
            ...author,
            organisations: author.organisations.map(org => {
              return {
                ...org,
                isAffiliationAtTimeOfWriting: !org.from_researcher // Extra property to show affiliation at time of writing in ResearcherSummary
              }
            })
          }
        }).sort((a, b) => a.position - b.position)
        this.isAuthorsLoaded = true
        // Let vue update the things before we parse the new authors with highlights
        setTimeout(() => {
          this.updateHighlights()
        }, 0)
      }).catch(err => {
        this.isAuthorsLoaded = !this.isEmpty(this.error)
        this.error = this.handleError(err)
      })
    },
    track (urlType, url) {
      MPService.trackEvent('full_text_clicked', {
        item_type: 'publication',
        slug_values: this.data.slug_values,
        url_type: urlType,
        url: url
      })
    }
  }
}
</script>
