<template>
  <div>
    <h2 class="t-primary t-400 mb-0 t-overflow">{{ titleOverride ? titleOverride : `Write an email to ${contacts.length > 1 ? 'one of the contacts in this group' : getFullName(contact) }` }}</h2>
    <p v-if="subTitleOverride">{{ subTitleOverride }}</p>
    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>
    <static-messages v-if="success" :messages="success" :variant="`success`"/>
    <button type="button" v-if="success" @click.prevent="$events.$emit('modal:close')" class="btn btn-form btn-secondary pull-right mt-3">Continue</button>

    <!-- you can only send a message when logged in -->
    <template v-if="$currentUser && isEmpty(success)">
      <template v-if="showTutorial">
        <p class="t-500 mb-1">
          What happens after you click the Send button:
        </p>
        <ul class="list-unstyled ml-2 clearfix">
          <li class="d-flex align-items-start">
            <i class="fa fa-check t-green mr-2 mt-1"></i>We deliver your email without any AcademicLabs promotions.
          </li>
          <li class="d-flex align-items-start">
            <i class="fa fa-check t-green mr-2 mt-1"></i><span>Your name is mentioned as the sender.</span>
          </li>
          <li class="d-flex align-items-start">
            <i class="fa fa-check t-green mr-2 mt-1"></i><span>Your email address is the Reply-to email address, so their reply goes directly to you and not via AcademicLabs.</span>
          </li>
          <li class="d-flex align-items-start">
            <i class="fa fa-check t-green mr-2 mt-1"></i><span>You receive a copy of the email.</span>
          </li>
          <li class="d-flex align-items-start">
            <i class="fa fa-check t-green mr-2 mt-1"></i><span>The emails are encrypted.</span>
          </li>
        </ul>
      </template>

      <!-- add invites form -->
      <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
        <!-- personal message -->
        <div class="row">
          <div v-if="filteredContacts.length > 1" class="form-group col-md-12">
            <label class="t-500 label-required">
              Who do you wish to write to?
            </label>
            <label class="select-label">
              <select class="form-control" v-model="contact" @change="handleContactChanged(contact)">
                <option v-for="(contact, key) in filteredContacts" :key="key" :value="contact" class="t-sm">
                  {{ getFullName(contact) }}
                </option>
              </select>
            </label>
          </div>

          <div class="form-group col-md-12" :class="{'has-icon-inline-label': errors.has('message.subject') }">
            <label class="t-500 label-required">
              Your email subject line
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Mention what you are looking for"
              name="message.subject"
              v-model="message.subject"
              v-validate="'required|min:5'"
              data-vv-as="subject"
              data-vv-delay="1000"
              :class="{'is-invalid': errors.has('message.subject')}"
            />
            <i v-show="errors.has('message.subject')" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="errors.has('message.subject')">{{ errors.first('message.subject') }}</span>
          </div>

          <div class="message-create richtext-wrapper col-md-12 mb-0" :class="{'is-invalid has-icon-inline-label': errors.has('message.body')}">
            <label class="t-500 label-required">
              Your message
            </label>
            <!-- add rich text editor -->
            <rich-text-editor v-model="message.body"
              name="message.body"
              v-validate="'required|min:20'"
              data-vv-as="message"
              data-vv-delay="1000"
              :placeholder="`Start writing your message...`"
              :content="message.body"
              :minLength="minLength"
              :minimal="true">
            </rich-text-editor>

            <i v-show="errors.has('message.body')" class="fa fa-warning t-secondary"></i>
            <span class="invalid-feedback" v-show="errors.has('message.body')">{{ errors.first('message.body') }}</span>

          </div>
        </div>

        <!-- action buttons -->
        <form-buttons :isRequest="isRequest"
          :isEditMode="false"
          :isCreateMode="true"
          :isRemove="false"
          :createText="`Send`"
          :containerClass="`pt-3`"
          @cancel="$events.$emit('modal:close')"
        />
      </form>
    </template>

    <template v-if="!$currentUser">
      <login-modal :scope="'contact'" class="w-50 mx-auto"/>
    </template>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'
import LoginModal from '@/components/login/LoginModal'
import RichTextEditor from '@/components/shared/form/RichTextEditor'

import ResearcherService from '@/services/researcher'
import MPService from '@/services/mixpanel'

export default {
  name: 'contactMailCreate',
  components: {
    StaticMessages,
    FormButtons,
    LoginModal,
    RichTextEditor
  },
  props: {
    contacts: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    phone: {
      type: String,
      required: false,
      default: ''
    },
    scope: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    state: {
      type: Object,
      required: false,
      default: function () { return {} } // Can't just pass the value directly because reasons (Vue)
    }
  },
  data () {
    return {
      error: null,
      success: null,
      isRequest: false,
      minLength: 20,
      contact: {},
      filteredContacts: [],
      message: {
        subject: '',
        body: ''
      },
      showTutorial: true,
      titleOverride: null,
      subTitleOverride: null
    }
  },
  created () {
    this.filteredContacts = this.contacts.filter((c) => { if (c.email) return true })
    !this.isEmpty(this.filteredContacts)
      ? this.contact = this.filteredContacts[0]
      : this.$events.$emit('modal:close')

    let id = this.scope === 'researchers' ? this.contact.slug_values[0] : this.slug
    let name = this.scope === 'researchers' ? this.getFullName(this.contact) : this.name

    this.handleInitialState()

    MPService.getInTouch(this.scope, id, name)
  },
  methods: {
    handleInitialState () {
      if (!this.state || this.isEmpty(this.state)) return

      if (this.state.type === 'opportunity') {
        const opportunity = this.state.opportunity
        const community = this.state.community
        this.message.subject = `Regarding your opportunity "${opportunity.title}" in ${community.name}`
        this.showTutorial = false
        this.titleOverride = `Respond to ${opportunity.title}`
        this.subTitleOverride = `Send an email to ${this.getFullName(this.contact)} to respond to this opportunity`
      }
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true
          this.handleResearcher()
        }
      })
    },
    handleContactChanged (contact) {
      this.contact = contact
    },
    handleResearcher () {
      ResearcherService.message(this.contact.slug_values[0], this.message).then((message) => {
        this.isRequest = false
        this.success = `Message sent`
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    }
  }
}
</script>
