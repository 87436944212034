import Vue from 'vue'

export default {
  // Fetches all profiles a user is following (=has created an alert for)
  getFollowing (slug) {
    return Vue.prototype.$httpSkip500.get(`/users/${slug}/subscriptions`).then(response => response.data)
  },
  followResearcher (slug) {
    return Vue.prototype.$httpSkip500.post(`/researchers/${slug}/subscribe`).then(response => response.data)
  },
  unfollowResearcher (slug) {
    return Vue.prototype.$http.delete(`/researchers/${slug}/subscribe`).then(response => response.data)
  },
  followResearchGroup (id) {
    return Vue.prototype.$httpSkip500.post(`/research_groups/${id}/subscribe`).then(response => response.data)
  },
  unfollowResearchGroup (id) {
    return Vue.prototype.$http.delete(`/research_groups/${id}/subscribe`).then(response => response.data)
  },
  followOrganisation (id) {
    return Vue.prototype.$httpSkip500.post(`/organisations/${id}/subscribe`).then(response => response.data)
  },
  unfollowOrganisation (id) {
    return Vue.prototype.$http.delete(`/organisations/${id}/subscribe`).then(response => response.data)
  }
}
