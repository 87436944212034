<template>
  <div class="p-4">
    <pro-pitch />

    <span class="pb-2" v-if="category === 'bookmark' && $currentUser">
      <i class="t-secondary t-xs fa fa-info-circle" /> In the meantime, if you wish to bookmark interesting results, you can save these one by one.
    </span>

    <div class="d-flex justify-content-end align-items-center mt-4">
      <button
        class="btn bg-transparent no-border t-primary mr-3"
        type="button"
        @click="close"
      >Close</button>

      <a class="btn btn-primary px-4"
        href="https://www.academiclabs.com/academiclabs-get-started"
        target="_blank"
      >Book a demo</a>
    </div>
  </div>
</template>

<script>
import ProPitch from '@/components/pro/Pitch'

export default {
  name: 'genericModal',
  components: {
    ProPitch
  },
  props: {
    category: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    close () {
      this.$events.$emit('modal:close')
    }
  }
}
</script>
