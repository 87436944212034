<template>
  <div class="">
    <h4 class="t-primary t-400 mb-0">Import {{ this.scope }}</h4>
    <hr class="hr-small mb-4" />

    <!-- render static messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <!-- show publications -->
    <form @submit.prevent="validateBeforeSubmit" v-if="isLoaded">
      <template v-if="havePublications">
        <div v-for="(publication, index) in initPublications(fetchedData)" :key="index" class="form-group mb-0 clearfix">
          <hr v-if="index > 0"/>

          <checkbox v-model="publication.import" :name="publication.titles_attributes[0].title" :containerClass="`pull-left publication_checkbox-connect`"></checkbox>
          <label class="pull-left publication_content-connect" :for="publication.titles_attributes[0].title">
            <span class="d-block mb-0 t-600 t-blue">{{ publication.titles_attributes[0].title }}</span>
            <span class="d-block mb-0" v-if="!isEmpty(publication.authors_list)">{{ publication.authors_list.join() }}</span>
            <span class="d-block mb-0 t-secondary" v-if="publication.journal_issue_attributes && publication.journal_issue_attributes.pub_date">{{  publication.journal_issue_attributes.pub_date }}</span>
            <!-- <span v-if="publication._update" class="t-yellow t-sm"><i class="fa fa-warning pr-2"></i>Update found in your Orcid account. We will sync the publication.</span> -->
          </label>
        </div>
      </template>

      <!-- no publications -->
      <div v-if="!havePublications">
        No publications found to import...
      </div>

      <div class="mt-3 bd-t pt-4 clearfix">
        <!-- import -->
        <button v-if="havePublications" type="submit" tabindex="8" class="btn btn-primary px-5 t-700 position-relative pull-right">
          <i v-if="isRequest" class="fa fa-spinner fa-spin t-white fa-spin-left t-xxs"></i>
          Import
        </button>

        <!-- cancel -->
        <a href="#" @click.prevent="close()" class="t-600 t-regular pull-right">
          <span v-if="havePublications" class="d-block mt-2 mr-3">Cancel</span>
          <span v-if="!havePublications">Close</span>
        </a>
      </div>
    </form>

    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px text-left`"></spinner>
  </div>
</template>

<script>
import UserService from '@/services/user'
import OrcidService from '@/services/orcid'

import Spinner from '@/components/shared/Spinner'
import Checkbox from '@/components/shared/form/Checkbox'
import StaticMessages from '@/components/shared/StaticMessages'

export default {
  name: 'userConnect',
  components: {
    Spinner,
    Checkbox,
    StaticMessages
  },
  props: {
    scope: {
      type: String,
      default: ''
    },
    connectData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isRequest: false,
      isUpdate: false,
      isLoaded: false,
      error: null,
      fetchedData: {}
    }
  },
  computed: {
    havePublications () {
      return this.fetchedData.length > 0
    }
  },
  created () {
    if (this.connectData.length >= 1) {
      if (this.connectData[this.scope].length >= 1) {
        this.fetchedData = this.connectData[this.scope]
        this.isLoaded = true
      }
    } else {
      this.fetch()
    }
  },
  watch: {
    // emitOnClose (newVal, oldVal) {
    //   if (newVal !== oldVal && newVal) {
    //     this.$ls.removeConnectData()
    //     this.$router.push({ name: 'researcher.show', params: { id: this.$currentUser.slug_values[0] } })
    //   }
    // }
  },
  methods: {
    fetch () {
      OrcidService.fetch(this.scope).then(orcid => {
        orcid[this.scope]
          ? this.fetchedData = orcid[this.scope]
          : this.error = 'Could not fetch data from ORCID'

        this.isLoaded = true
      }).catch(err => {
        this.error = this.handleError(err)
        this.isLoaded = !this.isEmpty(this.error)
      })
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true

          let publications = this.fetchedData.filter((p) => { return Boolean(p.import) })
          if (publications.length >= 1) {
            UserService.importData(this.$currentUser.user_id, {
              publications: publications
            }).then(pubs => {
              this.$events.$emit('researcher:updated', 'publications', pubs.publications)
              this.$events.$emit('researcher:updated', 'publication_count', pubs.total)
              this.close()
              this.isRequest = false
            }).catch(err => {
              this.isRequest = false
              this.error = this.handleError(err)
            })
          } else {
            this.close()
            this.isRequest = false
          }
        }
      })
    },
    initPublications (publications) {
      for (let publication of publications) publication.import = true
      return publications
    },
    close () {
      this.$ls.removeConnectData()
      this.$router.push({ name: 'researcher.show', params: { id: this.$currentUser.slug_values[0] } })
      this.$events.$emit('modal:close')
    }
  }
}
</script>
