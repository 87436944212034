<template>
  <a v-if="alertType === 'alert'"
    href="#"
    class="dropdown-item d-flex my-1"
    @click.prevent="$emit('goToSearch')"
    :class="{ 'bg-red-light': isHovering }"
  >
    <span class="dropdown-item_icon t-sm">
      <i class="fa fa-search t-secondary with_offset"></i>
    </span>
    <div class="dropdown-item_text">
      <span class="d-block" :class="{ 't-primary': isHovering }">
        {{ alert.name }}
        <span class="t-green t-sm ml-1" v-if="alert.new_results">
          +{{ alert.new_results }} {{ 'result' | pluralize(alert.new_results) }}
        </span>
      </span>
      <span v-if="alert.data.index" class="t-secondary t-sm d-block">{{ getIndexAsString(alert.data.index) }}</span>
      <filter-labels :filters="alert.data.filters" />
      <exclude-labels :excludes="alert.data.exclude" />
      <search-by-fields-labels :fields="alert.data.search_by_fields" />
      <span
        v-if="alert.data.ignore_synonyms"
        class="t-sm bd-radius bd px-1 mr-1 t-primary d-inline-block mb-1"
        v-tooltip="'Ignoring synonyms'"
      ><s>Synonyms</s></span>
    </div>
    <i
      @click.stop="$emit('deleteAlert')"
      class="fa fa-trash t-secondary is-hover--red align-self-center p-1 show-on-hover"
      v-tooltip.left="'Delete this alert'"
      @mouseover="isHovering = true"
      @mouseleave="isHovering = false"
    />
  </a>

  <router-link v-else
    class="dropdown-item d-flex my-1"
    :class="{ 'bg-red-light': isHovering }"
    :to="{name: `${alertType}.show`, params: { id: alert.slug_values[0] }}"
  >
    <span class="dropdown-item_icon t-sm">
      <i class="t-secondary with_offset" :class="alertIcon"></i>
    </span>
    <div class="dropdown-item_text">
      <span class="d-block"
        :class="{ 't-primary': isHovering }"
      >
        {{ alert.name }}
        <span class="t-green t-sm ml-1" v-if="alert.new_results">
          +{{ alert.new_results }} {{ 'result' | pluralize(alert.new_results) }}
        </span>
      </span>
      <span class="t-secondary t-sm">{{ $options.filters.singularize(getIndexAsString(alertType)) }}</span>
    </div>
    <i
      @click.prevent.stop="$emit('deleteAlert')"
      class="fa fa-trash t-secondary is-hover--red align-self-center p-1 show-on-hover"
      v-tooltip.left="'Delete this alert'"
      @mouseover="isHovering = true"
      @mouseleave="isHovering = false"
    />
  </router-link>

</template>

<script>
import FilterLabels from '@/components/search/save/FilterLabels'
import ExcludeLabels from '@/components/search/save/ExcludeLabels'
import SearchByFieldsLabels from '@/components/search/save/SearchByFieldsLabels'

export default {
  name: 'headerAlert',
  props: {
    alertType: {
      type: String,
      required: true
    },
    alert: {
      type: Object,
      required: true
    }
  },
  components: {
    FilterLabels,
    ExcludeLabels,
    SearchByFieldsLabels
  },
  computed: {
    alertIcon () {
      switch (this.alertType) {
        case 'researcher':
          return 'fa fa-user'
        case 'research_group':
          return 'fa fa-users'
        case 'organisation':
          return this.renderOrganisationCategoryIcon(this.alert.organisation_types || '')
        default:
          return 'fa fa-search'
      }
    }
  },
  data () {
    return {
      isHovering: false
    }
  }
}
</script>
