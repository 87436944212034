<template>
  <div>
    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>
    <static-messages v-if="success" :messages="success" :variant="`success`" @closed="success = null"/>

    <!-- search on Email -->
    <form v-show="!isLoaded" @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <div class="form-group mb-4" :class="{'has-icon-small': errors.has('email') && !isInOnboardingFlow, 'has-icon': errors.has('email') && isInOnboardingFlow}">
        <!-- <label v-if="!isInOnboardingFlow" class="t-500 t-sm label-required">Add your company or institutional email address</label> -->
        <input type="text"
          class="form-control"
          name="email"
          v-model="email"
          v-validate="'required|email'"
          data-vv-delay="1000"
          data-vv-as="email"
          :placeholder="`Enter your official email address`"
          :class="{'is-invalid': errors.has('email'), 'form-control-lg': isInOnboardingFlow}"
        />

        <i v-show="errors.has('email')" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('email')">{{ errors.first('email') }}</span>
      </div>

      <!-- action buttons -->
      <form-buttons v-if="!isEmpty(email)"
        :isCreateMode="true"
        :isEditMode="false"
        :isRequest="isRequest"
        :createText="`Get Verified`"
        :isCancel="false"
        :btnClass="isInOnboardingFlow ? 'btn-scoped-view-flow-centered bd-none' : 'pull-none px-5'"
        :containerClass="isInOnboardingFlow ? 'bd-none mb-4 mt-0' : 'bd-none text-center'"
        class=""
        @cancel="handleCancel()">
      </form-buttons>
    </form>

    <!-- show organisations -->
    <div v-if="!isEmpty(organisationRequests)">
      <p class="font-italic t-sm my-3 text-left">
        <i class="fa fa-info-circle mr-2 t-secondary"></i>Which {{ 'organisation' | pluralize(organisationRequests.length) }} do you want to join?
      </p>

      <spinner v-if="isRequest" :containerClass="`width--50px min-height--40px mb-3`" />

      <div v-for="(request, index) in organisationRequests" :key="index">
        <div class="row">
          <div class="col-md-6">
            <p class="mb-0">
              {{ request.organisation.name }}
            </p>
          </div>

          <div class="col-md-6">
            <p class="pull-right mb-0">
              <a href="#" @click.prevent="handleDeclineOrganisation(request, request.organisation.name, index)" class="btn btn-secondary--grey t-sm">
                <i class="fa fa-times t-red t-sm pr-1"></i>Decline
              </a>
            </p>
            <p class="pull-right mb-0">
              <a href="#" @click.prevent="handleAcceptOrganisation(request, request.organisation.name, index)" class="btn btn-secondary--grey t-sm mr-2">
                <i class="fa fa-check t-green t-sm pr-1"></i>Confirm
              </a>
            </p>
          </div>
        </div>
        <hr v-if="index < organisationRequests.length - 1" class="mb-3" />
      </div>
    </div>

    <!-- show need to confirm email address -->
    <!-- show need to confirm email address -->
    <div v-if="!isNewEmailAddress && secondaryEmail.confirmation_sent_at" class="message-centered-verified">
      <p class="alert alert-warning t-sm">
        Oops, you've already added this email address to your account, please confirm this email address<br />
      </p>
      <p v-if="!isInOnboardingFlow" class="mb-0 text-center">
        <a href="#" @click.prevent="handleResendEmail()">Resend confirmation email here.</a>
      </p>
      <p v-if="isInOnboardingFlow" class="mb-0 text-center">
        <a href="#" @click.prevent="skipStep()" class="btn btn-secondary--grey t-600 mt-3">Search organisation by name</a>.
      </p>
    </div>

    <!-- show need to confirm email address -->
    <!-- <d v-if="isNewEmailAddress && !isEmpty(organisations)"> -->
    <div v-if="isNewEmailAddress && !isEmpty(organisations)" class="message-centered-verified">
      <p class="alert alert-success t-sm">
        Almost done! We've sent a confirmation email to {{ email }}, please complete your verification process by clicking on the link. <br />
      </p>
      <p v-if="!isModal && !isInOnboardingFlow" class="mb-0 text-center">
        <a href="#" @click.prevent="handleCancel()">Continue</a>
      </p>
    </div>

    <!-- show need to confirm email address -->
    <div v-if="isNewEmailAddress && isEmpty(organisations) && isEmpty(organisationRequests) && isLoaded" class="message-centered-verified">
      <p class="alert alert-warning t-sm">
        Unfortunately, we could not find any organisation based on your email {{ email }}<br />
      </p>
      <p class="mb-0 text-center">
        <a href="#" @click.prevent="skipStep()" class="btn btn-secondary--grey t-600 mt-3">Search organisation by name</a>.
      </p>
    </div>

    <div v-if="!isNewEmailAddress && isEmpty(organisations) && isEmpty(organisationRequests) && isLoaded" class="message-centered-verified">
      <p class="alert alert-warning t-sm">
        Unfortunately, we could not find any organisation based on your email {{ email }}<br />
      </p>
      <p class="mb-0 text-center">
        <a href="#" @click.prevent="skipStep()" class="btn btn-secondary--grey t-600 mt-3">Search organisation by name</a>.
      </p>
    </div>

    <p v-if="isHandled && isEmpty(organisationRequests) && isEmpty(secondaryEmail)" class="alert alert-success t-sm">
      <i class="fa fa-info-circle mr-2 t-green"></i>Successfully accepted all organisations.
      <a href="#" @click.prevent="handleCancel()">Continue</a>
    </p>
  </div>
</template>

<script>

import UserService from '@/services/user'

import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'
import Spinner from '@/components/shared/Spinner'

export default {
  name: 'organisationJoinByEmail',
  components: {
    StaticMessages,
    FormButtons,
    Spinner
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    researcher: {
      type: Object,
      required: true
    },
    isModal: {
      type: Boolean,
      required: false,
      default: false
    },
    isInOnboardingFlow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      error: null,
      success: null,
      isRequest: false,
      isLoaded: false,
      isNewEmailAddress: false,
      isHandled: false,
      email: '',
      organisationRequests: [],
      organisations: [],
      secondaryEmail: {}
    }
  },
  metaInfo () {
    return {
      titleTemplate: `Organisation join  | AcademicLabs`,
      meta: [
        { vmid: 'description', name: 'description', content: `Join an organisation` }
      ]
    }
  },
  watch: {
    email (newVal, oldVal) {
      this.isEmpty(newVal) ? this.$emit('showInfo') : this.$emit('hideInfo')
    }
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true
          this.email = this.email.toLowerCase()
          UserService.getOrganisationRequestsByEmail(this.$currentUser.user_id, this.email).then(request => {
            this.isLoaded = true
            this.isRequest = false

            this.isNewEmailAddress = request.new
            if (!this.isEmpty(request.secondary_email)) this.secondaryEmail = request.secondary_email
            if (!this.isEmpty(request.organisations)) this.organisations = request.organisations

            if (!this.isEmpty(request.organisation_requests)) {
              this.organisationRequests = request.organisation_requests
              this.$events.$emit('user:updated', 'pending_organisation_requests_count', this.organisationRequests.length)
            }

            this.$emit('completed')
            if (this.isNewEmailAddress && !this.isEmpty(request.organisations)) this.$emit('joined')
          }).catch(err => {
            this.isRequest = false
            this.error = this.renderError(err)
          })
        }
      })
    },
    handleAcceptOrganisation (organisation, name, index) {
      if (!this.isRequest) {
        this.isRequest = true
        UserService.acceptOrganisationRequest(this.$currentUser.user_id, organisation.id).then(organisations => {
          this.organisations = organisations
          this.organisationRequests.splice(index, 1)
          this.isHandled = true
          this.isRequest = false

          // send all events for this, we want to update the profile, user object and send an emit to upper component
          this.$events.$emit('current_user:update')
          this.$events.$emit('researcher:updated', 'organisations', this.organisations)
          this.$events.$emit('user:updated', 'pending_organisation_requests_count', this.organisationRequests.length)
          this.success = `Added ${name} to your profile.`
        }).catch(err => {
          this.isRequest = false
          this.error = this.handleError(err)
        })
      }
    },
    handleDeclineOrganisation (organisation, name, index) {
      if (!this.isRequest) {
        this.isRequest = true
        UserService.declineOrganisationRequest(this.$currentUser.user_id, organisation.id).then(organisations => {
          this.organisationRequests.splice(index, 1)
          this.isHandled = true
          this.isRequest = false
          this.$events.$emit('user:updated', 'pending_organisation_requests_count', this.organisationRequests.length)
          this.success = `Declined ${name} request.`
        }).catch(err => {
          this.isRequest = false
          this.error = this.renderError(err)
        })
      }
    },
    handleCancel () {
      this.$emit('cancel')
      this.$events.$emit('modal:close')
    },
    reset () {
      this.isLoaded = this.isNewEmailAddress = this.isHandled = false
      this.secondaryEmail = {}
      this.organisations = []
      this.organisationRequests = []
    },
    skipStep () {
      this.reset()
      this.$emit('skip')
    },
    handleResendEmail (email) {
      UserService.resendSecondaryEmail(this.$currentUser.user_id, this.secondaryEmail.id).then(resp => {
        this.success = `Email successfully resended to ${this.email}.`
      }).catch(err => {
        this.error = this.handleError(err)
      })
    }
  }
}
</script>
