<template>
  <div :class="{ 'card card-body mb-4 flex-chart-card-wrapper': withContainer }">
    <div v-if="isLoaded && legend" class="custom-legend-wrapper" :class="{ 'small-legend': isSmall }">
      <div class="custom-legend-inner">
        <span class="legend-square"
          :style="{ backgroundColor: color }" />
        <span class="t-700 ml-2 mr-1">{{ legend.total | formatNumberDecimal }}</span>
        <span>{{ name }}</span>
        <i v-tooltip="getTooltip"
          v-if="legend.suggestions > 0" class="fa fa-info-circle t-secondary p-2" />
      </div>
    </div>
    <div v-if="isLoaded && hasGraphData" class="flex-chart ratio-7-4 mb-1">
      <div :id="chartId" class="ratio-content" />
    </div>

    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import Spinner from '@/components/shared/Spinner'
import helpers from './helpers'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

export default {
  name: 'flexibleChart',
  components: {
    Spinner
  },
  mixins: [helpers],
  props: {
    isLoaded: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    withFill: {
      type: Boolean,
      default: false
    },
    graphData: {
      type: Array,
      required: true
    },
    legend: {
      type: Object,
      required: false
    },
    scope: {
      type: String,
      required: false,
      default: ''
    },
    chartType: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    withContainer: {
      type: Boolean,
      default: false
    },
    xField: {
      type: String,
      default: 'year'
    },
    yField: {
      type: String,
      default: 'y'
    },
    yLabel: {
      type: String,
      default: 'valueY'
    },
    hideYAxis: {
      type: Boolean,
      default: false
    },
    withTooltip: {
      type: Boolean,
      required: false,
      default: true
    },
    tooltipLabel: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      hasGraphData: false
    }
  },
  mounted () {
    this.$nextTick(() => this.parseData())
  },
  watch: {
    graphData (newVal, oldVal) {
      if (newVal !== oldVal && newVal) this.parseData()
    }
  },
  computed: {
    getTooltip () {
      if (!this.hasGraphData || !this.scope) return null
      if (this.scope === 'researchers') {
        return `${this.legend.suggestions} of these results were found based on the researcher's name. These are not yet verified and could belong to someone with an identical name.`
      } else if (this.scope === 'organisations') {
        return `${this.legend.suggestions} of these results were found based on the organisation's name. These are not yet verified and could belong to another organisation with an identical name.`
      } else {
        return `${this.legend.suggestions} of these results were found based on the names of this group's researchers. These are not yet verified and could belong to someone with an identical name.`
      }
    }
  },
  methods: {
    parseData () {
      this.hasGraphData = false
      if (this.graphData && this.graphData.length > 0) {
        this.hasGraphData = true

        this.$nextTick(() => {
          this.renderGraph(this.graphData, this.chartType)
        })
      }
    },
    renderGraph (data, type) {
      const root = this.initRoot()

      this.setAxisStyle(root)
      // Init standard XY chart element
      const chart = this.initChartElement(root)

      // Create Y-axis
      let yAxis
      if (this.hideYAxis) {
        yAxis = this.generateHiddenYAxis({
          root,
          chart
        })
      } else {
        yAxis = this.generateYAxis({
          root,
          chart
        })
      }

      const xAxis = this.generateXAxis({
        root,
        chart,
        minGridDistance: 30,
        categoryField: this.xField
      })

      xAxis.data.setAll(data)

      let tooltip
      if (this.withTooltip) {
        tooltip = am5.Tooltip.new(root, {
          getFillFromSprite: false,
          getStrokeFromSprite: true,
          labelText: this.tooltipLabel || `[bold]{${this.xField}}[/]\t {${this.yLabel}} {name}`,
          autoTextColor: false
        })

        tooltip.get('background').setAll({
          fill: am5.color(0x656F79),
          fillOpacity: 0.9,
          strokeOpacity: 0
        })

        tooltip.label.setAll({
          fill: am5.color(0xffffff),
          fontSize: '14px',
          oversizedBehavior: 'wrap-no-break',
          maxWidth: 280,
          width: 'auto'
        })
      }

      let series

      if (type === 'bar') {
        series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: this.name,
            valueYField: this.yField,
            categoryXField: this.xField,
            xAxis: xAxis,
            yAxis: yAxis,
            fill: am5.color(this.color),
            tooltip: tooltip,
            calculateAggregates: true
          })
        )
        series.columns.template.set('width', am5.percent(80))
        series.data.setAll(data)
      } else if (type === 'line') {
        series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name: this.name,
            valueYField: this.yField,
            categoryXField: this.xField,
            xAxis: xAxis,
            yAxis: yAxis,
            stroke: am5.color(this.color),
            fill: this.withFill ? am5.color(this.color) : null,
            tooltip: tooltip,
            calculateAggregates: true
          })
        )
        series.data.setAll(data)

        series.strokes.template.setAll({
          strokeWidth: 2
        })
        if (this.withFill) {
          series.fills.template.setAll({
            fillOpacity: 0.25,
            visible: true
          })
        }
      }

      // Show y-axis line to indicate max value
      if (this.hideYAxis) {
        let max = 0
        let maxIndex = -1
        // let maxCount = 0
        data.forEach((item, index) => {
          if (item.normalisedCount > max) {
            max = item.normalisedCount
            maxIndex = index
            // maxCount = item.count
          }
        })
        const x = (100 / data.length) * maxIndex

        const line = am5.Line.new(root, {
          stroke: this.color,
          width: am5.percent(x),
          centerX: am5.percent(100),
          strokeDasharray: 3,
          strokeDashoffset: 3,
          strokeWidth: 2,
          height: 0,
          x: am5.percent(97),
          y: 0,
          lineJoin: 'round'
        })

        const label = am5.Label.new(this.root, {
          fill: am5.color(0x475258),
          x: am5.percent(100),
          centerX: am5.percent(100),
          y: -10,
          centerY: am5.percent(50),
          fontSize: 12,
          textAlign: 'right',
          text: `${this.$options.filters.formatNumber(max, 2)}%`
        })

        const grid1 = am5.Line.new(root, {
          stroke: '#e8e8ec',
          width: am5.percent(100),
          strokeWidth: 1,
          height: 0,
          x: 0,
          y: am5.percent(33),
          lineJoin: 'round',
          layer: -1
        })
        const grid2 = am5.Line.new(root, {
          stroke: '#e8e8ec',
          width: am5.percent(100),
          strokeWidth: 1,
          height: 0,
          x: 0,
          y: am5.percent(66),
          lineJoin: 'round',
          layer: -1
        })
        const grid3 = am5.Line.new(root, {
          stroke: '#e8e8ec',
          width: am5.percent(100),
          strokeWidth: 1,
          height: 0,
          x: 0,
          y: am5.percent(100),
          lineJoin: 'round',
          layer: -1
        })

        chart.plotContainer.children.push(line)
        chart.plotContainer.children.push(label)
        chart.plotContainer.children.push(grid1)
        chart.plotContainer.children.push(grid2)
        chart.plotContainer.children.push(grid3)
      }

      // Attach an invisible cursor to the chart, needed to make the tooltips work
      const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}))
      cursor.lineX.setAll({
        visible: false
      })

      cursor.lineY.setAll({
        visible: false
      })
    }
  }
}
</script>
