import ResearchGroupService from '@/services/research_group'
import ResearcherService from '@/services/researcher'
import OrganisationService from '@/services/organisation'

export default {
  props: {
    popup: { // Type of popup
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      gotTooltip: false,
      fetchedTooltipData: null,
      isLoading: false,
      hasTooltip: true
    }
  },
  watch: {
    '$route.query.keyword': {
      handler () {
        this.gotTooltip = false
      }
    }
  },
  methods: {
    injectTitleWrapper (type) {
      return type === 'open' ? `<p class="my-1 position-relative t-primary t-sm">` : `</p>`
    },
    injectTitle (title, iconValues) {
      return `<span class="d-block t-overflow" title="${title}">
        <i class="${iconValues}"></i>
        ${title}
      </span>`
    },
    fetchTooltipData () {
      if (this.gotTooltip || this.isLoading) return
      if (this.popup === 'research_group') {
        this.fetchResearchGroupTooltipData(this.bucket.value)
      } else if (this.popup === 'researcher') {
        this.fetchResearcherTooltipData(this.bucket.value)
      } else if (this.popup === 'organisation') {
        this.fetchOrganisationTooltipData(this.bucket.value)
      } else if (this.popup === 'scientific_award') {
        this.fetchedTooltipData = this.bucket.data
        this.gotTooltip = true
      } else if (this.popup === 'funding_source') {
        this.fetchedTooltipData = this.bucket.data.funding_source
        this.gotTooltip = true
      }
    },
    fetchResearchGroupTooltipData (id) {
      this.isLoading = true

      ResearchGroupService.getTooltipData(id).then(researchGroup => {
        this.fetchedTooltipData = researchGroup
        this.gotTooltip = true
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        this.error = this.handleError(err)
      })
    },
    fetchResearcherTooltipData (authorSlug) {
      this.isLoading = true

      ResearcherService.getTooltipData(authorSlug).then(researcherGroups => {
        this.fetchedTooltipData = researcherGroups
        this.gotTooltip = true
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        this.error = this.handleError(err)
      })
    },
    fetchOrganisationTooltipData (organisationSlug) {
      this.hasTooltip = false
      this.isLoading = true

      OrganisationService.getTooltipData(organisationSlug).then(organisation => {
        this.fetchedTooltipData = organisation
        this.gotTooltip = true
        this.isLoading = false
      }).catch((err) => {
        this.isLoading = false
        this.error = this.handleError(err)
      })
    }
  },
  computed: {
    getContent () {
      // Don't show tooltip preloader if we already have spinner
      if (!this.hasTooltip) return ''
      // This tooltip content rendering is very brute force. Can't parse vue-components either, so have to work with pure html/css
      if (!this.gotTooltip) {
        return `
          <div class="v-spinner p-2">
            <div class="v-pulse v-pulse1">
            </div><div class="v-pulse v-pulse2">
            </div><div class="v-pulse v-pulse3">
            </div>
          </div>
        `
      }
      let output = ''

      // RESEARCH GROUP / COLLABORATING RESEARCH GROUP
      if (this.popup === 'research_group') {
        const researchGroups = this.fetchedTooltipData

        researchGroups.forEach(group => {
          const { name, organisations, projects, publications } = group
          const researchTopics = group.research_topics

          if (group.slug_values && group.slug_values.length > 0) {
            output += `
              <a class="t-700" href="/research_group/${group.slug_values[0]}">${name}</a>
            `

            if (researchTopics && researchTopics.length > 0) {
              output += this.injectTitleWrapper('open')
              researchTopics.forEach((topic, i) => {
                if (i > 2) return
                output += this.injectTitle(topic.title, 'fa fa-flask t-secondary')
              })

              output += this.injectTitleWrapper('close')
            } else if (projects && projects.length > 0) {
              output += this.injectTitleWrapper('open')
              projects.forEach((topic, i) => {
                if (i > 2) return
                output += this.injectTitle(topic.title, 'fa fa-file-text t-secondary')
              })

              output += this.injectTitleWrapper('close')
            } else if (publications && publications.length > 0) {
              output += this.injectTitleWrapper('open')
              publications.forEach((publication, i) => {
                if (i > 2) return
                output += this.injectTitle(publication.title, 'fa fa-filte-text t-secondary')
              })

              output += this.injectTitleWrapper('close')
            }

            if (organisations && organisations.length > 0) {
              organisations.forEach(organisation => {
                if (organisation && organisation.name) {
                  output += `<p class="t-primary my-0 t-sm">`

                  if (organisation.country && organisation.country.alpha_2) {
                    output += `
                      <span class="flag-icon bd-radius flag-icon-${organisation.country.alpha_2.toLowerCase()}"></span>
                      <span class="pl-1">${organisation.name}</span>
                    </p>`
                  } else if (organisation.organisation_types) {
                    output += `
                      <i class="${this.renderOrganisationCategoryIcon(organisation.organisation_types)} t-secondary"></i>
                      <span>${organisation.name}</span></p>
                    `
                  }
                }
              })
            }
          }
        })
      } /* AUTHOR */ else if (this.popup === 'researcher') {
        if (this.fetchedTooltipData && this.fetchedTooltipData.length > 0) {
          const researchers = this.fetchedTooltipData
          // Sort researchers, those with RG go first (in the order they are returned from BE)
          researchers.sort((a, b) => ((!('research_groups' in b) && ('research_groups' in a)) ? -1 : 1))
            .forEach(researcher => {
              if (researcher.research_groups) {
                researcher.research_groups.forEach(researchGroup => {
                  // Only show RG that are in the system (with slug_values)
                  if (researchGroup.slug_values && researchGroup.slug_values.length > 0) {
                    output += `<a class="t-700" href="/research_group/${researchGroup.slug_values[0]}">${researchGroup.name}</a>`
                    if (researchGroup.research_topics && researchGroup.research_topics.length > 0) {
                      output += this.injectTitleWrapper('open')
                      researchGroup.research_topics.forEach((researchTopic, i) => {
                        if (i > 2) return
                        output += this.injectTitle(researchTopic.title, 'fa fa-flask t-secondary')
                      })

                      output += this.injectTitleWrapper('close')
                    } else if (researchGroup.projects && researchGroup.projects.length > 0) {
                      output += this.injectTitleWrapper('open')
                      researchGroup.projects.forEach((project, i) => {
                        if (i > 2) return
                        output += this.injectTitle(project.title, 'fa fa-folder-open t-secondary')
                      })

                      output += this.injectTitleWrapper('close')
                    } else if (researchGroup.publications && researchGroup.publications.length > 0) {
                      output += this.injectTitleWrapper('open')
                      researchGroup.publications.forEach((publication, i) => {
                        if (i > 2) return
                        output += this.injectTitle(publication.title, 'fa fa-file t-secondary')
                      })

                      output += this.injectTitleWrapper('close')
                    }
                  }
                })
              }
            })
          if (!output) {
            output += `<span class="d-block t-overflow t-sm t-primary">No research group found</span>`
          }
        } else {
          output += `<span class="d-block t-overflow t-sm t-primary">No research group found</span>`
        }
      } /* SCIENTIFIC AWARD */ else if (this.popup === 'scientific_award') {
        if (this.fetchedTooltipData) {
          const award = this.fetchedTooltipData
          if (award.url) {
            output += `<a class="t-700" href="${award.url}" target="_blank">${award.name}</a>`
          } else {
            output += `<p class="t-700">${award.name}</p>`
          }
          if (award.description) {
            output += this.injectTitleWrapper('open')
            output += `${award.description}`
            output += this.injectTitleWrapper('close')
          }
          output += `<div class="mt-2">`
          if (award.category) {
            output += this.injectTitleWrapper('open')
            output += `<b class="t-600 mr-1">Category:</b><span>${award.category}</span>`
            output += this.injectTitleWrapper('close')
          }
          if (award.country) {
            output += this.injectTitleWrapper('open')
            output += `<b class="t-600 mr-1">Organising country:</b>`
            if (award.country.alpha_2) {
              output += `<span title="${this.renderCountryFlagTooltip(award.country)}" class="flag-icon bd-radius mr-1 flag-icon-${award.country.alpha_2.toLowerCase()}"></span>`
            }
            output += `<span v-if="${award.country.name}">${award.country.name}</span>`
            output += this.injectTitleWrapper('close')
          }
          output += `</div>`
        }
      } /* FUNDING SOURCE */ else if (this.popup === 'funding_source') {
        const fundingSource = this.fetchedTooltipData

        if (fundingSource.url) {
          output += `<a class="t-700" href="${fundingSource.url}" target="_blank">${fundingSource.full_name}</a>`
        } else {
          output += `<p class="t-700">${fundingSource.full_name}</p>`
        }
        if (fundingSource.description) {
          output += this.injectTitleWrapper('open')
          output += `${fundingSource.description}`
          output += this.injectTitleWrapper('close')
        }

        if (fundingSource.country) {
          output += this.injectTitleWrapper('open')
          if (fundingSource.country.alpha_2) {
            output += `<span title="${this.renderCountryFlagTooltip(fundingSource.country)}" class="flag-icon bd-radius mr-1 flag-icon-${fundingSource.country.alpha_2.toLowerCase()}"></span>`
          }
          output += `<span v-if="${fundingSource.country.name}">${fundingSource.country.name}</span>`
          output += this.injectTitleWrapper('close')
        }
      }
      return output
    },
    linkableOption () {
      return !!this.popup
    },
    searchable () {
      if (this.popup === 'scientific_award' || this.popup === 'funding_source') return false
      if (!this.gotTooltip) return false
      if (this.fetchedTooltipData && this.fetchedTooltipData.length > 0) return false
      return {
        name: 'searchHome',
        query: { keyword: this.bucket.label }
      }
    },
    linkable () {
      if (!this.gotTooltip) return false

      if (!this.fetchedTooltipData || this.fetchedTooltipData.length === 0) return false
      // RG tooltips (which contain publications and stuff...)
      if (this.popup === 'research_group') {
        const firstRelevantRG = this.fetchedTooltipData.find(r => r.slug_values && r.slug_values.length > 0)
        return {
          link: {
            name: 'research_group.show',
            params: { id: firstRelevantRG.slug_values[0] }
          },
          text: 'Go to research group profile'
        }
      } else if (this.popup === 'organisation') {
        const firstRelevantOrganisation = this.fetchedTooltipData.find(r => r.slug_values && r.slug_values.length > 0)
        return {
          link: {
            name: 'organisation.show',
            params: { id: firstRelevantOrganisation.slug_values[0] }
          },
          text: 'Go to profile'
        }
      } else if (this.popup === 'researcher') {
        // Researcher tooltips, which cna contain RGs that a researcher is part of
        const firstRelevantResearcher = this.fetchedTooltipData.find(r => r.slug_values && r.slug_values.length > 0)
        return {
          link: {
            name: 'researcher.show',
            params: { id: firstRelevantResearcher.slug_values[0] },
            query: { fullName: this.bucket.label }
          },
          text: 'Go to author profile'
        }
      }

      return false
    }
  }
}
