<template>
  <span
    v-if="!isEmpty(fields)"
    class="t-sm bd-radius bd px-1 mr-1 t-primary d-inline-block mb-1"
    v-tooltip="fields.join(', ')"
  >Search by {{ fields.length }}</span>
</template>

<script>

export default {
  name: 'searchByFieldsLabels',
  props: {
    fields: {
      type: Array,
      required: false
    }
  }
}
</script>
