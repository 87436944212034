import Vue from 'vue'

const ENDPOINT = '/communities'

export default {
  get () {
    return Vue.prototype.$http.get(`${ENDPOINT}`).then(response => response.data)
  },
  getByID (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}`).then(response => response.data)
  },
  updateByID (id, community) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}`, { community: community }).then(response => response.data)
  },
  updateAvatar (id, avatar) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/avatar`, { community: avatar }).then(response => response.data)
  },
  deleteAvatar (id) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/avatar`).then(response => response.data)
  },
  create (community) {
    return Vue.prototype.$http.post(`${ENDPOINT}`, { community: community }).then(response => response.data)
  },
  delete (id) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}`).then(response => response.data)
  },
  getQuestions (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/questions`).then(response => response.data)
  },
  getQuestion (id, question) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/questions/${question}`).then(response => response.data)
  },
  createQuestion (id, question) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/questions`, { question: question }).then(response => response.data)
  },
  updateQuestion (id, question) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/questions/${question.id}`, { question: question }).then(response => response.data)
  },
  removeQuestion (id, questionId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/questions/${questionId}`).then(response => response.data)
  },
  createAnswer (id, questionId, answer) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/questions/${questionId}/answers`, { answer: answer }).then(response => response.data)
  },
  updateAnswer (id, questionId, answer) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/questions/${questionId}/answers/${answer.id}`, { answer: answer }).then(response => response.data)
  },
  removeAnswer (id, questionId, answerId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/questions/${questionId}/answers/${answerId}`).then(response => response.data)
  },
  createCommentQuestion (id, questionId, comment) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/questions/${questionId}/comments`, { comment: comment }).then(response => response.data)
  },
  updateCommentQuestion (id, questionId, comment) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/questions/${questionId}/comments/${comment.id}`, { comment: comment }).then(response => response.data)
  },
  removeCommentQuestion (id, questionId, commentId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/questions/${questionId}/comments/${commentId}`).then(response => response.data)
  },
  createCommentAnswer (id, questionId, answerId, comment) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/questions/${questionId}/answers/${answerId}/comments`, { comment: comment }).then(response => response.data)
  },
  updateCommentAnswer (id, questionId, answerId, comment) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/questions/${questionId}/answers/${answerId}/comments/${comment.id}`, { comment: comment }).then(response => response.data)
  },
  removeCommentAnswer (id, questionId, answerId, commentId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/questions/${questionId}/answers/${answerId}/comments/${commentId}`).then(response => response.data)
  },
  getSettings (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/settings`).then(response => response.data)
  },
  updateSettings (id, settings) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/settings`, { community_settings: settings }).then(response => response.data)
  },
  getInviteRequests (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/invite_requests`).then(response => response.data)
  },
  acceptInviteRequest (id, inviteId) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/invite_requests/${inviteId}`).then(response => response.data)
  },
  declineInviteRequest (id, inviteId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/invite_requests/${inviteId}`).then(response => response.data)
  },
  getUserInvites (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/invites`).then(response => response.data)
  },
  cancelUserInvite (id, inviteId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/invites/${inviteId}`).then(response => response.data)
  },
  resendUserInvite (id, inviteId) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/invites/${inviteId}`).then(response => response.data)
  },
  createUserInvite (id, invites) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/invites`, { invite: invites }).then(response => response.data)
  },
  getResearchGroupInvites (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/research_group_invites`).then(response => response.data)
  },
  cancelResearchGroupInvite (id, inviteId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/research_group_invites/${inviteId}`).then(response => response.data)
  },
  resendResearchGroupInvite (id, inviteId) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/research_group_invites/${inviteId}`).then(response => response.data)
  },
  createResearchGroupInvite (id, invites) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/research_group_invites`, { invite: invites }).then(response => response.data)
  },
  getMembers (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/members`).then(response => response.data)
  },
  getResearchGroups (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/research_groups`).then(response => response.data)
  },
  getResearchInterests (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/research_interests`).then(response => response.data)
  },
  getPendingMembers (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/pending_members`).then(response => response.data)
  },
  updateMember (id, member) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/members/${member.slug}`, { member: member }).then(response => response.data)
  },
  deleteMember (id, member) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/members/${member.slug_values[0]}`).then(response => response.data)
  },
  getContact (slug) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${slug}/contacts`).then(response => response.data)
  },
  getResearchGroupInfo (slug, researchGroupId) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${slug}/research_groups/${researchGroupId}`).then(response => response.data)
  },
  getResearcherInfo (slug, researcherId) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${slug}/researchers/${researcherId}`).then(response => response.data)
  },
  getInterestsFromResearchers (id, limit) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/interests?limit=${limit}`).then(response => response.data)
  },
  getOpportunities (id, limit) {
    let endpoint = `${ENDPOINT}/${id}/opportunities`
    if (limit) endpoint += `?limit=${limit}`
    return Vue.prototype.$http.get(endpoint).then(response => response.data)
  }
}
