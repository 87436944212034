<template>
  <section class="d-flex flex-column h-100">
    <h2 class="mb-1 t-500 flex-grow-0">
      {{ state.total_results | formatNumber }} {{ humanReadableIndex }}  {{ state.keyword ? `for ${state.keyword}` : '' }}
      <span
        v-if="activeFilters.length > 0"
        v-tooltip="activeFilters.join(', ')"
      > - {{ activeFilters.length }} {{ 'filter' | pluralize(activeFilters.length) }}</span>
      <span
        v-if="state.exclude.length > 0"
        v-tooltip="state.exclude.join(', ')"
      > - {{ state.exclude.length }} {{ 'exclude' | pluralize(state.exclude.length) }}</span>
    </h2>

    <spinner v-if="!isLoaded" containerClass="width--50px min-height--150px pt-4 mt-4" />
    <h2 v-if="noData" class="t-secondary mt-4 pt-4 text-center flex-grow-1">No data to show on map</h2>
    <div v-show="!noData" id="mapdiv" class="flex-grow-1" />

    <div class="d-flex justify-content-end align-items-center mt-4 width--100per">
      <template v-if="isLoaded && !noData">
        <checkbox
          v-tooltip="checkboxTooltip"
          class="py-2 px-3 rounded bg-grey mr-3"
          :text="`Show density`"
          :name="'toggle_normalization'"
          :value="normalizedCounts"
          @toggle="toggleNormalisation"
        />

        <button class="btn btn-primary px-3 mr-3" @click="exportPNG" type="button">
          <i class="fa fa-arrow-down t-sm mr-2" /> Download PNG
        </button>
      </template>
      <button class="btn btn-primary px-3" @click="$events.$emit('modal:close')" type="button">Close</button>
    </div>
  </section>
</template>

<script>
import * as am5 from '@amcharts/amcharts5'
import * as am5map from '@amcharts/amcharts5/map'

import am5geodataWorldLow from '@amcharts/amcharts5-geodata/worldLow'
import am5geodataUsaLow from '@amcharts/amcharts5-geodata/usaLow'
import * as am5pluginsExporting from '@amcharts/amcharts5/plugins/exporting'

// import am5themesAnimated from '@amcharts/amcharts5/themes/Animated'
import moment from 'moment'

import Spinner from '@/components/shared/Spinner'
import Checkbox from '@/components/shared/form/Checkbox2'

import SearchService from '@/services/search'

export default {
  name: 'mapModal',
  components: {
    Spinner,
    Checkbox
  },
  props: {
    filter: {
      type: String,
      required: true
    },
    mapType: { // ['countries', 'US_states']
      type: String,
      required: true
    },
    state: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      regions: [],
      isLogarithmic: true,
      normalizedCounts: false,
      isLoaded: false,
      noData: false
    }
  },
  mounted () {
    /*
      AM-Charts 5
      Docs root: https://www.amcharts.com/docs/v5/
      Map chart docs: https://www.amcharts.com/docs/v5/charts/map-chart/ (very basic)
    */
    this.fetchData()
  },
  computed: {
    humanReadableIndex () {
      return this.getIndexAsString(this.state.index)
    },
    hasActiveCountry () {
      if (this.regions) {
        return !!this.regions.find(c => c.active)
      }
      return false
    },
    activeFilters () {
      const filters = this.state.filters
      if (this.isEmpty(filters)) return []

      return this.getActiveFilters(filters)
    },
    checkboxTooltip () {
      const unitType = this.mapType === 'countries' ? 'countries' : 'US states'
      return `<p class='text-left mb-0'><b>Checked</b>: easily spot ${unitType} with a high <b>percentage</b> of matching results (uses a linear scale)<br />
<b>Unchecked</b>: easily spot ${unitType} with a high <b>number</b> of matching results (uses a logarithmic scale)</p>`
    }
  },
  methods: {
    exportPNG () {
      this.exporting.download('png')
    },
    toggleNormalisation () {
      this.isLogarithmic = !this.isLogarithmic
      this.normalizedCounts = !this.normalizedCounts
      this.initRoot()
    },
    fetchData () {
      // // Default to normalisation if we have active filters or keywords
      // if (this.activeFilters.length > 0 || this.state.keyword) {
      //   this.isLogarithmic = false
      //   this.normalizedCounts = true
      // }
      this.isLoaded = false
      SearchService.getMapData({
        ...this.state,
        map: this.filter
      }).then(response => {
        if (!response || response.length === 0) {
          this.isLoaded = true
          this.noData = true
          return
        }
        this.noData = false
        // counts of 0 seem to break the logarithmic heatmap when all values are fairly low
        let totalCount = 0
        this.regions = response.filter(c => c.count > 0).map(c => {
          totalCount += c.total
          const normalizedCount = c.count / c.total * 100

          if (this.mapType === 'countries') {
            return {
              ...c,
              id: c.value,
              normalizedCount: normalizedCount
            }
          } else if (this.mapType === 'US_states') {
            // Extract US state code
            const splitState = c.value.split('-')
            const code = splitState[splitState.length - 1].toUpperCase()
            return {
              ...c,
              id: `US-${code}`,
              normalizedCount: normalizedCount
            }
          }
        })

        const averageModifier = (totalCount / this.regions.length) * 0.05
        // Mark regions as lowData when applicable. These will not be taken into account for the min/max values and colors
        this.regions.forEach(region => {
          if (region.total < averageModifier) {
            region.lowData = true
          }
        })

        // Calculate min/max values for both absolute and normalised counts. Ignore low data regions
        let lowest = Number.POSITIVE_INFINITY
        let highest = Number.NEGATIVE_INFINITY
        let lowestNormalised = Number.POSITIVE_INFINITY
        let highestNormalised = Number.NEGATIVE_INFINITY
        this.regions.forEach(region => {
          if (region.lowData) return
          if (region.count < lowest) lowest = region.count
          if (region.count > highest) highest = region.count
          if (region.normalizedCount < lowestNormalised) lowestNormalised = region.normalizedCount
          if (region.normalizedCount > highestNormalised) highestNormalised = region.normalizedCount
        })
        this.lowest = lowest
        this.highest = highest
        this.lowestNormalised = lowestNormalised
        this.highestNormalised = highestNormalised

        this.isLoaded = true
        am5.ready(() => this.initRoot())
      })
    },
    initRoot () {
      if (this.root) this.root.dispose()

      this.root = am5.Root.new('mapdiv')
      this.drawChart()
    },
    drawChart () {
      // Set themes
      this.root.interfaceColors.set('primaryButton', am5.color(0xffffff))
      this.root.interfaceColors.set('primaryButtonHover', am5.color(0xD6F6FE))
      this.root.interfaceColors.set('primaryButtonActive', am5.color(0x009ABD))
      this.root.interfaceColors.set('primaryButtonDown', am5.color(0x009ABD))
      this.root.interfaceColors.set('primaryButtonText', am5.color(0x009ABD))
      this.root.interfaceColors.set('primaryButtonStroke', am5.color(0x009ABD))

      this.root.numberFormatter.setAll({
        numberFormat: '#,###',
        numericFields: ['startText', 'startValue', 'count']
      })

      const chartOptions = this.mapType === 'US_states' ? {
        layout: this.root.horizontalLayout,
        projection: am5map.geoAlbersUsa()
      } : {
        panX: 'rotateX',
        layout: this.root.horizontalLayout,
        projection: am5map.geoNaturalEarth1()
      }

      let chart = this.root.container.children.push(
        am5map.MapChart.new(this.root, chartOptions)
      )

      // Create polygon series
      let polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(this.root, {
          // geoJSON: this.updatedGeoDataWorldLow,
          geoJSON: this.mapType === 'US_states' ? am5geodataUsaLow : am5geodataWorldLow,
          useGeoData: true,
          valueField: this.normalizedCounts ? 'normalizedCount' : 'count',
          calculateAggregates: true,
          exclude: ['AQ']
        })
      )

      polygonSeries.mapPolygons.template.setAll({
        tooltipText: '{name}: {count}',
        interactive: true,
        fill: am5.color(0xDCDCDC),
        toggleKey: 'active'
      })

      polygonSeries.mapPolygons.template.adapters.add('tooltipText', (text, target) => {
        const count = target.dataItem.dataContext.count
        const total = target.dataItem.dataContext.total
        if (count <= 0 || !count) return '{name}: no results'

        let suffix = ''
        if (this.hasActiveCountry) {
          if (target.dataItem.dataContext.active) {
            suffix = '[#666]Click to deselect filter[/]'
          } else {
            suffix = '[#666]Click to select as filter[/]'
          }
        } else {
          suffix = '[#666]Click to select as filter[/]'
        }
        if (this.normalizedCounts) {
          return `${this.$options.filters.formatNumber(target.dataItem.dataContext.normalizedCount, target.dataItem.dataContext.normalizedCount > 9 ? 2 : 3)}% of all ${this.humanReadableIndex} in [bold]{name}[/] match your search
${count} ${this.humanReadableIndex} out of ${total}${target.dataItem.dataContext.lowData ? '\n[#666]Low amount of data; percentage may not be accurate[/]' : ''}
${suffix}`
        }
        return `${text}\n${suffix}`
      })

      const tooltip = am5.Tooltip.new(this.root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false
      })

      tooltip.get('background').setAll({
        fill: am5.color(0xffffff),
        fillOpacity: 0.6
      })

      tooltip.label.setAll({
        fill: am5.color(0x000000),
        fontSize: 14
      })

      polygonSeries.set('tooltip', tooltip)

      // This is our custom  heatLegend color coding rules
      // Supports 2 types of heatlegends (active/inactive filters)
      // Also takes care of "edge case low data" regions by giving them a pattern
      polygonSeries.set('heatRules', [{
        target: polygonSeries.mapPolygons.template,
        dataField: 'value', // has to be 'value' for some reason, even though valueField defined above is `valueField: 'count'`
        key: 'fill',
        logarithmic: true,
        min: am5.color(0xD6F6FE),
        max: am5.color(0x009ABD),
        customFunction: (sprite, min, max, value) => {
          // Logarithmic math to manually set heatmap colors
          const context = sprite.dataItem.dataContext
          let step, realPosition, stepPosition
          if (this.normalizedCounts) {
            min = this.lowestNormalised
            max = this.highestNormalised
          } else {
            min = this.lowest
            max = this.highest
          }
          if (this.isLogarithmic) {
            const logMin = Math.log(min)
            const logMax = Math.log(max)
            const logValue = Math.log(value)
            step = 1 / 12 // # of steps in the logarithmic gradient
            realPosition = (logValue - logMin) / (logMax - logMin)

            stepPosition = step
            // Discrete steps for the color
            while (stepPosition < realPosition) {
              stepPosition += step
            }
          } else {
            stepPosition = value / (max - min)
          }

          // Color prototype used to access interpolation function
          const c = am5.color(0xff0000)
          if (!this.hasActiveCountry || context.active) {
            // Interpolate color position % between min and max color
            const d = c.constructor.interpolate(stepPosition <= 1 ? stepPosition : 1, am5.color(0xD6F6FE), am5.color(0x009ABD))
            sprite.set('fill', d)
          } else {
            const d = c.constructor.interpolate(stepPosition <= 1 ? stepPosition : 1, am5.color(0xCCCCCC), am5.color(0x555555))
            sprite.set('fill', d)
          }
          // Have an accent for regions with low data
          if (this.normalizedCounts && context.lowData) {
            sprite.set('fillPattern', am5.LinePattern.new(this.root, {
              color: am5.color(0xcccccc),
              colorOpacity: 0.4,
              rotation: 45,
              width: 512,
              height: 512,
              gap: 5
            }))
          }
        }
      }])

      let startValue, endValue
      if (this.isLogarithmic) {
        startValue = 0
        endValue = 1
      } else if (this.normalizedCounts) {
        startValue = this.lowestNormalised
        endValue = this.highestNormalised
      } else {
        startValue = this.lowest
        endValue = this.highest
      }

      const legendTooltipCopy = this.normalizedCounts ? `Percentage of matching ${this.humanReadableIndex} / all ${this.humanReadableIndex} (linear scale)` : `Number of ${this.humanReadableIndex} (logarithmic scale)`

      const heatLegend = chart.children.push(am5.HeatLegend.new(this.root, {
        orientation: 'vertical',
        startColor: am5.color(0xD6F6FE),
        endColor: am5.color(0x009ABD),
        // startText: this.normalizedCounts ? this.$options.filters.formatNumber(normMinValue, 2) : this.$options.filters.formatNumber(minValue),
        // endText: this.normalizedCounts ? this.$options.filters.formatNumber(normMaxValue, 2) : this.$options.filters.formatNumber(maxValue),
        startText: '',
        endText: '',
        startValue: startValue,
        endValue: endValue,
        stepCount: 12,
        height: am5.percent(40),
        fontSize: 12,
        tooltipText: legendTooltipCopy,
        tooltipPosition: 'fixed',
        tooltipY: -20,
        tooltipX: -10,
        y: 20
      }))

      heatLegend.startLabel.set('fontSize', 12)
      heatLegend.endLabel.set('fontSize', 12)

      const startLabel = am5.Label.new(this.root, {
        fill: am5.color(0x000000),
        x: 75,
        centerX: 100,
        y: am5.percent(91),
        fontSize: 12,
        textAlign: 'right',
        width: 30,
        text: this.normalizedCounts ? `${this.$options.filters.formatNumber(this.lowestNormalised, this.lowestNormalised > 2 ? 2 : 3)}%` : this.$options.filters.formatNumber(this.lowest)
      })
      const endLabel = am5.Label.new(this.root, {
        fill: am5.color(0x000000),
        x: 75,
        centerX: 100,
        fontSize: 12,
        textAlign: 'right',
        width: 30,
        text: this.normalizedCounts ? `${this.$options.filters.formatNumber(this.highestNormalised, this.highestNormalised > 2 ? 2 : 3)}%` : this.$options.filters.formatNumber(this.highest)
      })

      heatLegend.children.push(startLabel)
      heatLegend.children.push(endLabel)

      const shape = am5.Triangle.new(this.root, {
        stroke: am5.color(0xffffff),
        fill: am5.color(0x009ABD),
        rotation: 90,
        width: 12,
        height: 12,
        x: 0
      })
      shape.hide() // Must be initially hidden
      heatLegend.children.push(shape)

      polygonSeries.mapPolygons.template.events.on('pointerover', (ev) => {
        const context = ev.target.dataItem.dataContext

        if (!context.count) return

        const value = this.normalizedCounts ? context.normalizedCount : context.count
        // const valueToDisplay = context.count
        let newY

        if (this.isLogarithmic) {
          // Get logarithmic position on heatlegend
          // const value = ev.target.dataItem.get('value')
          const logMin = Math.log(polygonSeries.getPrivate('valueLow'))
          const logMax = Math.log(polygonSeries.getPrivate('valueHigh'))
          const logValue = Math.log(value)
          const realPosition = (logValue - logMin) / (logMax - logMin)
          // heatLegend.showValue(realPosition, ` `)
          newY = heatLegend.innerHeight() * (1 - realPosition)
        } else {
          // heatLegend.showValue(value, ` `)
          const startValue = heatLegend.get('startValue', 0)
          const endValue = heatLegend.get('endValue', 1)
          const c = (value - startValue) / (endValue - startValue)
          newY = heatLegend.innerHeight() * (1 - c)
        }

        shape.animate({
          key: 'y',
          to: newY,
          duration: 250,
          easing: shape.get('animationEasing')
        })
        shape.show()
      })

      polygonSeries.mapPolygons.template.events.on('pointerout', (ev) => {
        shape.hide()
      })

      polygonSeries.mapPolygons.template.events.on('click', this.clickedArea)

      const zoomControl = chart.set('zoomControl', am5map.ZoomControl.new(this.root, {}))
      zoomControl.plusButton.setAll({
        marginBottom: 10,
        scale: 2,
        height: 16,
        width: 16
      })
      zoomControl.minusButton.setAll({
        scale: 2,
        height: 16,
        width: 16
      })

      zoomControl.minusButton.get('background').setAll({
        cornerRadiusTL: 2,
        cornerRadiusTR: 2,
        cornerRadiusBR: 2,
        cornerRadiusBL: 2
      })

      zoomControl.plusButton.get('background').setAll({
        cornerRadiusTL: 2,
        cornerRadiusTR: 2,
        cornerRadiusBR: 2,
        cornerRadiusBL: 2
      })

      const filePrefix = `${this.humanReadableIndex}-${moment().format('YYYY-MM-DD')} - ${this.mapType === 'countries' ? 'World map' : 'US map'}`

      let exporting = am5pluginsExporting.Exporting.new(this.root, {
        pngOptions: {
          minWidth: 1000,
          maxWidth: 2000
        }
      })
      exporting.set('filePrefix', filePrefix)

      exporting.events.on('exportstarted', () => {
        zoomControl.hide()
        zoomControl.hide()
      })

      exporting.events.on('exportfinished', () => {
        zoomControl.show()
        zoomControl.show()
      })

      this.exporting = exporting

      polygonSeries.data.setAll(this.regions)

      this.chart = chart
      this.polygonSeries = polygonSeries
    },
    clickedArea (ev) {
      const selectedIndex = this.regions.findIndex(c => c.id === ev.target.dataItem.dataContext.id)
      if (selectedIndex > -1) {
        this.regions[selectedIndex].active = !this.regions[selectedIndex].active
        // We want to remove and re-add the data item so that the heatRule colors get applied correctly
        this.polygonSeries.data.removeIndex(selectedIndex)
        this.polygonSeries.data.insertIndex(selectedIndex, this.regions[selectedIndex])

        this.$events.$emit('filter:changed:browse', this.regions, this.filter)
      }
    }
  },
  beforeDestroy () {
    if (this.root) this.root.dispose() // Clean up charts
  }
}
</script>
