<template>
  <div class="">
    <h2 class="t-primary t-400 mb-0" v-if="!isEditMode">Create <span v-if="isCreateSubMode">sub</span>community</h2>
    <h2 class="t-primary t-400 mb-0" v-if="isEditMode">Edit {{ community.name }}</h2>
    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <!-- render form edit/create -->
    <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <div v-if="!isEmpty(create.parent_id)" class="form-group position-relative">
        <label class="select-label">
          <select class="form-control" v-model="create.community_type_id">
            <option value="3" class="t-sm">Member network</option>
            <option value="2" class="t-sm">Project consortium</option>
          </select>
        </label>
      </div>

      <!-- public private -->
      <div class="form-group mb-0">
        <label class="t-500 t-sm">
          Type of community
        </label>
         <div v-for="(type, index) in accessRights" :key="index">
          <checkbox v-model="type.checked"
            :containerClass="'create-checkbox'"
            :text="type.name"
            :name="`privacy`"
            :description="type.description"
            :icon="type.icon"
            :object="type"
            @input="handleCheckboxAccessRights">
          </checkbox>
        </div>
      </div>

      <!-- name -->
      <div class="form-group mb-4" :class="{'has-icon-label': errors.has('create.name')}">
        <label class="t-500 t-sm label-required">
          Name
        </label>
        <input
          type="text"
          class="form-control"
          placeholder="Name"
          name="create.name"
          v-model="create.name"
          v-validate="'required|min:3'"
          data-vv-delay="1000"
          data-vv-as="name"
          :class="{'is-invalid': errors.has('create.name')}"
        />

        <i v-show="errors.has('create.name')" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('create.name')">{{ errors.first('create.name') }}</span>
      </div>

      <div class="form-group mb-4" :class="{'has-icon-inline-label': !isEmpty(errorCountry)}">
        <label class="t-500 t-sm label-required">Country</label>
        <typeahead :remote="`country`"
          :name="`country`"
          :value="create.country_name"
          :displayKey="`name`"
          :limit="5"
          :placeholder="`Country`"
          :customClass="`mt-0`"
          :clearOnSelect="false"
          :class="{'is-invalid': !isEmpty(errorCountry)}"
          @selected="handleTypeaheadCountry"
          @opened="$emit('opened')"
          @closed="$emit('closed')"
        ></typeahead>

        <i v-show="!isEmpty(errorCountry)" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="!isEmpty(errorCountry)">{{ errorCountry }}</span>
      </div>

      <!-- tagline -->
      <div class="form-group mb-4" :class="{'has-icon-label': errors.has('create.tagline')}">
        <label class="t-500 t-sm label-required">
          Tagline
          <i class='fa fa-info-circle ml-1 t-sm t-secondary' v-tooltip.top="`1 line describing your community`"></i>
        </label>
        <input
          type="text"
          class="form-control"
          placeholder="A short tagline about your community"
          name="create.tagline"
          v-model="create.tagline"
          v-validate="'required|min:5|max:100'"
          data-vv-delay="1000"
          data-vv-as="tagline"
          :class="{'is-invalid': errors.has('create.tagline')}"
        />

        <i v-show="errors.has('create.tagline')" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('create.tagline')">{{ errors.first('create.tagline') }}</span>
      </div>

      <div class="row mb-4">
        <div class="form-group mb-0 col-md-8" :class="{'has-icon-inline-label': errors.has('create.website')}">
          <label class="t-500 t-sm">Website</label>
          <input
            type="text"
            class="form-control"
            placeholder="Website"
            name="create.website"
            v-model="create.website"
            v-validate="'url:require_protocol'"
            data-vv-delay="1000"
            data-vv-as="website"
            :class="{'is-invalid': errors.has('create.website')}"
          />

          <i v-show="errors.has('create.website')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.website')">{{ errors.first('create.website') }}</span>
        </div>

        <div class="form-group mb-0 col-md-4" :class="{'has-icon-inline-label': errors.has('create.foundation_year')}">
          <label class="t-500 t-sm">Foundation year</label>
          <input
            type="text"
            class="form-control"
            placeholder="e.g 2014"
            name="create.foundation_year"
            v-model="create.foundation_year"
            v-validate="'digits:4|year'"
            data-vv-delay="1000"
            data-vv-as="foundation year"
            :class="{'is-invalid': errors.has('create.foundation_year')}"
          />

          <i v-show="errors.has('create.foundation_year')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.foundation_year')">{{ errors.first('create.foundation_year') }}</span>
        </div>
      </div>

      <!-- action buttons -->
      <form-buttons
        :isCreateMode="isCreateMode"
        :isEditMode="isEditMode"
        :isRequest="isRequest"
        :createText="'Create'"
        @cancel="handleCancel()">
      </form-buttons>
    </form>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'
import Checkbox from '@/components/shared/form/Checkbox'
import Typeahead from '@/components/shared/form/Typeahead'
import staticData from '@/assets/js/_data'

import CommunityService from '@/services/community'

export default {
  name: 'communityCreate',
  components: {
    StaticMessages,
    FormButtons,
    Checkbox,
    Typeahead
  },
  props: {
    community: {
      type: Object,
      required: true
    },
    isSubCommunity: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      error: null,
      isEditMode: false,
      isCreateMode: false,
      isCreateSubMode: false,
      isRequest: false,
      isCommunityNameValid: false,
      errorCountry: '',
      privacies: [
        {
          name: 'Public',
          icon: 'fa fa-unlock',
          description: 'Community is visible to non-community members.',
          checked: true
        }, {
          name: 'Private',
          icon: 'fa fa-lock',
          description: 'Community is only visible to community members.',
          checked: false
        }
      ],
      accessRights: [
        {
          name: 'Open',
          key: 'open',
          icon: 'fa fa-unlock',
          description: 'Anyone can join this community without requiring your approval.',
          checked: true
        }, {
          name: 'Invite only',
          key: 'closed',
          icon: 'fa fa-lock',
          description: 'People can only join this community when you approve of their request to join.',
          checked: false
        }
      ],
      privacySettings: [
        {
          name: 'Public',
          key: 'public',
          icon: 'fa fa-unlock',
          description: 'Anyone can see your community',
          checked: true
        },
        {
          name: 'Private',
          key: 'private',
          icon: 'fa fa-lock',
          description: 'Only people that have joined the community can access your community',
          checked: false
        }
      ],
      inviteOnly: '',
      privacySetting: '',
      isInviteOnlyChanged: false,
      isPrivacySettingsChanged: false,
      create: {
        parent_id: '',
        community_type_id: '',
        name: '',
        website: '',
        tagline: '',
        country_id: '',
        country_name: '',
        subdomain: '',
        street: '',
        post_code: '',
        city: '',
        foundation_year: ''
      },
      communityType: []
    }
  },
  mounted () {
    if (!this.isEmpty(this.community) && !this.isSubCommunity) {
      this.preFill()
    } else if (!this.isEmpty(this.community) && this.isSubCommunity) {
      // this.inviteOnly = this.accessRights[0]
      this.preFill()
      this.create.community_type_id = 3
      this.create.parent_id = this.community.id
      this.create.name = ''
      this.create.tagline = ''
      this.isEditMode = false
      this.isCreateMode = true
      this.isCreateSubMode = true
    } else {
      let type = staticData.community_type.filter((ct) => { if (ct.value === this.$route.query.type) return ct })
      this.communityType = type[0]
      this.create.community_type_id = this.communityType.key
      this.inviteOnly = this.accessRights[0]
      this.privacySetting = this.privacySettings[0]
      this.isCreateMode = true
    }
  },
  metaInfo () {
    return {
      titleTemplate: `Community create | AcademicLabs`,
      meta: [
        { vmid: 'description', name: 'description', content: `Create a community` }
      ]
    }
  },
  watch: {
    'create.country_name' (value) {
      this.isEmpty(value) ? this.errorCountry = 'The country field is required' : this.errorCountry = ''
    }
  },
  methods: {
    preFill () {
      this.create.community_type_id = this.community.community_type_id
      this.create.parent_id = this.community.parent_id
      this.create.name = this.community.name
      this.create.website = this.community.website
      this.create.tagline = this.community.tagline
      this.create.street = this.community.street
      this.create.post_code = this.community.post_code
      this.create.city = this.community.city
      this.create.research_group_types = this.community.research_group_types
      this.create.slug_values = this.community.slug_values
      this.create.foundation_year = this.community.foundation_year
      this.create.subdomain = this.community.subdomain
      this.create.country_id = this.community.country.id
      this.create.country_name = this.community.country.name

      if (this.community.rw_pages.invite_only) {
        this.accessRights[0].checked = false
        this.accessRights[1].checked = true
      }

      this.isEditMode = true
    },
    handleTypeaheadCountry (name, item) {
      this.$set(this.create, 'country_id', item.id)
      this.$set(this.create, 'country_name', item.name)
    },
    handleCheckboxAccessRights (value, obj) {
      this.isInviteOnlyChanged = true
      this.accessRights.map((p) => {
        (p.name === obj.name) ? p.checked = value : p.checked = !value
        if (p.checked) this.inviteOnly = Object.assign({}, p)
      })
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        // validate fields
        if (this.isEmpty(this.create.country_name)) {
          result = false
          if (this.isEmpty(this.create.country_name)) this.errorCountry = 'The country field is required'
        }

        if (result && !this.isRequest) {
          this.isRequest = true
          this.isEditMode ? this.handleEdit() : this.handleCreate()
        }
      })
    },
    handleCreate () {
      this.create.invite_only = this.inviteOnly.key !== 'open'
      // this.create.private = this.privacySetting.key === 'private'

      CommunityService.create(this.create).then(community => {
        this.replaceRoute(community, true)
        this.isRequest = false
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleEdit () {
      if (this.isInviteOnlyChanged) this.create.invite_only = this.inviteOnly.key !== 'open'
      // if (this.isPrivacySettingsChanged) this.create.private = this.privacySetting.key === 'private'

      CommunityService.updateByID(this.create.slug_values[0], this.create).then(community => {
        this.isRequest = false
        this.replaceRoute(community, false)
        this.$events.$emit('community:updated:all', community)
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleCancel () {
      if (this.isCreateSubMode) {
        this.$events.$emit('modal:close')
      } else if (this.isCreateMode) {
        this.$router.push({ name: 'create.community.type' })
      } else {
        this.$events.$emit('modal:close')
      }
    },
    replaceRoute (community, addToUser) {
      if (this.isCreateSubMode) {
        this.$events.$emit('modal:close')
        this.$router.push({ name: 'community.show', params: { id: community.slug_values[0] } })
      } else if (this.isCreateMode) {
        this.$router.replace({ name: 'community.show', params: { id: community.slug_values[0] } })
      } else {
        this.$events.$emit('modal:close')
      }

      // add community to $currentUser
      if (addToUser) {
        let c = { name: community.name, slug_values: community.slug_values }
        let user = Object.assign({}, this.$currentUser)
        if (user.communities) {
          user.communities.push(c)
        } else {
          user.communities = [c]
        }
        this.$auth.setCurrentUser(user)
      }
    }
  }
}
</script>
