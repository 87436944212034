import Vue from 'vue'
import Vuex from 'vuex'
import alerts from './modules/alerts'
import bookmarks from './modules/bookmarks'
import search from './modules/search'
import settings from './modules/settings'

// Vuex store
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    alerts,
    bookmarks,
    search,
    settings
  },
  strict: debug
})

if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept(['./modules/alerts', './modules/bookmarks', './modules/search'], () => {
    // require the updated modules
    // have to add .default here due to babel 6 module output
    const newAlerts = require('./modules/alerts').default
    const newBookmarks = require('./modules/bookmarks').default
    const newSearch = require('./modules/search').default
    const newSettings = require('./modules/settings').default
    // swap in the new modules and mutations
    store.hotUpdate({
      modules: {
        alerts: newAlerts,
        bookmarks: newBookmarks,
        search: newSearch,
        settings: newSettings
      }
    })
  })
}

export default store
