import UserService from '@/services/user'
import FollowService from '@/services/follow'

const state = {
  alerts: [],
  researchGroups: [],
  researchers: [],
  organisations: [],
  gotAlerts: false
}

// getters
const getters = {
  // List of alerts for currently logged in user
  alerts: (state) => state.gotAlerts ? state.alerts : [],
  researchGroups: (state) => state.gotAlerts ? state.researchGroups : [],
  researchers: (state) => state.gotAlerts ? state.researchers : [],
  organisations: (state) => state.gotAlerts ? state.organisations : []
}

// actions
const actions = {
  resetAlerts ({ commit }) {
    commit('resetAlerts')
  },
  getAlerts ({ commit, state }, { useCache = true }) {
    return new Promise((resolve, reject) => {
      if (
        useCache && state.gotAlerts
      ) {
        // Use cached version of alerts
        resolve()
      } else {
        const searchAlerts = UserService.getSaveSearches(this._vm.$currentUser.user_id).then(alerts => {
          commit('setAlerts', { alerts })
        }, error => reject(error))
        const followAlerts = FollowService.getFollowing(this._vm.$currentUser.user_id).then(subscriptions => {
          if (!subscriptions) return

          if (subscriptions.research_groups) {
            commit('setResearchGroupAlerts', { researchGroups: subscriptions.research_groups })
          }
          if (subscriptions.researchers) {
            commit('setResearcherAlerts', { researchers: subscriptions.researchers })
          }
          if (subscriptions.organisations) {
            commit('setOrganisationAlerts', { organisations: subscriptions.organisations })
          }
        }, error => reject(error))

        Promise.allSettled([searchAlerts, followAlerts]).then(() => {
          commit('gotAlerts')
          resolve()
        })
      }
    })
  },
  createAlert ({ commit }, { alert, type = '' }) {
    return new Promise((resolve, reject) => {
      if (!type) {
        UserService.saveSearch(this._vm.$currentUser.user_id, alert).then(newAlert => {
          commit('addAlert', { alert: newAlert })
          resolve(newAlert)
        }, error => reject(error))
      } else {
        const id = alert.slug_values[0]
        switch (type) {
          case 'researchers':
            FollowService.followResearcher(id).then(() => {
              resolve()
            }, error => reject(error))
            break
          case 'research_groups':
            FollowService.followResearchGroup(id).then(() => {
              resolve()
            }, error => reject(error))
            break
          case 'organisations':
            FollowService.followOrganisation(id).then(() => {
              resolve()
            }, error => reject(error))
            break
          default:
            break
        }
      }
    })
  },
  deleteAlert ({ commit }, { id, type = '' }) {
    return new Promise((resolve, reject) => {
      if (!type) {
        UserService.deleteSavedSearch(this._vm.$currentUser.user_id, id).then(() => {
          commit('removeAlert', { id })
          resolve()
        }, error => {
          // Alert is already gone, so this is fine.
          if (error && error.status === 404) {
            commit('removeAlert', { id })
            resolve()
          }
          reject(error)
        })
      } else {
        switch (type) {
          case 'researchers':
            FollowService.unfollowResearcher(id).then(() => {
              commit('removeResearcherAlert', { id })
              resolve()
            }, error => reject(error))
            break
          case 'research_groups':
            FollowService.unfollowResearchGroup(id).then(() => {
              commit('removeResearchGroupAlert', { id })
              resolve()
            }, error => reject(error))
            break
          case 'organisations':
            FollowService.unfollowOrganisation(id).then(() => {
              commit('removeOrganisationAlert', { id })
              resolve()
            }, error => reject(error))
            break
          default:
            break
        }
      }
    })
  }
}

// mutations
const mutations = {
  resetAlerts (state) {
    state.gotAlerts = false
  },
  setAlerts (state, { alerts }) {
    state.alerts = alerts
  },
  setResearchGroupAlerts (state, { researchGroups }) {
    state.researchGroups = researchGroups
  },
  setResearcherAlerts (state, { researchers }) {
    state.researchers = researchers
  },
  setOrganisationAlerts (state, { organisations }) {
    state.organisations = organisations
  },
  addAlert (state, { alert }) {
    state.alerts.unshift(alert)
  },
  removeAlert (state, { id }) {
    state.alerts = state.alerts.filter(alert => alert.id !== id)
  },
  removeResearcherAlert (state, { id }) {
    state.researchers = state.researchers.filter(alert => alert.slug_values.indexOf(id) === -1)
  },
  removeResearchGroupAlert (state, { id }) {
    state.researchGroups = state.researchGroups.filter(alert => alert.slug_values.indexOf(id) === -1)
  },
  removeOrganisationAlert (state, { id }) {
    state.organisations = state.organisations.filter(alert => alert.slug_values.indexOf(id) === -1)
  },
  gotAlerts (state) {
    state.gotAlerts = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
