<template>
  <div class="">
    <h2 class="t-primary t-400 mb-0">Change permission for {{ getFullName(user) }}</h2>
    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <!-- render form -->
    <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <radio-button :name="`role`"
        v-if="canView(3)"
        :value="'3'"
        :valueText="'Owner'"
        :isActive="getActive(3)"
        :description="'User can invite users, remove users, accept invitations and edit the settings '"
        @input="handleRadioButton">
      </radio-button>

      <radio-button :name="`role`"
        v-if="canView(2)"
        :value="'2'"
        :valueText="'Admin'"
        :isActive="getActive(2)"
        :description="'User can invite users, remove users, accept invitations'"
        @input="handleRadioButton">
      </radio-button>

      <radio-button :name="`role`"
        v-if="canView(1)"
        :value="'1'"
        :valueText="'Member'"
        :isActive="getActive(1)"
        :description="'User doesn\'t have rights to change important information'"
        @input="handleRadioButton">
      </radio-button>

      <!-- action buttons -->
      <form-buttons :isRequest="isRequest"
        :isEditMode="true"
        :isCreateMode="false"
        @cancel="$events.$emit('modal:close')">
      </form-buttons>
    </form>
  </div>
</template>

<script>
import RadioButton from '@/components/shared/form/RadioButton'
import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'

import CommunityService from '@/services/community'
import ResearchGroupService from '@/services/research_group'

export default {
  name: 'permissions',
  components: {
    RadioButton,
    StaticMessages,
    FormButtons
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    community: {
      type: Object,
      required: true
    },
    researchGroup: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      error: null,
      isRequest: false,
      selectedRole: 0
    }
  },
  created () {
    this.$validator.attach({ name: 'selectedRole', rules: 'required', alias: '' })
  },
  computed: {
    rw_access () {
      return this.researchGroup && this.researchGroup.slug_values ? this.researchGroup.rw_access : this.community.rw_access
    }
  },
  methods: {
    canView (role) {
      let userRole = this.$can.roles().find(r => r.value === this.rw_access)
      return (role <= userRole.key)
    },
    getActive (key) {
      return key === this.user.role.id
    },
    handleRadioButton (checked, value) {
      this.selectedRole = parseInt(value)
    },
    validateBeforeSubmit () {
      this.$validator.validateAll({
        selectedRole: this.selectedRole
      }).then((result) => {
        if (result && !this.isRequest) {
          this.error = null
          this.isRequest = true
          this.community.name ? this.updateRoleForCommunity() : this.updateRoleForResearchGroup()
        } else {
          this.error = 'Please select a role'
        }
      })
    },
    updateRoleForCommunity () {
      CommunityService.updateMember(this.community.slug_values[0], {
        slug: this.user.slug_values[0],
        role_id: this.selectedRole
      }).then(member => {
        this.$events.$emit('community:permissions:updated', member, this.user)
        this.$events.$emit('modal:close')
        this.isRequest = false
      }).catch(err => {
        this.isRequest = false
        this.error = this.renderError(err)
      })
    },
    updateRoleForResearchGroup () {
      ResearchGroupService.updateMember(this.researchGroup.slug_values[0], {
        slug: this.user.slug_values[0],
        role_id: this.selectedRole
      }).then(member => {
        this.$events.$emit('research_group:permissions:updated', member, this.user)
        this.$events.$emit('modal:close')
        this.isRequest = false
      }).catch(err => {
        this.isRequest = false
        this.error = this.renderError(err)
      })
    }
  }
}
</script>
