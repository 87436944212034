<template>
  <div class="">
    <h2 class="t-primary t-400 mb-0" v-if="!isEditMode">Ask a new question</h2>
    <h2 class="t-primary t-400 mb-0" v-if="isEditMode">Edit {{ question.title }}</h2>
    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <!-- <div v-if="!isEditMode" class="mb-4"> -->
      <!-- <p class="t-lr mb-2 t-primary t-600">
        Tips on getting good answers quickly
      </p>
      <ul class="list-unstyled t-primary t-500 ml-3">
        <li>
          <i class="fa fa-check t-green pr-2"></i>Make sure your question hasn't been asked already
        </li>
        <li>
          <i class="fa fa-check t-green pr-2"></i>Keep your question short and to the point
        </li>
        <li>
          <i class="fa fa-check t-green pr-2"></i>Double-check grammar and spelling
        </li>
      </ul>
    </div> -->

    <!-- render form -->
    <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <div class="form-group mb-4" :class="{'has-icon-label': errors.has('title') }">
        <label class="t-500 t-sm label-required">
          {{ placeholderText }}
        </label>
        <input
          type="text"
          class="form-control"
          placeholder="What is your question?"
          name="title"
          v-model="question.title"
          :class="{'is-invalid': errors.has('title') }"
        />
        <i v-show="errors.has('title')" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('title')">{{ errors.first('title') }}</span>
      </div>

      <!-- input tags -->
      <div class="form-group mb-4" :class="{'has-icon-label': errors.has('tags') }">
        <label class="t-500 t-sm">
          Tags
          <i class='fa fa-info-circle ml-1 t-sm t-secondary' v-tooltip.top="`Tag your question, at least one, max 5 tags`"></i>
        </label>

        <input-tag :tags.sync="tags"
          :limit="tagLimit"
          :class="{'is-invalid': errors.has('tags')}"
          placeholder="Tag your question, at least one, max 5 tags">
        </input-tag>

        <i v-show="errors.has('tags') || tagLimit === tags.length" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('tags')">{{ errors.first('tags') }}</span>
        <span v-if="tagLimit === tags.length" class="t-regular d-inline-block t-sm mt-2 pl-2">Maximum tags reached</span>
      </div>

      <!-- content -->
      <div class="form-group" :class="{'has-icon-label': errors.has('content'), 'mb-0': !isDescription, 'mb-4': isDescription }">
        <label class="t-500 t-sm is-hover" @click.prevent="isDescription = !isDescription" :class="{'t-blue': !isDescription}">Optional: Add a description</label>

        <rich-text-editor v-if="isDescription"
           v-model="question.content"
          :class="{'is-invalid': errors.has('content') }"
          :placeholder="placeholderTextDescription"
          :content="question.content">
        </rich-text-editor>

        <i v-show="errors.has('content')" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('content')">{{ errors.first('content') }}</span>
      </div>

      <!-- private or not -->
      <div class="form-group mb-4 mt-0">
        <switches v-model="isPublic"
          :text-enabled="`Public: Question is visible both for community members and external visitors (unless the admin ensured questions are for members-only)`"
          :text-disabled="`Private: Question is only visible to members of the community`"
        ></switches>
      </div>

      <!-- action buttons -->
      <form-buttons :isRequest="isRequest"
        :isEditMode="isEditMode"
        :isCreateMode="isCreateMode"
        :isRemove="isEditMode"
        :createText="`Create`"
        @remove="handleRemove()"
        @cancel="$events.$emit('modal:close')">
      </form-buttons>
    </form>
  </div>
</template>

<script>
import Switches from '@/components/shared/Switches'
import StaticMessages from '@/components/shared/StaticMessages'
import InputTag from '@/components/shared/form/InputTag'
import FormButtons from '@/components/shared/form/Buttons'
import RichTextEditor from '@/components/shared/form/RichTextEditor'

import CommunityService from '@/services/community'

export default {
  name: 'questionCreate',
  components: {
    Switches,
    StaticMessages,
    InputTag,
    FormButtons,
    RichTextEditor
  },
  props: {
    question: {
      type: Object,
      default: () => ({
        title: '',
        content: '',
        tags: []
      })
    },
    community: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isRequest: false,
      isEditMode: false,
      isCreateMode: false,
      isDescription: false,
      error: null,
      isPublic: true,
      tags: [],
      tagLimit: 5,
      placeholderText: `Start your question with "What", "How", "Why", etc.`,
      placeholderTextDescription: `Explain your question a bit more, but keep it as short as possible.`
    }
  },
  created () {
    if (!this.$currentUser) {
      this.$events.$emit('modal:close')
      return this.$router.push({ name: 'community.questions', params: { id: this.community.slug_values[0] } })
    }

    this.$validator.attach({ name: 'tags', rules: '', alias: 'tags' })
    this.$validator.attach({ name: 'content', rules: 'min:10', alias: 'content' })
    this.$validator.attach({ name: 'title', rules: 'required|min:5', alias: 'title' })

    this.preFill()
  },
  watch: {
    'question.title' (value) {
      this.$validator.validate('title', value)
    },
    'question.content' (value) {
      this.$validator.validate('content', this.stripTags(value))
    },
    'tags': {
      handler (value) {
        this.$validator.validate('tags', value)
      },
      deep: true
    }
  },
  methods: {
    preFill () {
      !this.isEmpty(this.question.title) ? this.isEditMode = true : this.isCreateMode = true
      this.isEmpty(this.question.tags) ? this.question.tags = [] : this.tags = this.question.tags
      if (!this.isEmpty(this.question.description)) this.isDescription = true
    },
    validateBeforeSubmit () {
      if (!this.question.tags) this.question.tags = []
      this.question.tags = this.tags

      this.$validator.validateAll({
        tags: this.tags,
        title: this.question.title,
        content: this.stripTags(this.question.content)
      }).then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true
          this.isEditMode ? this.handleEdit() : this.handleCreate()
        }
      })
    },
    handleCreate () {
      this.question.is_public = this.isPublic
      CommunityService.createQuestion(this.community.slug_values[0], this.question).then(question => {
        this.isRequest = false

        let questionCount = this.community.questions + 1
        this.$events.$emit('community:updated', 'questions', questionCount)

        this.$events.$emit('modal:close')
        this.$router.push({ name: 'community.questions.question', params: { id: this.community.slug_values[0], question: question.id } })
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleEdit () {
      this.question.is_public = this.isPublic
      CommunityService.updateQuestion(this.community.slug_values[0], this.question).then(question => {
        this.isRequest = false

        this.$events.$emit('community:questions:updated', question)
        this.$events.$emit('modal:close')
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleRemove () {
      if (!this.isRequest) {
        this.isRequest = true
        CommunityService.removeQuestion(this.community.slug_values[0], this.question.id).then(() => {
          let questionCount = this.community.questions - 1
          this.$events.$emit('community:updated', 'questions', questionCount)
          this.$router.replace({ name: 'community.questions', params: { id: this.community.slug_values[0] } })
          this.$events.$emit('modal:close')
          this.isRequest = false
        }).catch(err => {
          this.isRequest = false
          this.error = this.handleError(err)
        })
      }
    }
  }
}
</script>
