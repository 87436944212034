<template>
  <div class="change-account" :class="{'min-height--500px': !isInfoText}">
    <h2 class="t-primary t-400 mb-0">Get verified as a {{ user.category.name | lowercase }}</h2>

   <div v-if="!isEmpty(success)">
      <static-messages :messages="success" :variant="`success`" class="mt-3" />
      <button type="button" class="btn btn-secondary pull-right mt-3" @click="$events.$emit('modal:close')">Close</button>
    </div>

    <div v-if="isLoaded && isEmpty(success)">
      <div v-if="isShowPending">
        <p class="t-sm my-3 text-left">
          <i class="fa fa-info-circle mr-2 t-secondary"></i><strong>Choose one of the following options.</strong> Getting verified depends on your {{ textOrganisation }} and your matching email address.
        </p>
        <hr class="hr-small mb-4" />

        <!-- pending organisations -->
        <div class="row">
          <div class="col-md-6">
            <div class="card min-height--100per">
              <div class="card-body py-3">
                <p class="t-500 text-center info-text">
                  Let AcademicLabs do the work for you.
                </p>

                <p class="text-center">
                  <a href="#" @click.prevent="handleOption('pending')" class="btn btn-bottom btn-primary--green px-4 py-2 bd-radius t-700 mt-3">
                    Confirm {{ textOrganisation }}
                  </a>
                </p>
              </div>
            </div>
          </div>

          <!-- find on email address -->
          <div class="col-md-6">
            <div class="card min-height--100per">
              <div class="card-body py-3">
                <p class="t-500 text-center info-text">
                  Join an {{ textOrganisation }} that matches your email address.
                </p>

                <p class="text-center">
                  <a href="#" @click.prevent="handleOption('email')" class="btn btn-primary btn-bottom px-4 py-2 bd-radius t-700 mt-3">
                    Find my {{ textOrganisation }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- search for organisation by email -->
      <div v-if="isJoinByEmail">
        <organisation-join-by-email :user="user"
          :isModal="true"
          :researcher="researcher"
          @skip="handleOrganisationJoinedByEmailNotFound"
          @showInfo="isInfoText = true"
          @hideInfo="isInfoText = false"
          class="mt-4">
        </organisation-join-by-email>

        <template v-if="isInfoText">
          <or-separator />
          <p class="my-3 text-center">
            Don't have another email?
          </p>
          <p class="text-center">
            <a href="#" @click.prevent="toggleOrganisation()" class="btn btn-secondary--grey  mx-auto">Search for an organisation</a>
          </p>
        </template>
      </div>

      <!-- accept organisation requests -->
      <div v-if="isOrganisationRequest">
        <p class="t-sm my-3 text-left">
          <i class="fa fa-info-circle mr-2 t-secondary"></i>Accept or decline the following requests.
        </p>
        <hr class="hr-small mb-4" />

        <organisation-request-accept :user="user"
          :researcher="researcher"
          @accepted="showPending"
          @declined="showPending">
        </organisation-request-accept>
      </div>

      <template v-if="isOrganisationCreate">
        <!-- create or join an organisation -->
        <organisation-create :isWithoutTitle="true"
          :user="user"
          :researcher="researcher"
          @created="organisationCreated"
          @joined="organisationJoined"
          @showInfo="isInfoText = true"
          @noResults="isInfoText = false"
          @hideInfo="isInfoText = false"
          :class="'mt-4'">
        </organisation-create>

        <template v-if="isInfoText">
          <or-separator />
          <p class="my-3 text-center">
            Don't find your organisation?
          </p>
          <p class="text-center">
            <a href="#" @click.prevent="toggleOrganisation()" class="btn btn-secondary--grey  mx-auto">Add a secondary email address</a>
          </p>
        </template>
      </template>
    </div>

    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import OrganisationRequestAccept from '@/components/organisation_request/Accept'
import OrganisationCreate from '@/components/organisation/Create'
import OrganisationJoinByEmail from '@/components/organisation/JoinByEmail'
import StaticMessages from '@/components/shared/StaticMessages'
import OrSeparator from '@/components/login/OrSeparator'
import Spinner from '@/components/shared/Spinner'

import UserService from '@/services/user'
import SharedService from '@/services/shared'

export default {
  name: 'verifyOptions',
  components: {
    OrganisationRequestAccept,
    OrganisationCreate,
    StaticMessages,
    OrganisationJoinByEmail,
    OrSeparator,
    Spinner
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    researcher: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOrganisationRequest: false,
      isOrganisationCreate: false,
      isShowPending: false,
      isJoinByEmail: false,
      isHandledByUser: false,
      isNotFoundByEmail: false,
      isLoaded: false,
      isInfoText: true,
      success: '',
      textOrganisation: 'organisation'
    }
  },
  created () {
    this.fetch()
    if (this.isValidAcademicCategory(this.user.category.name)) this.textOrganisation = 'institution'
  },
  methods: {
    fetch () {
      UserService.getOrganisationRequests(this.$currentUser.user_id).then(requests => {
        this.showPending(requests.length)
      }).catch(err => {
        this.isLoaded = !this.isEmpty(this.error)
        this.error = this.handleError(err)
      })
    },
    handleOption (option) {
      this.isJoinByEmail = this.isOrganisationRequest = this.isOrganisationCreate = this.isNotFoundByEmail = this.isShowPending = false

      if (option === 'email') this.isJoinByEmail = true
      if (option === 'pending') this.isOrganisationRequest = true
      if (option === 'organisation') this.isOrganisationCreate = true
    },
    showPending (availableOrganisations) {
      this.isShowPending = false
      if (availableOrganisations > 0) this.isShowPending = true

      if (this.$currentUser.verified && !this.isShowPending) {
        this.isOrganisationRequest = false
        this.success = `You have successfully verified and accepted all your organisation requests.`
        this.isLoaded = true
      } else if (!this.$currentUser.verified && !this.isShowPending) {
        this.checkDomain()
      } else {
        this.isLoaded = true
      }
    },
    checkDomain () {
      SharedService.domains(this.$currentUser.email).then(domain => {
        this.isLoaded = true
        domain.public_domain ? this.isJoinByEmail = true : this.isOrganisationCreate = true
      }).catch(err => {
        this.isLoaded = !this.isEmpty(this.error)
        this.error = this.handleError(err)
      })
    },
    toggleOrganisation () {
      this.isJoinByEmail = !this.isJoinByEmail
      this.isOrganisationCreate = !this.isOrganisationCreate
    },
    organisationCreated () {
      this.isHandledByUser = true
    },
    organisationJoined (confirmed) {
      this.isHandledByUser = true
    },
    handleOrganisationJoinedByEmailNotFound () {
      this.isJoinByEmail = false
      this.isOrganisationCreate = true
      this.isNotFoundByEmail = true
    }
  }
}
</script>
