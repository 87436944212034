<template>
  <label class="clearfix mt-2 mb-0" :class="classObject">
    <input type="checkbox" :disabled="disabled" @change="trigger" :checked="value">
    <span class="switches_style pull-left" :class="{' switches_style--light': isLight }"></span>
    <span class="switches_label pull-left ml-3" v-if="shouldShowLabel">
      <span v-if="label" v-html="label"></span>
      <span v-if="!label && value" v-html="textEnabled"></span>
      <span v-if="!label && !value" v-html="textDisabled"></span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'switches',
  props: {
    value: {
      default: false
    },
    disabled: {
      default: false
    },
    label: {
      default: ''
    },
    textEnabled: {
      default: ''
    },
    textDisabled: {
      default: ''
    },
    emitOnMount: {
      default: true
    },
    isLight: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    if (this.emitOnMount) this.$emit('input', this.value)
  },
  methods: {
    trigger (e) {
      this.$emit('input', e.target.checked)
    }
  },
  computed: {
    classObject () {
      const { value, disabled } = this

      return {
        'switches': true,
        'switches_unchecked': !value,
        'switches_disabled': disabled
      }
    },
    shouldShowLabel () {
      return this.label !== '' || this.textEnabled !== '' || this.textDisabled !== ''
    }
  }
}
</script>
