<template>
  <div :class="classObject" :id="`typeahead-${name}`">
    <v-popover
      container="body"
      :boundariesElement="attachedToBody ? '' :`typeahead-${name}`"
      :autoHide="false"
      trigger="manual"
      popoverBaseClass="no-padding dd-wrapper tooltip"
      popoverInnerClass="typeahead-inner"
      popoverArrowClass="no-arrow"
      :open="isOpen"
      placement="bottom-end"
    >
      <div class="position-relative">
        <div v-if="!isElasticSearchAutocomplete" class="input-group-addon flat-addon" :class="customInputClass">
          <i v-if="!isSearching" class="fa fa-search t-secondary" ></i>
          <i class="fa fa-spinner fa-spin t-secondary" v-else></i>
        </div>

        <input type="text"
          ref="typeahead"
          :disabled="disabled"
          :class="classObjectInput"
          v-model="q"
          :placeholder="placeholder"
          @keydown.down="keyIsDown"
          @keydown.up="keyIsUp"
          @keydown.enter.prevent="keyIsEnter"
          @keydown.esc="reset"
          @blur="reset"
        >
      </div>

      <!-- This will be the content of the popover -->
      <template slot="popover">
        <ul class="list-group list-group-typeahead" :class="`${customDropdownClass}`">
          <li class="list-group-item typeahead-item"
            v-for="(result, index) in sortedResults"
            :key="index"
            :class="activeClass(index)"
            @mousedown="selectItem(result)"
            @mousemove="setActive(index)"
            v-html="parseResult(result)"
          ></li>

          <li v-if="isNoResults && (!canAdd || (canAdd && q.length <= canAddMinLength))" class="list-group-item typeahead-item font-italic">
            No results...
          </li>

          <li v-if="canAdd && q.length > canAddMinLength"
            @mousedown="addItem()"
            class="list-group-item typeahead-item-add t-sm t-600 t-blue text-right"
            :class="activeClass(results.length + 1)"
          ><i class="fa fa-plus pr-2 t-sm "></i>{{ canAddPlaceholder }}</li>

        </ul>
      </template>
    </v-popover>
  </div>
</template>

<script>
import SearchService from '@/services/search'
import SharedService from '@/services/shared'
import OrganisationService from '@/services/organisation'

import debounce from '@/mixins/debounce'
import axios from 'axios'

export default {
  name: 'typeahead',
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    displayKey: {
      type: String,
      default: ''
    },
    remote: {
      type: String,
      default: ''
    },
    highlight: {
      type: Boolean,
      default: true
    },
    emitOnBlur: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    minLength: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    placeholder: {
      type: String,
      default: ''
    },
    clearOnSelect: {
      type: Boolean,
      default: true
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    canAdd: {
      type: Boolean,
      default: false
    },
    canAddPlaceholder: {
      type: String,
      default: 'Add'
    },
    canAddMinLength: {
      type: Number,
      default: 2
    },
    customClass: {
      type: String,
      default: ''
    },
    customInputClass: {
      type: String,
      default: ''
    },
    customDropdownClass: {
      type: String,
      default: ''
    },
    isElasticSearch: {
      type: Boolean,
      default: false
    },
    isElasticSearchOrganisation: {
      type: Boolean,
      default: false
    },
    isElasticSearchAutocomplete: {
      type: Boolean,
      default: false
    },
    elasticState: {
      type: Object,
      default: () => ({})
    },
    attachedToBody: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      q: '',
      results: [],
      isOpen: false,
      isSearching: false,
      isNoResults: false,
      isNoRequest: false,
      current: -1,
      cancelToken: axios.CancelToken,
      cancelTokenSource: ''
    }
  },
  mounted () {
    if (this.value) this.setValue()
  },
  watch: {
    q (newVal, oldVal) {
      this.$emit('keyIsDown', this.q)
      if (newVal !== oldVal) this.fetchData()
    },
    value (newVal, oldVal) {
      if (newVal !== oldVal) this.setValue()
    },
    focus (newVal, oldVal) {
      if (newVal !== oldVal && newVal) this.$nextTick(() => this.$refs.typeahead.focus())
    }
  },
  computed: {
    classObject () {
      return this.customClass
        ? `typeahead-wrapper ${this.customClass}`
        : 'typeahead-wrapper mt-3'
    },
    classObjectInput () {
      return `${this.customInputClass || ''} form-control`
    },
    sortedResults () {
      if (this.results && this.results.length > 0 && this.results[0].hasOwnProperty('count')) {
        const sortedResults = [...this.results]
        return sortedResults.sort((a, b) => b.count - a.count)
      }

      return this.results
    }
  },
  methods: {
    fetchData: debounce(function () {
      if (this.q.length >= this.minLength && !this.isNoRequest) {
        this.isSearching = true
        this.isNoResults = false
        if (this.isElasticSearch) this.fetchEs()
        if (this.isElasticSearchOrganisation) this.fetchEsOrganisation()
        if (this.isElasticSearchAutocomplete) this.fetchEsAutocomplete()
        if (!this.isElasticSearchAutocomplete && !this.isElasticSearch && !this.isElasticSearchOrganisation) this.fetchPostGres()
      } else {
        this.isNoRequest = false
      }
    }, 150),
    parseResult (data) {
      return this.highlight
        ? data instanceof Object ? this.$options.filters.capitalize(this.getValue(data)) : this.setHighlight(data)
        : data instanceof Object ? this.$options.filters.capitalize(this.getValue(data)) : this.$options.filters.capitalize(data)
    },
    getValue (data) {
      let name = data[this.displayKey]
      if (this.highlight) name = this.setHighlight(name)

      return data.hasOwnProperty('count')
        ? `${name} <span class='t-secondary t-sm'>(${data.count})</span>`
        : name
    },
    fetchPostGres () {
      SharedService.typeahead(this.remote, this.q, this.limit).then(results => {
        if (results) this.handleResponse(results)
      }).catch(() => {
        if (this.isOpen) this.close()
      })
    },
    fetchEs () {
      let filters = Object.assign({}, this.elasticState)
      filters.typeahead = {
        name: this.remote,
        keyword: this.q,
        limit: this.limit
      }

      if (!this.isEmpty(this.cancelTokenSource)) this.cancelTokenSource.cancel('User navigated to different page')
      this.cancelTokenSource = this.cancelToken.source()

      SearchService.typeahead(filters, this.cancelTokenSource.token).then(results => {
        if (results) this.handleResponse(results)
      }).catch(() => {
        if (this.isOpen) this.close()
      })
    },
    fetchEsOrganisation () {
      OrganisationService.typeahead(this.q, this.limit).then(results => {
        if (results) this.handleResponse(results)
      }).catch(() => {
        if (this.isOpen) this.close()
      })
    },
    fetchEsAutocomplete () {
      let state = Object.assign({}, this.elasticState)
      state.keyword = this.q

      SearchService.autocomplete(state).then(results => {
        if (results) this.handleResponse(results)
      }).catch(() => {
        if (this.isOpen) this.close()
      })
    },
    handleResponse (results) {
      this.open()
      this.isSearching = false
      this.results = results

      if (results.length === 0) {
        this.isSearching = false
        this.isNoResults = true
        this.$emit('noResults')
      }
    },
    setHighlight (value) {
      let splitString = this.q.split(' ')
      let highlightString = ''

      for (let i = 0; i < splitString.length; i++) {
        let iQuery = new RegExp(splitString[i], 'ig')
        if (splitString[i].length > 0) {
          highlightString = value.toString().replace(iQuery, (matchedTxt) => {
            return ('<strong>' + matchedTxt + '</strong>')
          })
        }
      }

      return highlightString.length <= 0 ? value : highlightString
    },
    addItem () {
      this.$emit('add', this.name, this.q.trim(), this.canAddMinLength)
      this.close()
    },
    selectItem (item) {
      this.$emit('selected', this.name, item)
      this.close()
    },
    close () {
      this.isOpen = false
      this.current = -1
      this.$emit('closed')
    },
    open () {
      this.isOpen = true
      this.$emit('opened')
    },
    setActive (index) {
      this.current = index
    },
    activeClass (index) {
      return {
        isActive: this.current === index
      }
    },
    keyIsUp () {
      if (this.current > 0) {
        this.current--
      } else if (this.current === -1) {
        this.current = this.results.length - 1
      } else {
        this.current = -1
      }
    },
    keyIsDown () {
      this.current < this.results.length - 1
        ? this.current++
        : this.current = -1
    },
    keyIsEnter () {
      // user pushed enter, did not select anything + is only for elasticsearch
      if (!this.results[this.current] && this.isElasticSearchAutocomplete) {
        this.selectItem(this.q)
        this.close()
      }

      // selected with results
      if (this.current !== -1 && !this.isNoResults) this.selectItem(this.results[this.current])

      // enter with no results on canAdd
      if (this.current === -1 && this.isNoResults && this.canAdd) this.addItem()
    },
    setValue () {
      this.q = this.value
      this.isNoRequest = true
    },
    reset () {
      if (!this.isNoRequest) {
        if (this.emitOnBlur) this.$emit('blurred', this.name, this.q, this.canAddMinLength)

        this.clearOnSelect ? this.q = '' : this.setValue()
        this.$emit('closed')
        this.results = []
        this.isOpen = false
        this.isSearching = false
        this.isNoResults = false
        this.current = -1
      }
    }
  }
}
</script>
