  <template>
  <div class="">
    <h2 class="t-primary t-400 mb-0">Edit your profile</h2>
    <hr class="hr-small mb-4"/>

    <!-- render static messages -->
    <static-messages v-if="success" :messages="success" :variant="`success`" :delay="6000" @closed="success = null"/>
    <static-messages v-if="error" :messages="error" :variant="`danger`" :delay="6000" @closed="error = null"/>

    <!-- render profile form -->
    <form @submit.prevent="validateBeforeSubmit" class="mt-4" v-if="isInitialized" autocomplete="off">
      <!-- first_name && last_name -->
      <div class="form-group row mb-4" >
        <div class="col-md-6" :class="{'has-icon-inline-label': errors.has('create.first_name')}">
          <label class="t-500 t-sm label-required">First name</label>
          <input
            ref="first_name"
            type="text"
            class="form-control"
            placeholder="First name"
            name="create.first_name"
            tabindex="1"
            v-model="create.first_name"
            v-validate="'required|min:2'"
            data-vv-delay="1000"
            data-vv-as="first name"
            :class="{'is-invalid': errors.has('create.first_name')}"
          />

          <i v-show="errors.has('create.first_name')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.first_name')">{{ errors.first('create.first_name') }}</span>
        </div>

        <div class="col-md-6" :class="{'has-icon-inline-label': errors.has('create.last_name')}">
          <label class="t-500 t-sm label-required">Last name</label>
          <input
            type="text"
            ref="last_name"
            class="form-control"
            placeholder="Last name"
            name="create.last_name"
            tabindex="2"
            v-model="create.last_name"
            v-validate="'required|min:2'"
            data-vv-delay="1000"
            data-vv-as="last name"
            :class="{'is-invalid': errors.has('create.last_name')}"
          />

          <i v-show="errors.has('create.last_name')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.last_name')">{{ errors.first('create.last_name') }}</span>
        </div>
      </div>

      <!-- academic_title && function_title-->
      <div class="form-group row mb-4">
        <div class="col-md-6" :class="{'has-icon-inline-label': errors.has('create.academic_title')}">
          <label class="t-500 t-sm">Academic title</label>
          <input
            ref="academic_title"
            type="text"
            class="form-control"
            placeholder="Academic Title"
            name="create.academic_title"
            tabindex="3"
            v-model="create.academic_title"
            v-validate="'min:2'"
            data-vv-delay="1000"
            data-vv-as="academic title"
            :class="{'is-invalid': errors.has('create.academic_title')}"
          />

          <i v-show="errors.has('create.academic_title')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.academic_title')">{{ errors.first('create.academic_title') }}</span>
        </div>

        <div class="col-md-6" >
          <label class="t-500 t-sm">Function title</label>
          <!-- render typeahead -->
          <typeahead :remote="functionTitleEndpoint"
            :name="functionTitleEndpoint"
            :value="create.function_title.name"
            :displayKey="functionTitleDisplayKey"
            :focus="isFocusFunctionTitle"
            :limit="15"
            :emitOnBlur="true"
            :canAdd="true"
            :clearOnSelect="false"
            :tabindex="`4`"
            :canAddPlaceholder="`Add a new position`"
            :placeholder="`Search for a function title`"
            :customClass="`mt-0`"
            @selected="handleTypeaheadFunctionTitle"
            @add="handleAddTypeahead"
            @blurred="handleBlurTypeahead"
            @opened="$emit('opened')"
            @closed="$emit('closed')"
          ></typeahead>
        </div>
      </div>

      <!-- country && gender-->
      <div class="form-group row mb-4">
        <div class="col-md-6">
          <label class="t-500 t-sm">Country</label>

          <!-- render typeahead -->
          <typeahead :remote="countryEndpoint"
            :name="countryEndpoint"
            :value="create.country_name"
            :displayKey="countryDisplayKey"
            :focus="isFocusCountry"
            :limit="5"
            :clearOnSelect="false"
            :tabindex="`7`"
            :placeholder="`Country`"
            :customClass="`mt-0`"
            @selected="handleTypeaheadCountry"
            @opened="$emit('opened')"
            @closed="$emit('closed')"
          ></typeahead>
        </div>

        <div class="col-md-6">
          <label class="t-500 t-sm">
            Gender
            <i class='fa fa-info-circle ml-1 t-sm t-secondary' v-tooltip.top="`Many users have asked us to include a filter on gender to make it easier to find someone specific or build gender-balanced consortia.`"></i>
          </label>
          <label class="select-label">
            <select class="form-control" tabindex="8" v-model="create.gender" @change="handleGender(create.gender)">
              <option v-if="!create.gender_name" value="null" selected disabled hidden>Choose here</option>
              <option v-for="(gender, index) in genders" :key="index" :value="gender.id" class="t-sm">
                {{ gender.name }}
              </option>
            </select>
          </label>
        </div>
      </div>

      <form-buttons :isCreateMode="false"
        :isEditMode="true"
        :isRequest="isRequest"
        :containerClass="`pt-3`"
        @cancel="$events.$emit('modal:close')">
      </form-buttons>
    </form>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import Typeahead from '@/components/shared/form/Typeahead'

import staticData from '@/assets/js/_data'
import ResearcherService from '@/services/researcher'
import FunctionTitleService from '@/services/function_title'
import FormButtons from '@/components/shared/form/Buttons'

export default {
  name: 'userEdit',
  components: {
    StaticMessages,
    Typeahead,
    FormButtons
  },
  props: {
    focusOn: {
      type: String,
      required: false,
      default: 'first_name'
    },
    researcher: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isRequest: false,
      isInitialized: false,
      isFocusFunctionTitle: false,
      isFocusCountry: false,
      error: null,
      success: null,
      functionTitleEndpoint: 'function_title',
      functionTitleDisplayKey: 'name',
      countryEndpoint: 'country',
      countryDisplayKey: 'name',
      create: {
        first_name: '',
        last_name: '',
        academic_title: '',
        function_title_id: '',
        country_id: '',
        country_name: '',
        gender: '',
        gender_id: '',
        gender_name: ''
      }
    }
  },
  mounted () {
    this.preFill()
  },
  computed: {
    genders () {
      return staticData.gender
    }
  },
  methods: {
    preFill () {
      this.create.first_name = this.stripTags(this.researcher.first_name)
      this.create.last_name = this.stripTags(this.researcher.last_name)
      this.create.academic_title = this.researcher.academic_title
      // this.create.website = this.researcher.website
      // this.create.phone_number = this.researcher.phone_number
      // this.create.gender = this.researcher.gender

      this.researcher.function_title
        ? this.$set(this.create, 'function_title', this.researcher.function_title)
        : this.$set(this.create, 'function_title', { id: null, name: '' })
      this.create.function_title_id = this.create.function_title.id

      this.researcher.gender
        ? this.$set(this.create, 'gender', this.researcher.gender.id)
        : this.$set(this.create, 'gender', { id: null, name: '' })
      this.create.gender_id = this.create.gender.id

      if (this.researcher.country) this.create.country_id = this.researcher.country.id
      if (this.researcher.country) this.create.country_name = this.researcher.country.name

      this.isInitialized = true

      this.$nextTick(() => {
        if (this.$refs[this.focusOn]) this.$refs[this.focusOn].focus()
        if (this.focusOn === 'function_title') this.isFocusFunctionTitle = true
        if (this.focusOn === 'country') this.isFocusCountry = true
      })
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true

          // capitalize
          this.create.first_name = this.$options.filters.capitalize(this.create.first_name)
          this.create.last_name = this.$options.filters.capitalize(this.create.last_name)

          ResearcherService.update(this.$currentUser.slug_values[0], this.create).then(researcher => {
            for (let key in researcher) this.$events.$emit('researcher:updated', `${key}`, researcher[key])
            // Trigger update in header dropdown
            this.$events.$emit('navbar:researcher:update:full_name', researcher.full_name)
            // Update $currentUser object
            this.$auth.updateCurrentUser({
              full_name: researcher.full_name
            })

            this.$events.$emit('modal:close')
            this.isRequest = false
          }).catch(err => {
            this.isRequest = false
            this.error = this.handleError(err)
          })
        }
      })
    },
    handleTypeaheadFunctionTitle (name, item) {
      this.setFunctionTitleValue(item)
    },
    handleTypeaheadCountry (name, item) {
      this.$set(this.create, 'country_id', item.id)
      this.$set(this.create, 'country_name', item.name)
    },
    handleGender (id) {
      this.$set(this.create, 'gender_id', id)
    },
    handleAddTypeahead (name, item, minLength) {
      // Check for min length
      if (item.length < minLength) {
        this.error = `Function title must be at least ${minLength} characters.`
      } else {
        FunctionTitleService.create({ name: item }).then(title => {
          this.setFunctionTitleValue(title)
          this.success = `Function title '${title.name}' added`
        }).catch(err => {
          if (!err.message) this.error = this.renderError(err)
        })
      }
    },
    handleBlurTypeahead (name, item) {
      if (!item) this.setFunctionTitleValue({ id: null, name: '' })
    },
    setFunctionTitleValue (item) {
      this.create.function_title_id = item.id
      this.$set(this.create, 'function_title', item)
    }
  }
}
</script>
