import am5themesAnimated from '@amcharts/amcharts5/themes/Animated'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

export default {
  props: {
    minYear: {
      type: Number,
      default: 2005
    }
  },
  data () {
    return {
      chartId: Math.random().toString(36).slice(2, 7)
    }
  },
  beforeDestroy () {
    if (this.root) this.root.dispose() // Clean up charts
  },
  methods: {
    /**
     * @description Create new root object, attached to the div with id `chartId`, and set it on `this`
     */
    initRoot () {
      if (this.root) this.root.dispose()
      this.root = am5.Root.new(this.chartId, {
        tooltipContainerBounds: {
          top: 100,
          right: 5,
          bottom: 10,
          left: 200
        }
      })
      return this.root
    },
    /**
     * @description Apply default axis styles to the root element
     */
    setAxisStyle (root) {
      // Define standard axis theme, shared by (most) charts
      const myTheme = am5.Theme.new(root)
      myTheme.rule('Label', ['axis']).setAll({
        fill: am5.color(0x656f78),
        fontSize: '11px'
      })
      myTheme.rule('Label', ['axis', 'x']).setAll({
        rotation: -45,
        dx: -20
      })

      root.setThemes([
        myTheme,
        am5themesAnimated.new(root)
      ])
    },
    /**
     * @description Init standard XY chart element
     * @returns {chart} chart Object
     */
    initChartElement (root) {
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          zoomY: false,
          zoomX: false,
          layout: root.verticalLayout,
          paddingBottom: 0
        })
      )
      chart.zoomOutButton.set('forceHidden', true)

      return chart
    },
    /**
     * @description Generate default y-axis, possibly with a 'synched' invertedYaxis.
     * @returns {(Object|[Object, Object])} Either a single yAxis, or an array with 2 yAxises.
     */
    generateYAxis ({
      root,
      chart,
      withInvertedAxis = false
    }) {
      // Create Y-axis
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            minGridDistance: 40
          }),
          numberFormat: '#.#a',
          maxPrecision: 0,
          min: 0
        })
      )

      if (withInvertedAxis) {
        // Create Y-axis-opposite
        const yAxisInverted = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            syncWithAxis: yAxis,
            renderer: am5xy.AxisRendererY.new(root, {
              opposite: true,
              minGridDistance: 40
            }),
            numberFormat: '#.#a',
            maxPrecision: 0,
            min: 0
          })
        )

        yAxis.get('renderer').grid.template.set('visible', false)
        yAxisInverted.get('renderer').grid.template.set('visible', true)

        return [yAxis, yAxisInverted]
      }

      return yAxis
    },
    generateHiddenYAxis ({
      root,
      chart
    }) {
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
          strictMinMax: true,
          min: 0
          // extraMax: 0.05
        })
      )
      yAxis.gridContainer.hide()
      yAxis.labelsContainer.hide()

      return yAxis
    },
    /**
     * @param {Object} config - Configuration of the xAxis
     * @param {Object} config.root - root chart element
     * @param {Object} config.chart - root chart element
     * @param {string} config.categoryField - property which the xAxis values are based on
     * @param {number} config.minGridDistance - minimum distance between xAxis values in pixels
     * @description Generate a x-axis
     * @returns {(Object|[Object, Object])} Either a single yAxis, or an array with 2 yAxises.
     */
    generateXAxis ({
      root,
      chart,
      categoryField,
      minGridDistance = 30
    }) {
      // Create X-Axis, make it available on `this`
      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: minGridDistance,
            inversed: true
          }),
          categoryField: categoryField
        })
      )
      xAxis.get('renderer').grid.template.set('visible', false)

      return xAxis
    },
    /**
     * @description Accepts an array of charts ([{x, y}]), and makes sure that they are of equal length
     * @returns {[{ x: number, y: number, year: String}])} An array of charts with "equal" data ([{x, y, year}])
     */
    syncCharts (...charts) {
      let min = 3000
      let max = 0
      // Check which are the minimum and maximum years for the given data
      charts.forEach(chart => {
        if (chart.length > 0) {
          if (chart[0].x < min) max = chart[0].x
          if (chart[chart.length - 1].x > max) min = chart[chart.length - 1].x
        }
      })

      // If the minimum year is greater than 2005, we set it to 2005, we'll append empty datapoints for the missing years
      if (min > this.minYear) min = this.minYear
      // Add additional items to the graphs if needed, to make sure they all have the same length and x-values.
      charts.forEach(chart => {
        if (chart.length === 0) {
          for (let i = min; i <= max; i++) {
            chart.unshift({
              x: i,
              y: 0,
              year: i.toString()
            })
          }
        } else if (chart.length !== max - min + 1) {
          const localMax = chart[0] && chart[0].x
          const localMin = chart[chart.length - 1] && chart[chart.length - 1].x

          for (let i = localMin - 1; i >= min; i--) {
            chart.push({
              x: i,
              y: 0,
              year: i.toString()
            })
          }
          for (let j = localMax + 1; j <= max; j++) {
            chart.unshift({
              x: j,
              y: 0,
              year: j.toString()
            })
          }
        }
      })

      return charts
    }
  }
}
