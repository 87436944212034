<template>
  <div class="select-menu-modal-holder text-left" aria-expanded="false" aria-labelledby="member-role">
    <div v-if="title" class="dropdown-item t-primary select-menu-header bg-grey">
      {{ title }}
      <i class="fa fa-times t-secondary is-hover t-xs pull-right pt-1" @click.prevent="$emit('closed')"></i>
    </div>

    <a href="#" v-for="(selectItem, index) in options" :key="index" @click.prevent="$emit('onChange', selectItem)" class="dropdown-item select-menu-item bd-b mb-0 clearfix">
      <span v-if="isWithCheckMark" class="select-menu-item_icon pull-left" :class="{'d-none': selectItem.key !== selected}">
        <i class="fa fa-check pt-2 t-sm t-green" ></i>
      </span>

      <span class="select-menu-item_text mb-0">
        <span class="t-primary t-500" :class="{'t-red': selectItem.delete}">{{ selectItem.title }}</span>
        <span class="t-secondary t-sm" :class="{'t-red': selectItem.delete}">{{ selectItem.description }}</span>
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'selectMenu',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    selected: {
      type: [Number, String, Array, Boolean],
      required: false,
      default: ''
    },
    isWithCheckMark: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      clickOutsideCount: 0
    }
  },
  methods: {
    hide () {
      this.clickOutsideCount++
      if (this.clickOutsideCount > 1) this.$emit('hide')
    }
  }
}
</script>
