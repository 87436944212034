<template>
  <div class="search_results search_results--preview">
    <template v-if="isResultLoaded">
      <template v-if="routeName !== 'patent'">
        <div v-for="(result, key) in results" :key="key">
          <div v-if="result.total_suggestion_results > 0" class="card mb-4">
            <div class="card-body py-3">
              <h3 class="mb-2 t-500">
                {{ 'Profile' | pluralize( result.total_suggestion_results) }} with similar name
              </h3>
              <component :is="'ResearcherCompact'"
                v-for="(suggestion, i) in result.suggestion_results" :key="`${i + 1}`"
                :routeName="routeName"
                :data="suggestion.data"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-if="routeName === 'patent'">
        <div v-for="(result, index, key) in results" :key="key">
          <div v-if="result.total_suggestion_results > 0">
            <h3 class="margin-t--40px mb-1 t-600">
              <span>Related documents of this patent</span>
            </h3>

            <ul class="list-unstyled mb-0 mt-2">
              <div v-for="(suggestion, i) in result.suggestion_results" :key="`related_${i}`" >
                <li class="row-content row-content_inline--small" :class="{ 'mt-2': i > 0 }">
                  <i class="fa fa-file-text row-content--side"></i>
                  <ul class="list-unstyled row-content--main">
                    <li class="row-content">
                      <span class="row-content--main">
                        <router-link :to="{ name: $route.name, params: $route.params, query: getQuery(index, suggestion.data) }" v-html="$options.filters.titleize(suggestion.data.title)"></router-link>
                      </span>
                    </li>

                    <li class="row-content row-content_text">
                      <span class="row-content--main">
                        <span class="t-400">Document Kind:</span> {{ suggestion.data.document_kind }}
                      </span>
                    </li>
                    <li class="row-content row-content_text">
                      <span v-if="isEmpty(suggestion.data.granted_date)" class="row-content--main">
                        <span class="t-400">Publication Date:</span> {{ suggestion.data.publication_date | formatDate('YYYY-MM-DD') }}
                      </span>
                      <span v-else class="row-content--main">
                        <span class="t-400">Granted Date:</span> {{ suggestion.data.granted_date | formatDate('YYYY-MM-DD') }}
                      </span>
                    </li>
                  </ul>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </template>
    </template>

    <spinner v-if="!isResultLoaded" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import SearchService from '@/services/search'
import searchHelpers from '@/mixins/search/helpers'

import ResearcherCompact from '@/components/researcher/result/Compact'
import Spinner from '@/components/shared/Spinner'

export default {
  name: 'searchRelatedDocuments',
  mixins: [searchHelpers],
  components: {
    Spinner,
    ResearcherCompact
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    parent: {
      type: Object,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    indexes: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data () {
    return {
      isResultLoaded: false,
      results: {}
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    fetch () {
      const searchObject = this.getSearchObjectRelatedMatches(this.filter, this.indexes)
      SearchService.search(searchObject).then(results => {
        this.results = results.categories
        if (this.routeName === 'patent') {
          this.results[this.indexes[0]].suggestion_results = this.sortResults(results.categories)
        }

        this.isResultLoaded = true
      }).catch(err => {
        this.isResultLoaded = !this.isEmpty(err)
        this.error = this.handleError(err)
      })
    },
    sortResults (results) {
      let parsedResults = Object.assign([], results[this.indexes[0]].suggestion_results)
      let sortedParsedResults = parsedResults.slice().sort((a, b) => a.data.publication_date_epoch - b.data.publication_date_epoch)
      return sortedParsedResults
    },
    getQuery (index, data) {
      let obj = Object.assign({}, this.$route.query)
      obj[`${this.singularizeIndex(index)}`] = data.slug_values[0]
      obj.hr = data.slug_text
      return Object.assign({}, this.$route.query, obj)
    }
  }
}
</script>
