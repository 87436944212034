<template>
  <label class="checkbox2" :class="`${isDisabled ? 'is-disabled' : ''}`">
    <input type="checkbox" :name="name" :checked="value" @change="$emit('toggle')" :disabled="isDisabled" v-tooltip="tooltip" />
    <span v-html="text" v-tooltip="tooltip" />
  </label>
</template>

<script>
export default {
  name: 'checkbox',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
