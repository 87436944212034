<template>
  <div>
    <!-- orcid button -->
    <orcid-button v-if="!user.orcid_id"
      :text="`Connect with ORCID `"
      :containerClass="`mt-4 mb-0`"
      :isConnect="true" />
  </div>
</template>

<script>
import OrcidButton from '@/components/login/OrcidButton'

export default {
  name: 'userImport',
  components: {
    OrcidButton
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  }
}
</script>
