export const ICONS = {
  research_groups: 'fa fa-group',
  'research group': 'fa fa-group',
  researchers: 'fa fa-user',
  researcher: 'fa fa-user',
  research_topics: 'fa fa-flask',
  'ongoing research': 'fa fa-flask',
  projects: 'al-icon-funded_project',
  'funded project': 'al-icon-funded_project',
  clinical_trials: 'fa fa-stethoscope',
  'clinical trial': 'fa fa-stethoscope',
  publications: 'fa fa-file-text-o',
  publication: 'fa fa-file-text-o',
  patents: 'al-icon-patent',
  patent: 'al-icon-patent',
  organisations: 'fa fa-building',
  organisation: 'fa fa-building',
  web_pages_organisations: 'fa fa-globe',
  web_pages_research_groups: 'fa fa-globe',
  'company web page': 'fa fa-globe',
  'research group website': 'fa fa-globe',
  genes: 'al-icon-genes',
  diseases: 'al-icon-diseases',
  hospitals: 'fa fa-hospital-o',
  universities: 'fa fa-university',
  news: 'fa fa-newspaper-o'
}

export const COLORS = {
  academic: '#48AC80',
  company: '#258DAA',
  hospital: '#A680B7',
  government: '#8EA2B8',
  other: '#BFCCDA',
  unknown: '#BFCCDA',
  spinoff: '#4BC2DB',
  consortium: '#32C283',
  health: '#D5A4EB',
  investor: '#A49831',
  incubator: '#C7B941',
  research_group: '#32C283'
}

export default {
  search_modals: [
    'research_topic',
    'publication',
    'clinical_trial',
    'project',
    'opportunity',
    'patent',
    'news'
  ],
  indexes: [
    {
      index: 'research_groups',
      value: 'research_groups'
    }, {
      index: 'researchers',
      value: 'researchers'
    }, {
      index: 'research_topics',
      value: 'research_topics'
    }, {
      index: 'publications',
      value: 'publications'
    }, {
      index: 'projects',
      value: 'projects'
    }, {
      index: 'patents',
      value: 'patents'
    }, {

      index: 'clinical_trials',
      value: 'clinical_trials'
    },
    {
      index: 'organisations',
      value: 'organisations'
    },
    {
      index: 'news',
      value: 'news'
    },
    {
      index: 'web_pages_organisations',
      value: 'web_pages_organisations'
    },
    {
      index: 'web_pages_research_groups',
      value: 'web_pages_research_groups'
    }
  ],
  // These search indexes have deviating behaviours.
  index_configuration_options: {
    web_pages_research_groups: {
      fixedResultComponent: 'WebsiteMatches', // FixedResultComponent has to be defined in BaseCategories.vue too in order to access them
      hideSearchBy: true,
      hideFilters: true,
      fixedSearchView: 'list'
    },
    web_pages_organisations: {
      fixedResultComponent: 'WebsiteMatches',
      hideSearchBy: true,
      hideFilters: true,
      fixedSearchView: 'list'
    }
  },
  state: {
    global: true,
    index: 'researchers',
    keyword: '',
    exclude: [],
    filters: {},
    filter_order: [],
    saved: 0,
    page: 0,
    per_page: 12,
    viewport: 'block',
    index_changed: true,
    search_by_fields: []
  },
  months: [
    {
      name: 'January',
      short_value: 'Jan',
      value: 1
    }, {
      name: 'February',
      short_value: 'Feb',
      value: 2
    }, {
      name: 'March',
      short_value: 'Mar',
      value: 3
    }, {
      name: 'April',
      short_value: 'Apr',
      value: 4
    }, {
      name: 'May',
      short_value: 'May',
      value: 5
    }, {
      name: 'June',
      short_value: 'Jun',
      value: 6
    }, {
      name: 'July',
      short_value: 'Jul',
      value: 7
    }, {
      name: 'August',
      short_value: 'Aug',
      value: 8
    }, {
      name: 'September',
      short_value: 'Sep',
      value: 9
    }, {
      name: 'October',
      short_value: 'Oct',
      value: 10
    }, {
      name: 'November',
      short_value: 'Oct',
      value: 11
    }, {
      name: 'December',
      short_value: 'Dec',
      value: 12
    }
  ],
  gender: [
    {
      name: 'Male',
      value: 'male',
      id: 1
    }, {
      name: 'Female',
      value: 'female',
      id: 2
    }, {
      name: 'Trans*',
      value: 'trans',
      id: 3
    }, {
      name: 'Prefer not to answer',
      value: 'prefer_not_to_answer',
      id: 4
    }
  ],
  research_group_type: [
    {
      value: 'academic',
      key: 0
    }, {
      value: 'professional',
      key: 1
    }
  ],
  community_type: [
    {
      name: 'Institute/Centre',
      value: 'institute-centre',
      key: 1
    }, {
      name: 'Project consortium',
      value: 'project-consortium',
      key: 2
    }, {
      name: 'Member network',
      value: 'member-network',
      key: 3
    }, {
      name: 'Open topic community',
      value: 'open-topic-community',
      key: 4
    },
    {
      name: 'Sub community',
      value: 'sub-community',
      key: 5
    }
  ],
  experience_category: [
    {
      name: 'Academic',
      value: 'academic',
      key: 0
    }, {
      name: 'Non Academic',
      value: 'non_academic',
      key: 1
    }
  ],
  role: [
    {
      name: 'Member',
      value: 'member',
      key: 1
    }, {
      name: 'Admin',
      value: 'admin',
      key: 2
    }, {
      name: 'Owner',
      value: 'owner',
      key: 3
    }
  ],
  contact_types: [
    {
      name: 'Website',
      value: 'url',
      id: 1
    }, {
      name: 'Telephone',
      value: 'telephone',
      id: 2
    }, {
      name: 'Mobile Phone',
      value: 'mobile-phone',
      id: 4
    }
  ],
  categories: [
    {
      name: 'Public',
      value: 'public',
      key: 1
    }, {
      name: 'Student',
      value: 'student',
      key: 2
    }, {
      name: 'Academic',
      value: 'academic',
      key: 3
    }, {
      name: 'Professional',
      value: 'professional',
      key: 4
    }
  ]
}
