<template>
  <quill-editor v-if="isLoaded"
    ref="textEditor"
    name="name"
    v-model="text"
    :options="editorOption"
    :class="{'hide-toolbar': noToolbar}"
    @change="changed">
  </quill-editor>
</template>

<script>
// import Quill from 'quill'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'richTextEditor',
  components: {
    quillEditor
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      required: false,
      default: -1
    },
    minLength: {
      type: Number,
      required: false,
      default: -1
    },
    content: {
      type: String,
      required: true
    },
    minimal: {
      type: Boolean,
      required: false,
      default: false
    },
    minimalWithMedia: {
      type: Boolean,
      required: false,
      default: false
    },
    noToolbar: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Insert text here...'
    }
  },
  data () {
    return {
      isLoaded: false,
      qlTB_media: ['link'],
      text: '',
      editorOption: {
        placeholder: this.placeholder,
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': [1, 2, 3, false] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'align': 'center' }]
          ]
        }
      }
    }
  },
  $_veeValidate: {
    // fetch the current value from the innerValue defined in the component data.
    value () {
      return this.stripTags(this.text)
    }
  },
  created () {
    if (this.minimal) this.setMinimalEditor()
    if (this.minimalWithMedia) this.setMinimalMediaEditor()
    this.text = this.content
    if (this.maxLength === -1 && !this.minimal) this.qlTB_media.push('image')
    !this.noToolbar ? this.editorOption.modules.toolbar.push(this.qlTB_media) : this.editorOption.modules.toolbar = []

    this.isLoaded = true
  },
  watch: {
    content (newVal, oldVal) {
      if (newVal !== oldVal) this.text = this.content
    }
  },
  methods: {
    changed (e) {
      this.maxLength > -1 && e.quill.getLength() > this.maxLength & e.quill.getLength() !== 0
        ? this.$emit('input', false)
        : e.quill.getLength() <= 1 ? this.$emit('input', '') : this.$emit('input', e.html)
    },
    setMinimalEditor () {
      let toolbar = [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'header': [1, 2, 3, false] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]

      ]
      this.editorOption.modules.toolbar = toolbar
      this.qlTB_media = []
    },
    setMinimalMediaEditor () {
      let toolbar = [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'header': [1, 2, 3, false] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]
      ]
      this.editorOption.modules.toolbar = toolbar
    }
  }
}
</script>
