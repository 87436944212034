<template>
  <div>
    <h2 class="t-primary t-400 mb-0">Join {{ this.community.name }}</h2>
    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <p>
      Please, select which research groups you'd like to bring along with you to <router-link :to="{ name: 'community.show', params: { id: community.slug_values[0]}}">{{ community.name }}</router-link>. They will have access to the community, as well as be visible amongst the members.
    </p>

    <ul class="list-unstyled">
      <li class="block-fa-span clearfix">
        <i class="fa fa-check mt-1 t-green pr-2"></i><span class="block-span">Your <strong>ongoing research</strong> will be displayed in <router-link :to="{ name: 'community.show', params: { id: community.slug_values[0]}}">{{ community.name }}</router-link></span>
      </li>
      <li class="block-fa-span clearfix">
        <i class="fa fa-check mt-1 t-green pr-2"></i><span class="block-span">Your <strong>services</strong> will be displayed in <router-link :to="{ name: 'community.show', params: { id: community.slug_values[0]}}">{{ community.name }}</router-link></span>
      </li>
    </ul>

    <!-- add invites form -->
    <form v-if="isResearchGroupsLoaded" @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <table class="table table-researcher table-padding-x-0 mb-0">
        <tbody>
          <tr v-for="(researchGroup, i) in researchGroups" :key="i" class="clearfix">
            <td class="width--30px pr-0">
              <checkbox v-if="!researchGroup.in_community"
                v-model="researchGroup.import"
                :name="researchGroup.name"
                :containerClass="`research_group_checkbox-import mt-3 mb-0`">
              </checkbox>
              <i v-else class="fa fa-check mt-1 t-green" v-tooltip="`Already added to the community`"></i>
            </td>
            <td class="avatar pl-0">
              <research-group-thumbnail :researchGroup="researchGroup" />
            </td>
            <td>
              <router-link :to="{ name: 'research_group.show', params: {id: researchGroup.slug_values[0]} }">
                {{ researchGroup.name }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- action buttons -->
      <form-buttons :isRequest="isRequest"
        :isEditMode="false"
        :isCreateMode="true"
        :isRemove="false"
        :createText="`Join community`"
        :containerClass="`pt-3 m-reset`"
        @cancel="$events.$emit('modal:close')"
       />
    </form>

    <spinner v-if="!isResearchGroupsLoaded" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'
import Checkbox from '@/components/shared/form/Checkbox'
import Spinner from '@/components/shared/Spinner'
import ResearchGroupThumbnail from '@/components/research_group/Thumbnail'

import ResearcherService from '@/services/researcher'
import CommunityService from '@/services/community'

export default {
  name: 'joinConfirm',
  components: {
    StaticMessages,
    FormButtons,
    Checkbox,
    Spinner,
    ResearchGroupThumbnail
  },
  props: {
    community: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      error: null,
      isRequest: false,
      isResearchGroupsLoaded: false,
      researchGroups: []
    }
  },
  created () {
    this.fetchResearchGroups()
  },
  methods: {
    fetchResearchGroups () {
      CommunityService.getResearcherInfo(this.community.slug_values[0], this.$currentUser.slug_values[0]).then(info => {
        for (let rg of info.research_groups) if (!rg.in_community) rg.import = true
        this.researchGroups = info.research_groups
        this.isResearchGroupsLoaded = true
      }).catch(err => {
        this.error = this.handleError(err)
        this.isLoaded = !this.isEmpty(this.error)
      })
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (!this.isRequest) {
          var researchGroupIds = []
          for (var rg of this.researchGroups) { if (rg.import) researchGroupIds.push(rg.id) }
          this.isRequest = true
          this.handleJoin(researchGroupIds)
        }
      })
    },
    handleJoin (researchGroupIds) {
      this.isRequest = true
      ResearcherService.joinCommunity(this.$currentUser.slug_values[0], this.community.slug_values[0], {
        research_group_ids: researchGroupIds
      }).then(invite => {
        this.$events.$emit('current_user:update')
        this.$router.push({ name: 'community.show', params: { id: this.community.slug_values[0] }, query: { success: true } })
        this.isRequest = false
        this.$events.$emit('modal:close')
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    }
  }
}
</script>
