<template>
  <div>
    <h2 class="t-primary t-400 mb-0">Accept the invitation to join {{ this.community.name }} for {{ this.researchGroup.name }}</h2>
    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <ul class="list-unstyled ">
      <li class="block-fa-span clearfix">
        <i class="fa fa-check mt-1 t-green pr-2"></i><span class="block-span">Your <strong>ongoing research</strong> will be displayed in <router-link :to="{ name: 'community.show', params: { id: community.slug_values[0]}}">{{ community.name }}</router-link></span>
      </li>
      <li class="block-fa-span clearfix">
        <i class="fa fa-check mt-1 t-green pr-2"></i><span class="block-span">Your <strong>services</strong> will be displayed in <router-link :to="{ name: 'community.show', params: { id: community.slug_values[0]}}">{{ community.name }}</router-link></span>
      </li>
    </ul>

    <p>
      Please, select which team members you'd like to bring along with you to <router-link :to="{ name: 'community.show', params: { id: community.slug_values[0]}}">{{ community.name }}</router-link>. They will have access to the community, as well as be visible amongst the members.
    </p>

    <!-- add invites form -->
    <form v-if="isMemberLoaded" @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <table class="table table-researcher table-padding-x-0 mb-0">
        <tbody>
          <tr v-for="(member, i) in members" :key="i" class="clearfix">
            <td class="width--30px pr-0">
              <checkbox v-if="!member.in_community"
                v-model="member.import"
                :name="member.full_name"
                :containerClass="`member_checkbox-import mt-3 mb-0`">
              </checkbox>
              <i v-else class="fa fa-check mt-1 t-green" v-tooltip="`Already added to the community`"></i>
            </td>
            <td class="avatar pl-0">
              <researcher-thumbnail :researcher="member" />
            </td>
            <td>
              <researcher-link :researcher="member" />
            </td>
          </tr>
        </tbody>
      </table>

      <!-- action buttons -->
      <form-buttons :isRequest="isRequest"
        :isEditMode="false"
        :isCreateMode="true"
        :isRemove="false"
        :createText="`Accept invitation`"
        :containerClass="`pt-3 m-reset`"
        @cancel="$events.$emit('modal:close')">
      </form-buttons>
    </form>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'
import Checkbox from '@/components/shared/form/Checkbox'
import ResearcherThumbnail from '@/components/researcher/Thumbnail'
import ResearcherLink from '@/components/researcher/Link'

import ResearchGroupService from '@/services/research_group'
import CommunityService from '@/services/community'

export default {
  name: 'inviteConfirm',
  components: {
    StaticMessages,
    FormButtons,
    Checkbox,
    ResearcherThumbnail,
    ResearcherLink
  },
  props: {
    researchGroup: {
      type: Object,
      required: true
    },
    community: {
      type: Object,
      required: true
    },
    invite: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      error: null,
      isRequest: false,
      isMemberLoaded: false,
      isAutoAccept: true,
      members: []
    }
  },
  created () {
    if (!this.isEmpty(this.invite)) this.isAutoAccept = false
    this.fetchMembers()
  },
  methods: {
    fetchMembers () {
      CommunityService.getResearchGroupInfo(this.community.slug_values[0], this.researchGroup.slug_values[0]).then(info => {
        this.members = this.initMembers(info.researchers)
        this.isMemberLoaded = true
      }).catch(err => {
        this.error = this.handleError(err)
        this.isMemberLoaded = !this.isEmpty(this.error)
      })
    },
    initMembers (members) {
      for (let m of members) if (!m.in_community) m.import = true
      return members
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (!this.isRequest) {
          var researcherIds = []
          for (var m of this.members) { if (m.import) researcherIds.push(m.slug_values[0]) }
          this.isRequest = true
          this.isAutoAccept ? this.handleAutoJoin(researcherIds) : this.handleAccept(researcherIds)
        }
      })
    },
    handleAutoJoin (researcherIds) {
      let researchGroupIds = []
      researchGroupIds.push(this.researchGroup.slug_values[0])

      CommunityService.createResearchGroupInvite(this.community.slug_values[0], {
        research_group_ids: researchGroupIds,
        researcher_ids: researcherIds
      }).then(invite => {
        this.$events.$emit('modal:close')
        this.isRequest = false
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleAccept (researcherIds) {
      ResearchGroupService.acceptCommunityInvite(this.researchGroup.slug_values[0], this.invite.id, { researcher_ids: researcherIds }).then(inv => {
        this.$events.$emit('research_group:community:invite:accepted', this.invite.index)
        this.$events.$emit('modal:close')
        this.isRequest = false
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    }
  }
}
</script>
