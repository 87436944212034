import UserService from '@/services/user'

export default {
  data () {
    return {
      settings: {
        acknowledged_cookies: true,
        cookie_analytics: false
      },
      isClearingNewsCookies: false
    }
  },
  methods: {
    applySettings () {
      if (this.settings.cookie_analytics) {
        this.initAnalyticsScripts()
      } else {
        // Clear analytics cookies
        this.clearAnalyticsCookies()

        if (!this.isClearingNewsCookies) {
          this.resetNewsCookies()
          this.isClearingNewsCookies = true
        }
      }
    },
    initAnalyticsScripts () {
      if (this.$currentUser) this.$mp.initialize(this.$currentUser)
      this.$scripts.initialize(this.$currentUser)
      this.$alIntercom.initialize(this.$currentUser)
    },
    clearAnalyticsCookies () {
      this.$alIntercom.shutdown()
      this.$mp.shutdown()

      // Array of cookie prefixes that we should get rid of if the user so desires
      const COOKIE_ARRAY = ['_hj', '_ga', '_gid', 'mp_', 'intercom', '__hs', 'hubspot', '__ddg1', 'vitallyToken', '__cf_bm', 'c']
      const cookies = document.cookie.split('; ')

      for (let i = 0; i < cookies.length; i++) {
        COOKIE_ARRAY.forEach(prefix => {
          if (cookies[i].indexOf(prefix) === 0) {
            // Cookies are weird. You can just update the string on the document, and the JS interpreter will make some magic
            // happen so that only the relevant cookie gets affected
            const currentCookie = cookies[i].split('=')[0] // Gets name of cookie
            document.cookie = `${currentCookie}=;domain=.academiclabs.com;expires=Thu, 21 Sep 1979 00:00:01 UTC;path=/` // Append date in the past to deactive it
          }
        })
      }
    },
    resetNewsCookies () {
      // This is a looping script that unsets cookies set by the PrNewsWire trackers. Will run forever and always if analytic cookies are turned off.
      const COOKIES = [
        {
          name: '__cf_bm',
          domain: '.prnewswire.com'
        },
        {
          name: '__cf_bm',
          domain: '.newswire.ca'
        },
        {
          name: 'c',
          domain: 'c212.net'
        },
        {
          name: 'uuid',
          domain: '.mathtag.com'
        }
      ]

      COOKIES.forEach(cookie => {
        document.cookie = `${cookie.name}=;domain=${cookie.domain};expires=Thu, 21 Sep 1979 00:00:01 UTC;path=/` // Append date in the past to deactive it
      })

      if (!this.settings.cookie_analytics) {
        setTimeout(() => {
          this.resetNewsCookies()
        }, 2000)
      } else {
        this.isClearingNewsCookies = false
      }
    },
    updateSettings () {
      this.$events.$emit('cookies:updated', this.settings)

      if (this.$currentUser) {
        return this.updateUser()
      } else {
        return this.updateGuestLS()
      }
    },
    updateUser () {
      this.$auth.setCurrentUser({
        ...this.$currentUser,
        ...this.settings
      })

      return UserService.updateSettings(this.$currentUser.user_id, this.settings)
    },
    updateGuestLS () {
      this.$ls.setCookieSettings(this.settings)
      return Promise.resolve()
    }
  }
}
