import MixPanel from 'mixpanel-browser'

export default {
  install (Vue, options) {
    Vue.prototype.$mp = {
      isInitialised: false,
      initialize (user) {
        MixPanel.init(process.env.VUE_APP_MIXPANEL_CODE, {
          api_host: 'https://api.mixpanel.com',
          loaded: () => {
            this.isInitialised = true
            if (process.env.VUE_APP_DEPLOY_ENV !== 'dev') {
              MixPanel.identify(user.user_id)
              MixPanel.register({
                'User id': user.user_id,
                'User name': user.full_name,
                'User email': user.email,
                'User category': user.category.name,
                'User verified': user.verified
              })
            }
          }
        })
      },
      getDistinctID () {
        return this.isInitialised ? MixPanel.get_distinct_id() : null
      },
      trackPage (from, to) {
        if (process.env.VUE_APP_DEPLOY_ENV !== 'dev') {
          // If not initialised, don't track!
          if (!this.isInitialised) return
          let params = to.params.prevRoute ? {} : to.params
          let name = to.name.split('.').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
          MixPanel.track(name, {
            'Page name (previous)': from.name,
            'Page path (previous)': from.path,
            'Page name': to.name,
            'Page path': to.path,
            'Page params': params,
            'Page query': to.query
          })
        }
      },
      logout () {
        if (process.env.VUE_APP_DEPLOY_ENV !== 'dev') {
          if (!this.isInitialised) return
          MixPanel.track('Logout')
          MixPanel.reset()
        }
      },
      shutdown () {
        if (!this.isInitialised) return
        this.isInitialised = false
        MixPanel.disable()
      }
    }
  }
}
