<template>
  <div @click="focusNewTag()" :class="{'read-only': readOnly}" class="input-tag-wrapper form-control">
    <span v-for="(tag, index) in innerTags" :key="index" class="input-tag px-2" :class="{'is-hover': !readOnly}" @click.prevent.stop="remove(index)">
      {{ tag }}<i v-if="!readOnly" class="fa fa-trash pl-2 t-secondary"></i>
    </span>

    <input v-if="!readOnly && !isLimit"
      :placeholder="placeholder"
      ref="inputtag"
      type="text"
      v-model="newTag"
      class="new-tag"
      @keydown.delete.stop="removeLastTag"
      @keydown="addNew"
      @blur="handleBlur($event)"
    />
  </div>
</template>

<script>
/*eslint-disable*/
const validators = {
  email: new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
  url: new RegExp(/^(https?|ftp|rmtp|mms):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i),
  text: new RegExp(/^[a-zA-Z]+$/),
  digits: new RegExp(/^[\d() \.\:\-\+#]+$/),
  isodate: new RegExp(/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/)
}
/* eslint-enable */

export default {
  name: 'inputTag',
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    validate: {
      type: String,
      default: ''
    },
    addTagOnKeys: {
      type: Array,
      default: function () {
        return [
          13, // Return
          188, // Comma ','
          9 // Tab
        ]
      }
    },
    limit: {
      default: -1
    }
  },
  data () {
    return {
      newTag: '',
      innerTags: [...this.tags]
    }
  },
  watch: {
    tags () {
      this.innerTags = [...this.tags]
    }
  },
  computed: {
    isLimit () {
      return this.limit > 0 && Number(this.limit) === this.innerTags.length
    }
  },
  methods: {
    focusNewTag () {
      if (this.readOnly || !this.$el.querySelector('.new-tag')) { return }
      if (this.innerTags.length < this.limit) this.$nextTick(() => this.$refs.inputtag.focus())
    },
    addNew (e) {
      // Do nothing if the current key code is
      // not within those defined within the addTagOnKeys prop array.
      if ((e && this.addTagOnKeys.indexOf(e.keyCode) === -1) || this.isLimit) {
        return
      }

      // We prevent default & stop propagation for all
      // keys except tabs (used to move between controls)
      if (e && e.keyCode !== 9) {
        e.stopPropagation()
        e.preventDefault()
      }

      if (this.newTag && this.innerTags.indexOf(this.newTag) === -1 && this.validateIfNeeded(this.newTag)) {
        !this.validate ? this.innerTags.push(this.newTag) : this.innerTags.push(this.newTag)
        this.newTag = ''
        this.tagChange()
      } else {
        this.$emit('error')
      }
    },
    validateIfNeeded (tagValue) {
      if (this.validate === '' || this.validate === undefined) {
        return true
      } else if (Object.keys(validators).indexOf(this.validate) > -1) {
        return validators[this.validate].test(tagValue)
      }

      return true
    },
    handleBlur (e) {
      if (this.newTag && this.innerTags.indexOf(this.newTag) === -1 && this.validateIfNeeded(this.newTag)) {
        this.innerTags.push(this.newTag)
        this.newTag = ''
        this.tagChange()
      } else {
        this.newTag = ''
        // if (this.innerTags.length < this.limit && e.keyCode === 9) this.$nextTick(() => this.$refs.inputtag.focus())
      }
    },
    remove (index) {
      this.innerTags.splice(index, 1)
      this.tagChange()
    },
    removeLastTag () {
      if (this.newTag) { return }
      this.innerTags.pop()
      this.tagChange()
    },
    tagChange () {
      this.focusNewTag()
      this.$emit('update:tags', this.innerTags)
    }
  }
}
</script>
