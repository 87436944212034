<template>
  <div :class="{'search_results search_results--preview': !disableStyling}">
    <template v-if="isResultLoaded">
      <h3 v-if="withTitle && totalResults > 0" class="mt-4 mb-1 t-500">{{ withTitle }}</h3>

      <div v-for="(result, index) in sortedResults" :key="index">
        <div v-if="result.total_results > 0 || result.total_suggestion_results > 0" :class="{'card mb-4': !disableStyling}">
          <div :class="{'card-body py-3': !disableStyling}">
            <template v-if="!disableStyling">
              <router-link v-if="showViewAll && result.name" :to="{ name: `${route.name}.explore`, params: { category: result.name }}" class="t-sm pull-right t-400">
                view all
              </router-link>
              <h3 class="mb-0 t-500">
                <router-link :to="{ name: `${route.name}.explore`, params: { category: result.name }}" class="t-primary text-decoration-none">
                  <span v-if="innerTitleOverride">{{ innerTitleOverride }}</span>
                  <span v-else-if="isWithKeyword && getMatchingCount(result) > 0">Top {{ getMatchingCount(result) }} matching {{ getIndexAsString(result.name) }}</span>
                  <span v-else>{{ getIndexAsString(result.name) }}</span>
                </router-link>
                <span class="t-secondary ml-1">{{ result.total | formatNumber }}</span>
                <i v-if="result.total_suggestion_results > 0" class="fa fa-info-circle t-secondary t-xs ml-2" v-tooltip="getTooltip(result)" />
              </h3>
            </template>

            <ul class="list-unstyled mb-0 mt-2" v-if="!isNewsPreview">
              <li v-for="(resultData, i) in mergedResults(result.results, result.suggestion_results)" :key="`${i + 1}`"
                class="mt-1 t-overflow"
              >
                <i v-if="!resultData.suggestion" class="fa fa-file-text t-sm t-secondary pr-2"></i>
                <i v-else class="fa fa-file-text t-secondary-light pr-2" v-tooltip="`Results based on name match: ${getSuggestionsAsString()}`"></i>
                <span v-if="!isEmpty(resultData.hit_type)" class="t-xs t-yellow px-1 py-1 mr-2 bg-grey bd-radius t-uppercase">{{ resultData.hit_type | humanize }}</span>
                <router-link :to="getRoute(result.name, resultData.data)" v-html="getTitle(resultData)"></router-link>
              </li>
            </ul>

            <ul class="list-unstyled mb-0 mt-2" v-else>
              <li v-for="(resultData, i) in mergedResults(result.results, result.suggestion_results)" :key="`${i + 1}`"
                class="mt-1 py-2"
                :class="{ 'bd-b': i < totalDocuments -1 }"
              >
                <router-link :to="getRoute(result.name, resultData.data)" class="t-primary">
                  <div class="d-block t-regular mb-1">
                    <span class="mr-2 t-sm">{{ resultData.data.publication_date | formatDate('MMMM D, YYYY') }}</span>
                    <span v-if="!isEmpty(resultData.hit_type)" class="t-xs t-yellow px-1 py-1 mr-2 bg-grey bd-radius t-uppercase">{{ resultData.hit_type | humanize }}</span>
                    <span class="t-sm t-600 pull-right">{{ resultData.data.publication_date | fromNow }}</span>
                  </div>
                  <div v-html="getTitle(resultData)" class="mb-1" />
                </router-link>
              </li>
            </ul>

          </div>
        </div>
      </div>
    </template>

    <spinner v-if="!isResultLoaded" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import SearchService from '@/services/search'
import searchHelpers from '@/mixins/search/helpers'

import Spinner from '@/components/shared/Spinner'

export default {
  name: 'searchContainerPreview',
  mixins: [searchHelpers],
  components: {
    Spinner
  },
  props: {
    route: {
      type: Object,
      required: true
    },
    searchFilterObject: {
      type: Object,
      required: true
    },
    indexes: {
      type: Array,
      required: true
    },
    suggestionIndexes: {
      type: Array,
      required: false,
      default: () => ([])
    },
    totalDocuments: {
      type: Number,
      required: false,
      default: 5
    },
    disableStyling: {
      type: Boolean,
      required: false,
      default: false
    },
    titleKey: {
      type: String,
      required: false,
      default: 'title'
    },
    scope: {
      type: String,
      required: false
    },
    withTitle: { // Title for the 'search preview in modals'
      type: String,
      required: false,
      default: ''
    },
    innerTitleOverride: { // Override inner title of index (will apply to ALL indexes if more than 1)
      type: String,
      required: false,
      default: ''
    },
    showViewAll: {
      type: Boolean,
      required: false,
      default: true
    },
    isNewsPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isWithKeyword: false,
      isResultLoaded: false,
      results: {},
      totalResults: 0
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    sortedResults () {
      return (this.isResultLoaded && this.sortIndexes(this.results)) || []
    }
  },
  methods: {
    fetch () {
      const searchObject = this.getSearchObjectPreview(this.searchFilterObject, this.indexes, this.totalDocuments, this.suggestionIndexes)
      if (!this.isEmpty(searchObject.keyword) && this.totalDocuments < 50) this.isWithKeyword = true

      SearchService.searchTopMatches(searchObject).then(results => {
        let countResults = 0
        // Filter out results that are exactly the same as the modal/page where we render this component
        Object.entries(results.categories).forEach(([name, resultCategory]) => {
          results.categories[name].results = resultCategory.results.filter(result => {
            return !this.hasMatchingSlug(this.route.slug, result.data.slug_values)
          })
          // Count how many results we have finally
          countResults += results.categories[name].results.length + results.categories[name].suggestion_results.length
        })
        this.results = results.categories
        this.totalResults = countResults
        this.isResultLoaded = true
      }).catch(err => {
        this.isResultLoaded = !this.isEmpty(err)
        this.error = this.handleError(err)
      })
    },
    mergedResults (results, suggestionResults) {
      let sortable = [].concat(results, suggestionResults.map(obj => ({ ...obj, suggestion: true })))
      return sortable
      // return sortable.slice().sort((a, b) => b.score - a.score)
    },
    getSuggestionsAsString () {
      return this.searchFilterObject.suggestions.reduce((arr, elem) => arr.concat(elem.must), []).join(', ')
    },
    getRoute (index, data) {
      if (index === 'organisations') {
        return { name: 'organisation.show', params: { id: data.slug_values[0] } }
      }
      return { name: this.$route.name, params: this.$route.params, query: this.getQuery(index, data) }
    },
    getQuery (index, data) {
      let obj = Object.assign({}, this.$route.query)
      obj[this.singularizeIndex(index)] = data.slug_values[0]
      obj.hr = data.slug_text
      return obj
    },
    sortIndexes (results) {
      let sortable = []
      for (let key in results) {
        const res = { ...results[key] }
        res.name = key
        sortable.push(res)
      }
      sortable.sort((a, b) => a.order - b.order)

      return sortable
    },
    getTooltip (result) {
      if (result.total_suggestion_results === 0) return 'All results are verified.'
      if (this.scope === 'researchers') {
        return `${result.total_suggestion_results} of these results were found based on the researcher's name. These are not yet verified and could belong to someone with an identical name.`
      } else if (this.scope === 'organisations') {
        return `${result.total_suggestion_results} of these results were found based on the organisation's name. These are not yet verified and could belong to another organisation with an identical name.`
      } else {
        return `${result.total_suggestion_results} of these results were found based on the names of this group's researchers. These are not yet verified and could belong to someone with an identical name.`
      }
    },
    getMatchingCount (result) {
      let matching = 0
      for (let data of result.results) if (!this.isEmpty(data.hit_type)) matching++
      return matching
    },
    getTitle (result) {
      if (!this.isEmpty(result.highlights)) {
        if (!this.isEmpty(result.highlights[this.titleKey])) return result.highlights[this.titleKey][0]
        if (!this.isEmpty(result.highlights.name)) return result.highlights.name[0]
        if (!this.isEmpty(result.highlights.full_title)) return result.highlights.full_title[0]
      }

      if (!this.isEmpty(result.data[this.titleKey])) return result.data[this.titleKey]
      if (!this.isEmpty(result.data.name)) return result.data.name
      if (!this.isEmpty(result.data.full_title)) return result.data.full_title
    }
  }
}
</script>
