<template>
  <div v-if="showComponent">
    <h3 @click="isOpen = !isOpen" @mouseover="isOpen = true" class="mb-0 text-right"><i class="fa t-secondary" :class="{
      'fa-minus': isOpen,
      'fa-plus': !isOpen
    }" /></h3>
    <div
      class="card-body py-3 card mb-4 w-auto"
      v-if="isOpen"
    >

        <ul v-if="identifiers">
          <li v-for="(val, index) in identifiers" :key="index">{{ val.name }}: {{ val.value }}</li>
        </ul>
        <template v-if="query">
          <hr />
          <span>{{ query }}</span>
        </template>
        <template v-if="item.qa.webpage">
          <hr />
          <span v-for="(webpage, i) in item.qa.webpage" :key="i" v-html="webpage" />
        </template>

    </div>
  </div>
</template>

<script>
export default {
  name: 'qaIdentifiers',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    identifiers () {
      if (this.item && this.item.qa) return this.item.qa.identifiers
      return null
    },
    query () {
      if (this.item && this.item.qa) return this.item.qa.query
      return null
    },
    showComponent () {
      return this.isDevServer() && (this.query || this.identifiers)
    }
  }
}
</script>
