<template>
  <span>
    <router-link v-if="researcher.profile_visible && !disableLink" :to="{ name: 'researcher.show', params: { id: researcher.slug_values[0] }}">
      <avatar-missing v-if="!researcher.avatar" :name="getName" :maxChars="maxChars" :isSmallClass="false"/>
      <avatar v-else :name="getName" :avatar="researcher.avatar" :editable="false" />
    </router-link>

    <span v-if="!researcher.profile_visible || disableLink">
      <avatar-missing v-if="!researcher.avatar" :name="getName" :maxChars="maxChars" :isSmallClass="false"/>
      <avatar v-else :name="getName" :avatar="researcher.avatar" :editable="false" />
    </span>
  </span>
</template>

<script>
import AvatarMissing from '@/components/shared/AvatarMissing'
import Avatar from '@/components/shared/Avatar'

export default {
  name: 'ResearcherThumbnail',
  components: {
    AvatarMissing,
    Avatar
  },
  props: {
    researcher: {
      type: Object,
      required: true
    },
    maxChars: {
      type: Number,
      required: false,
      default: 3
    },
    disableLink: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    getName () {
      return this.getFullName(this.researcher)
    }
  }
}
</script>
