export default {
  data () {
    return {
      prevHashedState: 0,
      hashedState: 1
    }
  },
  methods: {
    getSearchObjectPreview (suggestionsData, indexes, perPage = 5, suggestionIndexes = []) {
      let state = !this.isEmpty(this.$ls.getState()) ? this.$ls.getState() : {}
      let searchObject = {}
      if (this.isEmpty(state.preference)) searchObject.preference = !this.isEmpty(this.$currentUser) ? this.$currentUser.slug_values[0] : new Date()
      searchObject.indexes = indexes
      searchObject.suggestion_indexes = suggestionIndexes
      searchObject.suggestions = suggestionsData.suggestions
      searchObject.filter_order = []
      searchObject.filter_order.push(suggestionsData.key)
      searchObject.filters = {}
      searchObject.filters[suggestionsData.key] = {}
      searchObject.filters[suggestionsData.key].buckets = []
      searchObject.filters[suggestionsData.key].buckets.push({ active: true, values: suggestionsData.values })
      searchObject.suggestions_only = false
      searchObject.global = false
      searchObject.page = 0
      searchObject.per_page = perPage
      searchObject.keyword = state.keyword
      searchObject.exclude = state.exclude || []
      searchObject.scoped_slug_texts = suggestionsData.scoped_slug_texts || []

      return searchObject
    },
    getSearchObjectGraphData (suggestionsData, indexes) {
      let state = !this.isEmpty(this.$ls.getState()) ? this.$ls.getState() : {}
      let searchObject = {}
      if (this.isEmpty(state.preference)) searchObject.preference = !this.isEmpty(this.$currentUser) ? this.$currentUser.slug_values[0] : new Date()
      searchObject.index = indexes[0]
      searchObject.filter_order = []
      searchObject.filter_order.push(suggestionsData.key)
      searchObject.filters = {}
      searchObject.filters[suggestionsData.key] = {}
      searchObject.filters[suggestionsData.key].buckets = []
      searchObject.filters[suggestionsData.key].buckets.push({
        active: true,
        values: suggestionsData.values,
        label: suggestionsData.label
      })
      searchObject.suggestions_only = false
      searchObject.global = false
      searchObject.suggestions = suggestionsData.suggestions
      searchObject.suggestion_indexes = [indexes[0]]
      searchObject.search_by_fields = state.search_by_fields || []
      searchObject.scoped_slug_texts = suggestionsData.scoped_slug_texts || []

      return searchObject
    },
    getSearchObjectGraphDataForSearchCard (index, filterKey, slugValues, currentState) {
      // let state = !this.isEmpty(this.$ls.getState()) ? this.$ls.getState() : {}
      const state = !this.isEmpty(currentState) ? currentState : {}
      const searchObject = {}
      if (this.isEmpty(state.preference)) searchObject.preference = !this.isEmpty(this.$currentUser) ? this.$currentUser.slug_values[0] : new Date()
      searchObject.index = index
      searchObject.filter_order = []
      searchObject.filter_order.push(filterKey)
      searchObject.filters = {}
      searchObject.filters[filterKey] = {}
      searchObject.filters[filterKey].buckets = []
      searchObject.filters[filterKey].buckets.push({ active: true, values: slugValues })
      searchObject.global = false
      searchObject.keyword = state.keyword
      searchObject.exact_match = !!state.exact_match
      searchObject.exclude = state.exclude || []
      searchObject.search_by_fields = state.search_by_fields || []

      return searchObject
    },
    getSearchObjectRelatedMatches (suggestionsData, indexes, perPage = 15) {
      let state = !this.isEmpty(this.$ls.getState()) ? this.$ls.getState() : {}
      let searchObject = {}
      if (this.isEmpty(state.preference)) searchObject.preference = !this.isEmpty(this.$currentUser) ? this.$currentUser.slug_values[0] : new Date()
      searchObject.index = indexes[0]
      searchObject.suggestion_indexes = indexes
      searchObject.suggestions = suggestionsData.suggestions
      searchObject.filter_order = []
      searchObject.filter_order.push(suggestionsData.key)
      searchObject.filters = {}
      searchObject.filters[suggestionsData.key] = {}
      searchObject.filters[suggestionsData.key].buckets = []
      if (suggestionsData.values) searchObject.filters[suggestionsData.key].buckets.push({ active: true, values: suggestionsData.values })
      else if (suggestionsData.value) searchObject.filters[suggestionsData.key].buckets.push({ active: true, value: suggestionsData.value })
      searchObject.page = 0
      searchObject.suggestions_only = true
      searchObject.global = true
      searchObject.per_page = perPage

      return searchObject
    },
    hasUpdatedSearchHash () {
      // Generate a hash of a "generic search object", that does not make references to pagination and certain filters.
      // In order to optimize server requests
      // return true/false depending on new hash or not
      const state = {
        ...this.state
      }
      const objectToHash = {
        keyword: state.keyword,
        exclude: state.exclude,
        filter_order: state.filter_order,
        filters: Object.values(state.filters).map(f => {
          if (!f.buckets) return ''
          const buckets = f.buckets.filter(b => b.active).map(b => b.value)
          return buckets.join(',')
        }).filter(f => f),
        index: state.index,
        search_by_fields: state.search_by_fields,
        sort: state.sort,
        term_suggestions: state.term_suggestions
      }

      const hash = this.hashString(JSON.stringify(objectToHash))
      this.prevHashedState = this.hashedState
      this.hashedState = hash

      return hash !== this.prevHashedState
    }
  }
}
