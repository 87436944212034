<template>
  <div>
    <h3 class="title-collapsible" @click.prevent="isCollapsed = !isCollapsed">
      <i class="fa" :class="{ 'fa-caret-down': !isCollapsed, 'fa-caret-right': isCollapsed }"></i>
      {{ title }}
      <font-size-editable @fontChanged="handleFontChanged"></font-size-editable>
    </h3>

    <long-text v-if="!isCollapsed"
      :truncateAt="truncateAt"
      :text="text"
      :class="'content-collapsible'"
      :style="longTextStyle"
      :isReadMore="isReadMore"
      :isRichText="true"
      :withPreWrap="withPreWrap">
    </long-text>
  </div>
</template>

<script>
import FontSizeEditable from '@/components/shared/FontSizeEditable'
import LongText from '@/components/shared/LongText'

export default {
  name: 'longTextCollapsible',
  components: {
    FontSizeEditable,
    LongText
  },
  props: {
    truncateAt: {
      type: Number,
      default: 450
    },
    isReadMore: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    withPreWrap: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data () {
    return {
      isCollapsed: false,
      fontStyle: {}
    }
  },
  computed: {
    longTextStyle () {
      if (this.withPreWrap) {
        return [this.fontStyle, { 'whiteSpace': 'pre-line' }]
      }
      return [this.fontStyle]
    }
  },
  methods: {
    handleFontChanged (fontStyle) {
      this.fontStyle = fontStyle
    }
  }
}
</script>
