import ls from '@/plugins/localstorage'
import { hasMatchingSlug } from '@/plugins/utils'

const Base = () => import(/* webpackChunkName: "researcher" */ '@/views/researcher/Base.vue')
const Show = () => import(/* webpackChunkName: "researcher" */ '@/views/researcher/Show.vue')
const Explore = () => import(/* webpackChunkName: "researcher" */ '@/views/researcher/Explore.vue')
const BookmarkFoldersBase = () => import(/* webpackChunkName: "researcher" */ '@/views/user/BaseBookmarkFolders.vue')
const BookmarkFoldersOverview = () => import(/* webpackChunkName: "researcher" */ '@/views/user/bookmark_folders/Overview.vue')
const BookmarkFoldersShow = () => import(/* webpackChunkName: "researcher" */ '@/views/user/bookmark_folders/Show.vue')
const Invite = () => import(/* webpackChunkName: "user" */ '@/views/user/Invite.vue')
const SettingsBase = () => import(/* webpackChunkName: "user" */ '@/views/user/BaseSettings.vue')
const SettingsShow = () => import(/* webpackChunkName: "user" */ '@/views/user/settings/Show.vue')
const SettingsChangeAccount = () => import(/* webpackChunkName: "user" */ '@/views/user/settings/ChangeAccount.vue')

export default [
  {
    path: '/profile',
    name: 'researcher',
    redirect: 'researcher.show',
    meta: {
      auth: false
    },
    component: Base,
    beforeEnter: (to, from, next) => {
      if (to.meta.permissions) {
        (ls.getUser() && !hasMatchingSlug(ls.getUser().slug_values, to.params.id))
          ? next({ name: to.meta.permissions.redirectTo, params: { id: to.params.id } })
          : next()
      } else {
        return next()
      }
    },
    children: [
      {
        path: ':id/',
        name: 'researcher.show',
        meta: {
          auth: false,
          searchModal: true,
          render: {
            searchBar: true,
            title: 'About'
          }
        },
        component: Show
      }, {
        path: ':id/explore/:category?/:buckets(.*)*',
        name: 'researcher.explore',
        meta: {
          auth: false,
          skipMixPanel: true,
          searchContainer: true,
          render: {
            checkForRenderInSubNavExplore: true,
            searchBar: true,
            title: 'Research',
            tooltip: 'Explore publications, funded projects and more'
          }
        },
        component: Explore
      }, {
        path: ':id/bookmark_folders',
        name: 'researcher.bookmark_folders',
        redirect: ':id/bookmark_folders',
        meta: {
          auth: false,
          render: {
            searchBar: true,
            checkForRenderInSubNavList: true,
            title: 'Bookmark folders',
            tooltip: 'Bookmarks created by the user',
            children: ['researcher.bookmark_folders', 'researcher.bookmark_folders.overview', 'researcher.bookmark_folders.show']
          }
        },
        component: BookmarkFoldersBase,
        children: [
          {
            path: '',
            name: 'researcher.bookmark_folders.overview',
            meta: {
              auth: false,
              render: {
                searchBar: true,
                title: 'Bookmark folders'
              }
            },
            component: BookmarkFoldersOverview
          }, {
            path: ':bookmark_folder/:category?/:buckets(.*)*',
            name: 'researcher.bookmark_folders.show',
            meta: {
              auth: false,
              skipMixPanel: true,
              searchContainer: true,
              render: {
                searchBar: true
              }
            },
            component: BookmarkFoldersShow
          }
        ]
      }, {
        path: ':id/invite',
        name: 'researcher.invite',
        meta: {
          auth: true,
          render: {
            searchBar: true,
            title: 'Manage invitations',
            tooltip: 'Overview of invites sent and their current status'
          },
          permissions: {
            role: 'owner',
            redirectTo: 'researcher.show'
          }
        },
        component: Invite
      }, {
        path: ':id/settings',
        name: 'researcher.settings',
        redirect: ':id/settings',
        meta: {
          auth: true,
          render: {
            searchBar: true,
            title: 'Settings',
            children: [
              'researcher.settings',
              'researcher.settings.change-account',
              'researcher.settings.show'
            ]
          },
          permissions: {
            role: 'owner',
            redirectTo: 'researcher.show'
          }
        },
        component: SettingsBase,
        children: [
          {
            path: '',
            name: 'researcher.settings.show',
            meta: {
              auth: true,
              render: {
                searchBar: true,
                title: 'Settings'
              },
              permissions: {
                role: 'owner',
                redirectTo: 'researcher.show'
              }
            },
            component: SettingsShow
          }, {
            path: 'change-account',
            name: 'researcher.settings.change-account',
            meta: {
              auth: true,
              render: {
                title: 'Change Account',
                searchBar: true
              },
              permissions: {
                role: 'owner',
                redirectTo: 'researcher.show'
              }
            },
            component: SettingsChangeAccount
          }
        ]
      }
    ]
  }
]
