<template>
  <div>
    <h3 v-if="!isEmpty(researchers)" class="mb-3 t-600">
      {{ title }} <span class="ml-2 t-secondary">{{ totalResearchers }}</span>
    </h3>

    <ul v-if="!isEmpty(researchers)" class="mb-2 list-inline list-meta-data">
      <researcher
        v-if="parsedResearchers.first"
        :researcher="parsedResearchers.first"
        subText="First author"
        class="pb-3"
      />

      <template v-if="parsedResearchers.middle && collapsed">
        <li class="position-relative">
          <a href="#" class="t-primary pb-2 t-600" @click.prevent="fetchAllResearchers()">
            <i class="position-absolute fa fa-caret-right py-2 pl-2 pr-4 t-xlr" :style="{ left: '-28px', top: '-6px' }" />
            Show more
          </a>
        </li>
        <li v-for="(researcher, index) in parsedResearchers.collpasedMiddle" :key="researcher.id" class="d-inline pb-3">
          <researcher-link
            :researcher="!isEmpty(researcher.researcher) ? researcher.researcher : researcher"
            :suggestSearchLink="isEmpty(researcher.researcher) && !researcher.slug_values ? true : false"
            :showSuggestIcon="false"
          >
          </researcher-link>{{ index !== parsedResearchers.collpasedMiddle.length - 1 ? ', ' : '' }}</li>{{ totalResearchers > maxResearchers ? ', ...' : '' }}
        <li class="pb-3" />
      </template>
      <template v-if="parsedResearchers.middle && !collapsed">
        <li class="position-relative pb-3">
          <a href="#" class="t-primary" @click.prevent="collapsed = true">
            <i class="position-absolute fa fa-caret-down py-2 pl-2 pr-4 t-xlr" :style="{ left: '-28px', top: '-6px' }" />
            Show less
          </a>
        </li>
        <researcher
          v-for="researcher in parsedResearchers.middle"
          :key="researcher.id"
          :researcher="researcher"
          class="pb-3"
        />
      </template>

      <researcher
        v-if="parsedResearchers.last"
        :researcher="parsedResearchers.last"
        subText="Last author"
      />
    </ul>

    <spinner v-if="!isLoadedResearchers" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import Spinner from '@/components/shared/Spinner'
import Researcher from '@/components/shared/researcherSummary/Researcher'
import ResearcherLink from '@/components/researcher/Link'

export default {
  name: 'researcherSummaryV2',
  components: {
    Spinner,
    Researcher,
    ResearcherLink
  },
  props: {
    researchers: {
      type: Array,
      required: true
    },
    totalResearchers: {
      type: Number,
      required: false
    },
    isLoadedResearchers: {
      type: Boolean,
      default: true
    },
    maxResearchers: {
      type: Number,
      default: -1
    },
    title: {
      type: String,
      default: 'Researchers'
    }
  },
  data () {
    return {
      collapsed: true
    }
  },
  computed: {
    parsedResearchers () {
      if (!this.researchers) return {}

      if (this.researchers.length > 2) {
        return {
          first: this.researchers[0],
          middle: this.researchers.slice(1, -1),
          collpasedMiddle: this.researchers.slice(1, this.researchers.length > this.maxResearchers ? 8 : -1),
          last: this.researchers[this.researchers.length - 1]
        }
      } else if (this.researchers.length === 2) {
        return {
          first: this.researchers[0],
          last: this.researchers[1]
        }
      } else if (this.researchers.length === 1) {
        return {
          first: this.researchers[0]
        }
      } else {
        return {}
      }
    }
  },
  methods: {
    fetchAllResearchers () {
      this.collapsed = false
      if (this.totalResearchers > this.researchers.length && this.maxResearchers > -1) {
        this.$emit('fetchAllResearchers')
      }
    }
  }
}
</script>
