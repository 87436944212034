<template>
  <button v-if="!isHyperlink" type="button" @click="$emit('click')" class="btn link-button">
    <i v-if="icon"
      :class="[icon, text && 'with-text', size && `t-${size}`]"
      class="fa" />
    <span
      :class="[size && `t-${size}`, multiline && 'text-multiline']"
      v-html="text" />
    <slot />
  </button>
  <a v-else-if="isHyperlink" class="btn link-button" @click="$emit('click')">
    <i v-if="icon"
      :class="[icon, text && 'with-text', size && `t-${size}`]"
      class="fa" />
    <span
      :class="[size && `t-${size}`, multiline && 'text-multiline']"
      v-html="text" />
    <slot />
  </a>
</template>

<script>
export default {
  name: 'linkButton',
  props: {
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    multiline: {
      type: Boolean,
      default: false
    },
    size: {
      validator: function (value) {
        // The value must match one of these strings
        return ['lr', 'md', 'sm'].indexOf(value) !== -1 || !value
      },
      default: ''
    },
    isHyperlink: {
      type: Boolean,
      defualt: false
    }
  }
}
</script>
