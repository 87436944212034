export default {
  methods: {
    generateSortValue (item) {
      let fromYear
      let fromMonth
      let toYear
      let toMonth
      if (!item.present && !item.from_year && !item.to_year) return 0
      if (item.present) {
        if (!item.from_year) return 999999999999
        else {
          fromMonth = item.from_month ? this.$options.filters.prependZeroToNumber(item.from_month) : 99
          fromYear = item.from_year
          return parseInt(`${999999}${fromYear}${fromMonth}`)
        }
      } else {
        if (item.from_year && item.to_year) {
          fromMonth = item.from_month ? this.$options.filters.prependZeroToNumber(item.from_month) : 99
          fromYear = item.from_year
          toMonth = item.to_month ? this.$options.filters.prependZeroToNumber(item.to_month) : 99
          toYear = item.to_year
        } else {
          toMonth = this.$options.filters.prependZeroToNumber(item.to_month || item.from_month || 99)
          toYear = item.to_year || item.from_year
          fromMonth = this.$options.filters.prependZeroToNumber(item.from_month || item.to_month || 99)
          fromYear = item.from_year || item.to_year
        }
        return parseInt(`${toYear}${toMonth}${fromYear}${fromMonth}`)
      }
    },
    /*
    Sorting algorithm for experience and education timeline
    */
    sortTimeline (data, field) {
      if (this.isEmpty(data)) return []
      const items = []
      data.forEach(item => {
        items.push({ ...item, sortValue: this.generateSortValue(item) })
      })

      items.sort((a, b) => {
        if (a.sortValue === b.sortValue) {
          return a[field] > b[field] ? 1 : -1
        } else {
          return b.sortValue - a.sortValue
        }
      })
      return items
    }
  }
}
