<template>
  <div>
    <template v-if="!isEmpty(researchers)">
      <h3 class="mb-2 t-600">
        {{ title }}
      </h3>

      <ul class="mb-2 list-inline list-meta-data">
        <researcher
          v-for="(researcher, i) in researchers"
          :key="i"
          :researcher="researcher"
        />
      </ul>
    </template>

    <spinner v-if="!isLoadedResearchers" :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import Researcher from '@/components/shared/researcherSummary/Researcher'

import Spinner from '@/components/shared/Spinner'

export default {
  name: 'researcherSummary',
  components: {
    Spinner,
    Researcher
  },
  props: {
    researchers: {
      type: Array,
      required: true
    },
    isLoadedResearchers: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Researchers'
    }
  }
}
</script>
