import { Base64 } from 'js-base64'

export default {
  install (Vue, options) {
    // set ls
    Vue.prototype.$ls = Vue.ls = this
  },
  version () {
    return process.env.VUE_APP_LS_VERSION.split('.').join('')
  },
  clearLS () {
    localStorage.clear()
  },
  clearPreviousVersion () {
    let arr = []
    // iterate over localStorage and insert the previous versions
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).indexOf('_') !== -1) {
        let key = localStorage.key(i).substring(0, localStorage.key(i).indexOf('_'))
        if (parseInt(key) < parseInt(this.version())) arr.push(localStorage.key(i))
      }
    }

    // remove the array
    for (let i = 0; i < arr.length; i++) localStorage.removeItem(arr[i])
  },

  // Token
  setToken (token) {
    localStorage.setItem(`${this.version()}_token`, token)
  },
  getToken () {
    return localStorage.getItem(`${this.version()}_token`)
  },
  removeToken () {
    if (this.getToken()) localStorage.removeItem(`${this.version()}_token`)
  },

  // User
  setUser (user) {
    localStorage.setItem(`${this.version()}_user`, Base64.encode(JSON.stringify(user)))
  },
  getUser () {
    let data = localStorage.getItem(`${this.version()}_user`)
    return data ? JSON.parse(Base64.decode(data)) : data
  },
  removeUser () {
    if (this.getUser()) localStorage.removeItem(`${this.version()}_user`)
  },

  // State
  setState (state, key) {
    key !== undefined && key !== ''
      ? localStorage.setItem(`${this.version()}_state_${key}`, Base64.encode(JSON.stringify(state)))
      : localStorage.setItem(`${this.version()}_state`, Base64.encode(JSON.stringify(state)))
  },
  getState (key) {
    let data = ''

    key !== undefined
      ? data = localStorage.getItem(`${this.version()}_state_${key}`)
      : data = localStorage.getItem(`${this.version()}_state`)

    return data ? JSON.parse(Base64.decode(data)) : ''
  },
  removeState () {
    if (this.getState()) localStorage.removeItem(`${this.version()}_state`)
  },

  // Register object (store data to use when registering)
  setRegisterData (data) {
    if (data) localStorage.setItem(`${this.version()}_register`, Base64.encode(JSON.stringify(data)))
  },
  getRegisterData () {
    let data = localStorage.getItem(`${this.version()}_register`)
    return data ? JSON.parse(Base64.decode(data)) : {}
  },
  removeRegisterData () {
    if (localStorage.getItem(`${this.version()}_register`)) localStorage.removeItem(`${this.version()}_register`)
  },

  // Register object (store data to use when registering)
  setInviteData (data) {
    if (data) localStorage.setItem(`${this.version()}_invite`, Base64.encode(JSON.stringify(data)))
  },
  getInviteData () {
    let data = localStorage.getItem(`${this.version()}_invite`)
    return data ? JSON.parse(Base64.decode(data)) : {}
  },
  removeInviteData () {
    if (localStorage.getItem(`${this.version()}_invite`)) localStorage.removeItem(`${this.version()}_invite`)
  },

  // Register object (store data to use when registering)
  setConnectData (data) {
    if (data) localStorage.setItem(`${this.version()}_connect`, Base64.encode(JSON.stringify(data)))
  },
  getConnectData () {
    let data = localStorage.getItem(`${this.version()}_connect`)
    return data ? JSON.parse(Base64.decode(data)) : {}
  },
  removeConnectData () {
    if (localStorage.getItem(`${this.version()}_connect`)) localStorage.removeItem(`${this.version()}_connect`)
  },

  // general information about the user
  setAccountData (data) {
    localStorage.setItem(`${this.version()}_account`, Base64.encode(JSON.stringify(data)))
  },
  getAccountData () {
    return localStorage.getItem(`${this.version()}_account`)
      ? JSON.parse(Base64.decode(localStorage.getItem(`${this.version()}_account`)))
      : {}
  },
  removeAccountData () {
    if (localStorage.getItem(`${this.version()}_account`)) localStorage.removeItem(`${this.version()}_account`)
  },

  // general information about the user
  setLastVisitedPage (data) {
    localStorage.setItem(`${this.version()}_last-page`, Base64.encode(JSON.stringify(data)))
  },
  getLastVisitedPage () {
    return localStorage.getItem(`${this.version()}_last-page`)
      ? JSON.parse(Base64.decode(localStorage.getItem(`${this.version()}_last-page`)))
      : {}
  },
  removeLastVisitedPage () {
    if (localStorage.getItem(`${this.version()}_last-page`)) localStorage.removeItem(`${this.version()}_last-page`)
  },
  // cookie status for guests
  setCookieSettings (data) {
    localStorage.setItem(`${this.version()}_cookie-settings`, Base64.encode(JSON.stringify(data)))
  },
  getCookieSettings () {
    const currentSettings = localStorage.getItem(`${this.version()}_cookie-settings`)
    if (currentSettings) {
      return JSON.parse(Base64.decode(currentSettings))
    } else {
      return {}
    }
  }
}
