import Vue from 'vue'

const ENDPOINT = '/mixpanel'

const memory = {
  previousQuery: '',
  previousScope: ''
}

function postEvent (name, meta = {}) {
  // Only send requests if Mixpanel is active
  if (!Vue.prototype.$mp.isInitialised) return

  return Vue.prototype.$http.post(`${ENDPOINT}`, { mixpanel: {
    event: name,
    object: meta
  } }).then(response => response.data)
}

export default {
  trackEvent (event, object = {}) {
    return postEvent(event, object)
  },
  getInTouch (type, id, name) {
    return postEvent('get_in_touch', { type, id, name })
  },
  websiteClicked (type, id, name, url) {
    return postEvent('website_clicked', { type, id, name, url })
  },
  operatorSuggestion (keyword, suggestedKeyword) {
    return postEvent('search_operator_suggestion', { keyword, suggested_keyword: suggestedKeyword })
  },
  trackSearch (query, scope) {
    // Only track new queries
    if (!query || (memory.previousQuery === query && memory.previousScope === scope)) return
    // Store query in local memory
    memory.previousQuery = query
    memory.previousScope = scope
    return postEvent('new_search', { scope })
  },
  trackFilterUsed (name, index) {
    return postEvent('filter_used', { name, index })
  },
  trackFilterSetUsed (name, index) {
    return postEvent('filter_set_used', { name, index })
  },
  trackFilterCollapsed (name, collapsed, index) { // collapsed: [true,false]
    return postEvent('filter_collapsed', { name, type: collapsed ? 'closed' : 'open', index })
  },
  trackProfileVisit () {
    return postEvent('profile_visited', {})
  },
  trackResearchGroupVisit () {
    return postEvent('research_group_visited', {})
  },
  trackCompareUsed (name, index) {
    return postEvent('compare_used', {
      name: name,
      index: index
    })
  },
  trackModalOpened (scope) {
    return postEvent('content_modal_opened', { scope })
  }
}
