<template>
  <div class="">
    <h2 class="t-primary t-400 mb-0" v-if="isSingleScope">Invite members to {{ scope }} <span class="t-600">{{ getName | truncate(60) }}</span></h2>

    <h2 class="t-primary t-400 mb-0" v-else>Invite {{ researchGroup.name }} to {{ community.name }}</h2>
    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <!-- add invites form -->
    <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <!-- manual invite -->
      <div class="row" v-if="!isBulkInvite && isSingleScope">
        <div class="col-md-5">
          <label class="t-500 t-sm">Email</label>
        </div>

        <div v-if="!isInviteBookmark" class="col-md-3">
          <label class="t-500 t-sm">First name (optional)</label>
        </div>

        <div v-if="!isInviteBookmark" class="col-md-3">
          <label class="t-500 t-sm">Last name (optional)</label>
        </div>
      </div>

      <!-- manual invite fields -->
      <div class="row" v-show="!isBulkInvite && isSingleScope" v-for="(invite, index) in newInvites" :key="index">
        <!-- email -->
        <div class="form-group mb-2 pr-0" :class="{'has-icon-small': errors.has(`invite.email_${index}`), 'col-md-5': !isInviteBookmark && newInvites.length > 1, 'col-md-9': isInviteBookmark && newInvites.length > 1, 'col-md-6': !isInviteBookmark && newInvites.length === 1, 'col-md-10': isInviteBookmark && newInvites.length === 1}">
          <input
            ref="email"
            type="text"
            class="form-control"
            placeholder="name@example.com"
            :name="`invite.email_${index}`"
            v-model="invite.email"
            v-validate="'email'"
            data-vv-delay="1000"
            data-vv-as="email"
            @blur="fillInUser(invite.email, index)"
            :class="{'is-invalid': errors.has(`invite.email_${index}`)}"
          />

          <i v-show="errors.has(`invite.email_${index}`)" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has(`invite.email_${index}`)">{{ errors.first(`invite.email_${index}`) }}</span>
        </div>

        <!-- first name -->
        <div v-if="!isInviteBookmark" class="form-group col-md-3 mb-2 pr-0" :class="{'has-icon-small': errors.has(`invite.first_name_${index}`)}">
          <input
            ref="first_name"
            type="text"
            class="form-control"
            placeholder="First name"
            :name="`invite.first_name_${index}`"
            v-model="invite.first_name"
            v-validate="'min:2'"
            data-vv-delay="1000"
            data-vv-as="first name"
            :class="{'is-invalid': errors.has(`invite.first_name_${index}`)}"
          />

          <i v-show="errors.has(`invite.first_name_${index}`)" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has(`invite.first_name_${index}`)">{{ errors.first(`invite.first_name_${index}`) }}</span>
        </div>

        <!-- last name -->
        <div v-if="!isInviteBookmark" class="form-group col-md-3 mb-2 pr-0" :class="{'has-icon-small': errors.has(`invite.last_name_${index}`)}">
          <input
            ref="last_name"
            type="text"
            class="form-control"
            placeholder="Last name"
            :name="`invite.last_name_${index}`"
            v-model="invite.last_name"
            v-validate="'min:2'"
            data-vv-delay="1000"
            data-vv-as="last name"
            :class="{'is-invalid': errors.has(`invite.last_name_${index}`)}"
          />

          <i v-show="errors.has(`invite.last_name_${index}`)" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has(`invite.last_name_${index}`)">{{ errors.first(`invite.last_name_${index}`) }}</span>
        </div>

        <!-- rights dropdown -->
        <div v-if="isInviteBookmark && !isBulkInvite" class="col-md-2 role-editable text-left position-relative" :class="{'pr-0': newInvites.length > 1}">
          <button type="button" @click.prevent="toggleInviteRoles(invite, !invite.open, index)" class="btn btn-select-menu width--100per t-secondary" :class="{'open': invite.open}">
            <i class="fa t-sm" :class="{'fa-eye': invite.permission === 'member', 'fa-pencil': invite.permission === 'admin', 'fa-cog': invite.permission === 'owner'}"></i>
            <span class="pr-2 t-sm text-left">{{ getReadablePermission(invite.permission) }}</span>
          </button>

          <!-- render select menu -->
          <select-menu v-if="invite.open"
            :selectItems="permissionSelectItems"
            :parent="invite"
            :isWithCheckMark="true"
            @closed="toggleInviteRoles(invite, false)"
            @selected="updateRole"
            @hide="hide">
          </select-menu>
        </div>

        <div class="col-md-1">
          <a href="#" v-if="newInvites.length > 1" @click.prevent="removeNewInvite(index)"><i class="fa fa-times mt-2 t-secondary is-hover--red"></i></a>
        </div>
      </div>

      <!-- bulk invite -->
      <div class="row" v-if="isBulkInvite && isSingleScope">
        <div class="form-group col-md-12 mb-2" :class="{'has-icon-inline-label': bulkInvitesError}">
          <label class="t-500 t-sm mb-4">
            Enter multiple email addresses
            <i class='fa fa-info-circle ml-1 t-sm t-secondary' v-tooltip.top="`Please separate multiple addresses with commas.`"></i>
          </label>

          <div v-if="isInviteBookmark" class="col-md-2 role-editable pull-right mb-3 position-relative pr-0">
            <button type="button" @click.prevent="toggleBulkInviteRole(bulkInviteRole, !bulkInviteRole.open)" class="btn btn-select-menu width--100per t-secondary" :class="{'open': invite.open}">
              <i class="fa t-sm" :class="{'fa-eye': bulkInviteRole.permission === 'member', 'fa-pencil': bulkInviteRole.permission === 'admin', 'fa-cog': bulkInviteRole.permission === 'owner'}"></i>
              <span class="pr-2 t-sm text-left">{{ getReadablePermission(bulkInviteRole.permission) }}</span>
            </button>

            <!-- render select menu -->
            <select-menu v-if="bulkInviteRole.open"
              :selectItems="permissionSelectItems"
              :parent="bulkInviteRole"
              :isWithCheckMark="true"
              @closed="toggleInviteRoles(bulkInviteRole, false)"
              @selected="updateBulkInviteRole"
              @hide="hide">
            </select-menu>
          </div>

          <textarea
            class="form-control"
            name="bulkInvites"
            placeholder="name@example.com,name@example.com,name@example.com"
            v-model="bulkInvites"
            data-vv-delay="1000"
            data-vv-as="last name"
            :class="{'is-invalid': bulkInvitesError}">
          </textarea>

          <i v-show="bulkInvitesError" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="bulkInvitesError">{{ bulkInvitesError }}</span>
        </div>
      </div>

      <!-- switch between views -->
      <div class="row" v-if="isSingleScope">
        <div class="col-md-12">
          <p v-if="!isBulkInvite" class="mb-0">
            <a href="#" @click.prevent="addNewInvite()"><i class="fa fa-plus-circle t-blue pr-2"></i>Add another email address</a> or <a href="#" @click.prevent="openBulkInvite()">add many at once</a>
          </p>

          <p v-else class="mb-0">
            <a href="#" @click.prevent="isBulkInvite = !isBulkInvite"><i class="fa fa-plus-circle t-blue pr-2"></i>Add single email addresses</a>
          </p>
        </div>
      </div>

      <!-- personal message -->
      <div class="row">
        <div class="form-group col-md-12 mb-0" :class="{'has-icon-inline-label': errors.has('invite.message'), 'mt-3': isSingleScope}">
          <label class="t-500 t-sm">Personal message</label>
          <textarea
            class="form-control textarea-large"
            name="invite.message"
            :placeholder="getPlaceholderText()"
            v-model="invite.message"
            v-validate="'min:10'"
            data-vv-as="message"
            data-vv-delay="1000"
            :class="{'is-invalid': errors.has('invite.message')}">
          </textarea>

          <i v-show="errors.has('invite.message')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('invite.message')">{{ errors.first('invite.message') }}</span>
        </div>
      </div>

      <!-- action buttons -->
      <form-buttons :isRequest="isRequest"
        :isEditMode="false"
        :isCreateMode="true"
        :isRemove="false"
        :createText="getCreateText"
        :containerClass="`pt-3`"
        @cancel="$events.$emit('modal:close')">
      </form-buttons>
    </form>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'
import SelectMenu from '@/components/shared/SelectMenu'

import ResearchGroupService from '@/services/research_group'
import CommunityService from '@/services/community'

export default {
  name: 'inviteCreate',
  components: {
    StaticMessages,
    FormButtons,
    SelectMenu
  },
  props: {
    researchGroup: {
      type: Object,
      required: true
    },
    community: {
      type: Object,
      required: true
    },
    bookmarkFolder: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      scope: '',
      error: null,
      isRequest: false,
      isBulkInvite: false,
      isInviteBookmark: false,
      isInviteResearchGroup: false,
      isInviteCommunity: false,
      isInviteResearchGroupCommunity: false,
      bulkInvites: '',
      bulkInviteRole: {
        permission: 'member',
        open: false
      },
      bulkInvitesError: '',
      newInvites: [
        {
          id: 0,
          email: '',
          first_name: null,
          last_name: null,
          permission: 'member',
          open: false
        }, {
          id: 1,
          email: '',
          first_name: null,
          last_name: null,
          permission: 'member',
          open: false
        }
      ],
      invite: {
        message: '',
        people: []
      },
      permissionSelectItems: [
        {
          title: 'View',
          key: 'member',
          description: 'These people can only view the content of the folder.'
        }, {
          title: 'Edit',
          key: 'admin',
          description: 'These people can add items to the folder, delete these, and invite more people.'
        }, {
          title: 'Owner',
          key: 'owner',
          description: 'These people can add and delete any item, invite more people, and rename or delete the folder.'
        }
      ],
      bookmarkInvites: []
    }
  },
  created () {
    if (!this.isEmpty(this.researchGroup.name) && !this.isEmpty(this.community.name)) {
      this.scope = 'research_group_community'
      this.isInviteResearchGroupCommunity = true
    } else if (!this.isEmpty(this.researchGroup.name) && this.isEmpty(this.community.name)) {
      this.scope = 'research_group'
      this.isInviteResearchGroup = true
    } else if (this.isEmpty(this.researchGroup.name) && !this.isEmpty(this.community.name)) {
      this.scope = 'community'
      this.isInviteCommunity = true
    }
  },
  computed: {
    getCreateText () {
      return this.isSingleScope ? 'Send invitations' : 'Send invitation'
    },
    getName () {
      if (this.isInviteResearchGroup) return this.researchGroup.name
      return this.community.name
    },
    isSingleScope () {
      return !!(this.isInviteResearchGroup || this.isInviteCommunity)
    }
  },
  methods: {
    hide (e) {
      this.newInvites.map((i) => { i.open = false })
    },
    hideBulkInvite (e) {
      this.bulkInviteRole.open = false
    },
    getPlaceholderText () {
      return this.isInviteResearchGroupCommunity
        ? `Briefly describe why you want to add this research group.`
        : `Briefly describe what you want to achieve with this ${this.scope}.`
    },
    getReadablePermission (permission) {
      if (permission === 'owner') return 'Owner'
      if (permission === 'admin') return 'Edit'
      return 'View'
    },
    addNewInvite () {
      this.newInvites.push({
        id: this.newInvites.length,
        email: '',
        first_name: '',
        last_name: '',
        permission: 'member'
      })
    },
    openBulkInvite () {
      this.bulkInvites = Object.assign([], this.newInvites.filter(obj => !this.isEmpty(obj.email)).map(obj => obj.email))
      this.isBulkInvite = !this.isBulkInvite
    },
    removeNewInvite (index) {
      this.newInvites.splice(index, 1)
    },
    toggleInviteRoles (invite, open, index) {
      this.newInvites.map((i) => { i.open = false })
      invite.open = open
      this.$set(this.newInvites, index, invite)
    },
    toggleBulkInviteRole (invite, open) {
      this.bulkInviteRole.open = open
    },
    updateRole (invite, item) {
      let i = this.newInvites.findIndex((i) => i.id === invite.id)
      invite.permission = item.key
      invite.open = false
      this.$set(this.newInvites, i, invite)
    },
    updateBulkInviteRole (invite, item) {
      this.bulkInviteRole.open = false
      this.bulkInviteRole.permission = item.key
    },
    validateBeforeSubmit () {
      this.isSingleScope
        ? !this.isBulkInvite ? this.handleSingleInvite() : this.handleBulkInvite()
        : this.handleResearchGroupCommunityInvites()
    },
    buildInvite (email, firstName = null, lastName = null, role = 'member') {
      let obj = {}
      obj.email = this.$options.filters.lowercase(email)
      obj.first_name = firstName
      obj.last_name = lastName
      obj.role = role

      return obj
    },

    handleBulkInvite () {
      this.reset()

      let emails = []
      for (let email of this.bulkInvites.split(',')) {
        email = this.$options.filters.lowercase(email.trim())
        if (email) emails.push(email)
      }

      // add a custom validator
      this.$validator.attach({ name: 'bulkInvites', rules: 'required|email' })
      this.$validator.validateAll({ bulkInvites: emails }).then(result => {
        if (result) {
          for (let email of emails) this.invite.people.push(this.buildInvite(email.trim(), '', '', this.bulkInviteRole.permission))
          this.handleSendInvites()
        } else {
          this.bulkInvitesError = `You've entered an invalid email address`
        }
      })
    },
    handleSingleInvite () {
      this.reset()
      this.$validator.validateAll().then((result) => {
        for (let invite of this.newInvites) {
          if (invite.email) this.invite.people.push(this.buildInvite(invite.email, invite.first_name, invite.last_name, invite.permission))
        }

        if (this.invite.people.length <= 0 && this.bulkInvitesError.length <= 0) {
          result = false
          this.error = 'You need to add at least one email address'
        }

        if (result && !this.isRequest) this.handleSendInvites()
      })
    },
    handleSendInvites () {
      if (!this.isRequest) {
        this.isRequest = true
        if (this.isInviteResearchGroup) this.handleResearchGroupInvites()
        if (this.isInviteCommunity) this.handleCommunityInvites()
        if (this.isInviteResearchGroupCommunity) this.handleResearchGroupCommunityInvites()
      }
    },
    handleResearchGroupInvites () {
      ResearchGroupService.createInvite(this.researchGroup.slug_values[0], this.invite).then(invite => {
        // update team member count
        if (invite.invites.length > 0) {
          this.$events.$emit('research_group:updated', 'users', this.researchGroup.users + invite.invites.length)
        }

        this.$events.$emit('research_group:invite:updated', invite)
        this.$events.$emit('modal:close')
        this.isRequest = false
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleCommunityInvites () {
      CommunityService.createUserInvite(this.community.slug_values[0], this.invite).then(invite => {
        this.isRequest = false

        if (invite.invites.length > 0) {
          this.$events.$emit('community:updated', 'users', this.community.researchers_count + this.invite.people.length)
        }

        this.$events.$emit('community:invite:updated', invite)
        this.$events.$emit('modal:close')
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    handleResearchGroupCommunityInvites () {
      let invite = { message: this.invite.message, research_group_ids: [ this.researchGroup.slug_values[0] ] }
      CommunityService.createResearchGroupInvite(this.community.slug_values[0], invite).then(i => {
        this.isRequest = false
        this.$events.$emit('modal:close')
        this.$events.$emit('research_group:community:invited', i.invites[0])
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    reset () {
      this.invite.people = []
      this.bulkInvitesError = ''
    },
    fillInUser (email, index) {
      if (!this.isInviteBookmark) {
        let name = this.getNameFromEmail(email)
        if (!this.newInvites[index]['first_name']) this.newInvites[index]['first_name'] = name.firstName
        if (!this.newInvites[index]['last_name']) this.newInvites[index]['last_name'] = name.lastName
      }
    }
  }
}
</script>
