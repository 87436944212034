<template>
  <div>
    <h2 class="t-primary t-400 mb-1">Search by specific fields</h2>
    <p>
      To eliminate noise in your results, you can search by specific fields.
    </p>
    <hr class="hr-small mb-4" />
    <div class="d-flex align-items-center mb-2">
      <checkbox
        :text="'All fields'"
        :name="'all'"
        :value="!hasActiveFields"
        :isDisabled="!hasActiveFields"
        @toggle="checkAll"
      />
      <i class="ml-4 t-sm fa fa-info-circle t-secondary mr-2" />
      <a class="t-sm" href="https://www.academiclabs.com/search-by-specific-fields-info-page" target="_blank">See all the fields we search by</a>
    </div>

    <span class="t-500">Specific fields in {{ getIndexAsString(index) }}:</span>

    <div class="row">
      <div class="col-6 col-md-4 my-1" v-for="field in localFields" :key="field.name">
        <checkbox
          :text="field.name"
          :name="field.name"
          :tooltip="field.tooltip"
          :value="field.active"
          @toggle="checkField(field)"
        />
      </div>
    </div>

    <!-- action buttons -->
    <form-buttons
      :createText="`Apply`"
      :containerClass="`mt-4 bd-t-none`"
      @cancel="$events.$emit('modal:close')"
      @save="applySearchByFields"
    />
  </div>
</template>

<script>
import FormButtons from '@/components/shared/form/Buttons'
import Checkbox from '@/components/shared/form/Checkbox2'

export default {
  name: 'searchByFieldModal',
  components: {
    FormButtons,
    Checkbox
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    index: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      localFields: []
    }
  },
  mounted () {
    this.localFields = [...this.fields]
  },
  computed: {
    hasActiveFields () {
      return !!this.localFields.find(f => f.active)
    }
  },
  methods: {
    checkField (field) {
      field.active = !field.active
    },
    checkAll () {
      if (!this.hasActiveFields) return
      this.localFields.forEach(field => {
        field.active = false
      })
    },
    applySearchByFields () {
      this.$events.$emit('search_by_fields:update', this.localFields.filter(f => f.active).map(f => f.name))
      this.$events.$emit('modal:close')
    }
  }
}
</script>
