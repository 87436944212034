<template>
  <!-- All logic regarding this tooltip is defined in the filterTooltips.js -->
  <div class="checkbox"
    v-tooltip.right-start="linkableOption ? {
      content: getContent,
      container: 'body',
      boundariesElement: 'body',
      delay: { show: 300, hide: 300 },
      autoHide: false,
      popperOptions: {
        onCreate: fetchTooltipData
      },
      classes: ['filter-card-tooltip']
    } : {}"
    :class="{ 'cursor-disabled': isLocked }"
  >
    <template v-if="!isWithTotalCount">
      <input
        type="checkbox"
        :name="parsedName"
        :id="parsedId"
        :checked="isActive"
        @click="$emit('selected', bucket, filterName)"
      />
      <label :for="parsedId" class="checkbox_label" :title="handleTitle" />

      <label
        :for="parsedId"
        class="d-flex align-items-center t-overflow justify-content-between w-100 mb-0 pl-2 cursor-pointer"
        :class="{ 't-blue': bucket.active }"
        :title="handleTitle"
        v-tooltip.right-end="bucket.tooltip ? {
          content: bucket.tooltip,
          container: 'body',
          boundariesElement: 'body',
          classes: ['hide-for-touch d-none d-md-block']
        } : {}"
      >
        <span class="t-overflow">
          <flag v-if="renderFlag" :iso="parsedName" :squared="false" class="t-sm bd-radius pr-2" />
          <span :class="{'pl-2': renderFlag}">{{ getValue }}</span>
        </span>
        <span class="text-nowrap" :class="{'t-blue': bucket.active, 't-secondary': !bucket.active}">
          <span v-if="bucket.new" class="label-base label-filter-header bg-yellow">NEW</span>
          {{ !hideCount ? getCount : '' }}<i class="fa fa-lock t-secondary ml-2" v-if="isLocked" />
        </span>
      </label>

      <span>
        <span class="bg-white h-100 position-absolute px-3" v-if="isLoading" style="right: 0px">
          <i class="fa fa-spinner fa-spin t-secondary" />
        </span>
        <span v-else-if="gotTooltip" class="checkbox_link px-1">
          <router-link
            v-if="linkable"
            :to="linkable.link"
            class="btn btn-sm btn-outline-primary m-1 py-0 t-600"
          >open page</router-link>
          <router-link
            v-else-if="searchable"
            :to="searchable"
            class="btn btn-sm btn-outline-primary m-1 py-0 t-600"
          ><i class="fa fa-search mr-1" />search</router-link>
        </span>
      </span>
    </template>

    <template v-else>
      <input
        type="checkbox"
        :name="parsedName"
        :id="parsedId"
        :checked="isActive"
        class="d-none"
        @click="$emit('selected', bucket, filterName)"
      />
      <label :for="parsedId" class="checkbox_label mr-2 mt-1" :title="handleTitle" />
      <label
        :for="parsedId"
        class="d-flex align-items-center t-overflow justify-content-between w-100 cursor-pointer"
        :class="{'t-blue': bucket.active}"
        :title="getValue"
      >
        <span class="t-overflow p-0">{{ getValue }}</span>
        <span class="t-secondary text-nowrap">
          <span class="t-regular t-500">{{ bucket.count }}</span> / {{ bucket.total }}
        </span>
      </label>
    </template>
  </div>
</template>

<script>
import tooltipProcessing from '@/mixins/search/filterTooltips'

export default {
  name: 'checkbox',
  mixins: [tooltipProcessing],
  props: {
    filterName: {
      type: String,
      required: true
    },
    isLocked: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: false
    },
    bucket: {
      type: Object,
      required: true
    },
    isWithTotalCount: {
      type: Boolean,
      required: false
    },
    hideCount: {
      type: Boolean,
      required: false,
      default: false
    },
    renderFlag: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getCount () {
      return this.$options.filters.formatNumberShort(this.bucket.count)
    },
    isActive () {
      return this.bucket.active
    },
    parsedName () {
      return this.isEmpty(this.name) ? this.bucket.value : this.name
    },
    parsedId () {
      return `${this.filterName}--${this.parsedName}`
    },
    handleTitle () {
      if (this.bucket.description) return !this.gotTooltip && this.bucket.description // show description title only when there is no tooltip
      return this.getValue
    },
    getValue () {
      return !this.isEmpty(this.bucket.label) ? this.$options.filters.capitalize(this.$options.filters.humanize(this.bucket.label)) : this.$options.filters.capitalize(this.$options.filters.humanize(this.bucket.value))
    }
  }
}
</script>
