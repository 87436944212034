<template>
  <header class="app-header" :class="headerColor">
    <div class="app-header_show container-fluid navbar navbar-expand position-relative ">
      <div class="navbar-header clearfix" :class="{'d-none d-sm-block': hideLogo}">
        <router-link :to="{ name: 'searchHome' }" class="navbar-brand">
          <i v-if="warningEnv" class="fa fa-warning t-yellow pr-2 pt-1 t-sm" v-tooltip:bottom="`You are working in a test environment!`" /><icon :icon="icon" :width="15" :height="15" class="al-logo" />
          <span class="al-logo-text ml-2">AcademicLabs</span>
        </router-link>
      </div>

      <!-- render search bar -->
      <bar-header-navigation v-if="isSearchBar"/>

      <div class="navbar-container container-fluid">
        <nav-bar />
      </div>

      <!-- render flash -->
      <flash />
    </div>
  </header>
</template>

<script>
import NavBar from '@/components/shared/header/NavBar'
import Icon from '@/components/shared/Icon'
import BarHeaderNavigation from '@/components/search/BarHeader'
import Flash from '@/components/shared/Flash'

export default {
  name: 'appHeader',
  components: {
    NavBar,
    BarHeaderNavigation,
    Icon,
    Flash
  },
  props: {
    isSearchBar: {
      type: Boolean,
      default: false
    },
    headerColor: {
      type: String,
      default: 'bg-blue-green-gradient'
    }
  },
  computed: {
    warningEnv () {
      return process.env.VUE_APP_DEPLOY_ENV === 'staging'
    },
    icon () {
      return (this.headerColor === 'bg-white') ? 'a-gradient' : 'a-white'
    },
    hideLogo () {
      return !(this.$route.name === 'searchHome' || this.$route.name === 'search')
    }
  }
}
</script>
