<template>
  <div class="card card--trend mb-2">
    <h3 class="t-overflow text-nowrap p-3 t-500" :title="parsedTrend.name">{{ parsedTrend.name }}</h3>
    <flex-chart
      :isLoaded="isLoaded"
      :graphData="parsedTrend.data || []"
      :name="'Publications'"
      :tooltipLabel="getTooltipLabel(parsedTrend.name)"
      chartType="line"
      color="#43AEC5"
      :yField="yField"
      yLabel="normalisedCount"
      withFill
      isSmall
      hideYAxis
      :withTooltip="withTooltip"
    />
  </div>
</template>

<script>
import FlexChart from '@/components/shared/chart/FlexChart'

export default {
  name: 'trend',
  components: {
    FlexChart
  },
  props: {
    state: {
      type: Object,
      required: true
    },
    trend: {
      type: Object,
      required: true
    },
    withTooltip: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      yField: 'normalisedCount',
      isLoaded: false,
      parsedTrend: {}
    }
  },
  mounted () {
    this.$nextTick(() => this.parseData())
  },
  watch: {
    trend (newVal, oldVal) {
      if (newVal !== oldVal && newVal) this.parseData()
    }
  },
  methods: {
    getTooltipLabel (trendName) {
      const index = this.state.index
      switch (index) {
        case 'publications':
          return `[bold]{normalisedCount}% [/] of Publications in [bold]{year}[/] mention ${trendName}`
        case 'research_groups':
          return `[bold]{normalisedCount}% [/] of Research Groups have worked on ${trendName} in [bold]{year}[/]`
        case 'researchers':
          return `[bold]{normalisedCount}% [/] of Researchers have worked on ${trendName} in [bold]{year}[/]`
        case 'organisations':
          return `[bold]{normalisedCount}% [/] of Companies have worked on ${trendName} in [bold]{year}[/]`
        default:
          return `[bold]{normalisedCount}% [/] in [bold]{year}[/]`
      }
    },
    parseData () {
      this.isLoaded = false

      this.parsedTrend = {
        ...this.trend,
        data: this.trend.data.sort((a, b) => b.year - a.year).map(d => {
          return {
            year: d.year.toString(),
            count: d.count,
            normalisedCount: Math.round((d.count / d.total) * 10000) / 100 // Trick to round numbers to 2 digits while preserving Number prototype
          }
        })
      }

      this.isLoaded = true
    }
  }
}
</script>
