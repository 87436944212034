<template>
  <ul v-if="!isEmpty(items)" class="pl-0 mb-0">
    <li class="list-inline-item mr-0"
      :class="{ 't-white': secondaryColor }"
    >
      <span v-for="(item, index) in itemsToRender" :key="index" class="mr-2">
        <i v-if="item.organisation_types" class="fa t-sm pr-2" :class="[!secondaryColor ? 't-secondary' : '', renderOrganisationCategoryIcon(item.organisation_types)]" />
        <i v-else class="fa t-sm pr-2" :class="[!secondaryColor ? 't-secondary' : '', listIcon]" />
        <router-link
          v-if="item.slug_values"
          v-html="item.name"
          v-tooltip="item.original_name ? item.original_name : ''"
          :to="{name: routeName, params: { id: item.slug_values[0] }}"
          :class="{ 't-white': secondaryColor }"
        />
        <span v-else
          v-html="item.name"
          v-tooltip="item.original_name ? item.original_name : ''"
        />
        <span v-if="showRoles && item.role"> ({{ item.present ? '' : 'former ' }}{{ item.role }})</span>
        <a v-if="showWebsites && item.website" class="fa fa-link rounded-circle--link ml-1" :href="item.website | formatWebsite" target="_blank"></a>
        <span class="mr-2">
          <flag :title="renderCountryFlagTooltip(item.country)" v-if="showFlags && item.country" :iso="item.country.alpha_2 | lowercase" :squared="false" class="bd-radius t-xxs ml-1" />
        </span>
      </span>
      <span>{{ canCollapse && isCollapsed ? '...' : '' }}</span>
      <a v-if="canCollapse"
        href="#"
        @click.prevent="isCollapsed = !isCollapsed"
        class="pl-2 t-500"
        :class="{ 't-white': secondaryColor }"
      >{{ isCollapsed ? 'See more' : 'See less' }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'linkedListCollapsible',
  props: {
    scope: {
      type: String,
      default: 'organisation'
    },
    items: {
      type: Array,
      required: true
    },
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    secondaryColor: {
      type: Boolean,
      required: false,
      default: false
    },
    showFlags: {
      type: Boolean,
      required: false,
      default: false
    },
    showRoles: {
      type: Boolean,
      required: false,
      default: false
    },
    showWebsites: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isCollapsed: true
    }
  },
  computed: {
    listIcon () {
      switch (this.scope) {
        case 'researcher':
          return 'fa-user'
        case 'research_group':
          return 'fa-users'
        case 'organisation':
        default:
          return 'fa-university'
      }
    },
    routeName () {
      switch (this.scope) {
        case 'researcher':
          return 'researcher.show'
        case 'research_group':
          return 'research_group.show'
        case 'organisation':
        default:
          return 'organisation.show'
      }
    },
    itemsToRender () {
      if (this.canCollapse && this.isCollapsed) {
        return this.items.slice(0, 3)
      }
      return this.items
    },
    canCollapse () {
      if (!this.collapsible) return false
      // If there's more than 4 items, we only show the first 4 and collapse the rest
      return this.items && this.items.length > 4
    }
  }
}
</script>
