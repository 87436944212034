<template>
  <footer class="app-footer py-3">
    <div class="app-body_show container-fluid">
      <footer-content></footer-content>
    </div>
  </footer>
</template>

<script>
import FooterContent from '@/components/shared/footer/Content'

export default {
  name: 'appFooter',
  components: {
    FooterContent
  }
}
</script>
