<template>
  <li class="nav-item dropdown d-none d-sm-block">
    <dropdown
      placement="bottom-end"
      noPadding
    >
      <template #button>
        <a href="#" @click.prevent class="nav-link" aria-haspopup="true" aria-expanded="false" id="support-header-dropdown">
          <i class="fa fa-question-circle mr-sm-2" />
          <span class="t-500 d-none d-lg-inline-block">Help</span>
        </a>
      </template>
      <div class="dropdown-menu-custom box-shadow dropdown-create" aria-labelledby="support-header-dd">
        <a
        href="https://academiclabscom.notion.site/What-s-New-b87919b67e7f4734b21c64afeb6bdb89"
        target="_blank"
        class="dropdown-item clearfix"
        @click="track('What\'s new')"
      >
        <span class="dropdown-item_icon pull-left">
          <i class="fa fa-gift t-secondary pt-0"></i>
        </span>
        <span class="dropdown-item_text pull-left">
          <span class="d-block t-600">What's new</span>
          <span class="t-secondary t-sm">Discover our latest updates.</span>
        </span>
      </a>

      <a
        v-if="$currentUser && $currentUser.cookie_analytics"
        href="#"
        @click.prevent="clickChatSupport"
        class="dropdown-item clearfix"
      >
        <span class="dropdown-item_icon pull-left">
          <i class="fa fa-comment t-secondary pt-0"></i>
        </span>
        <span class="dropdown-item_text pull-left">
          <span class="d-block t-600">Chat support</span>
          <span class="t-secondary t-sm">We'll be happy to answer any questions you might have.</span>
        </span>
      </a>

      <a
        href="https://academiclabscom.notion.site/Help-Support-3cd2c628902342e6a1d6edc199920b93"
        target="_blank"
        class="dropdown-item clearfix"
        @click="track('Help center')"
      >
        <span class="dropdown-item_icon pull-left">
          <i class="fa fa-lightbulb-o t-secondary pt-0"></i>
        </span>

        <span class="dropdown-item_text pull-left">
          <span class="d-block t-600">Help Center</span>
          <span class="t-secondary t-sm">Find answers fast, or book a meeting with us.</span>
        </span>
      </a>
      </div>
    </dropdown>
  </li>
</template>

<script>
import MPService from '@/services/mixpanel'
import Dropdown from '@/components/shared/form/Dropdown'

export default {
  name: 'headerSupportDropdown',
  components: {
    Dropdown
  },
  methods: {
    clickChatSupport () {
      this.$alIntercom.show()
      this.track('Chat support')
    },
    track (type) {
      MPService.trackEvent('support', {
        type: type
      })
    }
  }
}
</script>
