import AuthService from '@/services/auth'

const Base = () => import(/* webpackChunkName: "researchGroup" */ '@/views/research_group/Base.vue')
const Show = () => import(/* webpackChunkName: "researchGroup" */ '@/views/research_group/Show.vue')
const Explore = () => import(/* webpackChunkName: "researchGroup" */ '@/views/research_group/Explore.vue')
const Team = () => import(/* webpackChunkName: "researchGroup" */ '@/views/research_group/Team.vue')
const Invite = () => import(/* webpackChunkName: "researchGroupEssential" */ '@/views/research_group/Invite.vue')
// const Webpages = () => import(/* webpackChunkName: "organisation" */ '@/views/research_group/Webpages.vue')

export default {
  path: '/research_group',
  name: 'research_group',
  meta: {
    auth: false,
    render: {
      title: 'Research Group'
    }
  },
  redirect: 'research_group.show',
  beforeEnter: (to, from, next) => {
    if (to.meta.permissions) {
      AuthService.permissions({ type: 'research_group', id: to.params.id }).then(permissions => {
        return to.meta.permissions.role.split(',').includes(permissions.role.rw_access)
          ? next()
          : next({ name: to.meta.permissions.redirectTo, params: { id: to.params.id } })
      }).catch(() => {
        next({ name: to.meta.permissions.redirectTo, params: { id: to.params.id } })
      })
    } else {
      return next()
    }
  },
  component: Base,
  children: [
    {
      path: ':id',
      name: 'research_group.show',
      meta: {
        auth: false,
        searchModal: true,
        render: {
          searchBar: true,
          title: 'About'
        }
      },
      component: Show
    }, {
      path: ':id/explore/:category?/:buckets(.*)*',
      name: 'research_group.explore',
      meta: {
        auth: false,
        skipMixPanel: true,
        searchContainer: true,
        render: {
          searchBar: true,
          title: 'Research',
          tooltip: 'Explore funded projects, ongoing research, publications and more'
        }
      },
      component: Explore
    }, {
      path: ':id/team',
      name: 'research_group.team',
      meta: {
        auth: false,
        render: {
          searchBar: true,
          title: 'Explore members'
        },
        permissions: {
          role: 'owner,admin',
          redirectTo: 'research_group.show'
        }
      },
      component: Team
    }, {
      path: ':id/invite',
      name: 'research_group.invite',
      meta: {
        auth: false,
        render: {
          searchBar: true,
          title: 'Manage invitations',
          tooltip: 'Invite members and see an overview of all invites sent and their current status'
        },
        permissions: {
          role: 'owner,admin',
          redirectTo: 'research_group.show'
        }
      },
      component: Invite
    }
  ]
}
