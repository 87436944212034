import staticData from '@/assets/js/_data'
import { hasMatchingSlug } from '@/plugins/utils'

export default {
  install (Vue, options) {
    let isPublic = false

    // Roles for profile management, based on ownership
    const owner = 'owner'
    const admin = 'admin'
    const member = 'member'

    // App permissions/roles, based on user type
    /*
    const APP_PERMISSIONS = [
      # 'fullSearchResults',
      # 'viewFilters',
      # 'useFilters',
      # 'useQuickFilters',
      # 'useRefineTerms',
      # 'viewTrendsModal',
      # 'viewMapModal',
      # 'compareExpertise',
      # 'bulkBookmarkSave',
      # 'export',
      # 'sorting'
      # 'promptAI',
      # 'viewLandscape'
    ]

    const APP_ROLES = [
      'unregistered',
      'free', // non verified academic, student, personal interest
      'trial',
      'premium',
      'verified_academic'
    ]
    */

    const PERMISSIONS = {
      'unregistered': [],
      'free': ['viewFilters', 'compareExpertise'],
      'trial': [
        'viewFullSearchResults', 'viewFilters', 'useFilters', 'useRefineTerms', 'viewTrendsModal',
        'viewMapModal', 'compareExpertise', 'promptAI', 'inviteToAL', 'viewAllNewsResults', 'viewLandscape'
      ],
      'verified_academic': [
        'viewFullSearchResults', 'viewFilters', 'useFilters', 'useRefineTerms', 'viewTrendsModal',
        'viewMapModal', 'compareExpertise', 'bulkBookmarkSave', 'inviteToAL', 'viewLandscape',
        'viewAllNewsResults'
      ],
      'premium': [
        'viewFullSearchResults', 'viewFilters', 'useFilters', 'useRefineTerms', 'viewTrendsModal',
        'viewMapModal', 'compareExpertise', 'bulkBookmarkSave', 'export', 'promptAI', 'inviteToAL',
        'viewLandscape', 'giveFreeAccess', 'viewAllNewsResults'
      ]
    }

    Vue.prototype.$can = function (permission) {
      const role = Vue.prototype.$currentUser?.user_type ?? 'unregistered'

      // Check if user is allowed to do `permission`
      return PERMISSIONS[role]?.indexOf(permission) > -1
    }

    Object.assign(Vue.prototype.$can, {
      notAllowed (type = null) {
        if (Vue.prototype.$currentUser) {
          Vue.prototype.$events.$emit('modal:pro_generic:open', type)
        } else {
          Vue.prototype.$events.$emit('modal:login:open')
        }
      },
      roles () {
        return staticData.role
      },
      getRole (role) {
        return this.roles().find(r => r.value === role)
      },
      seeUrl (permissions, scope, role) {
        if (isPublic) role = 'public_by_user'
        return permissions ? permissions.role.split(',').includes(role) : true
      },
      view (scope, role) {
        if (!role) return false

        let can = false
        switch (scope) {
          case 'research_group':
          case 'research_groups':
            // must be owner or admin to edit
            if (role === owner || role === admin || role === member) can = true

            break

          case 'community':
          case 'communities':
            if (role === owner || role === admin || role === member) can = true

            break

          case 'organisation':
          case 'organisations':
            // must be owner or admin to edit
            if (role === owner || role === admin || role === member) can = true

            break

          default:
            can = false
            break
        }

        return can
      },
      edit (scope, role, parentRole) {
        if (!role) return false

        // requested as public
        if (isPublic) role = 'public_by_user'

        let can = false
        switch (scope) {
          case 'bookmark':
            // must be owner to edit
            can = role === owner
            break

          case 'researcher':
          case 'researchers':
            // must be owner to edit
            can = role === owner
            break

          case 'organisation':
          case 'organisations':
            // must be owner to edit
            can = role === owner
            break

          case 'flag':
            // must be admin to edit
            can = role === admin
            // can = role === admin
            break

          case 'list':
            // must be owner to edit
            can = role === owner
            break

          case 'research_group':
          case 'research_groups':
            if (parentRole !== undefined) {
              if (this.getRole(role)) {
                if (this.getRole(role).key >= 1) can = true
              }
            } else {
              // must be owner or admin to edit
              if (role === admin || role === owner) can = true
            }

            break

          case 'community':
          case 'communities':
            if (parentRole !== undefined) {
              if (this.getRole(role)) {
                if (this.getRole(role).key >= 1) can = true
              }
            } else {
              // must be owner or admin to edit
              if (role === admin || role === owner) can = true
            }

            break

          default:
            can = false
            break
        }

        return can
      },
      editBySlug (scope, slugValues, currentUserSlugValues) {
        if (!slugValues || !currentUserSlugValues) return false

        let can = false
        switch (scope) {
          case 'question':
            // role is slug
            can = hasMatchingSlug(slugValues, currentUserSlugValues)
            break

          default:
            can = false
            break
        }

        return can
      },
      setAsPublic (val) {
        isPublic = val
      },
      getAsPublic () {
        return isPublic
      },
      postOpportunity (scope, role) {
        if (!role) return false

        let can = false
        switch (scope) {
          case 'community':
          case 'communities':
            // members and up can post opportunities
            if (role === admin || role === owner || role === member) can = true
            break
          default:
            can = false
            break
        }

        return can
      },
      invite (scope, role) {
        if (!role) return false

        let can = false
        switch (scope) {
          case 'bookmark':
            // must be owner to edit
            can = (role === owner || role === admin)
            break

          default:
            can = false
            break
        }

        return can
      }
    })
  }
}
