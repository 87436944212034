<template>
  <div>

    <h2 class="t-grey mb-4 t-600">
      Landscape view - a powerhouse of insights
    </h2>

    <video autoplay loop>
      <source :src="require('@/assets/media/landscape-teaser.mp4')" type="video/mp4">
      Your borwser does not support loading this video
    </video>

    <h2 class="t-grey my-4 t-600">
      Discover how it will help you:
    </h2>

    <div class="row">
      <div class="col-12 col-md-6">
        <i class="fa fa-video-camera t-secondary mr-2" />
        <a href="https://www.youtube.com/watch?v=E_SZRATeD7Q" target="_blank">Introduction</a>
      </div>
      <div class="col-12 col-md-6">
        <i class="fa fa-video-camera t-secondary mr-2" />
        <a href="https://www.youtube.com/watch?v=E_SZRATeD7Q&t=240s" target="_blank">Researchers Landscape View</a>
      </div>
      <div class="col-12 col-md-6">
        <i class="fa fa-video-camera t-secondary mr-2" />
        <a href="https://www.youtube.com/watch?v=E_SZRATeD7Q&t=27s" target="_blank">Research Groups Landscape View</a>
      </div>
      <div class="col-12 col-md-6">
        <i class="fa fa-video-camera t-secondary mr-2" />
        <a href="https://www.youtube.com/watch?v=E_SZRATeD7Q&t=467s" target="_blank">Companies Landscape View</a>
      </div>
    </div>

    <div class="d-flex justify-content-end align-items-center mt-4">
      <button
        class="btn bg-transparent no-border t-primary mr-3"
        type="button"
        @click="close"
      >Close</button>

      <a class="btn btn-primary px-4"
        href="https://www.academiclabs.com/academiclabs-get-started"
        target="_blank"
      >Book a demo</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'landscapePreviewModal',
  methods: {
    close () {
      this.$events.$emit('modal:close')
    }
  }
}
</script>
