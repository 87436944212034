<template>
  <router-link
    :to="{ name: `${routeName}.show`, params: { id: data.slug_values[0] }}"
    class="d-block mb-0 bd-radius list-bg px-2 py-1"
    v-tooltip.right="`${data.publications_count} publications`"
  >
    <i class="fa fa-user t-secondary pr-2"></i> {{ getFullName(data) }}
    <ul class="m-0 pl-4 t-xs t-grey" v-if="parsedResearchGroups || data.organisations">
      <!-- research groups -->
      <li class="list-unstyled" v-if="parsedResearchGroups">
        <div v-for="(researchGroup, index) in parsedResearchGroups" :key="`${data.slug_values[0]}-rg-${index}`">
          <i class="fa fa-users t-secondary pr-2"></i>{{ researchGroup.name }}
        </div>
      </li>

      <!-- organisation -->
      <li class="list-unstyled" v-if="data.organisations">
        <div v-for="(organisation, index) in data.organisations" :key="`${data.slug_values[0]}-org-${index}`">
          <i class="fa fa-university t-secondary pr-2"></i>{{ organisation.name }}
          <flag :title="renderCountryFlagTooltip(organisation.country)" v-if="organisation.country" :iso="organisation.country.alpha_2 | lowercase" :squared="false" class="flag bd-radius t-sm ml-1" />
        </div>
      </li>
    </ul>
  </router-link>
</template>

<script>
export default {
  name: 'researcherResultCompact',
  props: {
    routeName: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    parsedResearchGroups () {
      if (this.data.research_groups) {
        return this.data.research_groups.filter(rg => rg.slug_values && rg.slug_values.length > 0)
      }
      return null
    }
  }
}
</script>
