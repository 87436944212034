import { Validator } from 'vee-validate'

Validator.extend('to_year', {
  getMessage: field => 'The ' + field + ' value is not valid',
  validate: (toYear, fromYearField) => {
    // parse toYear
    let fromYear = new Date().getFullYear()
    if (fromYearField[0]) fromYear = parseInt(fromYearField[0])

    // cannot be in the future
    if (toYear > new Date().getFullYear()) return false

    // cannot be lower than from year
    return !(toYear < fromYear)
  }
})

Validator.extend('from_year', {
  getMessage: field => 'The ' + field + ' value is not valid',
  validate: (fromYear, toYearField) => {
    // parse toYear
    let toYear = new Date().getFullYear()
    if (toYearField[0]) toYear = parseInt(toYearField[0])

    // cannot be in the future
    if (fromYear > new Date().getFullYear()) return false

    // cannot be lower than to year
    return !(toYear < fromYear)
  }
})

Validator.extend('year', {
  getMessage: field => 'The ' + field + ' value is not valid',
  validate: (year) => {
    // cannot be in the future
    if (year > new Date().getFullYear()) return false

    return true
  }
})

Validator.extend('slug', {
  getMessage: field => 'The ' + field + ' value is not valid. Only - and lowercase is allowed.',
  validate: (slug) => {
    let regex = new RegExp(/^[a-z0-9]+(?:(-|_)[a-z0-9]+)*$/)
    return regex.test(slug)
  }
})
