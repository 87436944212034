import VueRouter from 'vue-router'

// containers
import AppBase from '@/containers/AppBase'

// app routes
import researchGroupRoutes from '@/router/research_group'
import mainRoutes from '@/router/main'
import researcherRoutes from '@/router/researcher'
import communityRoutes from '@/router/community'
import organisationRoutes from '@/router/organisation'
import createRoutes from '@/router/create'

// login / signUp / account routes
import loginRoutes from '@/router/login'
import signUpRoutes from '@/router/signup'
import onboardingRoutes from '@/router/onboarding'

const Page401 = () => import(/* webpackChunkName: "appEssential" */ '@/containers/401.vue')
const Page403 = () => import(/* webpackChunkName: "appEssential" */ '@/containers/403.vue')
const Page404 = () => import(/* webpackChunkName: "appEssential" */ '@/containers/404.vue')
const Page429 = () => import(/* webpackChunkName: "appEssential" */ '@/containers/429.vue')
const Page500 = () => import(/* webpackChunkName: "appEssential" */ '@/containers/500.vue')

const baseRoutes = [
  {
    path: '/',
    name: 'home',
    redirect: '/login',
    component: AppBase,
    meta: {
      auth: false
    },
    // All the children routes for template Full will be placed here
    children: [].concat(
      mainRoutes,
      researchGroupRoutes,
      researcherRoutes,
      communityRoutes,
      organisationRoutes,
      createRoutes
    )
  }, {
    path: '/401',
    name: '401',
    meta: {
      skipParams: true,
      auth: false
    },
    component: Page401
  }, {
    path: '/403',
    name: '403',
    meta: {
      skipParams: true,
      auth: false
    },
    component: Page403
  }, {
    path: '/404',
    name: '404',
    meta: {
      skipParams: true,
      auth: false
    },
    component: Page404
  }, {
    path: '/429',
    name: '429',
    meta: {
      skipParams: true,
      auth: false
    },
    component: Page429
  }, {
    path: '/500',
    name: '500',
    meta: {
      skipParams: true,
      auth: false
    },
    component: Page500
  }, {
    path: '*',
    redirect: '/404',
    meta: {
      skipParams: true,
      auth: false
    }
  }
]

export default new VueRouter({
  mode: 'history',
  linkActiveClass: 'active-main',
  linkExactActiveClass: 'active',
  routes: baseRoutes.concat(
    loginRoutes,
    signUpRoutes,
    onboardingRoutes
  ),
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    if (from.name !== to.name) return { x: 0, y: 0 }
  }
})
