<template>
  <div class="">
    <h2 class="t-primary t-400 mb-0" v-if="!isEditMode">Create a new bookmark folder</h2>
    <h2 class="t-primary t-400 mb-0" v-else>Edit {{ bookmarkFolder.name }}</h2>
    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <!-- render form -->
    <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <div class="form-group" :class="{'has-icon-label': errors.has('name') }">
        <label class="t-500 t-sm label-required">
          Folder name
        </label>
        <input
          type="text"
          class="form-control"
          placeholder="Enter a folder name"
          name="name"
          v-model="name"
          v-validate="'required|min:3|max:100'"
          data-vv-validate-on="blur"
          :class="{'is-invalid': errors.has('name')}"
          ref="nameInput"
        />
        <i v-show="errors.has('name')" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('name')">{{ errors.first('name') }}</span>
      </div>

      <!-- description-->
      <div class="form-group" :class="{'has-icon-label': errors.has('description') }">
        <label class="t-500 t-sm">
          Description
        </label>

        <textarea
          class="form-control"
          name="description"
          placeholder="Describe your folder"
          v-model="description"
          v-validate="'min:3|max:2000'"
          data-vv-validate-on="blur"
          :class="{'is-invalid': errors.has('description')}">
        </textarea>

        <i v-show="errors.has('description')" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('description')">{{ errors.first('description') }}</span>
      </div>

      <!-- input tags -->
      <div class="form-group mb-4" :class="{'has-icon-label': errors.has('tags') }">
        <label class="t-500 t-sm">
          Tag your folder
          <i class='fa fa-info-circle ml-1 t-sm t-secondary' v-tooltip.top="`Tag your folder, at least one, max 5 tags`"></i>
        </label>
        <input-tag
          :tags.sync="tags"
          :limit="tagLimit"
          :class="{'is-invalid': errors.has('tags')}"
          placeholder="Enter tag by using Comma, Tab or Return">
        </input-tag>

        <i v-show="errors.has('tags') || tagLimit === tags.length" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('tags')">{{ errors.first('tags') }}</span>
        <span  v-if="tagLimit === tags.length" class="t-regular d-inline-block t-sm mt-2 pl-2">Maximum tags reached</span>
      </div>

      <div class="clearfix">
        <div class="form-group mb-0 position-relative">
          <label class="t-500 t-sm d-block">
            Privacy settings
          </label>

          <switches v-model="isPublic"
            :text-enabled="`Public: Full folder is visible to your profile visitors`"
            :text-disabled="`Private: Folder is for your eyes only`">
          </switches>
        </div>
      </div>

      <!-- action buttons -->
      <div class="clearfix pt-3">
        <a v-if="isEditMode" @click.prevent="showDeleteConfirmation" href="#" class="t-regular t-600 t-sm pt-2 d-inline-block position-relative">
          <i class="fa fa-trash mr-2 t-red"></i>Delete
        </a>
        <!-- save or edit -->
        <button type="submit" class="btn btn-form btn-primary px-3 t-700 position-relative pull-right ml-3" :class="{ 'disabled': isRequest }">
          <span class="pre-state-msg" :class="{'smooth-away' : isRequest}">
            Save
          </span>
          <span class="dots" :class="{'hide': !isRequest}">working</span>
        </button>
        <button v-if="isCreateMode" @click.prevent="saveAndInvite" type="button" class="btn btn-form btn-outline-primary px-3 pull-right ml-3" :class="{ 'disabled': isRequest }">
          <span :class="{'smooth-away' : isRequest}">
            <i class="fa fa-user-plus mr-2" />
            Save and invite colleagues
          </span>
          <span class="dots" :class="{'hide': !isRequest}">working</span>
        </button>
        <!-- cancel -->
        <a @click.prevent="$events.$emit('modal:close')" href="#" class="t-sm mt-2 t-regular mr-3 pull-right">
          Cancel
        </a>
      </div>

    </form>
  </div>
</template>

<script>
import MPService from '@/services/mixpanel'

import Switches from '@/components/shared/Switches'
import StaticMessages from '@/components/shared/StaticMessages'
import InputTag from '@/components/shared/form/InputTag'
import { mapActions } from 'vuex'

export default {
  name: 'bookmarkFolderCreate',
  components: {
    Switches,
    StaticMessages,
    InputTag
  },
  props: {
    bookmarkFolder: {
      type: Object,
      default: () => ({})
    },
    currentItem: {
      type: Object,
      required: false,
      default: () => {}
    },
    count: {
      type: Number,
      required: false
    },
    scope: {
      type: String,
      required: false
    },
    state: {
      type: Object,
      required: false
    },
    isBulkSave: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isRequest: false,
      isEditMode: false,
      isCreateMode: false,
      error: null,
      name: '',
      description: '',
      isPublic: false,
      tags: [],
      tagLimit: 5,
      createSuccessDone: false
    }
  },
  mounted () {
    this.preFill()
  },
  methods: {
    ...mapActions('bookmarks', [
      'createBookmarkFolder',
      'updateBookmarkFolder',
      'deleteBookmarkFolder',
      'addItem',
      'bulkAddItem'
    ]),
    saveAndInvite (e) {
      this.validateBeforeSubmit(e, true)
    },
    preFill () {
      if (Object.keys(this.bookmarkFolder).length > 0) {
        this.isEditMode = true
        this.name = this.bookmarkFolder.name
        this.description = this.bookmarkFolder.description
        this.isPublic = !this.bookmarkFolder.is_private
        this.tags = this.bookmarkFolder.tags || []
      } else {
        this.isCreateMode = true
        this.name = this.$route.query.keyword || ''
      }
      this.$nextTick(() => {
        if (this.$refs.nameInput) this.$refs.nameInput.focus()
      })
    },
    validateBeforeSubmit (e, shouldShowInviteAfterSubmit = false) {
      this.$validator.validateAll({
        name: this.name,
        description: this.description
      }).then((result) => {
        if (result && !this.isRequest) {
          this.isRequest = true
          this.isEditMode ? this.handleEdit() : this.handleCreate(shouldShowInviteAfterSubmit)
        }
      })
    },
    handleCreate (shouldShowInviteAfterSubmit) {
      const isPrivate = !this.isPublic
      const createObj = {
        name: this.name,
        description: this.description,
        is_private: isPrivate,
        tags: this.tags
      }

      this.createBookmarkFolder(createObj).then(bookmarkFolder => {
        const user = Object.assign({}, this.$currentUser)
        user.user_folder_ids.push(bookmarkFolder.id)
        this.$auth.setCurrentUser(user)

        this.$events.$emit('bookmark_folder:created', bookmarkFolder)

        // When adding a single item to a newly created bookmark folder
        if (!this.isEmpty(this.currentItem)) {
          this.addItem({
            bookmarkFolder,
            item: this.currentItem,
            scope: this.scope
          }).then(() => {
            const folders = Object.assign([], this.currentItem.bookmark_folders)
            folders.push(bookmarkFolder.id)
            this.$events.$emit('bookmark_folder:bookmarked', folders, this.scope, this.currentItem.id)

            this.$flash({
              message: `Added to <b>${bookmarkFolder.name}</b>`,
              cta: this.getFlashCta(bookmarkFolder)
            }, 'success')

            this.isRequest = false
            this.onCreateSuccess(bookmarkFolder, shouldShowInviteAfterSubmit)
          }).catch(err => {
            this.$flash({ message: this.renderError(err) }, 'danger')
            this.isRequest = false
          })
        } else if (this.isBulkSave) {
          // Show warning if request is taking too long
          setTimeout(() => {
            if (this.isRequest) {
              this.$flash({
                message: 'Your bookmark save operation is taking some time. Not to worry, it will keep running in the background. You can continue using AcademicLabs.'
              }, 'warning')

              this.onCreateSuccess(bookmarkFolder, shouldShowInviteAfterSubmit)
              this.createSuccessDone = true
            }
          }, 10000)
          // When bulk adding items to a newly created bookmark folder
          this.bulkAddItem({
            bookmarkFolder,
            searchState: {
              ...this.state,
              index: this.scope
            }
          }).then(response => {
            let message = ''
            let flashType = ''
            if (response && response.count) {
              if (this.count > 500) {
                message = `Only the first ${response.count} items were added to <b>${bookmarkFolder.name}</b>`
                flashType = 'warning'
              } else {
                message = `Added ${response.count} items to <b>${bookmarkFolder.name}</b>`
                flashType = 'success'
              }
            } else {
              message = `Added 0 items to <b>${bookmarkFolder.name}</b>`
              flashType = 'warning'
            }
            this.$flash({
              message: message,
              cta: this.getFlashCta(bookmarkFolder)
            }, flashType)

            this.$emit('added')
            this.isRequest = false
            this.onCreateSuccess(bookmarkFolder, shouldShowInviteAfterSubmit)
          }).catch(err => {
            this.$flash({ message: this.renderError(err) }, 'danger')
            this.isRequest = false
          })
        } else {
          // When simply creating a new bookmark folder
          if (!shouldShowInviteAfterSubmit) {
            this.$flash({
              message: `Created a new bookmark folder: <b>${bookmarkFolder.name}</b>`,
              cta: this.getFlashCta(bookmarkFolder)
            }, 'success')
          }
          this.isRequest = false
          this.onCreateSuccess(bookmarkFolder, shouldShowInviteAfterSubmit)
        }
      }).catch(err => {
        this.isRequest = false
        // Don't throw error on timeout, has been dealt with.
        if (err && err.response && err.response.status && err.response.status === 504) return
        this.error = this.handleError(err)
      })
    },
    onCreateSuccess (bookmarkFolder, shouldShowInviteAfterSubmit) {
      // If the bulk save takes too long, we do the createSucces earlier.
      // If the save does complete without a timeout, we  don't want to execute this logic again.
      if (this.createSuccessDone) return
      // Disable loading state and either close modal, or show "Invite colleagues modal"
      if (shouldShowInviteAfterSubmit) {
        this.isRequest = false
        MPService.trackEvent('invite_colleagues_to_bookmark_folder', { type: 'Create' })
        this.$events.$emit('modal:invite:bookmark:open:create', bookmarkFolder)
      } else {
        this.$events.$emit('modal:close')
      }
    },
    getFlashCta (bookmarkFolder) {
      // Generate route to bookmark folder detail page
      const route = {
        name: 'researcher.bookmark_folders.show',
        params: { id: this.$currentUser.slug_values[0], bookmark_folder: bookmarkFolder.slug_values[0] }
      }
      return {
        icon: 'fa-folder',
        text: 'View',
        action: () => { this.$router.push(route) }
      }
    },
    handleEdit () {
      const isPrivate = !this.isPublic
      const slug = this.bookmarkFolder.slug_values[0]
      this.updateBookmarkFolder({
        slug: slug,
        data: {
          name: this.name,
          description: this.description,
          is_private: isPrivate,
          tags: this.tags
        }
      }).then(() => {
        this.isRequest = false
        this.$events.$emit('modal:close')
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    },
    showDeleteConfirmation () {
      this.$dialog.confirm('Are you sure you want to delete this folder?', {
        ok: dialog => this.handleRemove(dialog),
        okText: 'Delete',
        cancelText: 'Cancel'
      }).then(dialog => {
        this.handleRemove(dialog)
      })
    },
    handleRemove (dialog) {
      if (!this.isRequest) {
        this.isRequest = true

        this.deleteBookmarkFolder(this.bookmarkFolder.slug_values[0]).then(() => {
          let user = Object.assign({}, this.$currentUser)
          user.user_folder_ids.splice(user.user_folder_ids.findIndex((i) => i === this.bookmarkFolder.id), 1)
          this.$auth.setCurrentUser(user)
          dialog.close()
          this.$events.$emit('modal:close')
          this.isRequest = false
        }).catch(err => {
          this.isRequest = false
          dialog.close()
          this.error = this.handleError(err)
        })
      }
    }
  }
}
</script>
