import SearchService from '@/services/search'
import MPService from '@/services/mixpanel'
import highlights from '@/mixins/highlights'
import staticData from '@/assets/js/_data'

export default {
  mixins: [highlights],
  props: {
    slug: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      error: '',
      isLoaded: false,
      isError404: false,
      scope: '',
      data: {}
    }
  },
  computed: {
    slugFromQuery () {
      return this.$route.query[this.scope]
    },
    getScope () {
      return this.$options.filters.pluralize(this.scope, 2)
    }
  },
  mounted () {
    this.cleanQuery()

    !this.slugFromQuery && !this.slug ? this.isError404 = true : this.fetch(this.slugFromQuery)

    this.$events.$on('bookmark_folder:bookmark:modal', (bookmarkFolders) => {
      this.$set(this.data, 'bookmark_folders', bookmarkFolders)
    })
  },
  watch: {
    slugFromQuery (newSlug, oldSlug) {
      if (this.isEmpty(newSlug)) this.closeModal()
    },
    isLoaded (newValue, oldValue) {
      if (newValue && newValue !== oldValue) MPService.trackModalOpened(this.scope)
    }
  },
  methods: {
    cleanQuery () {
      // Cleans query if more than 1 type of search modal is present in the query. This can cause weird behaviour where multiple
      // modals are open at the same time. This function checks for those indexes and updates the URL if needed
      if (this.scope) {
        const allIndexes = staticData.search_modals
        const otherIndexes = staticData.search_modals.filter(i => i !== this.scope)
        const updatedQuery = {
          ...this.$route.query
        }
        let shouldReplaceRoute = false
        if (this.$route.query) {
          Object.keys(this.$route.query).forEach(key => {
            if (allIndexes.indexOf(key) > -1 && otherIndexes.indexOf(key) !== -1) {
              shouldReplaceRoute = true
              delete updatedQuery[key]
            }
          })
        }

        if (shouldReplaceRoute) {
          this.$router.replace({ query: updatedQuery })
        }
      }
    },
    fetchHighlights (index, slug) {
      SearchService.highlightsDetailView(this.getSearchQueryForHighlighting(index, this.data.slug_values, this.$route.query.keyword)).then(highlights => {
        const parsedHighlights = this.generateHighlights(this.data, highlights, this.objectForHighlighting)
        this.$set(this.data, 'highlights', parsedHighlights)
        this.isLoaded = true
      }).catch(err => {
        this.error = this.handleError(err)
      })
    },
    updateHighlights () {
      const parsedHighlights = this.regenerateHighlights(this.data, this.objectForHighlighting)
      this.$set(this.data, 'highlights', parsedHighlights)
    },
    handleCustomError (err) {
      if (err.status === 404) {
        this.isError404 = this.isLoaded = true
      } else {
        this.error = this.handleError(err)
        this.isLoaded = !this.isEmpty(this.error)
      }
    },
    closeModal () {
      this.$events.$emit('modal:close')
    }
  },
  beforeDestroy () {
    if (!this.isEmpty(this.$route.query[this.scope])) {
      let queryObj = Object.assign({}, this.$route.query)
      delete queryObj[this.scope]
      delete queryObj.hr // Clear "human readable" slug_text from URL when closing search result modal
      this.$route.meta.searchContainer || this.$route.meta.searchModal ? this.$router.push({ query: queryObj }) : this.$router.replace({ query: queryObj })
    }
  },
  destroyed () {
    this.$events.$off('bookmark_folder:bookmark:modal')
  }
}
