import Sentry from '@/plugins/sentry'

export default {
  install (Vue, options) {
    const { GAId, TypekitId } = options

    // init TypeKit
    this.typekit(TypekitId)
    this.initSciteAi()

    // initialize scripts based on removal of scripts
    Vue.prototype.$scripts = {
      initialize (user) {
        let email = ''
        if (user) {
          email = user.email
        }

        if (process.env.VUE_APP_DEPLOY_ENV === 'production' && !email.includes('academiclabs')) {
          // init hotjar
          this.hotjar()
          // init google analytics
          this.googleAnalytics(GAId, user)
        }
      },
      hotjar () {
        (function (h, o, t, j, a, r) {
          h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) }
          h._hjSettings = { hjid: 141650, hjsv: 6 }
          a = o.getElementsByTagName('head')[0]
          r = o.createElement('script'); r.async = 1
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
          a.appendChild(r)
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
      },
      googleAnalytics (GAId, user) {
        let url = `https://www.googletagmanager.com/gtag/js?id=${GAId}`

        return new Promise((resolve, reject) => {
          // Load Google Analytics script file
          return this.loadScriptGa(url).then(() => {
            resolve()
          }).catch(() => {
            // let err = '[ga-analytics] It\'s not possible to load Google Analytics script'
            // new Error(err)
          })
        }).then(() => {
          this.onAnalyticsReady()
        }).then(() => {
          return typeof GAId === 'function' ? GAId() : GAId
        }).then(GAId => {
          /* eslint-disable */
          window.dataLayer = window.dataLayer || []
          function gtag () { dataLayer.push(arguments) }
          gtag('js', new Date())
          gtag('config', GAId)
          // Set user ID for Google Analytics
          if (user) {
            gtag('config', GAId, {
              'user_id': user.user_id
            });
          }
        }).catch(() => {
          // let err = `[ga-analytics] It\'s not possible to load Google Analytics script`
          // new Error(`${err}-${error}`)
        })
      },
      loadScriptGa (url) {
        return new Promise((resolve, reject) => {
          var head = document.head || document.getElementsByTagName('head')[0]
          const script = document.createElement('script')
          script.async = true
          script.src = url
          script.type = 'text/javascript'
          script.charset = 'utf8'

          head.appendChild(script)

          script.onload = resolve
          script.onerror = reject
        })
      },
      onAnalyticsReady () {
        return new Promise((resolve, reject) => {
          const poll = setInterval(() => {
            if (typeof window === 'undefined' || !window.ga) {
              return
            }

            resolve()
            clearInterval(poll)
          }, 10)
        })
      }
    }
  },
  typekit (TypekitId) {
    let url = `https://use.typekit.net/${TypekitId}.js`

    return new Promise((resolve, reject) => {
      return this.loadScriptTypekit(url).then(() => {
        resolve()
      }).catch(() => {
        Sentry.captureMessage(new Error('[typekit] It\'s not possible to load Typekit script'))
      })
    }).then(() => {
      /* eslint-disable */
      try { Typekit.load({ async: true }) } catch (e) {}
    })
  },
  loadScriptTypekit (url) {
    return new Promise((resolve, reject) => {
      var head = document.head || document.getElementsByTagName('head')[0]
      const script = document.createElement('script')
      script.async = true
      script.src = url
      script.charset = 'utf8'

      head.appendChild(script)

      script.onload = resolve
      script.onerror = reject
    })
  },
  initSciteAi () {
    return new Promise((resolve, reject) => {
      var head = document.head || document.getElementsByTagName('head')[0]
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://cdn.scite.ai/badge/scite-badge-latest.min.js'

      head.appendChild(script)

      script.onload = resolve
      script.onerror = reject
    })
  }
}
