<template>
  <li class="nav-item dropdown">
    <dropdown
      placement="bottom-end"
      noPadding
      @show="show"
      @applyHide="hide()"
    >
      <template #button>
        <a href="#" @click.prevent class="nav-link">
          <i class="al-icon-notifications mr-sm-2" :data-count="count" />
          <span class="t-500 d-none d-lg-inline-block" id="notification-header-dropdown">Notifications</span>
        </a>
      </template>
      <div class="dropdown-menu-custom--notification box-shadow pt-0 t-regular">
        <div class="wrapper-header clearfix t-sm mb-2 pb-2">
          <p class="t-lr mb-0 t-600 pull-left">
            Notifications
          </p>
          <a v-if="notifications.results.length > 0" href="#" @click.prevent="readAll()" class="pull-right">Mark all as read</a>
        </div>
        <div class="wrapper-outer">
          <div ref="notificationWrapper" class="wrapper-inner">
            <template v-if="notifications.results.length > 0">
              <div class="dropdown-items">
                <div v-for="(notification, index) in notifications.results"
                  :key="index"
                  class="dropdown-item"
                  :class="{'mb-0': index === notifications.results.length - 1}"
                  @click.prevent="openNotification(notification)"
                  :title="`${notification.data.message_prefix} ${notification.data.message} ${notification.data.message_suffix}`"
                >
                  <div class="dropdown-item--left avatar-header">
                    <avatar-missing v-if="isEmpty(notification.sender)" :name="`A L`" :maxChars="3" :containerClass="`bg-blue t-700`"/>
                    <avatar-missing v-if="!isEmpty(notification.sender) && !notification.sender.avatar" :name="getFullName(notification.sender)" :maxChars="3"/>
                    <avatar v-if="!isEmpty(notification.sender) && notification.sender.avatar" :name="getFullName(notification.sender)" :avatar="notification.sender.avatar" :editable="false" />
                  </div>
                  <div class="dropdown-item--right t-regular t-overflow" :class="{'bg-transparent-blue': !notification.viewed_at}">
                    <span class="message"><span class="t-600">{{ notification.data.message_prefix }}</span> {{ notification.data.message }} <span class="t-600">{{ notification.data.message_suffix }}</span></span>
                    <span class="d-block t-secondary">{{ notification.created_at | fromNow }}</span>
                  </div>
                </div>
              </div>
            </template>

            <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--65px`" />

            <infinite-loading
              @infinite="infiniteHandler"
              :distance="10"
              :identifier="infiniteId"
              ref="infiniteLoading"
              class="mt-2"
            >
              <span slot="spinner"></span>

              <span slot="no-results">
                <div v-if="notifications.results.length === 0" class="pt-2 pb-3">You have no notifications...</div>
                <div v-if="notifications.results.length > 0" class="pt-2 t-sm">No more notifications to show...</div>
              </span>

              <span slot="no-more">
                <div class="pt-2 t-sm">No more notifications to show...</div>
              </span>
            </infinite-loading>
          </div>
        </div>
      </div>
    </dropdown>
  </li>
</template>

<script>
import Spinner from '@/components/shared/Spinner'
import AvatarMissing from '@/components/shared/AvatarMissing'
import Avatar from '@/components/shared/Avatar'
import InfiniteLoading from 'vue-infinite-loading'
import Dropdown from '@/components/shared/form/Dropdown'

import NotificationService from '@/services/notifications'

export default {
  name: 'notificationDropdown',
  components: {
    Spinner,
    AvatarMissing,
    Avatar,
    InfiniteLoading,
    Dropdown
  },
  data () {
    return {
      isAnimating: false,
      isActive: false,
      isOpened: false,
      isLoaded: false,
      isCompleted: false,
      count: 0,
      page: 0,
      perPage: 12,
      notifications: {
        page_count: 0,
        total_count: 0,
        results: []
      },
      error: null,
      infiniteId: +new Date()
    }
  },
  created () {
    this.getCount(this.$currentUser.notifications, true)

    this.$events.$on('notify-bell', (notification) => {
      this.notify()

      let data = this.$ls.getAccountData()
      data.isNotificationOpened = false
      this.$ls.setAccountData(data)

      if (this.isActive) {
        let pushNotification = {
          created_at: new Date(),
          id: notification.notification_id,
          data: {
            link: notification.link,
            message: notification.message,
            message_prefix: notification.message_prefix,
            message_suffix: notification.message_suffix
          },
          sender: notification.sender
        }

        this.notifications.results.unshift(pushNotification)
      }
    })

    this.$events.$on('un-notify-bell', (id) => {
      this.updateNotification(id)
      this.getCount(this.$currentUser.notifications -= 1)
    })
  },
  methods: {
    isBottom (el) {
      return el.getBoundingClientRect().bottom - 200 <= 400
    },
    show () {
      this.isActive = true
      this.infiniteId += 1

      let data = this.$ls.getAccountData()
      data.isNotificationOpened = true
      this.$ls.setAccountData(data)

      this.getCount(this.$currentUser.notifications = 0)
    },
    hide () {
      this.reset()
    },
    infiniteHandler ($state) {
      this.page = this.page + 1
      this.isLoaded = false

      NotificationService.get(this.page, this.perPage).then(notifications => {
        if (this.notifications.results) {
          this.notifications.page_count = notifications.page_count
          this.notifications.total_count = notifications.total_count
          this.notifications.results.push(...notifications.results)
        } else {
          this.notifications = notifications
        }

        this.isLoaded = true

        // Set on completed
        if (this.notifications.total_count > this.page * this.perPage) {
          if ($state) $state.loaded()
        } else {
          if ($state) $state.complete()
          this.isCompleted = true
        }
      }).catch((err) => {
        this.error = this.handleError(err)
        this.isLoaded = !this.isEmpty(this.error)
      })
    },
    notify () {
      this.getCount(this.$currentUser.notifications += 1)
      this.isAnimating = true
      setTimeout(() => { this.isAnimating = false }, 1000)
    },
    getCount (count, isInit = false) {
      if (count < 0 || (!isInit && this.$ls.getAccountData().isNotificationOpened)) count = 0
      this.count = count > 9 ? `9+` : count
    },
    openNotification (notification) {
      if (notification.data.link) this.$router.push({ path: `${notification.data.link}` })
      this.hide()

      // update notification on viewed
      this.updateNotification(notification.id)

      if (this.$currentUser.notifications > 0) this.getCount(this.$currentUser.notifications -= 1)
    },
    readAll () {
      NotificationService.update([])
      this.notifications.results.map((r) => { r.viewed_at = new Date() })
      this.getCount(this.$currentUser.notifications = 0)
      this.$set(this.$currentUser, 'notifications', 0)
      this.$ls.setUser(this.$currentUser)
    },
    updateNotification (id) {
      let ids = []
      ids.push(id)
      NotificationService.update(ids)
    },
    reset () {
      this.page = 0
      this.isLoaded = false
      this.isCompleted = false
      this.isActive = false
      this.notifications = this.$options.data().notifications
    }
  },
  destroyed () {
    this.$events.$off('notify-bell')
    this.$events.$off('un-notify-bell')
  }
}
</script>
