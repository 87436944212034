<template>
  <div>
      <h2 class="t-primary t-400 mb-1">Synonyms</h2>
      <p>
        Include or ignore all synonyms in your search results
      </p>
      <hr class="hr-small mb-1" />

      <switches
        class="mb-2"
        v-model="isSynonymsActive"
        :text-enabled="`Synonyms are included`"
        :text-disabled="`Synonyms are ignored`"
      />

      <template v-if="!isEmpty(synonyms)">
        <p v-for="(syn, key) in synonyms" :key="key" :style="{
          'opacity': isSynonymsActive ? 1 : 0.5
        }">
          <span class="t-700 mt-3 t-primary">{{ key }}</span>
          <span class="t-sm d-block">{{ syn.join(', ') }}</span>
        </p>
      </template>

      <form-buttons
        :createText="`Apply`"
        :containerClass="`mt-4 bd-t-none`"
        :isDisabled="!hasChanges"
        @cancel="$events.$emit('modal:close')"
        @save="applySettings"
      />
  </div>
</template>

<script>
import Switches from '@/components/shared/Switches'
import FormButtons from '@/components/shared/form/Buttons'

export default {
  name: 'searchSynonyms',
  components: {
    Switches,
    FormButtons
  },
  data () {
    return {
      isSynonymsActive: true
    }
  },
  props: {
    state: {
      type: Object,
      required: false
    }
  },
  mounted () {
    this.isSynonymsActive = !this.state.ignore_synonyms
  },
  computed: {
    synonyms () {
      return this.state.synonyms
    },
    hasChanges () {
      return this.isSynonymsActive !== !this.state.ignore_synonyms
    }
  },
  methods: {
    applySettings () {
      this.$events.$emit('ignore_synonyms:update', !this.isSynonymsActive)
      this.$events.$emit('modal:close')
    }
  }
}
</script>
