export default {
  data () {
    return {
      highlights: {},
      esHighlights: {},
      baseObject: {}
    }
  },
  methods: {
    // Apply the same highlights to an updated object for highlighting
    // (used when loading in all authors in publication modal for example, we already have the relevant highlights
    // before we load in the extra authors, so we can recycle this information)
    regenerateHighlights (baseObject, objectForHighlighting) {
      return this.generateHighlights(baseObject, this.esHighlights, objectForHighlighting)
    },
    generateHighlights (baseObject, esHighlights, objectForHighlighting) {
      this.highlights = {}
      this.baseObject = JSON.parse(JSON.stringify(baseObject))
      // this.baseObject = baseObject
      this.esHighlights = esHighlights
      if (!this.isEmpty(objectForHighlighting.properties)) for (let esProperty of objectForHighlighting.properties) this.setHighlight(esProperty)
      if (!this.isEmpty(objectForHighlighting.objects)) for (let esProperty of objectForHighlighting.objects) this.setHighlight(esProperty)
      if (!this.isEmpty(objectForHighlighting.arrays)) for (let esProperty of objectForHighlighting.arrays) this.setHighlight(esProperty)
      if (!this.isEmpty(objectForHighlighting.researchers)) for (let esProperty of objectForHighlighting.researchers) this.setHighlight(esProperty, true)
      return this.highlights
    },
    setForString (property, esProperty) {
      if (this.isEmpty(this.highlights[property])) this.$set(this.highlights, property, this.baseObject[property])
      if (!this.isEmpty(this.esHighlights) && !this.isEmpty(this.esHighlights[esProperty])) this.$set(this.highlights, esProperty, this.esHighlights[esProperty][0])
    },
    setForObject (property, properties, esProperty) {
      if (this.isEmpty(this.highlights[property])) this.$set(this.highlights, property, Object.assign({}, this.baseObject[property]))
      if (!this.isEmpty(this.esHighlights) && !this.isEmpty(this.esHighlights[esProperty])) {
        // TODO: make dynamic
        if (properties.length === 2) this.$set(this.highlights[property], properties[1], this.esHighlights[esProperty][0])
        if (properties.length === 3) this.$set(this.highlights[property][properties[1]], properties[2], this.esHighlights[esProperty][0])
      }
    },
    setForArray (property, properties, esProperty, isForResearcher) {
      // console.log(property, properties, esProperty, this.highlights)
      if (this.isEmpty(this.highlights[property])) {
        this.$set(this.highlights, property, Object.assign([], this.baseObject[property]))
      }
      if (!this.isEmpty(this.esHighlights) && !this.isEmpty(this.esHighlights[esProperty])) {
        this.esHighlights[esProperty].forEach((esHighlight) => {
          const strippedHighlight = this.stripTags(esHighlight)
          // console.log(esHighlight, properties)
          if (!isForResearcher) {
            if (properties.length === 1) {
              let index = this.highlights[property].findIndex(x => x === strippedHighlight)
              if (index !== -1) {
                this.highlights[property][index] = esHighlight
              }
            } else {
              // Check if highlight is present on (part of) each array element and update with highlight
              this.highlights[property].forEach((x, index) => {
                // Replace text with highlighted text if present
                if (x[properties[1]]) {
                  const replacedText = x[properties[1]].replace(strippedHighlight, esHighlight)
                  this.$set(this.highlights[property][index], properties[1], replacedText)
                }
              })
            }
          } else {
            // TODO: make dynamic
            if (properties.length === 3) {
              let index = this.highlights[property].findIndex(x => this.getFullName(x) === strippedHighlight)
              if (index !== -1) {
                this.$set(this.highlights[property][index], 'full_name', esHighlight)
                if (!this.isEmpty(this.highlights[property][index].researcher)) this.$set(this.highlights[property][index].researcher, 'full_name', esHighlight)
              }
            }

            if (properties.length === 4) {
              let index = this.highlights[property][properties[1]].findIndex(x => this.getFullName(x) === strippedHighlight)
              if (index !== -1) {
                this.$set(this.highlights[property][properties[1]][index], 'full_name', esHighlight)
                if (!this.isEmpty(this.highlights[property][properties[1]][index].researcher)) this.$set(this.highlights[property][properties[1]][index].researcher, 'full_name', esHighlight)
              }
            }
          }
        })
      }
    },
    setHighlight (esProperty, isForResearcher = false) {
      const properties = esProperty.split('.')
      const property = properties[0]

      // console.log(properties, property, this.baseObject[property], this.highlights)

      if (this.isEmpty(this.baseObject[property])) {
        this.$set(this.highlights, property, '')
      } else if (typeof (this.baseObject[property]) === 'string') {
        this.setForString(property, esProperty)
      } else if (Array.isArray(this.baseObject[property]) || Array.isArray(this.baseObject[property][properties[1]])) {
        this.setForArray(property, properties, esProperty, isForResearcher)
      } else if (typeof (this.baseObject[property]) === 'object') {
        this.setForObject(property, properties, esProperty)
      }
    },
    getSearchQueryForHighlighting (index, slugValues, keyword = '') {
      let state = this.getMainStateOrScopedState()

      // If keyword is passed to this function, override the keyword we got from the state
      if (keyword) state.keyword = keyword

      // let filterName = index !== 'research_groups' ? this.$options.filters.singularize(index) : 'research_group'
      state.index = index
      state.filter_order = [index]
      state.filters = {}
      state.filters[index] = {}
      state.filters[index].buckets = []
      state.filters[index].buckets.push({ values: slugValues, active: true })
      state.global = false

      return state
    }
  }
}
