<template>
  <li class="nav-item dropdown">
    <dropdown
      placement="bottom-end"
      noPadding
      @show="fetchBookmarkFolders"
    >
      <template #button>
        <a href="#" class="nav-link" @click.prevent aria-haspopup="true" aria-expanded="false">
          <i
            class="fa fa-bookmark mr-sm-2 fade-alert"
            :class="{ 'clap-animating fade-alert-in': isRinging }"
          />
          <span class="t-500 d-none d-lg-inline-block" id="bookmarks-header-dropdown">Bookmarks</span>
        </a>
      </template>
      <div class="dropdown-menu-custom box-shadow dropdown-create" aria-labelledby="bookmarks-header-dropdown">
        <div class="dropdown-item d-flex align-items-center pb-2">
          <span class="dropdown-item_icon">
            <i class="fa fa-bookmark t-secondary" />
          </span>
          <span class="dropdown-item_text t-600 t-primary d-flex justify-content-between t-overflow">
            Bookmarks
            <div class='mw-100'>
              <link-button
                text="Create folder"
                icon="fa-plus"
                @click="handleCreate()"
                size="sm"
                class="py-0"
              />
            </div>
          </span>
        </div>
        <div v-if="!isLoaded && bookmarkFolders.length === 0" class="d-flex align-content-center">
          <spinner :containerClass="`min-height--65px `" />
        </div>
        <div v-else class="inner-list">
          <template v-if="bookmarkFolders.length === 0">
            <a href="#" @click="handleCreate()" class="dropdown-item dropdown-item_text t-sm w-100 mt-2">
              Create your first bookmark folder and start collecting!
            </a>
            <div class="dropdown-item dropdown-item_text w-100 mt-2">
              <span class="t-sm t-secondary">Collect interesting finds in your bookmark folders and share them with others.</span>
            </div>
          </template>
          <template v-else>
            <router-link
              class="dropdown-item d-flex my-1" v-for="(bookmarkFolder, i) in folders" :key="i"
              :to="{ name: 'researcher.bookmark_folders.show', params: { id: $currentUser.slug_values[0], bookmark_folder: bookmarkFolder.slug_values[0] } }"
              :title="bookmarkFolder.name"
            >
              <span class="dropdown-item_icon t-sm">
                <i
                  class="fa t-secondary with_offset"
                  :class="{ 'fa-unlock t-green': !bookmarkFolder.is_private, 'fa-lock t-red': bookmarkFolder.is_private }"
                />
              </span>
              <div class="dropdown-item_text t-overflow">
                <div class="d-flex align-items-baseline">
                  <span class="flex-grow-0 t-overflow">
                    {{ bookmarkFolder.name }}
                  </span>
                  <span class="t-green t-sm flex-grow-1 ml-2">
                    {{ bookmarkFolder.count }} {{ 'bookmark' | pluralize(bookmarkFolder.count) }}
                  </span>
                  <i v-if="bookmarkFolder.rw_access !== 'owner'" class="fa t-sm fa-share-alt ml-2 t-secondary" />
                </div>
                <span v-if="bookmarkFolder.description" class="t-secondary t-sm">{{ bookmarkFolder.description }}</span>
              </div>
            </router-link>
          </template>
        </div>
        <div v-if="bookmarkFolders.length !== 0" class="dropdown-item d-flex justify-content-end mt-2">
          <link-button
            text="Edit & share folders"
            icon="fa-pencil"
            size="sm"
            class="py-0"
            @click="viewBookmarks()"
          />
        </div>
      </div>
    </dropdown>
  </li>
</template>

<script>
import Spinner from '@/components/shared/Spinner'
import LinkButton from '@/components/shared/form/LinkButton'
import Dropdown from '@/components/shared/form/Dropdown'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'bookmarks',
  components: {
    Spinner,
    LinkButton,
    Dropdown
  },
  data () {
    return {
      isLoaded: false,
      isRinging: false
    }
  },
  computed: {
    ...mapGetters({
      bookmarkFolders: 'bookmarks/personalBookmarkFolders'
    }),
    folders () {
      const currentUserSlug = this.$currentUser && this.$currentUser.slug_values
      return Array.from(this.bookmarkFolders).sort(bm => this.hasMatchingSlug(bm.owner.slug_values, currentUserSlug) ? -1 : 1)
    }
  },
  created () {
    this.$events.$on('bookmark_folder:created', this.ringBell)
  },
  methods: {
    ...mapActions('bookmarks', [
      'getBookmarkFolders'
    ]),
    ringBell () {
      this.isRinging = true
      setTimeout(() => { this.isRinging = false }, 1600)
    },
    viewBookmarks () {
      this.$router.push({ name: 'researcher.bookmark_folders.overview', params: { id: this.$currentUser.slug_values[0] } })
    },
    handleCreate () {
      this.$events.$emit('modal:bookmark_folder:open:create')
    },
    fetchBookmarkFolders () {
      this.isLoaded = false
      this.getBookmarkFolders({ userId: this.$currentUser.user_id }).then(() => {
        this.isLoaded = true
      }, err => {
        this.$flash({ message: this.renderError(err) }, 'warning')
        this.isLoaded = true
      })
    }
  },
  destroyed () {
    this.$events.$off('bookmark_folder:created')
  }
}
</script>
