<template>
  <div>
    <h2 class="t-primary t-400 mb-0">Add your latest tweets</h2>
    <p class="mt-2 mb-1 t-regular">
      Enter your <span class="t-600 t-blue">twitter username</span>. We will add your latest tweets to your profile.
    </p>
    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <!-- render form -->
    <form @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <div class="form-group mb-4" :class="{'has-icon': errors.has('username')}">
        <input
          ref="twitter"
          type="text"
          class="form-control form-control-lg"
          placeholder="Twitter username"
          name="username"
          v-model="username"
          v-validate="'required'"
          data-vv-delay="1000"
          data-vv-as="twitter username"
          :class="{'is-invalid': errors.has('username')}"
        />

        <i v-show="errors.has('username')" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('username')">{{ errors.first('username')}}</span>
      </div>

      <!-- action buttons -->
      <form-buttons :isRequest="isRequest"
        :isEditMode="false"
        :isRemove="isRemove()"
        @remove="removeTwitter()"
        @cancel="$events.$emit('modal:close')" />
    </form>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'
import UserService from '@/services/user'

export default {
  name: 'twitterModal',
  components: {
    StaticMessages,
    FormButtons
  },
  data () {
    return {
      error: null,
      isRequest: null,
      username: null
    }
  },
  props: {
    twitterId: {
      type: String,
      default: ''
    }
  },
  created () {
    if (this.twitterId) this.username = this.twitterId
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.twitter.focus()
    })
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequest) {
          this.doRequest({ twitter_id: this.username.split('@').join('') })
        }
      })
    },
    isRemove () {
      return this.twitterId.length > 0
    },
    removeTwitter () {
      this.doRequest({ twitter_id: '' })
    },
    doRequest (data) {
      this.isRequest = true
      UserService.update(this.$currentUser.user_id, data).then(p => {
        this.isRequest = false

        // update user event
        this.$events.$emit('user:updated', 'twitter_id', data.twitter_id)

        // close modal event
        this.$events.$emit('modal:close')
      }).catch(err => {
        this.isRequest = false
        this.error = this.handleError(err)
      })
    }
  }
}
</script>
