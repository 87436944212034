<template>
  <span class="mb-0 t-xs clearfix pull-right">
    <a href="#" @click.prevent="$emit('create')" class="link-button d-flex align-items-center" v-if="!isActive">
      <i class="fa t-sm mr-2" :class="getIcon"></i>{{ text }}
    </a>

    <a href="#" @click.prevent="$emit('cancel')" class="link-button d-flex align-items-center" v-else>
      <i class="fa fa-check t-sm mr-2"></i>{{ cancelText }}
    </a>
  </span>
</template>

<script>
export default {
  name: 'editable',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: 'Add'
    },
    cancelText: {
      type: String,
      default: 'Save'
    }
  },
  computed: {
    getIcon () {
      return (this.text === 'Add' || this.text === 'Create') ? 'fa-plus' : 'fa-pencil'
    }
  }
}
</script>
