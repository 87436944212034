<template>
  <div class="row">
    <div class="col-12 align-self-start">
      <h2 class="t-primary t-400 mb-0">
        Invite colleagues to
        <span class="t-600">{{ getName }}</span>
      </h2>
      <p>
        <template v-if="bookmarkFolder.is_private">
          <i class="fa mr-2 fa-lock t-red" />
          <span class="t-600">This is a private folder.</span> Only you and anyone invited can access the contents of this folder.<br />
          If they don't have an AcademicLabs account, they can sign up for free.
        </template>
        <template v-else>
          <i class="fa mr-2 fa-unlock t-green" />
          <span class="t-600">This is a public folder.</span> Anyone with the <a :href="getLink" target="_blank">link</a> can view it. Only invitees can add bookmarks and invite others, if desired.<br />
          If an invitee does not have an AcademicLabs account, they can sign up for free.
        </template>
      </p>
      <hr class="hr-small mb-4">

      <!-- render error messages -->
      <static-messages
        v-if="error"
        :messages="error"
        :variant="`danger`"
        @closed="error = null"
      />

      <!-- add invites form -->
      <form
        autocomplete="off"
        @submit.prevent="validateBulkInvites"
      >
        <div class="d-flex flex-wrap">
          <!-- email -->
          <div
            class="mb-2 flex-grow-1 pr-2"
            :class="{
              'has-icon-small is-invalid': bulkInvitesError
            }"
          >
            <input
              ref="email"
              v-model="bulkInvites"
              v-validate="'email'"
              type="text"
              class="form-control"
              placeholder="Enter email addresses separated by a comma"
              name="bulkInvites"
              data-vv-delay="1000"
              data-vv-as="email"
              :class="{ 'is-invalid': bulkInvitesError }"
            >

            <span
              v-show="bulkInvitesError"
              class="invalid-feedback"
            >{{ bulkInvitesError }}</span>
          </div>

          <!-- rights dropdown -->
          <div class="flex-grow-1 flex-md-grow-0 mb-2 pr-2">
            <dropdown>
              <template #button>
                <button type="button" class="btn btn-select-menu width--100per t-primary pr-4">
                  <i
                    class="fa t-sm t-secondary"
                    :class="getIcon(bulkInviteRole)"
                  />
                  <span class="pr-2 t-blue">{{
                    getReadablePermission(bulkInviteRole)
                  }}</span>
                </button>
              </template>
              <link-button icon="fa-eye" text="Can view bookmarks" size="sm" @click="updateBulkInviteRole(1)" />
              <link-button icon="fa-pencil" text="Can add bookmarks and invite colleagues" size="sm" @click="updateBulkInviteRole(2)" />
            </dropdown>
          </div>
          <div class="flex-grow-1 flex-md-grow-0">
            <button class="btn width--100per btn-primary t-700">
              <i class="fa fa-plus t-sm mr-2" />
              Add
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="col-12 d-flex mt-2 mb-4">
      <div
        v-for="(invite, index) in invite.people"
        :key="index"
        class="bg-transparent-blue mr-2 rounded p-1 t-secondary is-hover--red"
        @click="removeInvite(invite)"
        v-tooltip="'Cancel invitation'"
      >
        <i
          class="fa t-sm px-2 t-secondary "
          :class="getIcon(invite.role_id)"
        />
        <span class="t-grey">{{ invite.email }}</span>
        <i class="fa fa-close cursor-pointer px-2" />
      </div>
    </div>

    <form
      autocomplete="off"
      @submit.prevent="handleBulkInvite"
      class="col-12"
    >
      <!-- Invite message view -->
      <span class="t-500 t-sm d-block mb-1 mt-2">Subject</span>
      <input
        type="text"
        class="form-control"
        name="invite.subject"
        :placeholder="getPlaceholderSubject"
        v-model="invite.subject"
        v-validate="'min:10|max:900'"
        data-vv-as="subject"
        data-vv-delay="1000"
        :class="{ 'is-invalid':  errors.has('invite.subject') }"
      />
      <span
        v-show="errors.has('invite.subject')"
        class="invalid-feedback"
      >{{ errors.first('invite.subject') }}</span>
      <span class="t-500 t-sm d-block mb-1 mt-2">Message</span>
      <textarea
        class="form-control textarea-xl"
        name="invite.message"
        :placeholder="getPlaceholderText"
        v-model="invite.message"
        v-validate="'min:10'"
        data-vv-as="message"
        data-vv-delay="1000"
        :class="{'is-invalid': errors.has('invite.message')}">
      </textarea>
      <span
        v-show="errors.has('invite.message')"
        class="invalid-feedback"
      >{{ errors.first('invite.message') }}</span>

      <div class="pt-2">
        <i class="fa fa-info-circle t-secondary t-sm" />
        The invitation email will include a link to the bookmark folder and guidance to sign up for free to AcademicLabs if needed.
      </div>

      <div class="align-self-end">
        <div class="clearfix pt-3">
          <!-- save or edit -->
          <button type="submit" class="btn btn-form btn-primary px-3 t-700 position-relative pull-right ml-3" :class="{ 'disabled': isRequest }">
            <span class="pre-state-msg" :class="{'smooth-away' : isRequest}">
              <i class="fa fa-paper-plane mr-2"></i>
              Send invitations
            </span>
            <span class="dots" :class="{'hide': !isRequest}">working</span>
          </button>
          <!-- cancel -->
          <a @click.prevent="$events.$emit('modal:close')" href="#" class="t-sm mt-2 t-regular mr-3 pull-right">
            Cancel
          </a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import LinkButton from '@/components/shared/form/LinkButton'
import Dropdown from '@/components/shared/form/Dropdown'

import BookmarkFolderService from '@/services/bookmark_folder'
import { mapActions } from 'vuex'

export default {
  name: 'inviteCreate',
  components: {
    StaticMessages,
    LinkButton,
    Dropdown
  },
  props: {
    bookmarkFolder: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      error: null,
      isRequest: false,
      bulkInvites: '',
      bulkInviteRole: 2,
      bulkInvitesError: '',
      invite: {
        subject: `Invitation to the folder ${this.bookmarkFolder.name} on AcademicLabs`,
        message: `Dear colleague,

I would like to invite you to my bookmark folder "${this.bookmarkFolder.name}" on AcademicLabs.

Kind regards,
${this.$currentUser.full_name}`,
        people: [
          // { 'email': 'wcsa@fef.few', 'first_name': '', 'last_name': '', 'role_id': 2 },
          // { 'email': 'wcdwa@fef.few', 'first_name': '', 'last_name': '', 'role_id': 2 }
        ]
      }
    }
  },
  computed: {
    getName () {
      return this.bookmarkFolder.name
    },
    globalRole () {
      if (this.invite.people.length === 0) return ''

      const roleId = this.invite.people[0].role_id
      const areAllPermissionsTheSame = this.invite.people.every(person => person.role_id === roleId)
      return areAllPermissionsTheSame ? roleId : ''
    },
    getPlaceholderText () {
      return `Dear colleague,

I would like to invite you to my bookmark folder "${this.bookmarkFolder.name}" on AcademicLabs.

Kind regards,
${this.$currentUser.full_name}`
    },
    getPlaceholderSubject () {
      return `Invitation to the folder ${this.bookmarkFolder.name} on AcademicLabs`
    },
    getLink () {
      return this.$router.resolve({
        name: 'researcher.bookmark_folders.show',
        params: {
          bookmark_folder: this.bookmarkFolder.slug_values[0],
          id: this.$route.params.id
        }
      }).href
    }
  },
  created () {
    this.$nextTick(() => {
      if (this.$refs.email) this.$refs.email.focus()
    })
  },
  methods: {
    ...mapActions([
      'bookmarks/updateLocalBookmarkFolder'
    ]),
    getIcon (roleId) {
      switch (roleId) {
        case 1:
          return 'fa-eye'
        case 2:
          return 'fa-pencil'
        case 3:
          return 'fa-cog'
        default:
          return ''
      }
    },
    getReadablePermission (roleId) {
      if (roleId === 3) return 'Owner'
      if (roleId === 2) return 'Can add bookmarks and invite colleagues'
      return 'Can view bookmarks'
    },
    removeInvite (index) {
      this.invite.people.splice(index, 1)
    },
    updateBulkInviteRole (roleId) {
      this.bulkInviteRole = roleId
    },
    buildInvite (email, firstName = null, lastName = null, roleId = 1) {
      let obj = {}
      obj.email = this.$options.filters.lowercase(email)
      obj.first_name = firstName
      obj.last_name = lastName
      obj.role_id = roleId

      return obj
    },
    extractEmails (text) {
      return text.match(/([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
    },
    validateBulkInvites () {
      this.bulkInvitesError = ''

      const emails = this.extractEmails(this.bulkInvites)

      if (emails) {
        // Filter out duplicates
        for (let email of emails) {
          email = this.$options.filters.lowercase(email)
          if (email && emails.indexOf(email) === -1) emails.push(email)
        }
      }

      // add a custom validator
      this.$validator.attach({ name: 'bulkInvites', rules: 'required|email' })
      return this.$validator.validateAll({ bulkInvites: emails }).then(result => {
        if (result) {
          for (let email of emails) {
            // Filter out duplicates again
            if (!this.invite.people.find(person => person.email === email)) {
              this.invite.people.push(
                this.buildInvite(
                  email.trim(),
                  '',
                  '',
                  this.bulkInviteRole
                )
              )
            }
          }

          this.bulkInvites = ''
        } else {
          this.bulkInvitesError = `You've entered an invalid email address`
        }
      })
    },
    async handleBulkInvite (e) {
      const validSubject = await this.$validator.validate('invite.subject')
      const validMessage = await this.$validator.validate('invite.message')

      if (validSubject && validMessage) {
        // If message is empty, fill it with default message
        if (!this.invite.message.trim()) {
          this.invite.message = this.getPlaceholderText
        }
        if (!this.invite.subject.trim()) {
          this.invite.subject = this.getPlaceholderSubject
        }
        if (this.invite.people.length > 0) {
          if (this.bulkInvites) {
            this.validateBulkInvites().then(() => {
              if (this.invite.people.length > 0) {
                this.handleSendInvites()
              }
            })
          } else {
            this.handleSendInvites()
          }
        } else {
          this.validateBulkInvites().then(() => {
            if (this.invite.people.length > 0) {
              this.handleSendInvites()
            }
          })
        }
      }
    },
    handleSendInvites () {
      if (!this.isRequest) {
        this.isRequest = true
        BookmarkFolderService.createInvite(this.bookmarkFolder.slug_values[0], this.invite)
          .then(response => {
            // update team member count
            if (response.invites.length > 0) {
              this['bookmarks/updateLocalBookmarkFolder']({
                slug: this.bookmarkFolder.slug_values[0],
                data: {
                  invite_count: this.bookmarkFolder.invite_count + response.invites.length
                }
              })
            }

            this.$flash({ message: `Invited ${response.invites.length} ${this.$options.filters.pluralize('colleague', response.invites.length)} to <b>${this.bookmarkFolder.name}</b>` }, 'success')

            this.$events.$emit('modal:close')
            this.isRequest = false
          })
          .catch(err => {
            this.isRequest = false
            this.error = this.handleError(err)
          })
      }
    }
  }
}
</script>
