<template>
  <div v-if="!isEmpty(organisations)">
    <h3 class="mb-2 t-600">
      {{ title }}
    </h3>

    <ul class="mb-2 list-inline list-meta-data">
      <organisation
        v-for="(organisation, i) in organisations"
        :key="i"
        :organisation="organisation.organisation || organisation"
      />
    </ul>
  </div>
</template>

<script>
import Organisation from '@/components/shared/organisationSummary/Organisation'

export default {
  name: 'organisationSummary',
  components: {
    Organisation
  },
  props: {
    organisations: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: 'Organisations'
    }
  }
}
</script>
