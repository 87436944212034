<template>
  <span>
    <router-link v-if="getProfileLink && !suggestSearchLink"
      :to="getProfileLink"
      v-html="getOriginalName"
      v-tooltip="getName"
      :class="classObject">
    </router-link>
    <router-link v-else-if="suggestSearchLink"
      :to="{ name: 'searchHome', query: { keyword: getSearchLink, suggestions: true }}"
    >
        <i v-if="showSuggestIcon" class="fa fa-search pr-2 t-xs"></i>
        <span v-html="getOriginalName" v-tooltip="getName"></span>
    </router-link>
    <span v-else v-html="getOriginalName" v-tooltip="getName" :class="classObject"></span>
  </span>
</template>

<script>
export default {
  name: 'researcherLink',
  props: {
    suggestSearchLink: {
      type: Boolean,
      required: false,
      default: false
    },
    researcher: {
      type: Object,
      required: true
    },
    classObject: {
      type: String,
      required: false,
      default: ''
    },
    lineClamp: {
      type: Number,
      required: false,
      default: -1
    },
    showSuggestIcon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getOriginalName () {
      return this.researcher.original_name ? this.researcher.original_name : this.getName
    },
    getName () {
      return this.getFullName(this.researcher)
    },
    getProfileLink () {
      if (this.researcher.slug_values) {
        return {
          name: 'researcher.show',
          params: { id: this.researcher.slug_values[0] },
          query: { fullName: this.stripTags(this.getName) }
        }
      }
      return false
    },
    getSearchLink () {
      return this.stripTags(this.getName)
    }
  }
}
</script>
