<template>
  <div>
    <span @click="collapsed = !collapsed" class="cursor-pointer d-block t-blue t-500">
      <i v-if="!hideIcon" class="fa width--20px" :class="{ 'fa-caret-right': collapsed, 'fa-caret-down': !collapsed }" />
      {{ title }}
    </span>
    <div v-show="!collapsed">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'blockCollapsible',
  props: {
    title: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      collapsed: !this.isOpen
    }
  }
}
</script>
