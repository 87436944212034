const SearchShow = () => import(/* webpackChunkName: "search" */ '@/views/search/Show.vue')
const SearchHome = () => import(/* webpackChunkName: "search" */ '@/views/search/Home.vue')

export default [
  {
    path: '/search',
    alias: '/dashboard',
    name: 'searchHome',
    meta: {
      auth: false,
      skipMixPanel: true,
      searchContainer: true,
      isGlobalSearch: true,
      render: {
        title: 'Search'
      }
    },
    component: SearchHome
  },
  {
    path: '/landscape/:category?/:buckets(.*)*',
    name: 'searchLandscape',
    meta: {
      auth: false,
      skipMixPanel: true,
      searchContainer: true,
      isGlobalSearch: true,
      isLandscape: true,
      render: {
        title: 'Search'
      }
    },
    component: SearchShow
  },
  {
    path: '/search/:category?/:buckets(.*)*',
    name: 'search',
    meta: {
      auth: false,
      skipMixPanel: true,
      searchContainer: true,
      isGlobalSearch: true,
      render: {
        title: 'Search'
      }
    },
    component: SearchShow
  }
]
