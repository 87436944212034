<template>
  <div class="v-spinner" :class="containerClass">
    <div class="v-pulse v-pulse1" :class="bgColor">
    </div><div class="v-pulse v-pulse2">
    </div><div class="v-pulse v-pulse3">
    </div>
  </div>
</template>

<script>
export default {
  name: 'spinner',
  props: {
    containerClass: {
      type: String,
      required: false,
      default: ''
    },
    bgColor: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
