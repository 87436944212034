<template>
  <div>
    <!-- <div class="app-warning">
      <p class="mb-0">
        We are upgrading to a new exciting version. You may experience some hiccups today.
      </p>
    </div> -->

    <!-- render header -->
    <!-- <app-header :isSearchBar="isSearchBar" class="app-warning-header"/> -->
    <app-header :isSearchBar="isSearchBar"/>

    <!-- render body -->
    <!-- <router-view class="app-warning-body" /> -->
    <router-view />

    <!-- render footer -->
    <app-footer />

  </div>
</template>

<script>
import AppHeader from '@/components/shared/header/AppHeader'
import AppFooter from '@/components/shared/footer/AppFooter'

export default {
  name: 'appBody',
  components: {
    AppHeader,
    AppFooter
  },
  props: {
    isSearchBar: {
      type: Boolean,
      default: false
    }
  }
}
</script>
