const Base = () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/Base.vue')
// const Intro = () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/Intro.vue')
const FunctionTitle = () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/FunctionTitle.vue')
const Organisation = () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/Organisation.vue')
const Interests = () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/Interests.vue')
const CollaborationInterests = () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/CollaborationInterests.vue')
// const About = () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/About.vue')
// const Research = () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/Research.vue')
// const Experience = () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/Experience.vue')
// const Social = () => import(/* webpackChunkName: "onboarding" */ '@/views/onboarding/Social.vue')

export default [
  {
    path: '/onboarding',
    name: 'onboarding',
    redirect: 'onboarding.intro',
    meta: {
      auth: true
    },
    component: Base,
    children: [
      {
        path: 'intro',
        name: 'onboarding.intro',
        meta: {
          auth: true,
          render: {
            title: 'Welcome',
            searchBar: false,
            step: 1
          }
        },
        component: Organisation
      }, {
        path: 'function-title',
        name: 'onboarding.function',
        meta: {
          auth: true,
          render: {
            title: 'Function Title',
            searchBar: false,
            step: 2
          }
        },
        component: FunctionTitle
      }, {
        path: 'interests',
        name: 'onboarding.interests',
        meta: {
          auth: true,
          render: {
            title: 'Research interest',
            searchBar: false,
            step: 3
          }
        },
        component: Interests
      }, {
        path: 'collaboration-interests',
        name: 'onboarding.collaboration',
        meta: {
          auth: true,
          render: {
            title: 'Collaboration interest',
            searchBar: false,
            step: 4
          }
        },
        component: CollaborationInterests
      }
    ]
  }
]
