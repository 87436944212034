<template>
  <section>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-top">
        <dropdown
          class="mr-3"
          placement="bottom-end"
          noPadding
        >
          <template #button>
            <label class="select-label--small width--120px">
              <button class="form-control pr-4 pl-0"
                :disabled="!isLoaded"
                :value="currentTrend"
              >{{ currentTrend | humanize }}
              </button>
            </label>
          </template>
          <a v-for="trendCategory in possibleTrendCategories"
            :key="trendCategory"
            href="#"
            @click.prevent="fetchTrends(trendCategory)"
            class="bg-grey-hover d-block py-2 px-3 t-primary d-flex"
          >
            <i class="fa fa-check t-secondary mr-2 mt-1" :class="{ 'invisible': currentTrend !== trendCategory }" />
            <span class="t-600 d-md-flex align-items-center">
              <span>{{ trendCategory | humanize }}</span>
            </span>
          </a>
        </dropdown>
        <div>
          <h2 class="t-500 mb-0">
            Trends {{ state.keyword ? `for ${state.keyword}` : '' }} in {{ getIndexAsString(state.index) }}
            <span
                v-if="activeFilters.length > 0"
                v-tooltip="activeFilters.join(', ')"
            > - {{ activeFilters.length }} {{ 'filter' | pluralize(activeFilters.length) }}</span>
            <span
              v-if="state.exclude.length > 0"
              v-tooltip="state.exclude.join(', ')"
            > - {{ state.exclude.length }} {{ 'exclude' | pluralize(state.exclude.length) }}</span>
          </h2>
          <span>{{ subTitle }}</span>
        </div>
      </div>
    </div>
    <hr class="hr-small mt-4 mb-3">
    <spinner v-if="!isLoaded" :containerClass="`width--50px min-height--150px pt-4`" />

    <div class="row small-gutters" v-if="isLoaded">
      <div class="col-12 col-xs-6 col-lg-4 col-xl-3"
        v-for="trend in trends[currentTrend]"
        :key="trend.name"
      >
        <trend
          :state="state"
          :trend="trend"
        />
      </div>
      <div class="col-12 d-flex justify-content-end align-items-center mt-4">
        <button class="btn btn-primary px-3" @click="$events.$emit('modal:close')" type="button">Close</button>
      </div>
    </div>

  </section>
</template>

<script>
import Trend from '@/components/shared/chart/Trend'
import Spinner from '@/components/shared/Spinner'
import Dropdown from '@/components/shared/form/Dropdown'

import SearchService from '@/services/search'

export default {
  name: 'trendsModal',
  components: {
    Spinner,
    Dropdown,
    Trend
  },
  props: {
    filter: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
    state: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoaded: false,
      trends: {},
      currentTrend: ''
    }
  },
  mounted () {
    this.currentTrend = this.filter
    this.fetchTrends()
  },
  computed: {
    possibleTrendCategories () {
      const arr = []
      Object.entries(this.state.filters).forEach(([key, value]) => {
        if (value.trend && value.trend === this.category) {
          arr.push(key)
        }
      })
      return arr
    },
    activeFilters () {
      const filters = this.state.filters
      if (this.isEmpty(filters)) return []

      return this.getActiveFilters(filters)
    },
    subTitle () {
      const index = this.state.index
      const trendType = this.$options.filters.singularize(this.$options.filters.humanize(this.currentTrend))
      switch (index) {
        case 'publications':
          return `Based on all Publications that match your search, and contain ${trendType} information.`
        case 'research_groups':
          return `Based on all Research Groups that match your search, and have done research on a ${trendType}.`
        case 'researchers':
          return `Based on all Researchers that match your search, and have done research on a ${trendType}.`
        case 'organisations':
          return `Based on all Companies that match your search, and have done research on a ${trendType}.`
        default:
          return ''
      }
    }
  },
  methods: {
    fetchTrends (trendCategory) {
      if (trendCategory) this.currentTrend = trendCategory
      if (this.trends[this.currentTrend]) return
      this.isLoaded = false

      SearchService.getTrendsData({
        ...this.state,
        trend: this.currentTrend
      }).then(response => {
        this.isLoaded = true
        this.trends[this.currentTrend] = response
      })
    }
  }
}
</script>
