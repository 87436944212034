<template>
  <li class="nav-item dropdown d-none d-sm-block">
    <dropdown
      placement="bottom-end"
      noPadding
    >
      <template #button>
        <a href="#" @click.prevent class="nav-link-button" aria-haspopup="true" aria-expanded="false"
          v-tooltip="'Get started with use case videos'">
          <i class="fa fa-video-camera" />
          <span id="get-started-dropdown" class="ml-2 d-none d-lg-inline-block t-500">Tutorials</span>
        </a>
      </template>
      <div class="dropdown-menu-custom box-shadow" aria-labelledby="get-started-dropdown">
        <div class="dropdown-item d-flex align-items-center pb-2">
          <span class="dropdown-item_text t-600 t-primary t-overflow d-flex justify-content-between">
            Get started with these use case videos
          </span>
        </div>
        <a
          :href="link1"
          :target="acceptedCookies ? '' : '_blank'"
          class="dropdown-item clearfix mb-1"
          @click="startTour('327204')"
        >
          <span class="dropdown-item_icon pull-left">
            <i class="fa fa-video-camera t-secondary pt-1 pr-3 t-sm" />
          </span>
          <span class="dropdown-item_text pull-left">
            Rising stars<br />
            Companies<br />
            Hotspot analysis<br />
            Highest-funded ongoing projects
          </span>
        </a>
        <a
          :href="link2"
          :target="acceptedCookies ? '' : '_blank'"
          class="dropdown-item clearfix mb-1"
          @click="startTour('329495')"
        >
          <span class="dropdown-item_icon pull-left">
            <i class="fa fa-video-camera t-secondary pt-1 pr-3 t-sm" />
          </span>
          <span class="dropdown-item_text pull-left">
            Most-cited review papers<br />
            Actionable R&D insights<br />
            Patent analysis<br />
            Ongoing research
          </span>
        </a>
        <a
          :href="link3"
          :target="acceptedCookies ? '' : '_blank'"
          class="dropdown-item clearfix"
          @click="startTour('329496')"
        >
          <span class="dropdown-item_icon pull-left">
            <i class="fa fa-video-camera t-secondary pt-1 pr-3 t-sm" />
          </span>
          <span class="dropdown-item_text pull-left">
            Academic labs worldwide<br />
            Connecting with group leaders<br />
            Exporting results<br />
            Customisable alerts
          </span>
        </a>
      </div>
    </dropdown>
  </li>
</template>

<script>
import Dropdown from '@/components/shared/form/Dropdown'

export default {
  name: 'headerGetStartedDropdown',
  components: {
    Dropdown
  },
  data () {
    return {
      acceptedCookies: false
    }
  },
  methods: {
    startTour (id) {
      this.$intercom.startTour(id)
    }
  },
  mounted () {
    // Check for logged in user
    if (this.$currentUser) {
      this.acceptedCookies = !!this.$currentUser.cookie_analytics
    } else {
      // Check for guests
      const settings = this.$ls.getCookieSettings()
      if (settings && settings.cookie_analytics) {
        this.acceptedCookies = true
      }
    }
    // Track event in  case settings get updated
    this.$events.$on('cookies:updated', settings => {
      if (settings && settings.cookie_analytics) {
        this.acceptedCookies = true
      } else {
        this.acceptedCookies = false
      }
    })
  },
  destroyed () {
    this.$events.$off('cookies:updated')
  },
  computed: {
    link1 () {
      if (this.acceptedCookies) return '#'
      return 'https://youtube.com/playlist?list=PLWkBzvjDToJ2KB51hCyAnlBP2ZkkNOewD&si=VJpxNdhjpUV6ED3h'
    },
    link2 () {
      if (this.acceptedCookies) return '#'
      return 'https://youtube.com/playlist?list=PLWkBzvjDToJ0MaqnB1tpzBj5Ah9oL-Nz5&si=9OFaBpGUB_WEX3Pc'
    },
    link3 () {
      if (this.acceptedCookies) return '#'
      return 'https://youtube.com/playlist?list=PLWkBzvjDToJ1mD95C8w0oc4ZPz-qvai9q&si=3BotFVnTB-7ZqC-U'
    }
  }
}
</script>
