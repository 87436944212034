import axios from 'axios'
import axiosRetry from 'axios-retry'

import router from '@/router'
import AuthService from '@/services/auth'
import Sentry from '@/plugins/sentry'

let httpDefault, httpSkip404, httpCancelRequest, httpSkip500, httpSilentError
let isRetryRequest = false

export default {
  install (Vue) {
    axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_VERSION}`
    if (Vue.prototype.$ls.getToken()) axios.defaults.headers.common.Authorization = Vue.prototype.$ls.getToken()

    httpDefault = axios.create()
    axiosRetry(httpDefault, { retries: 3 })
    httpDefault.interceptors.request.use(this.requestInterceptor(Vue, httpDefault))
    httpDefault.interceptors.response.use(this.responseInterceptor(Vue, httpDefault, [401, 403, 404, 429, 500]))

    httpSkip404 = axios.create()
    axiosRetry(httpSkip404, { retries: 3 })
    httpSkip404.interceptors.request.use(this.requestInterceptor(Vue, httpSkip404))
    httpSkip404.interceptors.response.use(this.responseInterceptor(Vue, httpSkip404, [401, 403, 429, 500]))

    httpSkip500 = axios.create()
    axiosRetry(httpSkip500, { retries: 3 })
    httpSkip500.interceptors.request.use(this.requestInterceptor(Vue, httpSkip500))
    httpSkip500.interceptors.response.use(this.responseInterceptor(Vue, httpSkip500, [401, 403, 429, 404]))

    httpSilentError = axios.create()
    axiosRetry(httpSilentError, { retries: 3 })
    httpSilentError.interceptors.request.use(this.requestInterceptor(Vue, httpSilentError))
    httpSilentError.interceptors.response.use(this.responseInterceptor(Vue, httpSilentError, []))

    httpCancelRequest = axios.create()
    axiosRetry(httpCancelRequest, { retries: 3 })
    httpCancelRequest.interceptors.request.use(this.requestInterceptor(Vue, httpCancelRequest))

    Vue.prototype.$http = httpDefault
    Vue.prototype.$httpSkip404 = httpSkip404
    Vue.prototype.$httpSkip500 = httpSkip500
    Vue.prototype.$httpSilentError = httpSilentError
    Vue.prototype.$httpCancelRequest = httpCancelRequest

    Vue.prototype.$axios = {
      updateToken (token) {
        axios.defaults.headers.common.Authorization = token
        Vue.prototype.$http.defaults.headers.common['Authorization'] = token
        Vue.prototype.$httpSkip404.defaults.headers.common['Authorization'] = token
        Vue.prototype.$httpSkip500.defaults.headers.common['Authorization'] = token
        Vue.prototype.$httpSilentError.defaults.headers.common['Authorization'] = token
        Vue.prototype.$httpCancelRequest.defaults.headers.common['Authorization'] = token
      }
    }
  },
  requestInterceptor (Vue, http) {
    http.interceptors.request.use(async (config) => {
      if (Vue.prototype.$auth.decodeToken()) {
        if (!isRetryRequest && !Vue.prototype.$auth.isValidToken()) {
          Vue.prototype.$auth.logout()
          return config
        } else if (!isRetryRequest && Math.round(new Date() / 1000) > Vue.prototype.$auth.decodeToken().iat + (60 * 60)) {
          isRetryRequest = true
          const token = await new Promise((resolve, reject) => {
            AuthService.refreshToken().then((auth) => {
              resolve(auth.token)
            }).catch((error) => {
              Vue.prototype.$auth.logout()
              reject(error)
            })
          })

          config.headers.authorization = token
          Vue.prototype.$auth.setToken(token)
          isRetryRequest = false
        }
      }

      return config
    }, (error) => {
      return Promise.reject(error)
    })
  },
  responseInterceptor (Vue, http, codes) {
    http.interceptors.response.use((response) => {
      return response
    }, (error) => {
      if (error.response) {
        // Expired session
        if (error.response.status === 498) {
          Vue.prototype.$auth.logout()
          throw new axios.Cancel(true)
        }

        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (codes.includes(error.response.status)) {
          router.push({ name: `${error.response.status}` })
          throw new axios.Cancel(true)
        } else {
          // It's possible that we don't want to crash the page on errors.
          // This ensures that we still capture the error in Sentry
          Sentry.captureException(error)
        }

        return Promise.reject(error.response)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // Sentry.captureMessage(new Error('Error in retrieving response. (error.request)'))
        Sentry.captureException(error)
        // router.push({ name: 'login' }).catch(() => {})
      } else {
        // Something happened in setting up the request that triggered an Error
        // Sentry.captureMessage(new Error('Error in retrieving response. (error === nil)'))
        Sentry.captureException(error)
        router.push({ name: 'login' }).catch(() => {})
      }

      return Promise.reject(error)
    })
  }
}
