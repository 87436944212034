
import Vue from 'vue'

const ENDPOINT = '/research_groups'
const FLASK_ENDPOINT = `${process.env.VUE_APP_FLASK_URL}/api/research_groups`

const RESEARCH_TOPIC = '/research_topics'

export default {
  get (id) {
    return Vue.prototype.$http.get(`${FLASK_ENDPOINT}/${id}`).then(response => response.data)
  },
  getTooltipData (slugText) {
    return Vue.prototype.$http.get(`${process.env.VUE_APP_FLASK_URL}/api/pop_ups/research_group/${slugText}`).then(response => response.data)
  },
  update (id, researchGroup) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}`, { research_group: researchGroup }).then(response => response.data)
  },
  create (researchGroup) {
    return Vue.prototype.$http.post(`${ENDPOINT}`, { research_group: researchGroup }).then(response => response.data)
  },
  delete (id) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}`).then(response => response.data)
  },
  getByTopicID (id) {
    return Vue.prototype.$httpSkip404.get(`${process.env.VUE_APP_FLASK_URL}/api${RESEARCH_TOPIC}/${id}`).then(response => response.data)
  },
  getMembers (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/members`).then(response => response.data)
  },
  getPendingMembers (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/pending_members`).then(response => response.data)
  },
  updateMember (id, member) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/members/${member.slug}`, { member: member }).then(response => response.data)
  },
  deleteMember (id, member) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/members/${member.slug_values[0]}`).then(response => response.data)
  },
  getCommunities (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/communities`).then(response => response.data)
  },
  leaveCommunity (id, communityId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/communities/${communityId}`).then(response => response.data)
  },
  getInvites (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/invites`).then(response => response.data)
  },
  cancelInvite (id, inviteId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/invites/${inviteId}`).then(response => response.data)
  },
  resendInvite (id, inviteId) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/invites/${inviteId}`).then(response => response.data)
  },
  createInvite (id, invites) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/invites`, { invite: invites }).then(response => response.data)
  },
  getCommunityInvites (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/community_invites`).then(response => response.data)
  },
  acceptCommunityInvite (id, inviteId, invite) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/community_invites/${inviteId}`, { invite: invite }).then(response => response.data)
  },
  declineCommunityInvite (id, inviteId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/community_invites/${inviteId}`).then(response => response.data)
  },
  getInviteRequests (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/invite_requests`).then(response => response.data)
  },
  acceptInviteRequest (id, inviteId) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/invite_requests/${inviteId}`).then(response => response.data)
  },
  declineInviteRequest (id, inviteId) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/invite_requests/${inviteId}`).then(response => response.data)
  },
  getResearchTopic (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}/${RESEARCH_TOPIC}`).then(response => response.data)
  },
  createResearchTopic (id, topic) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}${RESEARCH_TOPIC}`, { research_topic: topic }).then(response => response.data)
  },
  updateResearchTopic (id, topic) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}${RESEARCH_TOPIC}/${topic.id}`, { research_topic: topic }).then(response => response.data)
  },
  removeResearchTopic (id, topic) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}${RESEARCH_TOPIC}/${topic.id}`).then(response => response.data)
  },
  getContact (slug) {
    return Vue.prototype.$httpSilentError.get(`${ENDPOINT}/${slug}/contacts`).then(response => response.data)
  },
  updateSector (id, sector) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/sectors`, { sector: sector }).then(response => response.data)
  },
  updateTherapeutic (id, therapeutic) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/therapeutics`, { therapeutic: therapeutic }).then(response => response.data)
  },
  updateMedicalTechnology (id, medicalTechnologies) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/medical_technologies`, { medical_technology: medicalTechnologies }).then(response => response.data)
  },
  addDisease (id, idDisease) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/interests/${idDisease}?type=disease`).then(response => response.data)
  },
  createDisease (id, disease) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/interests?type=disease`, { interest: disease }).then(response => response.data)
  },
  removeDisease (id, idDisease) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/interests/${idDisease}?type=disease`).then(response => response.data)
  },
  addModelOrganism (id, idModelOrganism) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/interests/${idModelOrganism}?type=model_organism`).then(response => response.data)
  },
  createModelOrganism (id, modelOrganism) {
    return Vue.prototype.$http.post(`${ENDPOINT}/${id}/interests?type=model_organism`, { interest: modelOrganism }).then(response => response.data)
  },
  removeModelOrganism (id, idModelOrganism) {
    return Vue.prototype.$http.delete(`${ENDPOINT}/${id}/interests/${idModelOrganism}?type=model_organism`).then(response => response.data)
  },
  updateResearchType (id, researchType) {
    return Vue.prototype.$http.put(`${ENDPOINT}/${id}/research_types`, { research_type: researchType }).then(response => response.data)
  },
  getOpportunities (id, limit) {
    let endpoint = `${ENDPOINT}/${id}/opportunities`
    if (limit) endpoint += `?limit=${limit}`
    return Vue.prototype.$http.get(endpoint).then(response => response.data)
  }
}
