<template>
  <div @click.stop.prevent class="bookmark-trigger">
    <dropdown
      :placement="'top-end'"
      :offset="-36"
      :hasArrow="false"
      :attachedToBody="attachedToBody"
      :extraBaseClass="'bookmark-dd'"
    >
      <template #button>
        <template v-if="isBulkSave">
          <a href="#"
            @click.prevent="fetchBookmarkFolders"
            v-tooltip="getTooltip"
            ref="icobutton"
            class="btn btn-sm btn-primary position-relative px-3 btn-bookmark"
            aria-haspopup="true"
            :class="{ disabled: isSaving }"
          >
            <template v-if="!isSaving">
              <i class="fa fa-bookmark" />
              <span class="px-2 t-600">Save</span>
              <i class="fa fa-angle-down" />
            </template>
            <template v-else>
              <span class="px-2 t-600 dots">Saving</span>
            </template>
          </a>
        </template>

        <template v-else-if="isButton">
          <button
            type="button"
            class="btn width--100per t-600"
            :class="{
              'btn-primary--green': totalTimesAdded > 0,
              'btn-primary': totalTimesAdded <= 0,
              'py-1': !isFullButton
            }"
            @click.prevent="fetchBookmarkFolders"
            v-tooltip="getTooltip"
            ref="icobutton"
            aria-haspopup="true"
          >
            <i class="fa fa-bookmark mr-2" />
            <span v-if="totalTimesAdded > 0">Saved<span class="ml-1 semi-transparent">{{totalTimesAdded}}</span></span>
            <span v-if="totalTimesAdded <= 0">Save</span>
            <i class="ml-2 pt-1 fa fa-angle-down t-sm" />
          </button>
        </template>
      </template>
      <bookmark-items
        :currentItem="parsedCurrentItem"
        :scope="scope"
        :isLoaded="isLoaded"
        :isBulkSave="isBulkSave"
        :state="state"
        :isScopedSearch="isScopedSearch"
        :count="count"
        @added="addedToBookmarkFolder"
        @removed="removedFromBookmarkFolder"
        @startSave="isSaving = true"
        @stopSave="isSaving = false"
      />
    </dropdown>
  </div>
</template>

<script>
import BookmarkItems from '@/components/bookmark_folder/Items'
import Dropdown from '@/components/shared/form/Dropdown'

import { mapActions } from 'vuex'

export default {
  name: 'bookmarkFolderShow',
  components: {
    BookmarkItems,
    Dropdown
  },
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    scope: {
      type: String,
      default: ''
    },
    isFromSearch: {
      type: Boolean,
      default: false
    },
    isButton: {
      type: Boolean,
      default: false
    },
    isBulkSave: {
      type: Boolean,
      default: false
    },
    isFullButton: {
      type: Boolean,
      default: false
    },
    state: {
      type: Object,
      required: false
    },
    isScopedSearch: {
      type: Boolean,
      required: false,
      default: false
    },
    attachedToBody: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      required: false,
      default: 0
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      totalTimesAdded: 0,
      isLoaded: false,
      isSaving: false
    }
  },
  mounted () {
    this.getTotalTimesInBookmarkFolder()
  },
  watch: {
    'currentItem': {
      handler (value) {
        this.getTotalTimesInBookmarkFolder()
      },
      deep: true
    }
  },
  computed: {
    parsedCurrentItem () {
      if (this.currentItem && !this.currentItem.bookmark_folders) return { ...this.currentItem, bookmark_folders: [] }
      return this.currentItem
    },
    getTooltip () {
      if (this.tooltip) return this.tooltip
      if (this.scope !== 'research_groups' || this.isFromSearch) {
        if (this.totalTimesAdded === 0) {
          return 'Save to bookmarks'
        } else {
          return 'Save or remove bookmark'
        }
      }
      return ''
    }
  },
  methods: {
    ...mapActions('bookmarks', [
      'getBookmarkFolders'
    ]),
    fetchBookmarkFolders () {
      this.isLoaded = false
      this.getBookmarkFolders({ userId: this.$currentUser.user_id }).then(() => {
        this.isLoaded = true
      }, err => {
        this.$flash({ message: this.renderError(err) }, 'warning')
        this.isLoaded = true
      })
    },
    addedToBookmarkFolder () {
      this.totalTimesAdded += 1
    },
    removedFromBookmarkFolder () {
      this.totalTimesAdded -= 1
    },
    getTotalTimesInBookmarkFolder () {
      let count = 0
      if (!this.currentItem) return
      if (!this.isEmpty(this.currentItem.bookmark_folders) && !this.isEmpty(this.$currentUser)) {
        this.currentItem.bookmark_folders.map(item => {
          if (this.$currentUser.user_folder_ids.includes(item)) count += 1
        })
      }
      this.totalTimesAdded = count
    }
  }
}
</script>
