import Vue from 'vue'

const ENDPOINT = '/disciplines'

export default {
  get () {
    return Vue.prototype.$http.get(`${ENDPOINT}`).then(response => response.data)
  },
  getByID (id) {
    return Vue.prototype.$http.get(`${ENDPOINT}/${id}`).then(response => response.data)
  }
}
