<template>
  <div class="">
    <h2 class="t-primary t-400 mb-0" v-if="!isEditMode">Create research group</h2>
    <h2 class="t-primary t-400 mb-0" v-if="isEditMode">Edit {{ researchGroup.name }}</h2>
    <hr class="hr-small mb-4" />

    <!-- render error messages -->
    <static-messages v-if="error" :messages="error" :variant="`danger`" @closed="error = null"/>

    <!-- render form edit/create -->
    <form v-if="isResearchGroupTypesLoaded && isCommunitiesLoaded && isFTEsLoaded" @submit.prevent="validateBeforeSubmit" autocomplete="off">
      <!-- name -->
      <div class="form-group mb-4" :class="{'has-icon-label': errors.has('create.name')}">
        <label class="t-500 t-sm label-required">Name</label>
        <input
          type="text"
          class="form-control"
          placeholder="Name"
          name="create.name"
          v-model="create.name"
          v-validate="'required|min:3'"
          data-vv-delay="1000"
          data-vv-as="name"
          :class="{'is-invalid': errors.has('create.name')}"
        />

        <i v-show="errors.has('create.name')" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback " v-show="errors.has('create.name')">{{ errors.first('create.name') }}</span>
      </div>

      <!-- research group types -->
      <div class="form-group mb-3" :class="{'has-custom-invalid': errorResearchGroupType}">
        <label class="t-500 t-sm label-required">
          Research group types
          <i class='fa fa-info-circle ml-1 t-sm t-secondary' v-tooltip.top="`You can choose multiple types`"></i>
        </label>

        <div v-for="(researchGroupType, index) in researchGroupTypes" :key="index" :class="{'checkbox-wrapper is-invalid': errorResearchGroupType}">
          <checkbox v-model="researchGroupType.checked"
            :text="researchGroupType.name"
            :name="researchGroupType.name"
            v-tooltip.top="researchGroupType.description">
          </checkbox>
        </div>

        <span v-show="errorResearchGroupType" class="invalid-feedback clearfix">
          <i class="fa fa-warning t-secondary pull-left pt-1 pr-2"></i>
          <span class="pull-left">{{ errorResearchGroupType }}</span>
        </span>
      </div>

      <!-- add research group to community -->
      <research-group-to-community v-if="isCommunitiesLoaded && !isEditMode && !isEmpty(this.$currentUser.communities)"
        :communities="communities"
        :researchGroup="researchGroup"
        @selected="handleCommunitySelected"
        class="mb-4">
      </research-group-to-community>

      <div class="row mb-4">
        <div class="form-group mb-0 col-md-8" :class="{'has-icon-inline-label': !isEmpty(errorCountry)}">
          <label class="t-500 t-sm label-required">Country</label>

          <typeahead :remote="`country`"
            :name="`country`"
            :value="create.country_name"
            :displayKey="`name`"
            :limit="5"
            :placeholder="`Country`"
            :customClass="`mt-0`"
            :emitOnBlur="true"
            :class="{'is-invalid': !isEmpty(errorCountry)}"
            @selected="handleTypeaheadCountry"
            @blurred="handleBlurTypeahead"
            @opened="$emit('opened')"
            @closed="$emit('closed')"
          ></typeahead>

          <i v-show="!isEmpty(errorCountry)" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="!isEmpty(errorCountry)">{{ errorCountry }}</span>
        </div>

        <div class="form-group mb-0 col-md-4 position-relative">
          <label class="t-500 t-sm">
            FTE's
          </label>
          <label class="select-label">
            <select class="form-control" v-model="create.fte_id">
              <option value="" selected disabled hidden class="t-secondary">Choose</option>
              <option v-for="(fte, index) in ftes" :key="index" :value="fte.id" class="t-sm">
                {{ fte.name }}
              </option>
            </select>
          </label>
        </div>
      </div>

      <div class="row mb-4">
        <!-- website -->
        <div class="form-group mb-0 col-md-8" :class="{'has-icon-inline-label': errors.has('create.website')}">
          <label class="t-500 t-sm">Website</label>
          <input
            type="text"
            class="form-control"
            placeholder="Website"
            name="create.website"
            v-model="create.website"
            v-validate="'url:require_protocol'"
            data-vv-delay="1000"
            data-vv-as="website"
            :class="{'is-invalid': errors.has('create.website')}"
          />

          <i v-show="errors.has('create.website')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.website')">{{ errors.first('create.website') }}</span>
        </div>

        <!-- foundation year -->
        <div class="form-group mb-0 col-md-4" :class="{'has-icon-inline-label': errors.has('create.foundation_year')}">
          <label class="t-500 t-sm">Foundation year</label>
          <input
            type="text"
            class="form-control"
            placeholder="e.g 2014"
            name="create.foundation_year"
            v-model="create.foundation_year"
            v-validate="'digits:4|year'"
            data-vv-delay="1000"
            data-vv-as="foundation year"
            :class="{'is-invalid': errors.has('create.foundation_year')}"
          />

          <i v-show="errors.has('create.foundation_year')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.foundation_year')">{{ errors.first('create.foundation_year') }}</span>
        </div>
      </div>

      <!-- street -->
      <div class="form-group mb-4" :class="{'has-icon-label': errors.has('create.street')}">
        <label class="t-500 t-sm">Street & Nr.</label>
        <input
          type="text"
          class="form-control"
          placeholder="Street"
          name="create.street"
          v-model="create.street"
          :class="{'is-invalid': errors.has('create.street')}"
        />

        <i v-show="errors.has('create.street')" class="fa fa-warning t-secondary"></i>
        <span class="invalid-feedback" v-show="errors.has('create.street')">{{ errors.first('create.street') }}</span>
      </div>

      <div class="row">
        <!-- city -->
        <div class="form-group col-md-6" :class="{'has-icon-label': errors.has('create.city')}">
          <label class="t-500 t-sm">City</label>
          <input
            type="text"
            class="form-control"
            placeholder="City"
            name="create.city"
            v-model="create.city"
            :class="{'is-invalid': errors.has('create.city')}"
          />

          <i v-show="errors.has('create.city')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.city')">{{ errors.first('create.city') }}</span>
        </div>

        <!-- zip -->
        <div class="form-group col-md-6" :class="{'has-icon-label': errors.has('create.post_code')}">
          <label class="t-500 t-sm">Postal code</label>
          <input
            type="text"
            class="form-control"
            placeholder="Postal code"
            name="create.post_code"
            v-model="create.post_code"
            :class="{'is-invalid': errors.has('create.post_code')}"
          />

          <i v-show="errors.has('create.post_code')" class="fa fa-warning t-secondary"></i>
          <span class="invalid-feedback" v-show="errors.has('create.post_code')">{{ errors.first('create.post_code') }}</span>
        </div>
      </div>

      <!-- action buttons -->
      <form-buttons
        :isCreateMode="isCreateMode"
        :isEditMode="isEditMode"
        :isRequest="isRequest"
        :createText="'Create'"
        @cancel="handleCancel()">
      </form-buttons>
    </form>

    <spinner v-else :containerClass="`width--50px min-height--150px`" />
  </div>
</template>

<script>
import StaticMessages from '@/components/shared/StaticMessages'
import FormButtons from '@/components/shared/form/Buttons'
import Checkbox from '@/components/shared/form/Checkbox'
import Typeahead from '@/components/shared/form/Typeahead'
import Spinner from '@/components/shared/Spinner'
import ResearchGroupToCommunity from '@/components/invite/button/ResearchGroupToCommunityCreate'

import ResearchTypeService from '@/services/research_type'
import ResearcherService from '@/services/researcher'
import ResearchGroupService from '@/services/research_group'
import FTEService from '@/services/fte'

export default {
  name: 'researchGroupCreate',
  components: {
    StaticMessages,
    FormButtons,
    Checkbox,
    Typeahead,
    Spinner,
    ResearchGroupToCommunity
  },
  props: {
    researchGroup: {
      type: Object,
      required: true
    },
    isProfessional: {
      type: Boolean,
      default: false
    },
    isAcademic: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      error: null,
      errorResearchGroupType: '',
      errorCountry: '',
      isEditMode: false,
      isCreateMode: false,
      isRequest: false,
      isCommunitiesLoaded: false,
      isResearchGroupTypesLoaded: false,
      isFTEsLoaded: false,
      researchGroupTypes: [],
      communities: [],
      ftes: [],
      create: {
        category: '',
        name: '',
        website: '',
        research_group_types: [],
        country_id: '',
        country_name: '',
        fte_id: '',
        street: '',
        post_code: '',
        city: '',
        foundation_year: '',
        community_ids: []
      }
    }
  },
  created () {
    this.isProfessional && this.isEmpty(this.researchGroup) ? this.create.category = 'professional' : this.create.category = 'academic'
    if (!this.isEmpty(this.researchGroup)) this.create.category = this.researchGroup.category
    this.fetchResearchGroupTypes()
    this.fetchFTEs()
  },
  mounted () {
    !this.isEmpty(this.researchGroup) ? this.preFill() : this.isCreateMode = true
    this.isCreateMode ? this.fetchCommunities() : this.isCommunitiesLoaded = true
  },
  metaInfo () {
    return {
      titleTemplate: `Research group create | AcademicLabs`,
      meta: [
        { vmid: 'description', name: 'description', content: `Create a research group` }
      ]
    }
  },
  watch: {
    'create.country_name' (value) {
      this.isEmpty(value) ? this.errorCountry = 'The country field is required' : this.errorCountry = ''
    },
    researchGroupTypes: {
      handler (newVal, oldVal) {
        if (!this.isEmpty(oldVal)) {
          let ids = []
          for (let key of newVal) if (key.checked) ids.push(key.id)
          this.isEmpty(ids) ? this.errorResearchGroupType = 'The research group type field is required' : this.errorResearchGroupType = ''
        }
      },
      deep: true
    }
  },
  methods: {
    preFill () {
      this.create.category = this.researchGroup.category
      this.create.name = this.researchGroup.name
      this.create.website = this.researchGroup.website

      if (!this.isEmpty(this.researchGroup.country)) {
        this.create.country_id = this.researchGroup.country.id
        this.create.country_name = this.researchGroup.country.name
      }

      this.create.fte_id = this.researchGroup.fte_id
      this.create.street = this.researchGroup.street
      this.create.post_code = this.researchGroup.post_code
      this.create.city = this.researchGroup.city
      this.create.research_group_types = this.researchGroup.research_group_types
      this.create.slug_values = this.researchGroup.slug_values
      this.create.foundation_year = this.researchGroup.foundation_year

      this.isEditMode = true
    },
    fetchResearchGroupTypes () {
      ResearchTypeService.getForResearchGroup(this.create.category).then(categories => {
        this.researchGroupTypes = categories

        if (this.isEditMode) {
          for (let k of this.researchGroupTypes) {
            (this.create.research_group_types.filter(x => x.id === k.id)[0]) ? k.checked = true : k.checked = false
          }
        }

        this.isResearchGroupTypesLoaded = true
      }).catch(err => {
        this.error = this.handleError(err)
        this.isResearchGroupTypesLoaded = !this.isEmpty(this.error)
      })
    },
    fetchFTEs () {
      FTEService.get().then(ftes => {
        this.ftes = ftes
        this.isFTEsLoaded = true

        if (this.researchGroup.fte) this.create.fte_id = this.researchGroup.fte.id
      }).catch(err => {
        this.error = this.handleError(err)
        this.isFTEsLoaded = !this.isEmpty(this.error)
      })
    },
    fetchCommunities () {
      ResearcherService.getCommunities(this.$currentUser.slug_values[0]).then(communities => {
        this.communities = communities
        this.isCommunitiesLoaded = true
      }).catch(err => {
        this.error = this.handleError(err)
        this.isCommunitiesLoaded = !this.isEmpty(this.error)
      })
    },
    handleCommunitySelected (community, index) {
      let selected = !this.communities[index].isSelected
      this.$set(this.communities[index], 'isSelected', selected)

      let i = this.create.community_ids.findIndex(x => x === this.communities[index].id)

      i === -1
        ? this.create.community_ids.push(this.communities[index].id)
        : this.create.community_ids.splice(index, 1)
    },
    handleTypeaheadCountry (name, item) {
      this.$set(this.create, 'country_id', item.id)
      this.$set(this.create, 'country_name', item.name)
    },
    handleBlurTypeahead (name, item) {
      if (!item) {
        this.$set(this.create, 'country_id', item.id)
        this.$set(this.create, 'country_name', item.name)
      }
    },
    validateBeforeSubmit () {
      this.$validator.validateAll().then((result) => {
        this.errorResearchGroupType = null

        let ids = []
        for (let key of this.researchGroupTypes) if (key.checked) ids.push(key.id)

        // validate fields
        if (this.isEmpty(ids) || this.isEmpty(this.create.country_name)) {
          result = false

          if (this.isEmpty(ids)) this.errorResearchGroupType = 'The research group type field is required'
          if (this.isEmpty(this.create.country_name)) this.errorCountry = 'The country field is required'
        }

        if (result && !this.isRequest) {
          this.isRequest = true
          this.create.research_group_type_ids = ids
          this.isEditMode ? this.handleEdit() : this.handleCreate()
        }
      })
    },
    handleCreate () {
      ResearchGroupService.create(this.create).then(researchGroup => {
        this.isRequest = false
        this.replaceRoute(researchGroup, true)
      }).catch(err => {
        this.error = this.handleError(err)
        this.isRequest = false
      })
    },
    handleEdit () {
      ResearchGroupService.update(this.create.slug_values[0], this.create).then(researchGroup => {
        this.$events.$emit('research_group:updated:all', researchGroup)
        this.replaceRoute(researchGroup, false)
        this.isRequest = false
      }).catch(err => {
        this.error = this.handleError(err)
        this.isRequest = false
      })
    },
    handleCancel () {
      if (this.isCreateMode) {
        this.$router.push({ name: 'create.research_group.type' })
      } else {
        this.$events.$emit('modal:close')
      }
    },
    replaceRoute (researchGroup, addToUser) {
      if (this.isCreateMode) {
        this.$router.replace({ name: 'research_group.show', params: { id: researchGroup.slug_values[0] } })
      } else {
        this.$events.$emit('modal:close')
      }

      const rg = { name: researchGroup.name, slug_values: researchGroup.slug_values }
      const user = Object.assign({}, this.$currentUser)
      // add research group to $currentUser
      if (addToUser) {
        if (user.research_groups) {
          user.research_groups.push(rg)
        } else {
          user.research_groups = [rg]
        }
        this.$auth.setCurrentUser(user)
      }
    }
  }
}
</script>
